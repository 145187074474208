<mat-accordion displayMode="flat" [multi]="multi" class="adf-metadata-properties">
  <!-- Default Properties -->
  <mat-expansion-panel
    *ngIf="displayDefaultProperties"
    class="adf-content-metadata-panel"
    [expanded]="currentPanel.panelTitle === DefaultPanels.PROPERTIES && currentPanel.expanded"
    (opened)="expandPanel(DefaultPanels.PROPERTIES)"
    (closed)="closePanel(DefaultPanels.PROPERTIES)"
    [attr.data-automation-id]="'adf-metadata-group-properties'"
    hideToggle
    style="padding: 2px"
  >
    <mat-expansion-panel-header
      class="adf-metadata-properties-header"
      [class.adf-metadata-properties-header-expanded]="currentPanel.panelTitle === DefaultPanels.PROPERTIES && currentPanel.expanded"
    >
      <adf-content-metadata-header
        [title]="'CORE.METADATA.BASIC.HEADER'"
        [expanded]="currentPanel.panelTitle === DefaultPanels.PROPERTIES && currentPanel.expanded"
      >
        <button
          *ngIf="!readOnly && !isPanelEditing(DefaultPanels.PROPERTIES)"
          mat-icon-button
          (click)="toggleGroupEditing(DefaultPanels.PROPERTIES, $event)"
          [attr.title]="'CORE.METADATA.ACTIONS.EDIT' | translate"
          [attr.aria-label]="'CORE.METADATA.ACCESSIBILITY.EDIT' | translate"
          data-automation-id="meta-data-general-info-edit"
          class="adf-edit-icon-buttons"
        >
          <mat-icon>mode_edit</mat-icon>
        </button>
        <div *ngIf="isPanelEditing(DefaultPanels.PROPERTIES)" class="adf-metadata-action-buttons">
          <button
            mat-icon-button
            [attr.title]="'CORE.METADATA.ACTIONS.CANCEL' | translate"
            (click)="cancelGroupEditing(DefaultPanels.PROPERTIES, $event)"
            data-automation-id="reset-metadata"
            class="adf-metadata-action-buttons-clear"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <button
            mat-icon-button
            [attr.title]="'CORE.METADATA.ACTIONS.SAVE' | translate"
            (click)="saveChanges($event)"
            color="primary"
            data-automation-id="save-general-info-metadata"
            [disabled]="!hasMetadataChanged"
          >
            <mat-icon>check</mat-icon>
          </button>
        </div>
      </adf-content-metadata-header>
    </mat-expansion-panel-header>

    <adf-card-view
      class="adf-metadata-properties-expansion-panel"
      (keydown)="keyDown($event)"
      [properties]="basicProperties$ | async"
      [editable]="!readOnly && isPanelEditing(DefaultPanels.PROPERTIES)"
      [displayEmpty]="displayEmpty"
      [copyToClipboardAction]="copyToClipboardAction"
      [useChipsForMultiValueProperty]="useChipsForMultiValueProperty"
      [multiValueSeparator]="multiValueSeparator"
    >
    </adf-card-view>
  </mat-expansion-panel>

  <!-- Tags -->
  <ng-container *ngIf="displayTags">
    <mat-expansion-panel
      hideToggle
      [expanded]="currentPanel.panelTitle === DefaultPanels.TAGS && currentPanel.expanded"
      (opened)="expandPanel(DefaultPanels.TAGS)"
      (closed)="closePanel(DefaultPanels.TAGS)"
      class="adf-content-metadata-panel"
      data-automation-id="adf-content-metadata-tags-panel"
    >
      <mat-expansion-panel-header
        class="adf-metadata-properties-header"
        [class.adf-metadata-properties-header-expanded]="currentPanel.panelTitle === DefaultPanels.TAGS && currentPanel.expanded"
      >
        <adf-content-metadata-header
          [title]="'METADATA.BASIC.TAGS'"
          [expanded]="currentPanel.panelTitle === DefaultPanels.TAGS && currentPanel.expanded"
        >
          <button
            *ngIf="!readOnly && !isPanelEditing(DefaultPanels.TAGS)"
            mat-icon-button
            (click)="toggleGroupEditing(DefaultPanels.TAGS, $event)"
            [attr.title]="'CORE.METADATA.ACTIONS.EDIT' | translate"
            [attr.aria-label]="'CORE.METADATA.ACCESSIBILITY.EDIT' | translate"
            data-automation-id="showing-tag-input-button"
            class="adf-edit-icon-buttons"
          >
            <mat-icon>mode_edit</mat-icon>
          </button>
          <div *ngIf="isPanelEditing(DefaultPanels.TAGS)" class="adf-metadata-action-buttons">
            <button
              mat-icon-button
              [attr.title]="'CORE.METADATA.ACTIONS.CANCEL' | translate"
              (click)="cancelGroupEditing(DefaultPanels.TAGS, $event)"
              data-automation-id="reset-tags-metadata"
              class="adf-metadata-action-buttons-clear"
            >
              <mat-icon>clear</mat-icon>
            </button>
            <button
              mat-icon-button
              [attr.title]="'CORE.METADATA.ACTIONS.SAVE' | translate"
              (click)="saveChanges($event)"
              color="primary"
              data-automation-id="save-tags-metadata"
              [disabled]="!hasMetadataChanged"
            >
              <mat-icon>check</mat-icon>
            </button>
          </div>
        </adf-content-metadata-header>
      </mat-expansion-panel-header>
      <div *ngIf="currentPanel.panelTitle === DefaultPanels.TAGS && !editing" class="adf-metadata-properties-tags">
        <span *ngFor="let tag of tags" class="adf-metadata-properties-tag">{{ tag }}</span>
      </div>
      <div *ngIf="showEmptyTagMessage" class="adf-metadata-no-item-added">
        {{ 'METADATA.BASIC.NO_TAGS_ADDED' | translate }}
      </div>
      <adf-tags-creator
        *ngIf="!readOnly && isPanelEditing(DefaultPanels.TAGS)"
        class="adf-metadata-properties-tags"
        [tagNameControlVisible]="editing"
        (tagsChange)="storeTagsToAssign($event)"
        [mode]="tagsCreatorMode"
        [tags]="assignedTags"
        [disabledTagsRemoving]="saving"
      >
      </adf-tags-creator>
    </mat-expansion-panel>
  </ng-container>

  <!-- Categories -->
  <ng-container *ngIf="displayCategories">
    <mat-expansion-panel
      hideToggle
      [expanded]="currentPanel.panelTitle === DefaultPanels.CATEGORIES && currentPanel.expanded"
      (opened)="expandPanel(DefaultPanels.CATEGORIES)"
      (closed)="closePanel(DefaultPanels.CATEGORIES)"
      class="adf-content-metadata-panel"
      data-automation-id="adf-content-metadata-categories-panel"
    >
      <mat-expansion-panel-header
        class="adf-metadata-properties-header"
        [class.adf-metadata-properties-header-expanded]="currentPanel.panelTitle === DefaultPanels.CATEGORIES && currentPanel.expanded"
      >
        <adf-content-metadata-header
          [title]="'CATEGORIES_MANAGEMENT.CATEGORIES_TITLE'"
          [expanded]="currentPanel.panelTitle === DefaultPanels.CATEGORIES && currentPanel.expanded"
        >
          <button
            *ngIf="!readOnly && !isPanelEditing(DefaultPanels.CATEGORIES)"
            mat-icon-button
            (click)="toggleGroupEditing(DefaultPanels.CATEGORIES, $event)"
            [attr.title]="'CORE.METADATA.ACTIONS.EDIT' | translate"
            [attr.aria-label]="'CORE.METADATA.ACCESSIBILITY.EDIT' | translate"
            data-automation-id="meta-data-categories-edit"
            class="adf-categories-button adf-edit-icon-buttons"
          >
            <mat-icon>mode_edit</mat-icon>
          </button>
          <div *ngIf="isPanelEditing(DefaultPanels.CATEGORIES)" class="adf-metadata-action-buttons">
            <button
              mat-icon-button
              [attr.title]="'CORE.METADATA.ACTIONS.CANCEL' | translate"
              (click)="cancelGroupEditing(DefaultPanels.CATEGORIES, $event)"
              data-automation-id="reset-metadata"
              class="adf-metadata-action-buttons-clear"
            >
              <mat-icon>clear</mat-icon>
            </button>
            <button
              mat-icon-button
              [attr.title]="'CORE.METADATA.ACTIONS.SAVE' | translate"
              (click)="saveChanges($event)"
              color="primary"
              data-automation-id="save-categories-metadata"
              [disabled]="!hasMetadataChanged"
            >
              <mat-icon>check</mat-icon>
            </button>
          </div>
        </adf-content-metadata-header>
      </mat-expansion-panel-header>
      <div *ngIf="currentPanel.panelTitle === DefaultPanels.CATEGORIES && !editing">
        <p *ngFor="let category of categories" class="adf-metadata-categories">{{ category.name }}</p>
      </div>
      <div *ngIf="showEmptyCategoryMessage" class="adf-metadata-no-item-added">
        {{ 'CATEGORIES_MANAGEMENT.NO_CATEGORIES_ADDED' | translate }}
      </div>
      <adf-categories-management
        *ngIf="!readOnly && isPanelEditing(DefaultPanels.CATEGORIES)"
        class="adf-metadata-categories-header"
        [categoryNameControlVisible]="editing"
        [disableRemoval]="saving"
        [categories]="categories"
        [managementMode]="categoriesManagementMode"
        [classifiableChanged]="classifiableChanged"
        (categoriesChange)="storeCategoriesToAssign($event)"
      >
      </adf-categories-management>
    </mat-expansion-panel>
  </ng-container>

  <!-- XXXX -->
  <mat-expansion-panel
    *ngFor="let customPanel of customPanels"
    [expanded]="currentPanel.panelTitle === customPanel.panelTitle && currentPanel.expanded"
    (opened)="expandPanel(customPanel.panelTitle)"
    (closed)="closePanel(customPanel.panelTitle)"
    class="adf-content-metadata-panel"
    hideToggle
  >
    <mat-expansion-panel-header
      class="adf-metadata-properties-header"
      [class.adf-metadata-properties-header-expanded]="currentPanel.panelTitle === customPanel.panelTitle && currentPanel.expanded"
    >
      <adf-content-metadata-header
        class="adf-metadata-custom-panel-title"
        [title]="customPanel.panelTitle"
        [expanded]="currentPanel.panelTitle === customPanel.panelTitle && currentPanel.expanded"
      >
      </adf-content-metadata-header>
    </mat-expansion-panel-header>
    <adf-dynamic-component [id]="customPanel.component" [data]="{ node }"></adf-dynamic-component>
  </mat-expansion-panel>

  <!-- Custom Properties -->
  <ng-container *ngIf="groupedProperties$ | async; else loading; let groupedProperties">
    <div *ngFor="let group of groupedProperties; let first = first" class="adf-metadata-grouped-properties-container">
      <mat-expansion-panel
        [attr.data-automation-id]="'adf-metadata-group-' + group.title"
        [expanded]="
          multi
            ? (!displayDefaultProperties && first) || group.expanded
            : (!displayDefaultProperties && first) || (currentPanel.panelTitle === group.title && currentPanel.expanded)
        "
        (opened)="expandPanel(group.title); group.expanded = true"
        (closed)="closePanel(group.title); group.expanded = false"
        class="adf-content-metadata-panel"
        hideToggle
      >
        <!-- ??? -->
        <mat-expansion-panel-header
          class="adf-metadata-properties-header"
          [class.adf-metadata-properties-header-expanded]="currentPanel.panelTitle === group.title && currentPanel.expanded"
        >
          <adf-content-metadata-header [title]="group.title" [expanded]="currentPanel.panelTitle === group.title && currentPanel.expanded">
            <button
              *ngIf="group.editable && !this.readOnly && !isPanelEditing(group.title)"
              mat-icon-button
              [attr.title]="'CORE.METADATA.ACTIONS.EDIT' | translate"
              [attr.aria-label]="'CORE.METADATA.ACCESSIBILITY.EDIT' | translate"
              data-automation-id="meta-data-card-toggle-edit"
              class="adf-edit-icon-buttons"
              (click)="toggleGroupEditing(group.title, $event)"
            >
              <mat-icon>mode_edit</mat-icon>
            </button>
            <div class="adf-metadata-action-buttons" *ngIf="group.editable && isPanelEditing(group.title)">
              <button
                mat-icon-button
                [attr.title]="'CORE.METADATA.ACTIONS.CANCEL' | translate"
                (click)="cancelGroupEditing(group.title, $event)"
                data-automation-id="reset-metadata"
                class="adf-metadata-action-buttons-clear"
              >
                <mat-icon>clear</mat-icon>
              </button>

              <!-- Update Propeties -->
              <button
                mat-icon-button
                [attr.title]="'CORE.METADATA.ACTIONS.SAVE' | translate"
                (click)="saveChanges($event)"
                color="primary"
                data-automation-id="save-metadata"
                [disabled]="!hasMetadataChanged"
              >
                <mat-icon>check</mat-icon>
              </button>
            </div>
          </adf-content-metadata-header>
        </mat-expansion-panel-header>

        <!-- Standard Component -->
        <adf-card-view
          (keydown)="keyDown($event)"
          [properties]="group.properties"
          [editable]="!readOnly && group.editable && isPanelEditing(group.title)"
          [displayEmpty]="displayEmpty"
          [copyToClipboardAction]="copyToClipboardAction"
          [useChipsForMultiValueProperty]="useChipsForMultiValueProperty"
          [multiValueSeparator]="multiValueSeparator"
          [displayLabelForChips]="true"
        >
        </adf-card-view>

        <!-- Custom Component -->
        <div *ngIf="isDataLoaded">
          <div *ngFor="let aa of customType" class="dropdown-wrapper">
            <div *ngIf="checkDataLoaded(group.title, aa.groupTitle, aa.id)">
              <aca-dropdown-list
                [receiveData]="sendData"
                [receiveDefaultData]="defaultValue"
                [dropdownId]="aa.id"
                [label]="aa.inputLabel"
                [placeholder]="aa.inputPlaceholder"
                [enabled]="!editing"
                (selectionChange)="onDropdownSelectionChange($event, aa.id)"
              >
              </aca-dropdown-list>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </ng-container>

  <ng-template #loading>
    <mat-progress-bar mode="indeterminate" [attr.aria-label]="'DATA_LOADING' | translate"> </mat-progress-bar>
  </ng-template>
</mat-accordion>
