import { FormattingService } from './../utils/formatting.service';
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContextActionsDirective, PageComponent, PageLayoutComponent, PaginationDirective, ToolbarComponent } from '@alfresco/aca-shared';

import { CommonModule, DecimalPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent, DocumentListModule, PeopleContentService } from '@alfresco/adf-content-services';
import { DataTableModule, PaginationModule, TemplateModule } from '@alfresco/adf-core';
import { ExtensionsModule, ProfileState } from '@alfresco/adf-extensions';
import { FormControl, FormGroup } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
// import * as moment_ from 'moment';
import { MatDialog } from '@angular/material/dialog';
// const moment = moment_;
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PageLayoutModule } from '@alfresco/aca-shared';

import { TranslateService } from '@ngx-translate/core';
// import { addDays, differenceInDays } from 'date-fns';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
// import { dataTemps } from './datasource';
import {
  GridComponent, PageSettingsModel, ContextMenuService, ResizeService,
  SortService, ContextMenuItemModel, FreezeService, ExcelExportService, ToolbarItems
} from '@syncfusion/ej2-angular-grids';
import { GridModule, PageService, EditService, ToolbarService } from '@syncfusion/ej2-angular-grids';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import { MatCardModule } from '@angular/material/card';

import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';


import { VirtualScrollService } from '@syncfusion/ej2-angular-grids';

import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { CreateContractComponent } from '../create/create-contract.component';


import { CONTRACT_DATA_DOCUMENT } from '../data/temps-data';
// import { MenuEventArgs } from '@syncfusion/ej2-angular-navigations';
import { ContractManagementService } from '../services/contract-management.service';
import { OptionsRoleInterface } from '../data/options-role.interface';
import { ImproveContractComponent } from '../improve/improve-contract.component';
import { MatChipsModule } from '@angular/material/chips';

import { MatButtonModule } from '@angular/material/button';
import { ImproveDocumentComponent } from '../improve-document/improve-document.component';
import { ImproveContractApprovedComponent } from '../improve-approved/improve-contract-approved.component';
import { DataConfigService } from '../services/data-config.service';
import { ContentUrlService } from '../../../services/content-url.service';


import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from '../utils/date-format';
import { SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';

import { ngxCsv } from 'ngx-csv';

// import * as Papa from 'papaparse';

export interface ExcelExportColumn {
  field: string;
  headerText?: string;
  textAlign?: string;
  width?: number;
}


@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DocumentListModule,
    TemplateModule,
    TemplateModule,
    DataTableModule,
    ExtensionsModule,
    ContextActionsDirective,
    PaginationDirective,
    PageLayoutComponent,
    ToolbarComponent,
    PaginationModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    PageLayoutModule,
    MatDatetimepickerModule,
    GridModule,
    MatCardModule,
    DialogModule,
    ButtonModule,
    MatChipsModule,
    MatButtonModule,
    MatNativeDateModule,
  ],
  selector: 'aca-contract',
  templateUrl: './contract-report.component.html',
  styleUrls: ['./contract-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [VirtualScrollService,
    ExcelExportService,
    PageService, EditService,
    ToolbarService, ContextMenuService,
    FormattingService, DecimalPipe,
    FreezeService, ResizeService, SortService,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ]
})
export class ContractReportComponent extends PageComponent implements OnInit {

  public myForm: FormGroup;

  onDestroy$: Subject<boolean> = new Subject<boolean>();
  maxDate: Date;
  user = '';
  method = '';
  auditEntries: Array<{
    userName: string;
    nodeName: string;
    method: string;
    time: string;
    values: string[];
    entry: any;
  }>;
  pagination = {
    hasMoreItems: false,
    totalItems: 0
  };
  displayedColumns: string[] = ['userName', 'nodeName', 'method', 'time', 'values', 'entry'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  user$: Observable<ProfileState>;
  lockMenuActive = true;
  apiURL: any;
  headers: any;
  auth: any;


  public toolbar!: string[];
  public pageSettings?: PageSettingsModel;

  public dataTemp?: object[];
  public dataTempItem?: object[];
  public contextMenuItems?: ContextMenuItemModel[];

  // @ViewChild('grid')
  // public grid: GridComponent;

  @ViewChild('grid') public grid?: GridComponent;


  public toolbarOptions?: ToolbarItems[];

  selectedDocType: string;
  docType: OptionsRoleInterface[] = [];

  selectedDepartment: string;
  department: OptionsRoleInterface[] = [];


  selectedSignatureType: string;
  signatureType: OptionsRoleInterface[] = []

  selectedStatus: string;
  status: OptionsRoleInterface[] = []

  selectedResponsiblePerson: string;
  responsiblePerson: OptionsRoleInterface[] = []

  userId: any;
  userFullName: any;
  userEmail: any;
  groupComplianceActive = false;


  enableCreate = false;


  appNavNarMode$: Observable<'collapsed' | 'expanded'>;

  constructor(
    public dialog: MatDialog,
    public trans: TranslateService,
    private contractManagementService: ContractManagementService,
    private formattingService: FormattingService,
    private configService: DataConfigService,
    private peopleContentService: PeopleContentService,
    private contentUrlService: ContentUrlService,
    private dialogRef: MatDialog,
    // private appService: AppService
  ) {
    super();
    // this.appService.toggleAppNavBar$.next();

    this.myForm = new FormGroup({
      'docType': new FormControl({ value: '', disabled: false }),
      'depositNo': new FormControl({ value: '', disabled: false }),
      'contractName': new FormControl({ value: '', disabled: false }),
      'contractNo': new FormControl({ value: '', disabled: false }),
      'contractPairName': new FormControl({ value: '', disabled: false }),
      'department': new FormControl({ value: 'Sales Channel', disabled: false }),
      'contractValue': new FormControl({ value: '', disabled: false }),
      'contractCollateral': new FormControl({ value: '', disabled: false }),
      'signatureType': new FormControl({ value: '', disabled: false }),
      'status': new FormControl({ value: '', disabled: false }),
      'responsiblePerson': new FormControl({ value: '', disabled: false }),
      'contractEffectiveDate': new FormControl({ value: '', disabled: false }), // date
      'contractEndDate': new FormControl({ value: '', disabled: false }), // date
    });





  }


  async ngOnInit() {


    // GET USER GROUP ALFRESCO
    await this.checkedGroupAndRoleSite();

    // GET EMAIL GROUP COMPLIANCE
    await this.getEmailGroupCompliance();


    // SET OPTIONS
    this.department = await this.configService.getDepartmentOptions();
    this.docType = await this.configService.getDocTypeOptions();
    this.signatureType = await this.configService.getSignatureTypeOptions();
    this.status = await this.configService.getStatusOptions();
    this.responsiblePerson = await this.configService.getResponsiblePersonOptions();
    this.dataTemp = CONTRACT_DATA_DOCUMENT;
    // eslint-disable-next-line @cspell/spellchecker
    this.contextMenuItems = [
      { text: 'มุมมอง', target: '.e-content', id: 'viewContract', iconCss: 'e-icons e-folder-open' },
      { text: 'การปรับปรุงสัญญา', target: '.e-content', id: 'contractUpdate', iconCss: 'e-icons e-changes-accept' },
      { text: 'ดาวน์โหลด', target: '.e-content', id: 'downloadContract', iconCss: 'e-icons e-download' }
    ];
    this.pageSettings = { pageSize: 50 };

    this.toolbarOptions = ['ExcelExport', 'CsvExport'];



    if (!this.groupComplianceActive) {
      // Get Group
      console.log('this.groupUserData ', this.groupUserData);
      console.log('this.department ', this.department);
      const result = this.department.map(dept => {
        const match = this.groupUserData.find(group => {
          const displayName = group.entry?.displayName?.trim().toUpperCase();
          const viewValue = dept.viewValue?.trim().toUpperCase();
          return displayName === viewValue;
        });

        if (match) {
          return {
            ...dept,
            id: match.entry.id,
            displayName: match.entry.displayName
          };
        }
        return null;
      }).filter(item => item !== null);
      this.department = result;
      // Set Default
      this.myForm.patchValue({
        department: result[0].viewValue // ใส่ค่าใหม่ที่ต้องการ set
      });
    }


  }

  getViewValueFromValue(value: string): string {
    const departmentOption = this.department.find(option => option.value === value);
    return departmentOption ? departmentOption.viewValue : '';
  }


  groupUserData: any;
  private async checkedGroupAndRoleSite() {
    this.peopleContentService.getCurrentUserInfo().subscribe(async userInfo => {
      this.userId = userInfo.id;
      this.userFullName = userInfo.firstName + ' ' + (userInfo.lastName ? userInfo.lastName : '');
      this.userEmail = userInfo.email;
      const groupsData = await this.contractManagementService.getGroups(this.userId).toPromise();
      // this.groupUserData = groupsData.list.entries;
      this.groupUserData = await new Promise((resolve) => {
        resolve(groupsData.list.entries);
      });


      const groupCompliance = groupsData.list.entries.find((item) => item.entry.id === 'GROUP_compliance');
      if (await groupCompliance) {
        this.groupComplianceActive = true;
      }

      const siteData = await this.contractManagementService.getMembersInSites('ContractManagement').toPromise();

      const filteredData = siteData.list.entries.filter(entry =>
        (entry.entry.role === 'SiteContributor' || entry.entry.role === 'SiteManager') && entry.entry.id === this.userId
      );


      if (filteredData.length > 0) {
        this.enableCreate = true;
      } else {
        this.enableCreate = false;
        this.alertError('User ไม่มีสิทธิ์ในเมนู Contract Management', null)
      }

    });
  }

  listEmail = [];


  private async getEmailGroupCompliance() {
    try {
      const memberList = await this.contractManagementService.getListMemberFromGroup().toPromise();
      const listUserId = memberList.list.entries.map(entry => entry.entry.id);

      const userInfoPromises = listUserId.map(async (userId) => {
        try {
          const userInfo = await this.peopleContentService.getPerson(userId).toPromise();
          return userInfo.email;
        } catch (error) {
          console.error(`Error fetching user info for userId ${userId}:`, error);
          return null;
        }
      });
      const userEmails = await Promise.all(userInfoPromises);
      // Filter out nulls and remove duplicates by converting to a Set and back to an array
      this.listEmail = Array.from(new Set(userEmails.filter(email => email !== null)));
    } catch (error) {
      console.error('error getEmailGroupCompliance():', error);
    }
  }


  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }

  // =================================== Event Click Grid  ===================================
  openItemsActive = false;
  isContractDoucnetId = '';
  async onRecordClick(args: any) {
    const columnName = args.column.field;

    if (columnName === 'viewContract') {
      this.openItemsActive = true;
      const nodeName = (args.rowData.fileName);
      this.selectedPath = (nodeName).replace('.pdf', '') + ' /.. ';
      this.filterItems(args.rowData.contractDocumentId);
    }

    if (columnName === 'contractUpdate') {
      const contractDocumentId = (args.rowData.contractDocumentId);
      if (contractDocumentId) {
        this.improveDocumentData(contractDocumentId);
      }
    }

    if (columnName === 'downloadContract') {
      const fileName = (args.rowData.fileName);
      const nodeIdLast = (args.rowData.nodeIdLast);
      console.log('nodeIdLast ', nodeIdLast);
      this.contentUrlService.getNodeContentUrl(nodeIdLast, true).subscribe((contentUrl) => {
        this.download(contentUrl, fileName);
      });
    }

    if (columnName === 'deleteContract') {
      const contractDocumentId = (args.rowData.contractDocumentId);
      const contractStatusProcess = (args.rowData.contractStatusProcess);
      if (contractStatusProcess === 'Approved' || contractStatusProcess === 'Draft') {
        this.alertError('ลบได้เฉพาะเอกสารสภานะ Expired เท่านั้น', null)
        return
      }
      const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
        data: {
          title: 'ยืนยัน',
          message: 'คุณต้องการลบเอกสารสัญญาฉบับบนี้หรือไม่',
          yesLabel: 'ใช่',
          noLabel: 'ไม่'
        },
        minWidth: '250px'
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result === true) {
          if (contractStatusProcess === 'Expired') {
            await this.deleteContract(contractDocumentId);
          } else {
            await this.deleteContractDocument(contractDocumentId);
          }
        }
      });
    }
  }

  // ลบทั้ง 2 table
  async deleteContract(contractDocumentId: any) {
    try {
      const response = await this.contractManagementService.deleteContract(contractDocumentId).toPromise();
      if (response.status === 204) {
        console.log('Contract deleted successfully');
        this.filterData();
        this.alertInfo('ลบเอกสารสัญญาสำเร็จ')
      } else {
        console.log('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Error deleting contract item:', error);
    }
  }

  async deleteContractDocument(contractDocumentId: any) {
    try {
      const response = await this.contractManagementService.deleteContractDocument(contractDocumentId).toPromise();
      if (response.status === 204) {
        console.log('Contract deleted successfully');
        this.filterData();
        this.alertInfo('ลบเอกสารสัญญาสำเร็จ')
      } else {
        console.log('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Error deleting contract item:', error);
    }
  }


  selectedPath: any;
  contextMenuClick(args: any): void {
    if (args.item.id === 'viewContract') {
      const contractDocumentId = (args.rowInfo.rowData.contractDocumentId);
      const nodeName = (args.rowInfo.rowData.fileName);
      this.selectedPath = (nodeName).replace('.pdf', '') + ' /.. ';
      if (contractDocumentId) {
        this.openItemsActive = true
        this.isContractDoucnetId = (contractDocumentId);
        this.filterItems(contractDocumentId);
      }
    }

    if (args.item.id === 'contractUpdate') {
      const contractDocumentId = (args.rowInfo.rowData.contractDocumentId);
      if (contractDocumentId) {
        this.improveDocumentData(contractDocumentId);
      }
    }


    if (args.item.id === 'downloadContract') {
      const fileName = (args.rowInfo.rowData.fileName);
      const nodeIdLast = (args.rowInfo.rowData.nodeIdLast);
      this.contentUrlService.getNodeContentUrl(nodeIdLast, true).subscribe((contentUrl) => {

        this.download(contentUrl, fileName);
      });
    }
  }


  contextItemMenuClick(args: any): void {


    if (args.item.id === 'viewContract') {
      const nodeId = (args.rowInfo.rowData.nodeId);
      this.onViewFile(nodeId)
    }

    if (args.item.id === 'contractUpdate') {
      const contractStatusProcess = (args.rowInfo.rowData.contractStatusProcess);
      const contractDocumentNo = (args.rowInfo.rowData.contractDocumentNo);
      const contractItemId = (args.rowInfo.rowData.contractItemId);

      if (contractStatusProcess === 'Draft' || contractStatusProcess === 'Expired') {
        this.improveData(contractDocumentNo, contractItemId);
      }

      if (contractStatusProcess === 'Approved') {
        this.improveDataApproved(contractDocumentNo, contractItemId);
      }
    }

    if (args.item.id === 'downloadContract') {
      const fileName = (args.rowInfo.rowData.fileName);
      const nodeId = (args.rowInfo.rowData.nodeId);
      this.contentUrlService.getNodeContentUrl(nodeId, true).subscribe((contentUrl) => {
        this.download(contentUrl, fileName);
      });
    }


  }


  async onRecordItemClick(args: any) {
    const columnName = args.column.field;

    if (columnName === 'contractUpdate') {
      const contractStatusProcess = (args.rowData.contractStatusProcess);

      const contractDocumentNo = (args.rowData.contractDocumentNo);
      const contractItemId = (args.rowData.contractItemId);

      if (contractStatusProcess === 'Draft' || contractStatusProcess === 'Expired') {
        this.improveData(contractDocumentNo, contractItemId);
      }

      if (contractStatusProcess === 'Approved') {
        this.improveDataApproved(contractDocumentNo, contractItemId);
      }
    }

    if (columnName === 'viewContract') {
      console.log('#viewContract');
      const nodeId = (args.rowData.nodeId);
      this.onViewFile(nodeId)
    }

    if (columnName === 'downloadContract') {
      const fileName = (args.rowData.fileName);
      const nodeId = (args.rowData.nodeId);
      this.contentUrlService.getNodeContentUrl(nodeId, true).subscribe((contentUrl) => {
        this.download(contentUrl, fileName);
      });
    }

    if (columnName === 'deleteContract') {
      const contractItemId = (args.rowData.contractItemId);
      const contractStatusProcess = (args.rowData.contractStatusProcess);
      const contractDocumentId = (args.rowData.contractDocumentId);
      if (contractStatusProcess === 'Approved' || contractStatusProcess === 'Draft') {
        this.alertError('ลบได้เฉพาะเอกสารสภานะ Expired เท่านั้น', null)
        return
      }
      const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
        data: {
          title: 'ยืนยัน',
          message: 'คุณต้องการลบเอกสารสัญญาฉบับบนี้หรือไม่',
          yesLabel: 'ใช่',
          noLabel: 'ไม่'
        },
        minWidth: '250px'
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result === true) {
          await this.deleteContractItem(contractItemId, contractDocumentId);
        }
      });
    }
  }

  async deleteContractItem(contractItemId: any, contractDocumentId: any) {
    try {
      const response = await this.contractManagementService.deleteContractItems(contractItemId).toPromise();
      if (response.status === 204) {
        console.log('Contract deleted successfully');
        this.filterItems(contractDocumentId);
        this.alertInfo('ลบเอกสารสัญญาสำเร็จ')
      } else {
        console.log('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Error deleting contract item:', error);
    }
  }

  onRecordDoubleClick(args: any): void {
    console.log('Double-clicked row data:', args.rowData);
    const contractDocumentId = (args.rowData.contractDocumentId);
    const nodeName = (args.rowData.fileName);
    this.selectedPath = (nodeName).replace('.pdf', '') + ' /.. ';
    if (contractDocumentId) {
      this.openItemsActive = true
      this.isContractDoucnetId = args.rowData.contractDocumentId;
      this.filterItems(contractDocumentId);
    }
  }

  onRecordItemsDoubleClick(args: any): void {
    console.log('Double-clicked row data:', args.rowData);
    const nodeId = (args.rowData.nodeId);
    if (nodeId) {
      this.onViewFile(nodeId)
    }
  }

  onViewFile(nodeId: any) {
    const parameterValue = nodeId;
    window.open(`#/Contact-Management/Preview?param=${parameterValue}`, '_blank');
  }


  onBackClick() {
    this.myForm.reset();
    this.openItemsActive = false;
    this.filterData();
  }

  private download(url: string, fileName: string) {

    if (url && fileName) {
      console.log(url);
      const link = document.createElement('a');

      link.style.display = 'none';
      link.download = fileName;
      link.href = url;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  }



  convertToDate(dateStr: any) {
    if (dateStr) {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);

      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    } else {
      return null;
    }
  }

  // =================================== Event Search  ===================================


  clearData() {
    this.myForm.reset();
    this.dataTemp = [];
  }


  exportTempsData: any;

  async filterData() {
    console.log('#filterData() ');
    const data = {
      'doc_type': this.myForm.get('docType').value,
      'deposit_no': this.myForm.get('depositNo').value,
      'contract_name': this.myForm.get('contractName').value,
      'contract_document_no': this.myForm.get('contractNo').value,
      'contract_pair_name': this.myForm.get('contractPairName').value,
      'department_name': this.myForm.get('department').value,
      'contract_value': this.myForm.get('contractValue').value,
      'contract_collateral': this.myForm.get('contractCollateral').value,
      'signature_type': this.myForm.get('signatureType').value,
      'contract_status': this.myForm.get('status').value,
      'responsible_person': this.myForm.get('responsiblePerson').value,
      'contract_effective_date': this.convertToDate(this.myForm.get('contractEffectiveDate').value),
      'contract_end_date': this.convertToDate(this.myForm.get('contractEndDate').value),
    };

    try {
      console.log('this.openItemsActive ', this.openItemsActive);
      if (!this.openItemsActive) {
        const response = await this.contractManagementService.searchContractManagement(data).toPromise();
        const temp = [];
        const tempExport = [];
        let listNo = 1;
        for (const item of response) {
          // const temps = await this.contractManagementService.getContractStatusProcessByApproved(item.contract_document_id);
          // let temps2 = ''
          // let status = ''
          // let nodeIdLast = ''
          // let depNo = ''
          // if (JSON.parse(temps).length > 0) {
          //   temps2 = JSON.parse(temps);
          //   status = temps2[0];
          //   nodeIdLast = temps2[1];
          //   depNo = temps2[2];
          // }
          // For Grid
          const newItem = {
            listNo: listNo,
            contractDocumentId: item.contract_document_id,
            contractDocumentNo: item.contract_document_no,
            fileName: item.node_name,
            depositAccNumber: item.deposit_no,
            contractPairName: item.contract_pair_name,
            createDate: this.formattingService.convertToThaiDate(item.contract_date),
            editDate: this.formattingService.convertToThaiDate(item.contract_date_edit),
            notificationEmail: item.user_create_email,
            docOwner: item.user_create_fullname,
            department: item.department_name,
            expDate: this.formattingService.convertToThaiDate(item.contract_end_date),
            contractStatusProcess: item.contract_status_process,
            nodeId: item.node_id,
            nodeIdLast: item.node_id,
          };

          // For Export
          const newItemExport = {
            contract_type: item.doc_type,
            deposit_no: `="${item.deposit_no}"`,   // yyyy
            node_name: item.contract_name,
            contract_document_no: item.contract_item_no,
            contract_pair_name: item.contract_pair_name,
            department_name: item.department_name,
            user_create_fullname: item.deposit_name,
            user_create_email: item.contract_sender_email,
            contract_manager_name: item.contract_manager_name,
            contract_manager_email: item.contract_manager_email,
            contract_signing_date: this.formattingService.convertToThaiDate(item.contract_signing_date),
            contract_effective_date: this.formattingService.convertToThaiDate(item.contract_effective_date),
            contract_end_date: this.formattingService.convertToThaiDate(item.contract_end_date),
            contract_duration: item.contract_duration,
            contract_value: item.contract_value,
            contract_collateral: item.contract_collateral,
            signature_type: item.signature_type ? item.signature_type : '',
            contract_status: item.contract_status,
            storage_duration: item.storage_duration,
            compliance_deposit_date: this.formattingService.convertToThaiDate(item.compliance_deposit_date),
            storage_location: item.storage_location,
            responsible_person: item.responsible_person
          };
          listNo++;
          temp.push(newItem);
          tempExport.push(newItemExport);
        }
        this.dataTemp = temp;
        this.exportTempsData = tempExport;
      } else {
        // item
        data['contract_document_id'] = this.isContractDoucnetId;
        const response = await this.contractManagementService.searchContractItemManagement(data).toPromise();
        const tempExport = [];
        const temp = [];
        let listNo = 1;
        for (const item of response) {
          const newItem = {
            contractItemId: item.contract_item_id,
            listNo: listNo,
            contractDocumentId: item.contract_document_id,
            contractDocumentNo: item.contract_document_no,
            fileName: item.node_name,
            depositAccNumber: item.deposit_no,
            contractPairName: item.contract_pair_name,
            createDate: this.formattingService.convertToThaiDate(item.contract_date),
            editDate: this.formattingService.convertToThaiDate(item.contract_date_edit),
            notificationEmail: item.user_create_email,
            docOwner: item.user_create_fullname,
            department: item.department_name,
            expDate: this.formattingService.convertToThaiDate(item.contract_end_date),
            contractStatusProcess: item.contract_status_process,
            nodeId: item.node_id,
          };
          // For Export
          const newItemExport = {
            contract_type: item.doc_type,
            deposit_no: `="${item.deposit_no}"`, // yyyy
            node_name: item.contract_name,
            contract_document_no: item.contract_item_no,
            contract_pair_name: item.contract_pair_name,
            department_name: item.department_name,
            user_create_fullname: item.deposit_name,
            user_create_email: item.contract_sender_email,
            contract_manager_name: item.contract_manager_name,
            contract_manager_email: item.contract_manager_email,
            contract_signing_date: this.formattingService.convertToThaiDate(item.contract_signing_date),
            contract_effective_date: this.formattingService.convertToThaiDate(item.contract_effective_date),
            contract_end_date: this.formattingService.convertToThaiDate(item.contract_end_date),
            contract_duration: item.contract_duration,
            contract_value: item.contract_value,
            contract_collateral: item.contract_collateral,
            signature_type: item.signature_type ? item.signature_type : '',
            contract_status: item.contract_status,
            storage_duration: item.storage_duration,
            compliance_deposit_date: this.formattingService.convertToThaiDate(item.compliance_deposit_date),
            storage_location: item.storage_location,
            responsible_person: item.responsible_person
          };

          listNo++;
          temp.push(newItem);
          tempExport.push(newItemExport);
        }
        this.dataTempItem = temp;
        this.exportTempsData = tempExport;
      }
    } catch (error) {
      console.error('error filterData() :', error);
      this.dataTemp = [];
    }
  }

  async filterItems(contractDocumentId: string) {

    const data = {
      'contract_document_id': contractDocumentId,
    }

    const tempExport = [];

    await this.contractManagementService.searchContractItem(data).subscribe(response => {
      const temp = [];
      let listNo = 1;
      if (response) {
        response.forEach(item => {
          const newItem = {
            contractItemId: item.contract_item_id,
            listNo: listNo,
            contractDocumentId: item.contract_document_id,
            contractDocumentNo: item.contract_document_no,
            fileName: item.node_name,
            depositAccNumber: item.deposit_no,
            contractPairName: item.contract_pair_name,
            createDate: this.formattingService.convertToThaiDate(item.contract_date),
            editDate: this.formattingService.convertToThaiDate(item.contract_date_edit),
            notificationEmail: item.user_create_email,
            docOwner: item.user_create_fullname,
            department: item.department_name,
            expDate: this.formattingService.convertToThaiDate(item.contract_end_date),
            contractStatusProcess: item.contract_status_process,
            nodeId: item.node_id,
          };
          const newItemExport = {
            contract_type: item.doc_type,
            deposit_no: `="${item.deposit_no}"`, // yyyy
            node_name: item.contract_name,
            contract_document_no: item.contract_item_no,
            contract_pair_name: item.contract_pair_name,
            department_name: item.department_name,
            user_create_fullname: item.deposit_name,
            user_create_email: item.contract_sender_email,
            contract_manager_name: item.contract_manager_name,
            contract_manager_email: item.contract_manager_email,
            contract_signing_date: this.formattingService.convertToThaiDate(item.contract_signing_date),
            contract_effective_date: this.formattingService.convertToThaiDate(item.contract_effective_date),
            contract_end_date: this.formattingService.convertToThaiDate(item.contract_end_date),
            contract_duration: item.contract_duration,
            contract_value: item.contract_value,
            contract_collateral: item.contract_collateral,
            signature_type: item.signature_type ? item.signature_type : '',
            contract_status: item.contract_status,
            storage_duration: item.storage_duration,
            compliance_deposit_date: this.formattingService.convertToThaiDate(item.compliance_deposit_date),
            storage_location: item.storage_location,
            responsible_person: item.responsible_person
          };
          listNo++;
          temp.push(newItem);
          tempExport.push(newItemExport);
        });

        this.exportTempsData = tempExport;
      }
      this.dataTempItem = temp;
    }, error => {
      console.error('error filterData() :', error);
      this.dataTempItem = [];
    });

  }




  // =================================== Event Create  ===================================
  createData() {
    const dialogRef = this.dialog.open(CreateContractComponent, {
      width: '750px',
      autoFocus: false,
      disableClose: true,
      data: { listEmail: this.listEmail }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        console.log('# dialogRef.afterClosed()');
        this.filterData();
      }
    });
  }

  // =================================== Event Export  ===================================
  exportData() {
    console.log('exportData()');
    // (this.grid as GridComponent).csvExport();

    // this.exportTempsData.forEach(item => {
    //   item.deposit_no = `="${item.deposit_no}"`;
    // });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'ประเภทสัญญา/เอกสาร',
        'เลขที่รับฝาก',
        'ชื่อสัญญา',
        'เลขที่สัญญา',
        'ชื่อคู่สัญญา',
        'ฝ่ายงาน/ส่วนงาน',
        'ชื่อผู้ฝากสัญญา',
        'E-mail ผู้ฝากสัญญา',
        'ชื่อผู้บริหารที่ฝากสัญญา',
        'E-mail ผู้บริหารที่ฝากสัญญา',
        'วันที่ลงนามในสัญญา',
        'วันที่สัญญามีผล',
        'วันที่สัญญาสิ้นผล',
        'ระยะเวลาสัญญา',
        'มูลค่าสัญญา',
        'หลักประกันสัญญา',
        'ประเภทการลงนาม',
        'สถานะ',
        'ระยะเวลาจัดเก็บ',
        'วันที่นำฝาก Compliance',
        'สถานที่จัดเก็บ',
        'ผู้รับผิดชอบ'
      ]
    };

    new ngxCsv(this.exportTempsData, 'Contract-Management-Report', options);

  }

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'Grid_excelexport') {
      (this.grid as GridComponent).excelExport();
    }
    else if (args.item.id === 'Grid_csvexport') {
      (this.grid as GridComponent).csvExport();
    }
  }


  // =================================== Event Improve  ===================================


  // การปรับปรุงสัญญา
  improveData(contractDocumentNo: any, contractItemId: any) {
    const dialogRef = this.dialog.open(ImproveContractComponent, {
      width: '750px',
      autoFocus: true,
      disableClose: true,
      data: { contractDocumentNo: contractDocumentNo, contractItemId: contractItemId, groupComplianceActive: this.groupComplianceActive }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        console.log('data ', data);
        this.filterItems(data);
      }
    });
  }

  // การปรับปรุงสัญญาที่อนุมัติแล้ว
  improveDataApproved(contractDocumentNo: any, contractItemId: any) {
    const dialogRef = this.dialog.open(ImproveContractApprovedComponent, {
      width: '750px',
      autoFocus: true,
      disableClose: true,
      data: { contractDocumentNo: contractDocumentNo, contractItemId: contractItemId, groupComplianceActive: this.groupComplianceActive }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        console.log('data ', data);
        this.filterItems(data);
      }
    });
  }


  improveDocumentData(contractDocumentId: any) {
    const dialogRef = this.dialog.open(ImproveDocumentComponent, {
      width: '750px',
      autoFocus: true,
      disableClose: true,
      data: { contractDocumentId: contractDocumentId, groupComplianceActive: this.groupComplianceActive }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        console.log('# dialogRef.afterClosed()');
      }
    });
  }

}
