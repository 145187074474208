<div *ngIf="!loadSuccess" class="loading">
  Loading chart...
</div>

<ejs-accumulationchart
  *ngIf="loadSuccess"
  [title]="title"
  [legendSettings]="legendSettings"
  [tooltip]="tooltip"
  selectionMode="Point"
  [annotations]="annotations"
  (load)="load($event)"
>
  <e-accumulation-series-collection>
    <e-accumulation-series
      name="Revenue"
      [dataSource]="data"
      xName="x"
      [startAngle]="startAngle"
      yName="y"
      [radius]="radius"
      [innerRadius]="innerRadius"
      [dataLabel]="dataLabel"
    >
    </e-accumulation-series>
  </e-accumulation-series-collection>
</ejs-accumulationchart>
