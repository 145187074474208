import { Injectable } from '@angular/core';

import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PeopleContentService } from '@alfresco/adf-content-services';
import { NetworkService } from './services/network.service';
import { take } from 'rxjs/operators';

// Define interfaces for better type safety
interface Permission {
  authorityId: string;
  [key: string]: any;
}

interface NodePermissions {
  inherited?: Permission[];
  locallySet?: Permission[];
}

interface NodeInfo {
  entry: {
    permissions: NodePermissions;
  };
}

@Injectable({
  providedIn: 'root'
})
export class WebviewerExtService {
  userId: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private peopleContentService: PeopleContentService,
    private networkService: NetworkService
  ) {}

  getBaseUrl(): string {
    return this.document.location.origin;
  }

  /**
   * Get user permissions by checking direct user permissions and group permissions
   * @param nodeInfo Node information containing permission details
   * @param userId User ID to check permissions for
   * @returns Permission object or null if no permissions found
   */
  async getUserPermissions(nodeInfo: NodeInfo, userId: string): Promise<Permission | null> {
    try {
      if (!nodeInfo?.entry?.permissions) {
        console.warn('Invalid nodeInfo structure:', nodeInfo);
        return null;
      }

      const { inherited: inheritedPermissions, locallySet: locallySetPermissions } = nodeInfo.entry.permissions;

      // Check direct user permissions first
      const directPermission = this.findDirectPermission(userId, inheritedPermissions, locallySetPermissions);
      if (directPermission) {
        return directPermission;
      }

      // If no direct permissions, check group permissions
      if (inheritedPermissions?.length) {
        return await this.findGroupPermission(userId, inheritedPermissions);
      }

      if (locallySetPermissions?.length) {
        return await this.findGroupPermission(userId, locallySetPermissions);
      }

      return null;
    } catch (error) {
      console.error('Error in getUserPermissions:', error);
      return null;
    }
  }

  /**
   * Find direct user permission from inherited or local permissions
   */
  private findDirectPermission(userId: string, inheritedPermissions?: Permission[], locallySetPermissions?: Permission[]): Permission | null {
    // Check inherited permissions
    if (inheritedPermissions?.length) {
      const inheritedPermission = inheritedPermissions.find((permission) => permission.authorityId === userId);
      if (inheritedPermission) return inheritedPermission;
    }

    // Check local permissions
    if (locallySetPermissions?.length) {
      const localPermission = locallySetPermissions.find((permission) => permission.authorityId === userId);
      if (localPermission) return localPermission;
    }

    return null;
  }

  /**
   * Find permission through user's groups
   */
  private async findGroupPermission(userId: string, inheritedPermissions: Permission[]): Promise<Permission | null> {
    try {
      const groupResponse = await this.networkService.getGroupMembers(userId).pipe(take(1)).toPromise();
      const userGroups = groupResponse?.list?.entries;

      if (!userGroups?.length) {
        return null;
      }

      for (const group of userGroups) {
        const groupId = group?.entry?.id;
        if (!groupId) continue;

        const matchingPermission = inheritedPermissions.find((permission) => permission.authorityId === groupId);

        if (matchingPermission) {
          console.log('Found group permission:', { groupId, permission: matchingPermission });
          return matchingPermission;
        }
      }

      return null;
    } catch (error) {
      console.error('Error finding group permission:', error);
      return null;
    }
  }



  async getSelectorPermissions(userPermission, roleSelector, userId) {
    if (userId === 'admin') {
      const role = {
        Preview: true,
        Upload: true,
        Edit: true,
        DownloadOriginal: true,
        DownloadWatermark: true,
        Delete: true,
        UseEditAnnotation: true,
        UseEditAnnotationAll: true,
        PreviewAnnotation: true,
        DownloadAnnotation: true,
        HideAnnotation: true,
        UnhideAnnotation: true
      };
      return role;
    }

    if (!userPermission) {
      const role = {
        Preview: false,
        Upload: false,
        Edit: false,
        DownloadOriginal: false,
        DownloadWatermark: false,
        Delete: false,
        UseEditAnnotation: false,
        UseEditAnnotationAll: false,
        PreviewAnnotation: false,
        DownloadAnnotation: false,
        HideAnnotation: false,
        UnhideAnnotation: false
      };
      return role;
    }

    const userRoleName = userPermission.name.toUpperCase();

    for (const role in roleSelector) {
      if (roleSelector[role].matchRole.includes(userRoleName)) {
        return roleSelector[role];
      }
    }

    return null; // Return null if no matching role is found
  }

  async loadEcmUserIdInfo() {
    await this.peopleContentService.getCurrentUserInfo().subscribe((userInfo) => {
      this.userId = userInfo.id;
    });
  }
}
