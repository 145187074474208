import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  ContextActionsDirective,
  PageLayoutComponent,
  PaginationDirective,
  ToolbarComponent
} from '@alfresco/aca-shared';
import {
  AccumulationAnnotationService,
  AccumulationDataLabelService,
  AccumulationLegendService,
  AccumulationTooltipService,
  ChartModule,
  PieSeriesService
} from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts'
import { NgModule } from '@angular/core';
import { ChartSemipieComponent } from './chart-semipie.component';

import { AccumulationChartAllModule } from '@syncfusion/ej2-angular-charts';
import { ChartAllModule } from '@syncfusion/ej2-angular-charts';


@NgModule({
  declarations: [
    ChartSemipieComponent,
  ],
  exports: [
    ChartSemipieComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ChartModule,
    ContextActionsDirective,
    PageLayoutComponent,
    PaginationDirective,
    ToolbarComponent,
    DashboardLayoutModule,
    AccumulationChartModule,
    AccumulationChartAllModule,
    ChartAllModule
  ],
  providers: [PieSeriesService, AccumulationLegendService, AccumulationTooltipService, AccumulationDataLabelService,
    AccumulationAnnotationService]
})
export class ChartSemiPieModule { }
