<h2 mat-dialog-title>{{ 'APP.FILE.DIALOG.TITLE' | translate }}</h2>

<div *ngIf="isConfig">
  <mat-dialog-content>
    <!-- SELECT  -->
    <div>
      <mat-form-field style="width: 100%">
        <mat-label>Select Upload Form</mat-label>
        <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" required />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option (onSelectionChange)="onSelectorChange($event)" *ngFor="let option of filteredOptions | async" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <!-- FORMGROUP -->
    <div class="container" *ngIf="isSelector">
      <form [formGroup]="form">
        <span class="adf-fill-remaining-space"></span>

        <mat-card class="example-card">
          <!-- Filename -->
          <mat-form-field style="width: 100%">
            <input matInput formControlName="name" type="text" matInput required placeholder="Original Filename" readonly />
          </mat-form-field>

          <!-- New Filename -->
          <mat-form-field style="width: 100%" *ngIf="renameFileActive">
            <input matInput type="text" matInput required placeholder="New Filename" [value]="currentFileName" readonly />
          </mat-form-field>

          <!-- Path Desinations -->
          <mat-form-field style="width: 100%">
            <input matInput type="text" matInput required placeholder="Path Destination" [value]="currentPath" readonly />
          </mat-form-field>

          <div style="margin-bottom: 20px">
            <input hidden type="file" #fileInput (change)="onUploadFile($event)" />
            <button mat-raised-button style="width: 100%" (click)="fileInput.click()" [color]="fileUploaded ? 'primary' : ''">
              <mat-icon> {{ fileUploaded ? 'done' : 'file_upload' }} </mat-icon> {{ 'APP.FILE.DIALOG.UPLOAD_FILE' | translate }}
            </button>
          </div>
        </mat-card>

        <!-- ITEMS -->

        <mat-card class="example-card-config">
          <div *ngFor="let item of currentType.items">
            <!-- TEXT -->
            <mat-form-field style="width: 100%" *ngIf="!item.type || item.type === 'text'">
              <input
                matInput
                [formControlName]="item.field"
                type="text"
                matInput
                [required]="item.required"
                [placeholder]="item.placeholder"
                (ngModelChange)="onChanged()"
              />
            </mat-form-field>

            <!-- MAT AUTOCOMPLETE -->
            <mat-form-field style="width: 100%" *ngIf="item.type === 'dropdown'">
              <input
                #input
                type="text"
                [placeholder]="item.placeholder"
                aria-label="Number"
                matInput
                [formControlName]="item.field"
                [matAutocomplete]="auto"
                [required]="item.required"
                (ngModelChange)="onChangedAutoCompleteDropdown()"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filterOptions(item)" [value]="option.value"> {{ option.name }} </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <!-- DATE -->
            <mat-form-field #formField style="width: 100%" *ngIf="item.type === 'date'">
              <input
                matInput
                [matDatepicker]="picker"
                [formControlName]="item.field"
                [required]="item.required"
                [placeholder]="item.placeholder"
                (ngModelChange)="onChanged()"
                (click)="openCalendar(picker)"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <!-- MAT AUTOCOMPLETE - CUSTOM -->
            <mat-form-field style="width: 100%" *ngIf="item.type === 'dropdown-custom'">
              <input
                #input
                type="text"
                [placeholder]="item.placeholder"
                aria-label="Number"
                matInput
                [formControlName]="item.field"
                [matAutocomplete]="auto"
                [required]="item.required"
                (ngModelChange)="onChangedAutoCompleteDropdown()"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of customFilterOptions(item)" [value]="option.display"> {{ option.display }} </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </mat-card>
      </form>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="apply(form.value, form.valid)" [disabled]="!form.valid || form.hasError('emptyValue')">
      {{ 'APP.FILE.DIALOG.UPLOAD' | translate }}
    </button>
    <button mat-raised-button mat-dialog-close>{{ 'APP.FILE.DIALOG.CANCEL' | translate }}</button>
  </div>

  <br />

  <span class="adf-fill-remaining-space"></span>
</div>
