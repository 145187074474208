import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ExtensionService } from '@alfresco/adf-extensions';
import { DocumentExpireEffects } from './effects/document-expire.effects';
import { canDocumentExpire } from './evaluators';
import { CommonModule } from '@angular/common';
import { DocumentExpireComponent } from './dialog/document-expire.component';
import { DocumentExpireReportComponent } from './report/document-expire-report.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContentModule, GroupService } from '@alfresco/adf-content-services';
import { CoreModule } from '@alfresco/adf-core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

// Material
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { PageLayoutComponent, PageLayoutModule } from '@alfresco/aca-shared';

import { TranslateModule } from '@ngx-translate/core';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ContextMenuService, FilterService, GridModule, PageService, SortService } from '@syncfusion/ej2-angular-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { CheckBoxSelectionService, DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';

import {  ToolbarComponent } from '@alfresco/aca-shared';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';

@NgModule({
  imports: [
    ContentModule,
    CoreModule.forChild(),
    ContentModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    EffectsModule.forFeature([DocumentExpireEffects]),
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    PageLayoutComponent,
    TranslateModule,
    TextBoxModule,
    GridModule,
    FormsModule,
    ButtonModule,
    MultiSelectModule,
    ToolbarComponent,
    PageLayoutModule,
    DropDownListModule,
    DateRangePickerModule
  ],
  declarations: [DocumentExpireComponent, DocumentExpireReportComponent],
  exports: [
    DocumentExpireComponent,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginator
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { closeOnNavigation: true, hasBackdrop: true, autoFocus: true }
    },
    PageService,
    SortService,
    FilterService,
    GroupService,
    CheckBoxSelectionService,
    ContextMenuService
  ],
  entryComponents: [DocumentExpireComponent, DocumentExpireReportComponent]
})
export class DocumentExpireExtModule {
  constructor(extensions: ExtensionService) {
    extensions.setEvaluators({
      'aca.canDocumentExpire': canDocumentExpire
    });

    extensions.setComponents({
      'document-expire.main.component': DocumentExpireComponent
    });

    extensions.setComponents({
      'document-expire-report.main.component': DocumentExpireReportComponent
    });
  }
}
