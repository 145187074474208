<mat-form-field class="full-width">
  <input type="text" [placeholder]="settings?.placeholder" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" />
  <button mat-button *ngIf="myControl.value" matSuffix mat-icon-button (click)="reset()">
    <mat-icon>close</mat-icon>
  </button>

  <mat-autocomplete #auto="matAutocomplete">
    <ng-container *ngFor="let option of filteredOptions | async">
      <mat-option
        (click)="updateQuery(option.value, option.name)"
        [value]="option.name"
        [matTooltip]="option.name"
        matTooltipClass="full-width-tooltip"
      >
        {{ option.name }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
