import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgIf, JsonPipe } from '@angular/common';
import { MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { AddUserComponent } from './add-user/add-user.dialog';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import * as moment from 'moment'
import { MatIconModule } from '@angular/material/icon';
import { InternalShareService } from './services/internal-share.service';
import { AlfrescoApiService } from '@alfresco/adf-core';
// import { ContentTypeDialogComponent, ContentTypeDialogComponentData } from '@alfresco/adf-content-services';

interface optionsRoleInterFace {
  value: string;
  viewValue: string;
}

export interface PeriodicElement {
  id: string;
  fullname: string;
  email: string;
}

// interface Permission {
//   authorityId: string;
//   id: string;
//   name: string;
//   accessStatus: string;
// }


@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgIf,
    JsonPipe,
    MatTableModule,
    MatDatetimepickerModule,
    MatIconModule
  ],
  templateUrl: './internal-share.dialog.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./internal-share.dialog.scss'],
  host: { class: 'aca-internalshare-dialog' }
})
export class InternalShareComponent implements OnInit {
  error: any;
  public myForm: FormGroup;
  permissions: any;

  nodeId: any;
  nodeEntry: any;

  selectedValue: string;
  optionRole: optionsRoleInterFace[] = [
    { value: 'Preview', viewValue: 'Preview' },
    { value: 'Consumer', viewValue: 'Consumer' },
    { value: 'Editor', viewValue: 'Editor' },
    { value: 'Contributor', viewValue: 'Contributor' },
  ];

  displayedColumns: string[] = ['fullname', 'id', 'email', 'actions'];
  dataSource = [];

  @ViewChild(MatTable) table: MatTable<PeriodicElement>;

  @ViewChild('matDatetimepickerToggle')
  matDatetimepickerToggle;

  @ViewChild('slideToggleExpirationDate')
  slideToggleExpirationDate;

  @ViewChild('dateTimePickerInput')
  dateTimePickerInput;

  minDate = moment().add(1, 'd');


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InternalShareComponent>,
    private internalShareService: InternalShareService,
    private apiService: AlfrescoApiService,
  ) {
    this.nodeEntry = data.node.entry;
    console.log('this.nodeEntry ', this.nodeEntry);
  }

  ngOnInit(): void {
    this.structureForm();
    this.feedDataForm();
  }

  structureForm() {
    this.myForm = new FormGroup({
      optionrole: new FormControl({ value: '', disabled: false }, [Validators.required]),
      date: new FormControl({ value: '', disabled: false }, [Validators.required]),
    });

  }

  feedDataForm() {
    const optionrole = null;
    const date = null;
    this.myForm.setValue({
      optionrole, date
    });
  }


  onSubmit(internalShareForm) {
    if (internalShareForm.invalid) {
      alert('กรุณาระบุข้อมูลให้ครบถ้วนก่อนบันทึก!..');
      return;
    }
  }

  addData() {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '600px',
      autoFocus: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        data.forEach(item => {
          const isDuplicate = this.dataSource.some(existingItem => existingItem.id === item.id);
          if (!isDuplicate) {
            this.dataSource.push({
              displayName: item.displayName,
              email: item.email,
              id: item.id
            });
          }
        });
        this.table.renderRows();
      }
    });
  }


  clearData() {
    this.dataSource = [];
    this.table.renderRows();
  }


  removeData() {
    this.dataSource.pop();

  }

  isFormComplete(): boolean {
    if (this.dataSource.length === 0) {
      return false;
    }
    return this.myForm.valid;
  }

  removeRow(row: any): void {
    const index = this.dataSource.indexOf(row);
    if (index > -1) {
      this.dataSource.splice(index, 1);
      this.table.renderRows();
    }
  }

  completeForm(internalShareForm) {


    const aa = internalShareForm.value;
    console.log('optionrole = ', aa.optionrole);
    console.log('date = ', aa.date.format('YYYY-MM-DD'));

    const locallySetPermissions = this.dataSource.map(user => {
      return {
        authorityId: user.id,
        name: aa.optionrole,
        accessStatus: 'ALLOWED'
      };
    });

    const permissionsData = {
      permissions: {
        locallySet: locallySetPermissions
      }
    };

    // console.log('this.nodeEntry.id = ', this.nodeEntry.id);
    // console.log('permissionsData = ', permissionsData);


    console.log('this.dataSource ', this.dataSource);

    const tempItem = this.dataSource.map(user => {
      return {
        'node_id': this.nodeEntry.id,
        'user_id': user.id,
        'user_fullname': user.displayName,
        'user_email': user.email,
        'remark': ''
      };
    });


    console.log('tempItem ', tempItem);


    // const dataItem = [
    //   tempItem
    // ];

    // console.log('dataItem ', dataItem);


    // const dataItem = [
    //   {
    //     'node_id': 'aaaaaa',
    //     'user_id': 'aaaaaa',
    //     'user_fullname': 'aaaaaa',
    //     'user_email': 'aaaaaa',
    //     'remark': 'aaaaaa'
    //   },
    //   {
    //     'node_id': 'bbbbbbb',
    //     'user_id': 'bbbbbbb',
    //     'user_fullname': 'bbbbbbb',
    //     'user_email': 'bbbbbbb',
    //     'remark': 'bbbbbbb'
    //   }
    // ];


    const dataDocument = {
      'internal_share_document': {
        'node_id': this.nodeEntry.id,
        'node_name': this.nodeEntry.name,
        'node_url': '',
        'create_date': '2024-01-01',
        'create_user_id': 'ทดสอบ',
        'create_user_fullname': 'ทดสอบ',
        'create_user_email': 'dev@gmail.com',
        'update_date': null,
        'update_user_id': '',
        'update_user_fullname': '',
        'update_user_email': '',
        'permission_role': 'Preview',
        'date_expiry_duration': '2025-01-01',
        'expiry_status': false,
        'shared_document_count': 2,
        'remark': 'test comment'
      },
      'internal_share_item': tempItem
    };


    // Save Database (internal-share-backend)
    this.internalShareService.insertInternalShareData(dataDocument).subscribe(response1 => {

      console.log('Data inserted successfully:', response1);

      // Update Permission (alfresco)
      this.internalShareService.updateNode(this.nodeEntry.id, permissionsData, this.apiService.getInstance().contentAuth.ticket).subscribe(
        response2 => {
          console.log('Permissions updated successfully:', response2);
          alert('Permissions updated successfully');
          this.dialogRef.close();
        },
        error => {
          console.error('Error updating permissions:', error);
        }
      );
    }, error => {
      console.error('Error inserting data:', error);
    });


  }
}
