<ejs-accumulationchart
  #pie
  style="display: block; width: 92%"
  [centerLabel]="centerLabel"
  (pointRender)="pointRender($event)"
  [legendSettings]="legendSettings"
  (load)="load($event)"
  [enableBorderOnMouseMove]="false"
  [tooltip]="tooltip"
  [useGroupingSeparator]="groupingSeparator"
>
  <e-accumulation-series-collection>
    <e-accumulation-series
      name="Site"
      [dataSource]="data"
      xName="x"
      yName="y"
      [border]="border"
      [startAngle]="startAngle"
      innerRadius="55%"
      [radius]="radius"
      [dataLabel]="dataLabel"
    >
    </e-accumulation-series>
  </e-accumulation-series-collection>
</ejs-accumulationchart>
