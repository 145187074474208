<div *ngIf="!loadSuccess" class="loading">Loading chart...</div>

<ejs-accumulationchart
  *ngIf="loadSuccess"
  id="container"
  [legendSettings]="legendSettings"
  [tooltip]="tooltip"
  (load)="load($event)"
  [annotations]="annotations"
  [enableAnimation]="enableAnimation"
  [enableBorderOnMouseMove]="false"
>
  <e-accumulation-series-collection>
    <e-accumulation-series
      name="Browser"
      [dataSource]="data"
      xName="Browser"
      yName="Users"
      [startAngle]="startAngle"
      [endAngle]="endAngle"
      innerRadius="40%"
      [radius]="radius"
      [dataLabel]="dataLabel"
      tooltipMappingName="tooltipMappingName"
    >
    </e-accumulation-series>
  </e-accumulation-series-collection>
</ejs-accumulationchart>
