import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FormattingService {

  constructor(private decimalPipe: DecimalPipe) { }

  getFormattedValue(value: any): string {
    const stringToTransform = String(value ?? '')
      .replace(/[^\d.]/g, '')
      .replace(/^0+/, '');
    return (
      this.decimalPipe.transform(
        stringToTransform === '' ? '0' : stringToTransform,
        '1.0'
      )
    );
  }

  // convertToThaiDate(dateStr: string): string {


  //   if (!dateStr) {
  //     return 'N/A'
  //   }


  //   const monthNamesThai = [
  //     'มกราคม',
  //     'กุมภาพันธ์',
  //     'มีนาคม',
  //     'เมษายน',
  //     'พฤษภาคม',
  //     'มิถุนายน',
  //     'กรกฎาคม',
  //     'สิงหาคม',
  //     'กันยายน',
  //     'ตุลาคม',
  //     'พฤษจิกายน',
  //     'ธันวาคม'
  //   ];

  //   // const d = new Date(dateStr);
  //   // const dd = d.getDate();
  //   // const mm = monthNamesThai[d.getMonth()];
  //   // const yyyy = d.getFullYear() + 543;

  //   const d = new Date(dateStr);
  //   const dd = d.getUTCDate();
  //   const mm = monthNamesThai[d.getUTCMonth()];
  //   const yyyy = d.getUTCFullYear() + 543;


  //   return dd + ' ' + mm + ' ' + yyyy;
  // }


  convertToThaiDate(input: string | number | Date): string {

    if (!input) {
      return 'N/A'
    }



    const date = new Date(input);

    // Ensure month and day are two digits
    const padWithZero = (num: number) => num.toString().padStart(2, '0');

    const day = padWithZero(date.getDate());
    const month = padWithZero(date.getMonth() + 1); // +1 because getMonth() returns 0-11
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  formmatContractDocument() {
    return null;
  }







}
