import { RuleContext } from '@alfresco/adf-extensions';
import { AppConfigService, AuthenticationService } from '@alfresco/adf-core';

export interface AcaRuleContext extends RuleContext {
  withCredentials: boolean;
  appConfig: AppConfigService;
}

export function canDocumentExpire(context: AcaRuleContext): boolean {
  if (context.navigation && context.navigation.url && context.navigation.url.startsWith('/trashcan')) {
    return false;
  }

  const auth: AuthenticationService = (<any>context).auth;

  if (auth && auth.isOauth()) {
    return false;
  }

  if (!context || !context.selection) {
    return false;
  }

  const { file } = context.selection;
  if (!file || !file.entry) {
    return false;
  }

  if (!file.entry.properties) {
    return false;
  }

  if (file.entry.isLocked) {
    return false;
  }

  if (file.entry.properties['cm:lockType'] === 'WRITE_LOCK' || file.entry.properties['cm:lockType'] === 'READ_ONLY_LOCK') {
    return false;
  }

  const lockOwner = file.entry.properties['cm:lockOwner'];
  if (lockOwner && lockOwner.id !== context.profile.id) {
    return false;
  }

  const node = context.selection.file ? context.selection.file : context.selection.folder;

  if (!node) {
    return false;
  }



  if (!file || !file.entry) {
    return false;
  }

  const flag = context.appConfig.get<boolean | string>('documentExpire.enabled');
  if (!flag) {
    return false;
  }

  if (context.profile.id && context.selection.file) {
    return true;
  }

  return false;
}
