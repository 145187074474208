<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">{{ 'APPROVAL-WORKFLOW.COMPLETED-REQUESTS.TITLE' | translate }}</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>

    <!-- <div class="button-container">
      <button ejs-button class="rounded-button">New Adhoc Request</button>
      <button ejs-button class="rounded-button" (click)="openRequestDialog()">New Request</button>
    </div> -->
  </div>

  <div class="aca-page-layout-content">
    <ejs-grid
      *ngIf="loading"
      #grid
      [allowExcelExport]="true"
      width="100%"
      height="100%"
      [rowHeight]="30"
      [dataSource]="dataTemp"
      [pageSettings]="pageSettings"
      [enableHover]="false"
      [allowResizing]="true"
      [allowSorting]="true"
      allowPaging="true"
      (rowSelected)="onRowSelected($event)"
    >
      <e-columns>
        <e-column field="reqProcessId" headerText="REQ ID" width="50" textAlign="Right" [visible]="false"></e-column>
        <e-column
          field="workflowId"
          headerText="{{ 'APPROVAL-WORKFLOW.COMPLETED-REQUESTS.GRID_PROCESS_ID' | translate }}"
          width="80"
          textAlign="Right"
        ></e-column>
        <!-- <e-column field="taskId" headerText="TASK ID" width="90" textAlign="Right"></e-column> -->
        <e-column field="subject" headerText="{{ 'APPROVAL-WORKFLOW.COMPLETED-REQUESTS.GRID_SUBJECT' | translate }}" width="190"></e-column>
        <e-column field="createDate" headerText="{{ 'APPROVAL-WORKFLOW.COMPLETED-REQUESTS.GRID_CREATE_DATE' | translate }}" width="100"></e-column>
        <e-column field="creator" headerText="{{ 'APPROVAL-WORKFLOW.COMPLETED-REQUESTS.GRID_CREATE_USER' | translate }}" width="120"></e-column>
        <!-- <e-column field="assign" headerText="ASSIGN" width="120"></e-column> -->
        <!-- <e-column field="priority" headerText="PRIORITY" width="90"></e-column> -->

        <e-column field="priority" headerText="{{ 'APPROVAL-WORKFLOW.COMPLETED-REQUESTS.GRID_PRIORITY' | translate }}" width="100">
          <ng-template #template let-data>
            <ejs-chiplist>
              <e-chips>
                <e-chip [text]="data.priority" [cssClass]="getPriorityClass(data.priority)"></e-chip>
              </e-chips>
            </ejs-chiplist>
          </ng-template>
        </e-column>

        <e-column field="status" headerText="{{ 'APPROVAL-WORKFLOW.COMPLETED-REQUESTS.GRID_STATUS' | translate }}" width="130">
          <ng-template #template let-data>
            <ejs-chiplist>
              <e-chips>
                <e-chip [text]="data.status" [cssClass]="getStatusClass(data.status)"></e-chip>
              </e-chips>
            </ejs-chiplist>
          </ng-template>
        </e-column>

        <e-column field="actions" headerText="{{ 'APPROVAL-WORKFLOW.COMPLETED-REQUESTS.GRID_ACTION' | translate }}" width="110">
          <ng-template #template let-data>
            <button ejs-button iconCss="e-icons e-medium e-eye" class="rounded-button-item" (click)="onView(data)">
            </button>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</aca-page-layout>
