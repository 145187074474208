<aca-page-layout>
  <div class="aca-page-layout-header">
    <!-- <h1 class="aca-page-title">Workspace Report</h1> -->

    <h1 class="aca-page-title">{{ 'WORKSPACE-REPORT.WORKSPACE_REPORT_TITLE' | translate }}</h1>

    <aca-toolbar [items]="actions"></aca-toolbar>
  </div>
  <div class="aca-page-layout-content" *ngIf="lockMenuActive === true">
    <div class="audit-main-content">
      <ng-container>
        <article>
          <div class="example-container">
            <!-- Name -->
            <mat-form-field style="width: 150px">
              <input matInput type="text" placeholder="Name" [(ngModel)]="name" />
              <button mat-button *ngIf="name" matSuffix mat-icon-button aria-label="Clear" (click)="name = ''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!-- User -->
            <mat-form-field style="width: 150px">
              <input matInput type="text" placeholder="User" [(ngModel)]="user" />
            </mat-form-field>

            <!-- Select -->
            <mat-form-field style="width: 150px">
              <mat-select placeholder="Select" [(ngModel)]="method">
                <mat-option value="preview">PREVIEW</mat-option>
                <mat-option value="create">CREATE</mat-option>
                <mat-option value="copy">COPY</mat-option>
                <mat-option value="move">MOVE</mat-option>
                <mat-option value="deleted">DELETED</mat-option>
                <mat-option value="download">DOWNLOAD</mat-option>
                <mat-option value="download-watermark">DOWNLOAD WITH WATERMARK</mat-option>
                <mat-option value="download-digitalsignature">DOWN WITH DIGITAL-SIGNATURE</mat-option>
                <mat-option value="print">PRINT</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 150px">
              <input matInput [matDatepicker]="dp1" placeholder="Start Date" [formControl]="startdate" [readonly]="true" />
              <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1></mat-datepicker>
            </mat-form-field>

            <mat-form-field style="width: 150px">
              <input
                matInput
                [matDatepicker]="dp2"
                placeholder="End Date"
                [formControl]="enddate"
                [min]="startdate.value"
                [max]="maxDate"
                [readonly]="true"
              />
              <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2></mat-datepicker>
            </mat-form-field>

            <button mat-flat-button style="width: 150px; height: 45px; margin-right: 10px" (click)="clear()">
              {{ 'WORKSPACE-REPORT.SEARCH.CLEAR' | translate }}
            </button>
            <button mat-flat-button color="primary" style="width: 150px; height: 45px" (click)="search()">
              {{ 'WORKSPACE-REPORT.SEARCH.SEARCH' | translate }}
            </button>
          </div>

          <div *ngIf="pagination.hasMoreItems" class="example-container" style="padding: 10px; width: 100px; display: contents">
            <div style="padding: 10px">
              {{ 'WORKSPACE-REPORT.RESULT_SEARCH.DES_TITLE' | translate }} {{ pagination ? pagination.totalItems : 0 }}
              {{ 'WORKSPACE-REPORT.RESULT_SEARCH.ITEM_TITLE' | translate }}
              <button mat-icon-button (click)="downloadCSV()">
                <mat-icon>archive</mat-icon>
              </button>
            </div>
          </div>
          <div *ngIf="pagination.hasMoreItems === false" class="example-container" style="padding: 10px; width: 100px; display: contents">
            <div style="padding: 10px">
              {{ 'WORKSPACE-REPORT.RESULT_SEARCH.DES_TITLE' | translate }} {{ pagination ? pagination.totalItems : 0 }}
              {{ 'WORKSPACE-REPORT.RESULT_SEARCH.ITEM_TITLE' | translate }}
              <button mat-icon-button (click)="downloadCSV()">
                <mat-icon>archive</mat-icon>
              </button>
            </div>
          </div>
        </article>

        <div class="audit-table-container">
          <mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort class="mat-elevation-z0">
            <ng-container matColumnDef="userName">
              <mat-header-cell *matHeaderCellDef> {{ 'WORKSPACE-REPORT.COLUMS_TITLE.USER_NAME' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{ transaction.userName }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="nodeName">
              <mat-header-cell *matHeaderCellDef> {{ 'WORKSPACE-REPORT.COLUMS_TITLE.NODE_NAME' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{ transaction.nodeName }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="method">
              <mat-header-cell *matHeaderCellDef> {{ 'WORKSPACE-REPORT.COLUMS_TITLE.METHOD' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{ transaction.method }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef> {{ 'WORKSPACE-REPORT.COLUMS_TITLE.TYPE' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{ transaction.type }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="time">
              <mat-header-cell *matHeaderCellDef> {{ 'WORKSPACE-REPORT.COLUMS_TITLE.TIME' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{ transaction.time }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="entry" stickyEnd>
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let transaction">
                <button mat-icon-button aria-label="icon-button with a more_vert icon" (click)="openDialog(transaction.entry)">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </div>

        <mat-paginator [pageSizeOptions]="[100, 200, 300, 400, 500, 600, 1000]" [showFirstLastButtons]="true"></mat-paginator>
      </ng-container>
    </div>
  </div>

  <div class="aca-page-layout-content" *ngIf="lockMenuActive === false">
    <div class="audit-main-content">
      <div style="padding: 10px">
        <article>Available for GROUP_Audit_Workspace only.</article>
      </div>
    </div>
  </div>
</aca-page-layout>
