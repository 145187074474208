<div class="dialog-content">
  <div class="input-container-main">
    <div class="input-container">
      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.CONFIG.DROPDOWN_GROUP' | translate }}:</h4>
        <ejs-dropdownlist
          #droupDownGroup
          id="droupDownGroup"
          [dataSource]="dataGroup"
          [fields]="fieldsGroup"
          [placeholder]="textGroup"
          (change)="onDropdownChange($event)"
          [value]="defaultGroupId"
        ></ejs-dropdownlist>
      </div>

      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.CONFIG.DROPDOWN_SUB_GROUP' | translate }}:</h4>
        <ejs-dropdownlist
          #droupDownSubGroup
          id="droupDownSubGroup"
          [dataSource]="dataSubGroup"
          [fields]="fieldsSubGroup"
          [placeholder]="textSubGroup"
          (change)="onSubGroupChange($event)"
          [value]="defaultSubGroupId"
        ></ejs-dropdownlist>
      </div>
    </div>

    <div class="input-container">
      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.CONFIG.DROPDOWN_PROCESSNAME' | translate }}:</h4>
        <ejs-textbox #textProcessName id="textProcessName" [value]="defaultProcessName"></ejs-textbox>
      </div>
    </div>

    <div class="input-container">
      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.CONFIG.SETUP_APPROVAL_STEP' | translate }}:</h4>
        <button ejs-button iconCss="e-icons e-circle-add" class="rounded-button" style="margin-left: 5px" (click)="addUser()">
          {{ 'APPROVAL-WORKFLOW.CONFIG.ADD_USER' | translate }}
        </button>
        <button
          ejs-button
          iconCss="e-icons e-circle-remove"
          class="rounded-button"
          style="margin-left: 5px"
          (click)="deleteUser()"
          [disabled]="!selectedRowData || selectedRowData.length === 0"
        > 
          {{ 'APPROVAL-WORKFLOW.CONFIG.DELETE_USER' | translate }}
        </button>
      </div>
    </div>
  </div>
  <ejs-grid
    #gridUser
    [dataSource]="data"
    height="300px"
    width="100%"
    allowPaging="true"
    [pageSettings]="pageSettings"
    (rowSelected)="onRowSelected($event)"
  >
    <e-columns>
      <e-column field="listNo" headerText="{{ 'APPROVAL-WORKFLOW.CONFIG.GRID_USER_NO' | translate }}" width="70"></e-column>
      <e-column field="userId" headerText="ID" [visible]="false"></e-column>
      <e-column field="displayName" headerText="{{ 'APPROVAL-WORKFLOW.CONFIG.GRID_USER_DISPLAYNAME' | translate }}" width="180"></e-column>
      <e-column field="email" headerText="{{ 'APPROVAL-WORKFLOW.CONFIG.GRID_USER_EMAIL' | translate }}" width="200"></e-column>
      <e-column field="Action" headerText="{{ 'APPROVAL-WORKFLOW.CONFIG.GRID_USER_ACTION' | translate }}" width="130">
        <ng-template #template let-data>
          <button ejs-button iconCss="e-icons e-chevron-up" class="rounded-button-item" (click)="moveUp(data)" [disabled]="isFirstRow(data)">
          </button>
          <button ejs-button iconCss="e-icons e-chevron-down-thin" class="rounded-button-item" (click)="moveDown(data)" [disabled]="isLastRow(data)">
          </button>
        </ng-template>
      </e-column>
    </e-columns>
  </ejs-grid>
  <div style="text-align: right; margin-top: 30px">
    <button ejs-button iconCss="e-icons e-save-as" class="rounded-button" style="margin-left: 5px" [disabled]="isCreateDisabled()" (click)="onCreate()">
      {{ updateActive ? ('APPROVAL-WORKFLOW.CONFIG.BUTTON_UPDATE' | translate) : ('APPROVAL-WORKFLOW.CONFIG.BUTTON_CREATE' | translate) }}
    </button>
    <button ejs-button iconCss="e-icons e-circle-close-fill-3" class="rounded-button" style="margin-left: 5px" (click)="onCancel()">
      {{ 'APPROVAL-WORKFLOW.CONFIG.BUTTON_CANCEL' | translate }}
    </button>
  </div>
</div>
