<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">{{ 'APPROVAL-WORKFLOW.CONFIG.TITLE' | translate }}</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>
  </div>

  <div class="aca-page-layout-content">
    <div class="parent-container">
      <ejs-tab id="element">
        <div class="e-tab-header">
          <div class="tab-header-item"><mat-icon>group</mat-icon> {{ 'APPROVAL-WORKFLOW.CONFIG.MANAGER_GROUP' | translate }}</div>
          <div class="tab-header-item"><mat-icon>workflow</mat-icon> {{ 'APPROVAL-WORKFLOW.CONFIG.MANAGER_WORKFLOW' | translate }}</div>
        </div>

        <div class="e-content custom-padding">
          <!-- TAB 1 -->
          <div class="grid-container">
            <app-grid-group></app-grid-group>
          </div>

          <!-- TAB 2 -->
          <div class="grid-data">
            <!-- GRID -->
            <ejs-grid
              #grid
              [dataSource]="dataConfigWorkflow"
              width="100%"
              height="100%"
              [rowHeight]="30"
              allowPaging="true"
              [pageSettings]="pageSettings"
              [allowSorting]="true"
              [allowFiltering]="true"
              [filterSettings]="filterSettings"
              [contextMenuItems]="contextMenuItems"
              (contextMenuClick)="contextMenuClick($event)"
              [allowResizing]="true"
            >
              <ng-template #toolbarTemplate let-data>
                <div id="toolbar">
                  <button ejs-button iconCss="e-icons e-circle-add" (click)="onCreate()" class="rounded-button">
                    <!-- <i class="fas fa-plus"></i>  -->
                    {{ 'APPROVAL-WORKFLOW.CONFIG.BUTTON_CREATE' | translate }}
                  </button>
                </div>
              </ng-template>

              <e-columns>
                <e-column field="configWorkflowId" headerText="ID" width="0" [visible]="false" [allowFiltering]="false"></e-column>
                <e-column field="groupId" headerText="ID" width="0" [visible]="false" [allowFiltering]="false"></e-column>
                <e-column
                  field="groupName"
                  headerText="{{ 'APPROVAL-WORKFLOW.CONFIG.GRID_PROCESS_GROUP' | translate }}"
                  width="300"
                  [allowFiltering]="true"
                ></e-column>
                <e-column field="subGroupId" headerText="SUBID" width="0" [visible]="false" [allowFiltering]="false"></e-column>
                <e-column
                  field="subGroupName"
                  headerText="{{ 'APPROVAL-WORKFLOW.CONFIG.GRID_PROCESS_SUB_GROUP' | translate }}"
                  width="300"
                  [allowFiltering]="true"
                ></e-column>
                <e-column
                  field="processName"
                  headerText="{{ 'APPROVAL-WORKFLOW.CONFIG.GRID_PROCESSNAME' | translate }}"
                  width="400"
                  [allowFiltering]="true"
                ></e-column>
                <e-column field="user" headerText="{{ 'APPROVAL-WORKFLOW.CONFIG.GRID_PROCESS_USER' | translate }}" width="500" [allowFiltering]="false">
                  <ng-template #template let-data>
                    <ejs-chiplist [chips]="data.user.split(',')" cssClass="e-info"></ejs-chiplist>
                  </ng-template>
                </e-column>
              </e-columns>
            </ejs-grid>
          </div>
        </div>
      </ejs-tab>
    </div>
  </div>
</aca-page-layout>
