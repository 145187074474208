import { NodeEntry } from '@alfresco/js-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NodeViewerService {
  node: NodeEntry | null = null;

  setNode(node: NodeEntry): void {
    this.node = node;
  }

  getNode(): NodeEntry | null {
    return this.node;
  }

  clearNode(): void {
    this.node = null;
  }
}
