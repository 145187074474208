<div class="dialog-content">
  <div>
    <div class="input-container">
      <div class="input-section">
        <ejs-textbox placeholder="ID:" [(value)]="dataUserSelected" floatLabelType="Always" [readonly]="true"></ejs-textbox>
      </div>
    </div>

    <div class="input-container">
      <div class="input-section">
        <ejs-dropdownlist placeholder="ROLE:" id="ddlelement" [dataSource]="dataRole" [(value)]="selectRole" placeholder="Select a role"></ejs-dropdownlist>
      </div>
    </div>
  </div>

  <div style="text-align: right; margin-bottom: 2px">
    <button ejs-button class="rounded-button" style="margin-left: 5px" (click)="isOk()">Update</button>
    <button ejs-button class="rounded-button" style="margin-left: 5px" (click)="isCancel()">Cancel</button>
  </div>
</div>
