<div class="dialog-content">
  <div class="input-container-main">
    <div class="input-container">
      <div class="input-section">
        <ejs-textbox id="filterName" placeholder="Filter" floatLabelType="Auto" (input)="onInputChange($event)" (keyup.enter)="onEnterPress($event)">
        </ejs-textbox>
      </div>
    </div>
  </div>

  <ejs-grid #grid [dataSource]="data" height="300px" [selectionSettings]="selectionOptions" (rowSelecting)="rowSelecting($event)">
    <e-columns>
      <!-- <e-column type="checkbox" width="50"></e-column> -->

      <e-column type="checkbox" width="50">
        <ng-template #headerTemplate> </ng-template>
      </e-column>

      <e-column field="userId" headerText="USERID" [visible]="false"></e-column>
      <e-column field="displayName" headerText="DISPALYNAME" width="150"></e-column>
      <e-column field="email" headerText="EMAIL" width="150"></e-column>
    </e-columns>
  </ejs-grid>

  <div style="text-align: right; margin-bottom: 2px">
    <button ejs-button class="rounded-button" style="margin-left: 5px" (click)="isOk()">
      <!-- <i class="fas fa-check"></i>  -->
      OK</button>
    <button ejs-button class="rounded-button" style="margin-left: 5px" (click)="isCancel()">
      <!-- <i class="fas fa-times"></i>  -->
      Cancel</button>
  </div>
</div>
