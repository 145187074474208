import { OptionsRoleInterface } from './options-role.interface';



// export const DEPARTMENT_OPTIONS: OptionsRoleInterface[] = [
//   { value: '05', viewValue: 'Sales Channel' },
//   { value: '06', viewValue: 'Insurance Product' },
//   { value: '07', viewValue: 'Claims Officer' },
//   { value: '08', viewValue: 'Finance & Investment' },
//   { value: '09', viewValue: 'Customer Service Center & BSN' },
//   { value: '10', viewValue: 'Operating Officer' },
//   { value: '98', viewValue: 'Risk Officer' },
//   { value: '98', viewValue: 'Insurance Compliance' },
//   { value: '98', viewValue: 'Internal Audit' },
//   { value: '99', viewValue: 'Office of Ex-Committee and Chairman' },
//   { value: '99', viewValue: 'Innovation Officer' },
//   { value: '99', viewValue: 'Business Process Design' },
//   { value: '99', viewValue: 'Information Technology' },
//   { value: '99', viewValue: 'Human Resource' },
//   { value: '99', viewValue: 'Corporate strategy' }
// ];


// export const DOCTYPE_OPTIONS: OptionsRoleInterface[] = [
//   { value: 'สัญญาบริการ', viewValue: 'สัญญาบริการ' },
//   { value: 'สัญญาให้บริการ', viewValue: 'สัญญาให้บริการ' },
//   { value: 'สัญญาว่าจ้าง', viewValue: 'สัญญาว่าจ้าง' },
//   { value: 'สัญญาซื้อขาย', viewValue: 'สัญญาซื้อขาย' },
//   { value: 'สัญญาเช่า', viewValue: 'สัญญาเช่า' },
//   { value: 'สัญญาอนุญาตให้ใช้สิทธิ', viewValue: 'สัญญาอนุญาตให้ใช้สิทธิ' },
//   { value: 'สัญญาแก้ไขข้อเพิ่มเติม', viewValue: 'สัญญาแก้ไขข้อเพิ่มเติม' },
//   { value: 'สัญญารักษาความลับ', viewValue: 'สัญญารักษาความลับ' },
//   { value: 'สัญญาว่าด้วยการประมวลผลข้อมูลส่วนบุคคล', viewValue: 'สัญญาว่าด้วยการประมวลผลข้อมูลส่วนบุคคล' },
//   { value: 'สัญญาว่าด้วยการโอนหรือเปิดเผยข้อมูล', viewValue: 'สัญญาว่าด้วยการโอนหรือเปิดเผยข้อมูล' },
//   { value: 'บันทึกแนบท้าย', viewValue: 'บันทึกแนบท้าย' },
//   { value: 'เอกสารแนบท้าย', viewValue: 'เอกสารแนบท้าย' },
//   { value: 'อื่น ๆ ระบุ', viewValue: 'อื่น ๆ ระบุ' },
// ];

export const CONTRACTYPE_OPTIONS: OptionsRoleInterface[] = [
  { value: 'เลขที่สัญญา TNI', viewValue: 'เลขที่สัญญา TNI' },
  { value: 'เลขที่สัญญาของคู่ค้า', viewValue: 'เลขที่สัญญาของคู่ค้า' },
];

// export const SIGNATURETYPE_OPTIONS: OptionsRoleInterface[] = [
//   { value: 'Paper', viewValue: 'Paper' },
//   { value: 'E-sign', viewValue: 'E-sign' },
// ];

// export const STATUS_OPTIONS: OptionsRoleInterface[] = [
//   { value: 'ทำใหม่', viewValue: 'ทำใหม่' },
//   { value: 'ต่ออายุ', viewValue: 'ต่ออายุ' },
//   { value: 'ทำลาย', viewValue: 'ทำลาย' },
// ];

// export const RESPONSIBLEPERSON_OPTIONS: OptionsRoleInterface[] = [
//   { value: 'กิตติภา จันทนาประเสริฐ', viewValue: 'กิตติภา จันทนาประเสริฐ' },
//   { value: 'สุจิน นพชำนาญ', viewValue: 'สุจิน นพชำนาญ' },
//   { value: 'ณฐอร พิมพ์บาง', viewValue: 'ณฐอร พิมพ์บาง' },
//   { value: 'จิราพร แจ่มแจ้ง', viewValue: 'จิราพร แจ่มแจ้ง' },
// ];
