<ng-container *ngIf="nodeId">
  <!-- <div *ngIf="
      !(selection.file?.entry.allowableOperations && selection.file?.entry.allowableOperations.includes('download')) && displayFormat === 'center'
    " class="watermark-review">
    <p [style]="safeLineOneStyleCss()">{{ lineOne }}</p>
    <p [style]="safeLineTwoStyleCss()">{{ lineTwo }}</p>
  </div>

  <div
    *ngIf="!(selection.file?.entry.allowableOperations && selection.file?.entry.allowableOperations.includes('download')) && displayFormat === 'full'">
    <ng-container *ngFor="let index of [2, 3, 4, 5 , 6]">
      <div class="watermark-review-left-{{ index }}">
        <p [style]="safeLineOneStyleCss()">{{ lineOne }}</p>
        <p [style]="safeLineTwoStyleCss()">{{ lineTwo }}</p>
      </div>
    </ng-container>

    <ng-container *ngFor="let index of [2, 3, 4, 5 , 6]">
      <div class="watermark-review-right-{{ index }}">
        <p [style]="safeLineOneStyleCss()">{{ lineOne }}</p>
        <p [style]="safeLineTwoStyleCss()">{{ lineTwo }}</p>
      </div>
    </ng-container>
  </div> -->

  <adf-alfresco-viewer
    [nodeId]="nodeId"
    [allowNavigate]="false"
    [allowRightSidebar]="false"
    [allowPrint]="false"
    [showRightSidebar]="false"
    [allowDownload]="false"
    [allowFullScreen]="false"
    [canNavigateBefore]="false"
    [canNavigateNext]="false"
    [overlayMode]="allowFullScreen"
    (showViewerChange)="onViewerVisibilityChanged()"
    [maxRetries]="30"
  >
  </adf-alfresco-viewer>

  <!-- Watermark -->
  <div *ngIf="!(selection.file?.entry.allowableOperations && selection.file?.entry.allowableOperations.includes('download'))">
    <app-watermark
      *ngIf="configWaterEnable"
      [lineOne]="lineOne"
      [lineTwo]="lineTwo"
      [maxFontSizeLineOne]="maxFontSizeLineOne"
      [maxFontSizeLineTwo]="maxFontSizeLineTwo"
      [colorLineOne]="colorLineOne"
      [colorLineTwo]="colorLineTwo"
      [opacityLineOne]="opacityLineOne"
      [opacityLineTwo]="opacityLineTwo"
      [displayLayout]="displayLayout"
      [maxColumns]="maxColumns"
      [rightTabOpen]="rightTabOpen"
      [reviewOnly]="true"
    >
    </app-watermark>
  </div>
</ng-container>
