<div class="adf-search-filter-card">

  <adf-search-widget-container [id]="category.id" [selector]="category.component.selector"
    [settings]="category.component.settings">
  </adf-search-widget-container>

  <div class="example-button-row" *ngIf="category.component.settings.allowApply">
    <button mat-button class="example-button-row" (click)="clear()" id="clear-filter-button">
      {{ 'SEARCH.FILTER.BUTTONS.CLEAR' | translate }}
    </button>
    <button mat-flat-button class="example-button-row" (click)="apply()" id="apply-filter-button">
      {{ 'SEARCH.FILTER.BUTTONS.APPLY' | translate }}
    </button>
  </div>


</div>
