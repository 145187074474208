import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AlfrescoApiService } from '@alfresco/adf-core';

@Injectable({
  providedIn: 'root'
})
export class GroupAlfService {

  constructor(private apiService: AlfrescoApiService) {
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  getGroups(): Observable<any> {
    const pathService = `groups?skipCount=0&maxItems=1000`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  getUserById(userId: any): Observable<any> {
    const pathService = `people/${userId}`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  getGroupById(userId: any): Observable<any> {
    const pathService = `people/${userId}/groups?skipCount=0&maxItems=100`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  createGroup(iD: any, displayName: any): Observable<any> {
    const pathService = `groups`
    const body = {
      id: iD,
      displayName: displayName,
    };
    return from(this.publicApiCall(
      pathService,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  updateGroup(id: any, displayName: any): Observable<any> {
    const pathService = `groups/${id}`
    const body = {
      displayName: displayName,
    };
    return from(this.publicApiCall(
      pathService,
      'PUT',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  deleteGroupMemeber(groupId: any, userId: any): Observable<any> {
    const pathService = `groups/${groupId}/members/${userId}`
    const httpMethod = 'DELETE';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }




}
