/* eslint-disable max-len */

import { AlfrescoApiService } from '@alfresco/adf-core';
import { DOCUMENT } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BeflexWorkflowService {

  headers: any;
  service = 'approval-workflow-backend'
  url = ''

  constructor(@Inject(DOCUMENT) private document: Document, private apiService: AlfrescoApiService) {

    if (this.getBaseUrl().startsWith('http://localhost')) {
      this.url = 'http://localhost:3805'
    }
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });

  }


  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  private callCustomApi(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callCustomApi(path, httpMethod, ...params);
  }


  getBaseUrl(): string {
    return this.document.location.origin;
  }

  // =============================================== API Alfresco ===================================================
  getPeople(): Observable<any> {
    const pathService = `/people?skipCount=0&maxItems=10000`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  createProcess(data: any): Observable<any> {
    const apiPath = '/alfresco/api/-default-/public/workflow/versions/1/processes'
    const body = data;
    return from(this.callCustomApi(
      apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  // getTasks(): Observable<any> {
  //   const pathService = `/alfresco/api/-default-/public/workflow/versions/1/tasks`
  //   const httpMethod = 'GET';
  //   return from(this.callCustomApi(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  // }

  getTasks(userId: any): Observable<any> {
    let pathService = `/alfresco/api/-default-/public/workflow/versions/1/tasks?where=(assignee='${userId}')`
    if ('admin' === userId) {
      pathService = `/alfresco/api/-default-/public/workflow/versions/1/tasks`
    }
    const httpMethod = 'GET';
    return from(this.callCustomApi(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }


  approveTasks(taskId: string): Observable<any> {
    const apiPath = `/alfresco/api/-default-/public/workflow/versions/1/tasks/${taskId}?select=state,variables`
    const body = {
      'state': 'completed',
      'variables': [
        {
          'name': 'wf_reviewOutcome',
          'value': 'Approve',
          'scope': 'local'
        }
      ]
    }
    return from(this.callCustomApi(
      apiPath,
      'PUT',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  rejectTasks(taskId: string): Observable<any> {
    const apiPath = `/alfresco/api/-default-/public/workflow/versions/1/tasks/${taskId}?select=state,variables`
    const body = {
      'state': 'completed',
      'variables': [
        {
          'name': 'wf_reviewOutcome',
          'value': 'Reject',
          'scope': 'local'
        }
      ]
    }
    return from(this.callCustomApi(
      apiPath,
      'PUT',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  getProcess(userId: any) {
    let pathService = `/alfresco/api/-default-/public/workflow/versions/1/processes?where=(startUserId='${userId}')`
    if ('admin' === userId) {
      pathService = `/alfresco/api/-default-/public/workflow/versions/1/processes`
    }
    const httpMethod = 'GET';
    return from(this.callCustomApi(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  getInfoProcess(processId: any) {
    const pathService = `/alfresco/api/-default-/public/workflow/versions/1/processes/${processId}`
    const httpMethod = 'GET';
    return from(this.callCustomApi(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  cancelProcess(processId: any) {
    const pathService = `/alfresco/api/-default-/public/workflow/versions/1/processes/${processId}`
    const httpMethod = 'DELETE';
    return from(this.callCustomApi(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }


}
