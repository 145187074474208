<ng-container>

  <div id="spinner"></div>
  <!-- <div>
    <ng-container *ngFor="let index of [2, 3, 4, 5, 6]">
      <div class="watermark-left-{{ index }}">
        <p [style]="lineOneStyle">{{ lineOne }}</p>
        <p [style]="lineTwoStyle">{{ lineTwo }}</p>
      </div>
    </ng-container>

    <ng-container *ngFor="let index of [2, 3, 4, 5, 6]">
      <div class="watermark-center-{{ index }}">
        <p [style]="lineOneStyle">{{ lineOne }}</p>
        <p [style]="lineTwoStyle">{{ lineTwo }}</p>
      </div>
    </ng-container>

    <ng-container *ngFor="let index of [2, 3, 4, 5, 6]">
      <div class="watermark-right-{{ index }}">
        <p [style]="lineOneStyle">{{ lineOne }}</p>
        <p [style]="lineTwoStyle">{{ lineTwo }}</p>
      </div>
    </ng-container>
  </div> -->

  <ejs-toolbar cssClass="custom-toolbar">
    <e-items>
      <e-item *ngIf="!_isNotPdf" prefixIcon="e-icon pdf-icon" [text]="homeText"></e-item>
      <e-item *ngIf="_isNotPdf" prefixIcon="e-icon docx-icon" [text]="homeText"></e-item>
    </e-items>
  </ejs-toolbar>

  <ejs-pdfviewer
    id="pdfViewer"
    [documentPath]="document"
    (documentLoad)="documentLoaded($event)"
    [toolbarSettings]="toolbarSettings"
    style="height: 97vh; display: block"
  ></ejs-pdfviewer>

  <app-watermark [userName]="lineOne" [currentDate]="lineTwo"></app-watermark>
</ng-container>
