<mat-card *ngIf="node">
  <mat-card-content *ngIf="!edit">
    <div class="mat-form-field mat-form-field-type-mat-input mat-form-field-can-float mat-form-field-should-float">
      <div class="mat-form-field-wrapper" data-automation-id="library-name-properties-wrapper">
        <div class="mat-form-field-flex">
          <div class="mat-form-field-infix">
            <span class="mat-form-field-label-wrapper">
              <span class="mat-form-field-label">
                {{ 'LIBRARY.DIALOG.FORM.NAME' | translate }}
              </span>
            </span>

            <span class="mat-input-element">
              {{ form.controls.title.value }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="mat-form-field mat-primary mat-form-field-type-mat-input mat-form-field-can-float mat-form-field-should-float">
      <div class="mat-form-field-wrapper" data-automation-id="library-id-properties-wrapper">
        <div class="mat-form-field-flex">
          <div class="mat-form-field-infix">
            <span class="mat-form-field-label-wrapper">
              <span class="mat-form-field-label">
                {{ 'LIBRARY.DIALOG.FORM.SITE_ID' | translate }}
              </span>
            </span>

            <span class="mat-input-element">
              {{ form.controls.id.value }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="mat-form-field mat-primary mat-form-field-type-mat-input mat-form-field-can-float mat-form-field-should-float">
      <div class="mat-form-field-wrapper" data-automation-id="library-visibility-properties-wrapper">
        <div class="mat-form-field-flex">
          <div class="mat-form-field-infix">
            <span class="mat-form-field-label-wrapper">
              <span class="mat-form-field-label">
                {{ 'LIBRARY.DIALOG.FORM.VISIBILITY' | translate }}
              </span>
            </span>

            <span class="mat-input-element">
              {{ visibilityLabel | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="mat-form-field mat-primary mat-form-field-type-mat-input mat-form-field-can-float mat-form-field-should-float">
      <div class="mat-form-field-wrapper" data-automation-id="library-description-properties-wrapper">
        <div class="mat-form-field-flex">
          <div class="mat-form-field-infix">
            <span class="mat-form-field-label-wrapper">
              <span class="mat-form-field-label">
                {{ 'LIBRARY.DIALOG.FORM.DESCRIPTION' | translate }}
              </span>
            </span>

            <span class="mat-input-element">
              {{ form.controls.description?.value }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="quotaEnabled"
      class="mat-form-field mat-form-field-type-mat-input mat-form-field-can-float mat-form-field-should-float adf-full-width"
    >
      <div class="mat-form-field-wrapper">
        <div class="mat-form-field-flex">
          <div class="mat-form-field-infix">
            <span class="mat-form-field-label-wrapper">
              <span class="mat-form-field-label"> Quota </span>
            </span>

            <span class="mat-input-element">
              {{ form.controls.quota?.value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions align="end" *ngIf="!edit && canUpdateLibrary">
    <button mat-button color="primary" (click)="toggleEdit()">
      {{ 'LIBRARY.DIALOG.EDIT' | translate }}
    </button>
  </mat-card-actions>

  <mat-card-content *ngIf="edit">
    <form [formGroup]="form" autocomplete="off">
      <mat-form-field floatLabel="auto">
        <input
          matInput
          cdkFocusInitial
          required
          placeholder="{{ 'LIBRARY.DIALOG.FORM.NAME' | translate }}"
          formControlName="title"
          [errorStateMatcher]="matcher"
        />
        <mat-hint *ngIf="libraryTitleExists">{{ 'LIBRARY.HINTS.SITE_TITLE_EXISTS' | translate }}</mat-hint>
        <mat-error>
          {{ 'LIBRARY.ERRORS.TITLE_TOO_LONG' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="auto">
        <input matInput placeholder="{{ 'LIBRARY.DIALOG.FORM.SITE_ID' | translate }}" formControlName="id" />
      </mat-form-field>

      <mat-form-field floatLabel="auto">
        <mat-select placeholder="{{ 'LIBRARY.DIALOG.FORM.VISIBILITY' | translate }}" formControlName="visibility">
          <mat-option [value]="type.value" *ngFor="let type of libraryType">
            {{ type.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="auto">
        <textarea
          matInput
          placeholder="{{ 'LIBRARY.DIALOG.FORM.DESCRIPTION' | translate }}"
          rows="3"
          formControlName="description"
          [errorStateMatcher]="matcher"
        ></textarea>
        <mat-error>
          {{ 'LIBRARY.ERRORS.DESCRIPTION_TOO_LONG' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="quotaEnabled" class="adf-full-width">
        <input matInput placeholder="Quota (Size:Mb)" formControlName="quota" type="number" />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions align="end" *ngIf="edit && canUpdateLibrary">
    <button mat-button (click)="cancel()">
      {{ 'LIBRARY.DIALOG.CANCEL' | translate }}
    </button>
    <button mat-button color="primary" [disabled]="form.invalid || form.pristine" (click)="update()">
      {{ 'LIBRARY.DIALOG.UPDATE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
