import { ContractManagementService } from './../services/contract-management.service';
import { CommonModule, JsonPipe, NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FormattingService } from '../utils/formatting.service';
import { DecimalPipe } from '@angular/common';
import { OptionsRoleInterface } from '../data/options-role.interface';
import { CONTRACTYPE_OPTIONS } from '../data/options-data';
import { FileModel, PeopleContentService, UploadService } from '@alfresco/adf-content-services';
// import moment from 'moment';
import moment from 'moment-timezone';

// import * as moment2 from 'moment';

import { HttpClient } from '@angular/common/http';
import {
  AppStore,
  SnackbarErrorAction,
  SnackbarInfoAction
  // SnackbarInfoAction,
  // SnackbarWarningAction
} from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DataConfigService } from '../services/data-config.service';

import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from '../utils/date-format';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Type {
  id: string;
  name: string;
  path: string;
  fileName: string;
  fileNameDraft: string;
  fileNameApproved: string;
  depositRunNo: string;
  items: SmartItem[];
}
export interface SmartItem {
  id: string;
  title: string;
  placeholder: string;
  field: string;
  required: boolean;
  type: string;
  options: any[];
}

export interface SmartObject {
  types: Type[];
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgIf,
    JsonPipe,
    MatTableModule,
    MatDatetimepickerModule,
    MatIconModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatAutocompleteModule
  ],
  selector: 'app-create-contract',
  templateUrl: './create-contract.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    FormattingService,
    DecimalPipe,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ],
  styleUrls: ['./create-contract.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreateContractComponent implements OnInit {
  public myForm: FormGroup;

  selectedDocType: string;
  docType: OptionsRoleInterface[] = [];

  selectedContractType: string;
  contractType: OptionsRoleInterface[] = CONTRACTYPE_OPTIONS;

  selectedDepartment: string;
  department: OptionsRoleInterface[] = [];

  selectedSignatureType: string;
  signatureType: OptionsRoleInterface[] = [];

  selectedStatus: string;
  status: OptionsRoleInterface[] = [];

  selectedResponsiblePerson: string;
  responsiblePerson: OptionsRoleInterface[] = [];

  selectedNotificationActive = '1';

  selectedContractOriginalActive: any;

  fileUploaded = false;
  fileUploadedHiden = false;

  userId: any;
  userFullName: any;
  userEmail: any;

  contractNo = '';
  contractAutoTypeFix = 'เลขที่สัญญา TNI';

  filteredOptions: string[];

  isConfig = false;
  smartObject: SmartObject;

  currentType: Type;
  currentFile: FileModel[];
  currentPath = '';
  currentFileName = '';

  fileTemps: any;
  extensionFile = '';
  values: any;

  optionSelected = false;
  contractItemId: any;

  minDate: Date;

  selectedContractStatusProcess = 'Draft';

  listEmailGroup: any;

  constructor(
    private dialogRef: MatDialogRef<CreateContractComponent>,
    private formattingService: FormattingService,
    private contractManagementService: ContractManagementService,
    private peopleContentService: PeopleContentService,
    private uploadService: UploadService,
    private http: HttpClient,
    private store: Store<AppStore>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private configService: DataConfigService
  ) {
    this.myForm = new FormGroup({
      contractOriginalActive: new FormControl({ value: false, disabled: false }), // toggle
      contractRefNo: new FormControl({ value: '', disabled: false }), // text
      docType: new FormControl({ value: '', disabled: false }, [Validators.required]), // select
      depositNo: new FormControl({ value: '', disabled: true }),
      docTypeManual: new FormControl({ value: '', disabled: false }), // text
      contractName: new FormControl({ value: '', disabled: false }, [Validators.required]), // text
      contractType: new FormControl({ value: '', disabled: false }, [Validators.required]), // select
      contractNoAuto: new FormControl({ value: '', disabled: false }), // text
      contractNoManual: new FormControl({ value: '', disabled: false }, [Validators.required]), // text
      contractNo: new FormControl({ value: '', disabled: false }), // text
      contractPairName: new FormControl({ value: '', disabled: false }, [Validators.required]), // text
      department: new FormControl({ value: '', disabled: false }, [Validators.required]), // select
      departmentName: new FormControl({ value: '', disabled: false }), // text
      depositorName: new FormControl({ value: '', disabled: false }, [Validators.required]), // text
      contractSenderEmail: new FormControl({ value: '', disabled: false }, [Validators.required]), // text
      contractManagerName: new FormControl({ value: '', disabled: false }, [Validators.required]), // text
      contractManagerEmail: new FormControl({ value: '', disabled: false }, [Validators.required]), // text
      contractSigningDate: new FormControl({ value: '', disabled: false }), // date
      contractEffectiveDate: new FormControl({ value: '', disabled: false }), // date
      notificationActive: new FormControl({ value: '1', disabled: false }), // radio botton
      contractEndDate: new FormControl({ value: '', disabled: false }), // date
      contractDuration: new FormControl({ value: 0, disabled: false }), // number
      contractValue: new FormControl({ value: 0, disabled: false }), // number
      contractCollateral: new FormControl({ value: '', disabled: false }), // text
      signatureType: new FormControl({ value: '', disabled: false }), // select
      status: new FormControl({ value: '', disabled: false }, [Validators.required]), // text
      storageDuration: new FormControl({ value: 0, disabled: false }), // number
      complianceDepositDate: new FormControl({ value: '', disabled: false }), // date
      storageLocation: new FormControl({ value: '', disabled: false }), // text
      responsiblePerson: new FormControl({ value: '', disabled: false }, [Validators.required]), // select
      fileNameOriginal: new FormControl({ value: '', disabled: false }) // text
    });

    this.minDate = new Date();

    const listEmail = this.data.listEmail;
    this.listEmailGroup = listEmail;

    console.log('base_url: ', this.contractManagementService.getBaseUrl());
  }

  async ngOnInit() {
    // SET OPTIONS
    this.department = await this.configService.getDepartmentOptions();
    this.docType = await this.configService.getDocTypeOptions();
    this.signatureType = await this.configService.getSignatureTypeOptions();
    this.status = await this.configService.getStatusOptions();
    this.responsiblePerson = await this.configService.getResponsiblePersonOptions();

    await this.loadConfig();

    this.loadEcmUserInfo();

    this.myForm
      .get('department')
      .valueChanges.pipe(debounceTime(300))
      .subscribe(async (departmentValue) => {
        const depName = departmentValue;
        const depNo = this.getValueFromView(depName);
        this.myForm.patchValue({ departmentName: depName });

        if (this.myForm.get('contractType').value === this.contractAutoTypeFix) {
          this.generateDocumentNo(depNo);
        } else {
          this.onChanged();
        }
      });

    this.myForm
      .get('contractType')
      .valueChanges.pipe(debounceTime(300))
      .subscribe(async (contractTypeValue) => {
        const depName = this.myForm.getRawValue().department;
        const depNo = this.getValueFromView(depName);

        if (contractTypeValue === this.contractAutoTypeFix && depNo) {
          this.generateDocumentNo(depNo);
        } else {
          this.onChanged();
        }

        const filedId = 'contractNoManual';
        const conditionTxt1 = contractTypeValue;
        const conditionTxt2 = 'เลขที่สัญญาของคู่ค้า';
        this.setRequiteField(filedId, conditionTxt1, conditionTxt2);
      });

    this.myForm
      .get('contractPairName')
      .valueChanges.pipe(debounceTime(300))
      .subscribe(async (contractTypeValue) => {
        const depName = this.myForm.getRawValue().department;
        const depNo = this.getValueFromView(depName);

        if (contractTypeValue === this.contractAutoTypeFix && depNo) {
          this.generateDocumentNo(depNo);
        } else {
          this.onChanged();
        }
      });

    this.myForm
      .get('contractNoManual')
      .valueChanges.pipe(debounceTime(300))
      .subscribe(async (value) => {
        // Check duplicate contractNoManual
        if (value) {
          const data = {
            contract_no_manual: value
          };
          const response = await this.contractManagementService.searchContractItemManagement(data).toPromise();
          if (!response || Object.keys(response).length === 0) {
            console.log('No data found in response');
          } else {
            this.alertInfo(`เลขที่สัญญา ${value} นี้มีอยู่แล้วในระบบ`);
          }
        }

        this.onChanged();
      });

    this.myForm.get('contractOriginalActive').valueChanges.subscribe((value) => {
      console.log('value ', value);
      if (value === true) {
        this.setDisabledForm();
      } else {
        this.setEnabledForm();
      }
      const filedId = 'contractRefNo';
      const conditionTxt1 = value;
      const conditionTxt2 = true;
      this.setRequiteField(filedId, conditionTxt1, conditionTxt2);

      this.setRequiteField('depositNo', value, true);
    });

    this.myForm
      .get('contractRefNo')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchTerm: string) => {
          return this.contractManagementService.searchContractItemStatus(searchTerm);
        })
      )
      .subscribe(async (value) => {
        this.filteredOptions = value;
      });

    this.myForm.get('contractEffectiveDate')?.valueChanges.subscribe(() => {
      this.calculateDuration();
    });

    this.myForm.get('contractEndDate')?.valueChanges.subscribe(() => {
      this.calculateDuration();
    });
  }

  calculateDuration(): void {
    const start = this.myForm.get('contractEffectiveDate')?.value;
    const end = this.myForm.get('contractEndDate')?.value;
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const duration = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
      this.myForm.get('contractDuration')?.setValue(duration);
    } else {
      this.myForm.get('contractDuration')?.setValue(null);
    }
  }

  setRequiteField(fileId: any, conditionTxt1: any, conditionTxt2: any) {
    if (conditionTxt1 === conditionTxt2) {
      this.myForm.get(`${fileId}`).setValidators([Validators.required]);
    } else {
      this.myForm.get(`${fileId}`).clearValidators();
    }
    this.myForm.get(`${fileId}`).updateValueAndValidity();
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const contractRefNo = event.option.value;
    console.log('onOptionSelected contractRefNo ', contractRefNo);
    if (contractRefNo) {
      this.optionSelected = true;
      this.feedData(contractRefNo);
    } else {
      console.log('not selected');
    }
  }

  onInputBlur() {
    const contractRefNo = this.myForm.get('contractRefNo').value;
    console.log('onInputBlur contractRefNo ', contractRefNo);
  }

  async feedData(contractDocNo: any) {
    console.log('# feedData contractDocNo ', contractDocNo);
    // 1.Get ContractItemId from contractDocNo by contractDocNo , contract_status_process
    // 2.Get From API
    // 2.Set Value to Form
    const temps = await this.contractManagementService.getContractItemIdByExp(contractDocNo);
    const temp = JSON.parse(temps);
    const contractItemId = temp[0];
    this.contractItemId = contractItemId;

    const data = {
      contract_item_id: contractItemId
    };

    await this.contractManagementService.searchContractItemImproveData(data).subscribe(
      (response) => {
        if (response) {
          response.forEach((item) => {
            // console.log('item.contract_no_auto ', item.contract_no_auto);
            // this.myForm.patchValue({ contractNoAuto: item.contract_no_auto }, { emitEvent: false });
            this.myForm.setValue({
              contractOriginalActive: true,
              contractRefNo: contractDocNo,
              docType: item.doc_type,
              depositNo: item.deposit_no,
              docTypeManual: item.doc_type_manual,
              contractName: item.contract_name,
              contractType: item.contract_type,
              contractNoAuto: item.contract_no_auto,
              contractNoManual: item.contract_no_manual,
              contractNo: item.contract_document_no,
              contractPairName: item.contract_pair_name,
              department: item.department_name,
              departmentName: item.department_name,
              depositorName: item.deposit_name,
              contractSenderEmail: item.contract_sender_email,
              contractManagerName: item.contract_manager_name,
              contractManagerEmail: item.contract_manager_email,
              contractSigningDate: item.contract_signing_date ? item.contract_signing_date : null,
              contractEffectiveDate: item.contract_effective_date ? item.contract_effective_date : null,
              notificationActive: item.notification_active ? '2' : '1',
              contractEndDate: item.contract_end_date ? item.contract_end_date : null,
              contractDuration: item.contract_duration ? item.contract_duration : 0,
              contractValue: item.contract_value ? this.formattingService.getFormattedValue(item.contract_value) : 0,
              contractCollateral: item.contract_collateral,
              signatureType: item.signature_type,
              status: item.contract_status,
              storageDuration: item.storage_duration ? item.storage_duration : 0,
              complianceDepositDate: item.compliance_deposit_date ? item.compliance_deposit_date : null,
              storageLocation: item.storage_location,
              responsiblePerson: item.responsible_person,
              fileNameOriginal: item.node_name
            });
          });
        }
      },
      (error) => {
        console.error('error filterData() :', error);
      }
    );
  }

  setEnabledForm() {
    this.myForm.get('docType').enable();
    this.myForm.get('contractType').enable();
    this.myForm.get('contractPairName').enable();
    this.myForm.get('department').enable();
    this.myForm.get('depositorName').enable();
    this.myForm.get('contractSenderEmail').enable();
    this.myForm.get('contractManagerName').enable();
    this.myForm.get('contractManagerEmail').enable();
  }

  setDisabledForm() {
    this.myForm.get('docType').disable();
    this.myForm.get('contractType').disable();
    this.myForm.get('contractPairName').disable();
    this.myForm.get('department').disable();
    this.myForm.get('depositorName').disable();
    this.myForm.get('contractSenderEmail').disable();
    this.myForm.get('contractManagerName').disable();
    this.myForm.get('contractManagerEmail').disable();
  }

  getValueFromView(viewValue: string): string {
    const departmentOption = this.department.find((option) => option.viewValue === viewValue);
    return departmentOption ? departmentOption.value : '';
  }

  async getConfig(): Promise<any> {
    try {
      const data = await this.http.get('./contract-management.config.json').toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching configuration:', error);
      throw error;
    }
  }

  async loadConfig(): Promise<void> {
    try {
      const data = await this.getConfig();
      this.smartObject = data['contract-management'];
      this.currentType = this.smartObject.types[0];

      this.isConfig = true;
    } catch (error) {
      console.log('error loadConfig() :', error);
    }
  }

  public contractValueChanges() {
    const contractValueData = this.myForm.get('contractValue').value;
    const formattedValue = this.formattingService.getFormattedValue(contractValueData);
    this.myForm.patchValue({ contractValue: formattedValue }, { emitEvent: false });
  }

  private loadEcmUserInfo() {
    this.peopleContentService.getCurrentUserInfo().subscribe((userInfo) => {
      this.userId = userInfo.id;
      this.userFullName = userInfo.firstName + ' ' + (userInfo.lastName ? userInfo.lastName : '');
      this.userEmail = userInfo.email;
    });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  async generateDocumentNo(departmentValue) {
    if (this.selectedContractOriginalActive) {
      return;
    }

    const docNo = await this.contractManagementService.generateDocumentNo(departmentValue);
    this.myForm.patchValue({ contractNoAuto: docNo }, { emitEvent: false });
    this.onChanged();
  }

  private async createContractData(contractValueForm, nodeId: any, nodeName: any, nodePath: any) {
    const aa = contractValueForm;
    let contractDocumentNo = '';

    if (contractValueForm.contractType === this.contractAutoTypeFix) {
      const depName = this.myForm.getRawValue().department;
      const depNo = this.getValueFromView(depName);
      const genDocNo = await this.contractManagementService.generateDocumentNo(depNo);
      contractDocumentNo = genDocNo;
    } else {
      contractDocumentNo = contractValueForm.contractNoManual;
    }

    const contractData = await this.buildContractData(contractDocumentNo, aa, nodeId, nodeName, nodePath);
    return contractData;
  }

  private async buildContractData(contractDocumentNo, aa, nodeId: any, nodeName: any, nodePath: any) {
    return {
      contract_document_no: contractDocumentNo,
      contract_item_no: contractDocumentNo,
      contract_date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
      contract_date_edit: '',
      node_id: nodeId,
      node_name: nodeName,
      node_path: nodePath,
      user_create_id: this.userId,
      user_create_fullname: this.userFullName,
      user_create_email: this.userEmail,
      remark1: '',
      remark2: '',
      remark3: '',
      app_version: this.contractManagementService.getVersionApp(),
      contract_original_active: aa.contractOriginalActive,
      contract_ref_no: aa.contractRefNo,
      doc_type: aa.docType,
      doc_type_manual: aa.docTypeManual,
      contract_name: aa.contractName,
      contract_type: aa.contractType,
      contract_no_auto: aa.contractNoAuto,
      contract_no_manual: aa.contractNoManual,
      contract_pair_name: aa.contractPairName,
      department_no: this.getValueFromView(aa.departmentName),
      department_name: aa.departmentName,
      deposit_name: aa.depositorName,
      deposit_no: '',
      contract_sender_email: aa.contractSenderEmail,
      contract_manager_name: aa.contractManagerName,
      contract_manager_email: aa.contractManagerEmail,
      contract_signing_date: aa.contractSigningDate ? this.convertDate(aa.contractSigningDate) : null,
      contract_effective_date: aa.contractEffectiveDate ? this.convertDate(aa.contractEffectiveDate) : null,
      notification_active: aa.notificationActive === '2' ? true : false,
      contract_end_date: aa.notificationActive === '2' ? this.convertDate(aa.contractEndDate) : null,
      contract_duration: aa.contractDuration,
      contract_value: aa.contractValue ? parseFloat(aa.contractValue.replace(/,/g, '')) : 0,
      contract_collateral: aa.contractCollateral,
      signature_type: aa.signatureType,
      contract_status: aa.status,
      contract_status_process: 'Draft',
      storage_duration: aa.storageDuration,
      compliance_deposit_date: aa.complianceDepositDate ? aa.complianceDepositDate : null,
      storage_location: aa.storageLocation,
      responsible_person: aa.responsiblePerson,
      listno: '1',
      send_email_active: false,
      send_email_date: null
    };
  }

  getInvalidFields(form: FormGroup): string[] {
    const invalidFields: string[] = [];
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      if (control instanceof FormControl && control.invalid) {
        invalidFields.push(key);
      } else if (control instanceof FormGroup) {
        const groupInvalidFields = this.getInvalidFields(control);
        groupInvalidFields.forEach((subKey) => invalidFields.push(`${key}.${subKey}`));
      }
    });
    return invalidFields;
  }

  convertDate(dateTemp: any): any {
    const date = new Date(dateTemp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const dayStr = day < 10 ? `0${day}` : `${day}`;
    const monthStr = month < 10 ? `0${month}` : `${month}`;
    const dateStr = `${year}-${monthStr}-${dayStr}`;
    return dateStr;
  }

  async completeForm(contractValueForm) {
    const depositRunNo = await this.contractManagementService.generateItemDepositNo();
    Swal.fire({
      title: 'Loading',
      width: 300,
      html: 'LOADING...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          const contractOriginalActive = this.myForm.getRawValue().contractOriginalActive;
          const nodeId = await this.uploadFileToAlfresco(contractValueForm, depositRunNo);
          const nodeName = this.currentFileName;
          const nodePath = this.contractManagementService.getBaseUrl() + `/#/Contact-Management/Preview?param=${nodeId}`;
          if (nodeId) {
            if (contractOriginalActive) {
              const aa = contractValueForm.value;
              // UPDATE
              const data = {
                contract_item_id: this.contractItemId,
                contract_original_active: aa.contractOriginalActive,
                contract_ref_no: aa.contractRefNo,
                contract_name: aa.contractName,
                contract_signing_date: aa.contractSigningDate ? this.convertDate(aa.contractSigningDate) : null,
                contract_effective_date: aa.contractEffectiveDate ? this.convertDate(aa.contractEffectiveDate) : null,
                notification_active: aa.notificationActive === '2' ? true : false,
                contract_end_date: aa.notificationActive === '2' ? this.convertDate(aa.contractEndDate) : null,
                contract_duration: aa.contractDuration,
                contract_value: aa.contractValue ? parseFloat(aa.contractValue.replace(/,/g, '')) : 0,
                contract_collateral: aa.contractCollateral,
                signature_type: aa.signatureType,
                contract_status: aa.status,
                storage_duration: aa.storageDuration,
                compliance_deposit_date: aa.complianceDepositDate ? aa.complianceDepositDate : null,
                storage_location: aa.storageLocation,
                responsible_person: aa.responsiblePerson,
                node_id: nodeId,
                node_name: nodeName,
                node_path: nodePath,
                contract_date_edit: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
                contract_status_process: 'Approved',
                send_email_active: false,
                send_email_date: null,
                deposit_no: depositRunNo
              };
              await this.contractManagementService.updateContractManagement(data).subscribe(
                () => {
                  this.alertInfo('บันทึกข้อมูลการต่อสัญญาเดิมสำเร็จ');
                  Swal.close();
                  this.dialogRef.close(true);
                },
                (error) => {
                  this.alertError('บันทึกข้อมูลไม่สำเร็จ', error);
                  Swal.close();
                }
              );
            } else {
              // INSERT
              const contractData = await this.createContractData(contractValueForm.value, nodeId, nodeName, nodePath);
              const data = {
                contract_document: contractData,
                contract_item: [contractData]
              };
              await this.contractManagementService.insertContractManagement(data).subscribe(
                async () => {
                  const createDate = data.contract_document.contract_date; // subject
                  const contractDocNo = data.contract_document.contract_document_no; // body
                  const contractPairName = data.contract_document.contract_pair_name; // body
                  const departName = data.contract_document.department_name; // body
                  const nodePathContract = this.contractManagementService.getBaseUrl() + `/#/Contact-Management/`;
                  const emailGroup = this.listEmailGroup;
                  await this.sendEmailNotification(createDate, contractDocNo, contractPairName, departName, nodePathContract, emailGroup);
                  this.alertInfo('บันทึกข้อมูลสำเร็จ');
                  Swal.close();
                  this.dialogRef.close(true);
                },
                (error) => {
                  this.alertError('บันทึกข้อมูลไม่สำเร็จ', error);
                  Swal.close();
                }
              );
            }
          } else {
            this.alertError('กรุณาอัปโหลดไฟล์ก่อนบันทึกข้อมูล', null);
            Swal.close();
          }
        } catch (error) {
          this.alertError('บันทึกข้อมูลไม่สำเร็จ', error);
          Swal.close();
        }
      }
    });
  }

  async sendEmailNotification(createDate, contractDocNo, contractPairName, departName, nodePathContract, emailGroup) {
    const data = {
      createDate,
      contractDocNo,
      contractPairName,
      departName,
      nodePathContract,
      emailGroup
    };
    try {
      await this.contractManagementService.sendEmailNotification(data).toPromise();
    } catch (error) {
      console.error('Error sending email notification:', error);
    }
  }

  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }

  transform(value: string): string {
    const regex = /[^\w\s.-ก-๙เแะ-ูเ-๋]/g;
    return value.replace(regex, '_');
  }

  template(tpl: any, args: any) {
    return tpl.replace(/\${([A-Za-z0-9.,:-|]*)}/g, (_: any, v: any) => {
      const d = v.split('|');
      if (d.length > 1) {
        return args[d[0]] instanceof moment ? (args[d[0]] ? args[d[0]].format(d[1]) : v) : v;
      } else {
        return args[v] ? args[v] : v;
      }
    });
  }

  renameFile(file: File, newFilename: string): File {
    return new File([file], newFilename, { type: file.type });
  }

  onChanged() {
    if (this.myForm.get('contractType').value === this.contractAutoTypeFix) {
      this.myForm.patchValue({
        contractNo: this.myForm.get('contractNoAuto').value
      });
    } else {
      this.myForm.patchValue({
        contractNo: this.myForm.get('contractNoManual').value
      });
    }

    this.currentPath = this.template(this.currentType.path, {
      ...this.myForm.getRawValue(),
      created: moment()
    });

    const date_yyyy = moment().format('YYYY');
    const date_mm = moment().format('MM');
    const date_dd = moment().format('DD');
    const extension = this.extensionFile;

    const contractOriginalActive = this.myForm.getRawValue().contractOriginalActive;
    if (contractOriginalActive) {
      const formatFile = this.currentType.fileNameApproved;
      this.currentFileName = this.template(formatFile, { ...this.myForm.getRawValue(), date_yyyy, date_mm, date_dd, extension });
    } else {
      this.currentFileName = this.template(this.currentType.fileName, { ...this.myForm.getRawValue(), date_yyyy, date_mm, date_dd, extension });
    }
  }

  onUploadFile(e: any) {
    e.stopPropagation();
    e.preventDefault();
    const fileName = e.currentTarget.files[0].name;
    let file = e.currentTarget.files[0];

    this.fileTemps = file;
    const dotIndex = fileName.lastIndexOf('.');
    const extension = fileName.substring(dotIndex + 1);

    this.extensionFile = extension;

    //   const allowedExtensions = ['pdf', 'doc', 'docx','tiff' , 'tif'];
    const allowedExtensions = ['pdf', 'doc', 'docx'];
    if (allowedExtensions.includes(extension)) {
      console.log('Condition Extension:', extension);
    } else {
      console.log('Condition Extension:', extension);
      this.alertError(`ไม่สามารถอัปโหลดประเภทไฟล์: ${extension} ได้`, null);
      return;
    }

    this.extensionFile = extension;
    this.onChanged();

    file = this.renameFile(file, 'new_filename.pdf');
    (<AbstractControl>this.myForm.controls['fileNameOriginal']).setValue(fileName);

    console.log(' this.currentType.path ', this.currentType.path);

    const reader = new FileReader();
    this.currentFile = [
      new FileModel(file, {
        parentId: '-root-',
        path: this.currentPath,
        nodeType: 'cm:content'
      })
    ];

    reader.readAsText(file);
    this.fileUploaded = true;
  }

  async uploadFileToAlfresco(model: any, depositRunNo: any): Promise<any> {
    try {
      // สำหรับต่ออายุเอกสารเดิม ให้ gen ชื่อไฟล์ใหม่โดยกำหนดจาก เลขที่รับฝาก_xxx
      const contractOriginalActive = this.myForm.getRawValue().contractOriginalActive;
      if (contractOriginalActive) {
        if (!depositRunNo) {
          console.log('depositRunNo is valid');
          Swal.close();
          return false;
        }
        this.myForm.patchValue({ depositNo: depositRunNo }, { emitEvent: false });
        const date_yyyy = moment().format('YYYY');
        const date_mm = moment().format('MM');
        const date_dd = moment().format('DD');
        const extension = this.extensionFile;
        const formatFile = this.currentType.fileNameApproved;
        this.currentFileName = this.template(formatFile, { ...this.myForm.getRawValue(), date_yyyy, date_mm, date_dd, extension });
      }

      model.name = this.currentFileName;
      const file = this.renameFile(this.fileTemps, this.currentFileName);
      const reader = new FileReader();
      this.currentFile = [
        new FileModel(file, {
          parentId: '-root-',
          path: this.currentPath,
          nodeType: 'cm:content'
        })
      ];
      reader.readAsText(file);

      this.values = model;
      if (this.currentFile && this.currentFile.length > 0) {
        this.currentFile.forEach((e) => {
          e.options = { ...e.options, ...model };
          e.options.path = this.template(this.currentPath, {
            ...model,
            created: moment()
          });
        });

        if (this.currentFile.length > 0) {
          this.uploadService.addToQueue(...this.currentFile);
          this.uploadService.uploadFilesInTheQueue();

          // const subscription = await this.uploadService.fileUploadComplete.subscribe((completed) => {
          //   const nodeId = completed.data.entry.id;
          //   subscription.unsubscribe();
          // })
          return new Promise((resolve) => {
            const subscription = this.uploadService.fileUploadComplete.subscribe((completed) => {
              const nodeId = completed.data.entry.id;
              subscription.unsubscribe();
              resolve(nodeId);
            });
          });
        }
      }
    } catch (error) {
      console.log(error);
      Swal.close();
      return false;
    }
  }
}
