import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { PeopleContentService } from '@alfresco/adf-content-services';
import { DOCUMENT } from '@angular/common';
import { AlfrescoApiService } from '@alfresco/adf-core';





export interface Type {
  id: string;
  name: string;
  path: string;
  fileName: string;
  fileNameDraft: string;
  fileNameApproved: string;
  depositRunNo: string;
  baseUrl: string;
}

export interface SmartObject {
  types: Type[];
}


@Injectable({
  providedIn: 'root'
})
export class ContractManagementService {
  environment: any;
  apiURL: any;
  apiURL_backend: any;
  headers: any;
  userName: any;

  isConfig = false;
  smartObject: SmartObject;
  currentType: Type;

  constructor(private apiService: AlfrescoApiService,
    private httpClient: HttpClient, private peopleContentService: PeopleContentService, @Inject(DOCUMENT) private document: Document
  ) {
    this.apiURL = '';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.apiURL_backend = '';
    if (this.getBaseUrl().startsWith('http://localhost')) {
      this.apiURL_backend = 'http://localhost:3804'
    }

    this.loadConfig();

  }

  async getConfig(): Promise<any> {
    try {
      const data = await this.httpClient.get('./contract-management.config.json').toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching configuration:', error);
      throw error;
    }
  }


  async loadConfig(): Promise<void> {
    try {
      const data = await this.getConfig();
      this.smartObject = data['contract-management'];
      this.currentType = this.smartObject.types[0];
      this.isConfig = true;
    } catch (error) {
      console.log('error loadConfig() :', error);
    }
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  getBaseUrl(): string {
    // this.loadConfig();
    if (this.currentType?.baseUrl) {
      return this.currentType?.baseUrl;
    } else {
      return this.document.location.origin;
    }
  }

  userId: any;
  loadEcmUserIdInfo() {
    this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {
      this.userId = userInfo.id;
    });
  }

  getVersionApp() {
    // return this.packageJson.version;
    return '';
  }

  getGroups(personId: string): Observable<any> {
    const pathService = `/people/${personId}/groups?skipCount=0&maxItems=100`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  downloadNodes(nodeId: string): Promise<any> {
    const pathService = `/nodes/${nodeId}/content?attachment=true`;
    const httpMethod = 'GET';
    return this.publicApiCall(pathService, httpMethod, [null, null, null, null, null, ['application/json'], ['blob'], null, null, 'blob'])
      .then((response: any) => {
        if (response instanceof Blob) {
          return this.blobToBase64(response);
        } else {
          return Promise.reject(new Error('Response is not of type Blob'));
        }
      });
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        const base64Content = base64String.split(';base64,')[1];
        resolve(base64Content);
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(blob);
    });
  }


  getMembersInSites(siteId: string): Observable<any> {
    const pathService = `/sites/${siteId}/members?skipCount=0&maxItems=1000`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }


  // ======================================== Contract Management ========================================

  getDocumentNo(bodyData: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/document/generate`, bodyData);
  }

  async generateDocumentNo(departmentNo: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const bodyData = {
        department_no: departmentNo
      };
      this.getDocumentNo(bodyData).subscribe({
        next: (data) => {
          const docNo = data[0].docno;
          resolve(docNo);
        },
        error: (error) => {
          console.log('error getDocumentNo:', error);
          reject(error);
        }
      });
    });
  }




  getItemNo(bodyData: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/item/generate`, bodyData);
  }

  async generateItemNo(departmentNo: string): Promise<string> {
    return new Promise<string>((resolve, _reject) => {
      const bodyData = {
        department_no: departmentNo
      };
      this.getItemNo(bodyData).subscribe({
        next: (data) => {
          const docNo = data[0].docno;
          resolve(docNo);
        },
        error: (error) => {
          console.log('error generateItemNo:', error);
          // reject(error);
        }
      });
    });
  }


  getItemDepositNo(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.apiURL_backend}/contract-management/item/generate/diposit`);
  }


  async generateItemDepositNo(): Promise<string> {
    return new Promise<string>((resolve, _reject) => {
      this.getItemDepositNo().subscribe({
        next: (data) => {
          const docNo = data[0].docno;
          resolve(docNo);
        },
        error: (error) => {
          console.log('error generateItemNo:', error);
        }
      });
    });
  }


  updateContractItem(data: any) {
    return this.httpClient.post(`${this.apiURL_backend}/contract-management/contract-item/update`, data, { headers: this.headers });
  }



  insertContractManagement(data: any) {
    return this.httpClient.post(`${this.apiURL_backend}/contract-management/insert`, data, { headers: this.headers });
  }



  updateContractManagement(data: any) {
    return this.httpClient.post(`${this.apiURL_backend}/contract-management/update-item`, data, { headers: this.headers });
  }

  insertContractItem(data: any) {
    return this.httpClient.post(`${this.apiURL_backend}/contract-management/insert-item`, data, { headers: this.headers });
  }

  insertContractItemAndUpdateDeposit(data: any) {
    return this.httpClient.post(`${this.apiURL_backend}/contract-management/insert-item/update-deposit`, data, { headers: this.headers });
  }

  searchContractManagement(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/search`, data, { headers: this.headers });
  }

  searchContractItemManagement(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/search-item`, data, { headers: this.headers });
  }

  searchContractItem(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/getdata/contract-item`, data, { headers: this.headers });
  }

  searchContractItemByStatus(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/getdata/contract-item/status-appove-exp`, data, { headers: this.headers });
  }

  searchContractItemStatusData(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/getdata/contract-item/status`, data, { headers: this.headers });
  }

  async searchContractItemStatus(contractDocumentNo: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const bodyData = {
        contract_document_no: contractDocumentNo
      };
      this.searchContractItemStatusData(bodyData).subscribe({
        next: (data: any[]) => {
          // eslint-disable-next-line radix
          const newData = data.map(item => item.contract_item_no);
          resolve(newData);
        },
        error: (error) => {
          console.log('error searchContractItemStatus():', error);
          reject(false);
        }
      });
    });
  }


  async getContractStatusProcessByApproved(contractDocumentId: any): Promise<any> {
    return new Promise<string>((resolve, reject) => {
      const bodyData = {
        contractDocumentId: contractDocumentId
      };
      this.searchContractItem(bodyData).subscribe({
        next: (data: any[]) => {
          if (data.length > 0) {
            // eslint-disable-next-line radix
            data.sort((a, b) => parseInt(b.contract_item_id) - parseInt(a.contract_item_id));
            const sortedLimitedData = data.slice(0, 1);
            const contractItem = []
            contractItem.push(sortedLimitedData[0].contract_status_process, sortedLimitedData[0].node_id, sortedLimitedData[0].deposit_no)
            resolve(JSON.stringify(contractItem));
          } else {
            resolve(JSON.stringify([]));
          }
        },
        error: (error) => {
          console.log('error getContractStatusProcessByApproved():', error);
          reject(false);
        }
      });
    });
  }

  async getContractItemIdByExp(contractDocumentNo: string): Promise<any> {
    return new Promise<string>((resolve, reject) => {
      const bodyData = {
        contract_document_no: contractDocumentNo
      };
      this.searchContractItemByStatus(bodyData).subscribe({
        next: (data: any[]) => {
          // eslint-disable-next-line radix
          data.sort((a, b) => parseInt(b.contract_item_id) - parseInt(a.contract_item_id));
          const sortedLimitedData = data.slice(0, 1);
          const contractItem = []
          contractItem.push(sortedLimitedData[0].contract_item_id, sortedLimitedData[0].contract_status_process)
          resolve(JSON.stringify(contractItem));
        },
        error: (error) => {
          console.log('error getContractStatusProcessByApproved():', error);
          reject(false);
        }
      });
    });
  }


  getContractItemByNodeId(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/getdata/contract-item/nodeid`, data, { headers: this.headers });
  }

  countContractItem(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/getdata/contract-item/count`, data, { headers: this.headers });
  }

  async getCountContractItem(contractDocumentId: string): Promise<string> {
    return new Promise<string>((resolve) => {
      const bodyData = {
        contract_document_id: contractDocumentId
      };
      this.countContractItem(bodyData).subscribe({
        next: (data: any) => {
          resolve(data.count);
        },
        error: (error) => {
          console.log('error generateItemNo:', error);
          // reject(error);
        }
      });
    });
  }


  maxContractItemId(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/getdata/contract-item/max`, data, { headers: this.headers });
  }

  async getMaxContractItemId(contractDocumentId: string): Promise<string> {
    return new Promise<string>((resolve) => {
      const bodyData = {
        contract_document_id: contractDocumentId
      };
      this.maxContractItemId(bodyData).subscribe({
        next: (data: any) => {
          resolve(data.maxId.toString());
        },
        error: (error) => {
          console.log('error generateItemNo:', error);
          // reject(error);
        }
      });
    });
  }

  searchContractItemImproveData(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/impovedata/contract-item`, data, { headers: this.headers });
  }

  // Email-Notification
  sendEmailNotification(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.apiURL_backend}/contract-management/sendemailnoti`, data, { headers: this.headers });
  }

  getListMemberFromGroup(): Observable<any> {
    const pathService = `groups/GROUP_compliance/members?skipCount=0&maxItems=1000`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  // deleteContractItems(id: any): Observable<any> {
  //   return this.httpClient.delete(url, options)<any>(`${this.apiURL_backend}/contract-management/delete/contract-item/${id}`, { headers: this.headers });
  // }

  deleteContractItems(id: any): Observable<HttpResponse<any>> {
    return this.httpClient.delete<any>(`${this.apiURL_backend}/contract-management/delete/contract-item/${id}`, { headers: this.headers, observe: 'response' });
  }

  deleteContractDocument(id: any): Observable<HttpResponse<any>> {
    return this.httpClient.delete<any>(`${this.apiURL_backend}/contract-management/delete/contract-document/${id}`, { headers: this.headers, observe: 'response' });
  }

  deleteContract(id: any): Observable<HttpResponse<any>> {
    return this.httpClient.delete<any>(`${this.apiURL_backend}/contract-management/delete/contract/${id}`, { headers: this.headers, observe: 'response' });
  }

}
