<div class="sidenav-header">
  <div class="sidenav-header-title">

    <div class="sidenav-header-title-logo" tabindex=0 (click)="toggleNavBar.emit()" (keypress)="toggleNavBar.emit()">
      <img src="{{ logo$ | async }}" title="{{'APP.TOOLTIPS.COLLAPSE_NAVIGATION' | translate}}"
        alt="{{ 'CORE.HEADER.LOGO_ARIA' | translate }}" />
    </div>

    <div class="sidenav-header-title-text" [routerLink]="landingPage">
      {{ appName$ | async | translate }}
    </div>

    <aca-toolbar [items]="actions"></aca-toolbar>


  </div>
</div>
