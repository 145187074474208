/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AppStore,
  NodeActionTypes,
  PurgeDeletedNodesAction,
  DeleteNodesAction,
  UndoDeleteNodesAction,
  CreateFolderAction,
  EditFolderAction,
  RestoreDeletedNodesAction,
  ShareNodeAction,
  ManageVersionsAction,
  UnlockWriteAction,
  UnshareNodesAction,
  CopyNodesAction,
  MoveNodesAction,
  ManagePermissionsAction,
  PrintFileAction,
  getCurrentFolder,
  getAppSelection,
  ManageAspectsAction,
  NavigateRouteAction,
  ExpandInfoDrawerAction,
  ExpandInfoDrawerCommentAction,
  ExpandInfoDrawerPropertiesAction,
  ManageRulesAction,
  ShowLoaderAction,
  ToggleInfoDrawerAction,
  ToggleInfoDrawerCommentAction,
  SmartUploadAction,
  DownloadWatermarkAction,
  DownloadAuditTrailAction,
  InternalShareAction
} from '@alfresco/aca-shared/store';
import { ContentManagementService } from '../../services/content-management.service';
import { ConfirmDialogComponent, RenditionService } from '@alfresco/adf-content-services';
import { Router } from '@angular/router';
import { NetworkService } from '../../components/audit/services/network.service';
import { NodeEntry } from '@alfresco/js-api';
import { AlfrescoApiService } from '@alfresco/adf-core';

import { HttpClient } from '@angular/common/http';

// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import * as moment_ from 'moment-timezone';
const moment = moment_;

import { AuditEntriesService } from '../../services/audit-entries.service';
import { MatDialog } from '@angular/material/dialog';

// import { jsPDF } from 'jspdf';

@Injectable()
export class NodeEffects {
  constructor(
    private store: Store<AppStore>,
    private actions$: Actions,
    private router: Router,
    private contentService: ContentManagementService,
    private renditionViewer: RenditionService,
    private auditAPI: NetworkService,
    private api: AlfrescoApiService,
    private auditEntriesService: AuditEntriesService,
    private dialog: MatDialog,
    private http: HttpClient
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  shareNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ShareNodeAction>(NodeActionTypes.Share),
        map((action) => {
          if (action.payload) {
            this.contentService.shareNode(action.payload, action.configuration?.focusedElementOnCloseSelector);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.shareNode(selection.file, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  unshareNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UnshareNodesAction>(NodeActionTypes.Unshare),
        map((action) => {
          if (action && action.payload && action.payload.length > 0) {
            this.contentService.unshareNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.contentService.unshareNodes(selection.nodes);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  shareStandardNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ShareNodeAction>(NodeActionTypes.ShareStandard),
        map((action) => {
          if (action.payload) {
            this.contentService.shareStandardNode(action.payload, action.configuration?.focusedElementOnCloseSelector);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.shareStandardNode(selection.file, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  unshareStandardNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UnshareNodesAction>(NodeActionTypes.UnshareStandard),
        map((action) => {
          if (action && action.payload && action.payload.length > 0) {
            this.contentService.unshareStandardNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.contentService.unshareStandardNodes(selection.nodes);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  purgeDeletedNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PurgeDeletedNodesAction>(NodeActionTypes.PurgeDeleted),
        map((action) => {
          if (action && action.payload && action.payload.length > 0) {
            this.contentService.purgeDeletedNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.count > 0) {
                  this.contentService.purgeDeletedNodes(selection.nodes);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  // DEV
  restoreDeletedNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RestoreDeletedNodesAction>(NodeActionTypes.RestoreDeleted),
        map((action) => {
          if (action && action.payload && action.payload.length > 0) {
            // this.contentService.restoreDeletedNodes(action.payload);
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              data: {
                title: 'Restore',
                htmlContent: `<div> <p>Are you sure you want to restore</p> </div>`
              },
              minWidth: '250px'
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result === true) {
                this.contentService.restoreDeletedNodes(action.payload);
              }
            });
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                // if (selection && selection.count > 0) {
                //   this.contentService.restoreDeletedNodes(selection.nodes);
                // }
                console.log(selection.nodes);
                const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                  data: {
                    title: 'Restore',
                    htmlContent: `<div> <p>Are you sure you want to restore <b>${selection.nodes[0].entry.name}<b></p> </div>`
                  },
                  minWidth: '250px'
                });
                dialogRef.afterClosed().subscribe((result) => {
                  if (result === true) {
                    if (selection && selection.count > 0) {
                      this.contentService.restoreDeletedNodes(selection.nodes);
                    }
                  }
                });
              });
          }
        })
      ),
    { dispatch: false }
  );

  deleteNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DeleteNodesAction>(NodeActionTypes.Delete),
        map((action) => {
          this.store.dispatch(new ShowLoaderAction(true));
          if (action && action.payload && action.payload.length > 0) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              data: {
                title: 'Delete',
                message: `<div> <p>Are you sure you want to delete </p> </div>`
              },
              minWidth: '250px'
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result === true) {
                this.contentService.deleteNodes(action.payload);
              }
            });
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                  data: {
                    title: 'Delete',
                    message: `Are you sure you want to delete ${selection.nodes[0].entry.name}`
                  },
                  minWidth: '250px'
                });
                dialogRef.afterClosed().subscribe((result) => {
                  if (result === true) {
                    if (selection && selection.count > 0) {
                      this.contentService.deleteNodes(selection.nodes);
                    }
                  }
                });
              });
          }
        })
      ),
    { dispatch: false }
  );

  undoDeleteNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UndoDeleteNodesAction>(NodeActionTypes.UndoDelete),
        map((action) => {
          if (action.payload.length > 0) {
            this.contentService.undoDeleteNodes(action.payload);
          }
        })
      ),
    { dispatch: false }
  );

  createFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateFolderAction>(NodeActionTypes.CreateFolder),
        map((action) => {
          if (action.payload) {
            this.contentService.createFolder(action.payload);
          } else {
            this.store
              .select(getCurrentFolder)
              .pipe(take(1))
              .subscribe((node) => {
                if (node && node.id) {
                  this.contentService.createFolder(node.id);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  editFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EditFolderAction>(NodeActionTypes.EditFolder),
        map((action) => {
          if (action.payload) {
            this.contentService.editFolder(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.folder) {
                  this.contentService.editFolder(selection.folder, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  copyNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CopyNodesAction>(NodeActionTypes.Copy),
        map((action) => {
          if (action.payload?.length > 0) {
            this.contentService.copyNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.contentService.copyNodes(selection.nodes, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  moveNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MoveNodesAction>(NodeActionTypes.Move),
        map((action) => {
          if (action.payload?.length > 0) {
            this.contentService.moveNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.contentService.moveNodes(selection.nodes, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  managePermissions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ManagePermissionsAction>(NodeActionTypes.ManagePermissions),
        map((action) => {
          if (action?.payload) {
            const route = 'personal-files/details';
            this.store.dispatch(new NavigateRouteAction([route, action.payload.entry.id, 'permissions']));
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  const route = 'personal-files/details';
                  this.store.dispatch(new NavigateRouteAction([route, selection.first.entry.id, 'permissions']));
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  internalShare$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InternalShareAction>(NodeActionTypes.InternalShare),
        map(() => {
          this.store
            .select(getAppSelection)
            .pipe(take(1))
            .subscribe((selection) => {
              if (selection?.file) {
                this.contentService.internalShare(selection.file);
              } else if (selection?.folder) {
                this.contentService.internalShare(selection.folder);
              }
            });
        })
      ),
    { dispatch: false }
  );

  expandInfoCommentDrawer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ExpandInfoDrawerCommentAction>(NodeActionTypes.ExpandInfoCommentDrawer),
        map((action) => {
          if (action?.payload) {
            const route = 'personal-files/details';
            this.store.dispatch(new NavigateRouteAction([route, action.payload.entry.id, 'comments']));
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  const route = 'personal-files/details';
                  this.store.dispatch(new NavigateRouteAction([route, selection.first.entry.id, 'comments']));
                }
              });
          }
          this.store.dispatch(new ToggleInfoDrawerCommentAction());
        })
      ),
    { dispatch: false }
  );

  expandInfoPropertiesDrawer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ExpandInfoDrawerPropertiesAction>(NodeActionTypes.ExpandInfoPropertiesDrawer),
        map((action) => {
          if (action?.payload) {
            const route = 'personal-files/details';
            this.store.dispatch(new NavigateRouteAction([route, action.payload.entry.id, 'properties']));
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  const route = 'personal-files/details';
                  this.store.dispatch(new NavigateRouteAction([route, selection.first.entry.id, 'properties']));
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  expandInfoDrawer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ExpandInfoDrawerAction>(NodeActionTypes.ExpandInfoDrawer),
        map((action) => {
          if (action?.payload) {
            const route = 'personal-files/details';
            this.router.navigate([route, action.payload.entry.id], {
              queryParams: {
                location: this.router.url
              }
            });
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  const route = 'personal-files/details';
                  this.router.navigate([route, selection.first.entry.id], {
                    queryParams: {
                      location: this.router.url
                    }
                  });
                }
              });
          }

          this.store.dispatch(new ToggleInfoDrawerAction());
        })
      ),
    { dispatch: false }
  );

  manageVersions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ManageVersionsAction>(NodeActionTypes.ManageVersions),
        map((action) => {
          if (action?.payload) {
            this.contentService.manageVersions(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.manageVersions(selection.file, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  printFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PrintFileAction>(NodeActionTypes.PrintFile),
        map((action) => {
          if (action && action.payload) {
            this.printFile(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.printFile(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  unlockWrite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UnlockWriteAction>(NodeActionTypes.UnlockForWriting),
        map((action) => {
          if (action && action.payload) {
            this.contentService.unlockNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.unlockNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  aspectList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ManageAspectsAction>(NodeActionTypes.ChangeAspects),
        map((action) => {
          console.log('action aspectList');

          if (action?.payload) {
            this.contentService.manageAspects(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.contentService.manageAspects(selection.nodes[0], action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  printFile(node: any) {
    if (node && node.entry) {
      // shared and favorite
      const id = node.entry.nodeId || node.entry.guid || node.entry.id;
      const mimeType = node.entry.content.mimeType;
      // const name = node.entry.name;
      if (id) {
        this.auditAPI.createAuditShareStats(id, 'nodename', 'print');
        this.renditionViewer.printFileGeneric(id, mimeType);
      }
    }
  }

  manageRules$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ManageRulesAction>(NodeActionTypes.ManageRules),
        map((action) => {
          if (action?.payload) {
            this.store.dispatch(new NavigateRouteAction(['nodes', action.payload.entry.id, 'rules']));
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.store.dispatch(new NavigateRouteAction(['nodes', selection.first.entry.id, 'rules']));
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  smartUpload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SmartUploadAction>(NodeActionTypes.SmartUpload),
        map((action) => {
          if (action.payload) {
            this.contentService.createSmartUpload(action.payload);
          } else {
            this.store
              .select(getCurrentFolder)
              .pipe(take(1))
              .subscribe((node) => {
                if (node && node.id) {
                  this.contentService.createSmartUpload(node.id);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  downloadWatermark$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DownloadWatermarkAction>(NodeActionTypes.DownloadWatermark),
        map((action) => {
          if (action.payload) {
            this.downloadWatermarkNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.downloadWatermarkNodes(selection.nodes[0]);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  private downloadWatermarkNodes(node: NodeEntry) {
    const url = `workspace://SpacesStore/${node.entry.id}`;

    this.auditAPI.createAuditShareStats(node.entry.id, node.entry.name, 'download-watermark');

    this.api
      .getInstance()
      .authClient.callApi(
        `/s/watermark/download?nodeRef=${url}`,
        'GET',
        {},
        null,
        {},
        {},
        {},
        ['application/json'],
        ['application/json', 'text/html'],
        null,
        'alfresco',
        'blob'
      )
      .then(
        (webScriptData) => {
          const contentUrl = window.URL.createObjectURL(webScriptData);
          const link = document.createElement('a');
          link.style.display = 'none';
          const fileName = this.getFileName(node.entry.name);
          link.download = fileName;
          link.href = contentUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  private getFileName(filename: string): string {
    const extension = filename.split('.').pop()?.toLowerCase();
    // Map common file extensions to their types
    const fileTypeMap: { [key: string]: string } = {
      png: '.png',
      jpg: '.jpg',
      jpeg: '.jpeg',
      tiff: '.tiff'
    };
    const fileType = fileTypeMap[extension];
    if (fileType) {
      const fileName = filename.replace(fileType, '.pdf');
      return fileName;
    }
    return filename;
  }

  downloadAuditTrail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DownloadAuditTrailAction>(NodeActionTypes.DownloadAuditTrail),
        map((action) => {
          if (action.payload) {
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.loadCustomFonts();
                  this.generatePdf(selection.nodes[0]);
                  // this.generatePdf2(selection.nodes[0]);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  loadCustomFonts = async () => {
    const fontPaths = {
      'THSarabunNew.ttf': '../../../../assets/fonts/THSarabunNew.ttf',
      'THSarabunNew Bold.ttf': '../../../../assets/fonts/THSarabunNew Bold.ttf',
      'THSarabunNew Italic.ttf': '../../../../assets/fonts/THSarabunNew Italic.ttf',
      'THSarabunNew BoldItalic.ttf': '../../../../assets/fonts/THSarabunNew BoldItalic.ttf'
    };

    for (const [key, path] of Object.entries(fontPaths)) {
      const fontData = await this.http.get(path, { responseType: 'arraybuffer' }).toPromise();
      pdfMake.vfs[key] = new Uint8Array(fontData);
    }
  };

  contentData: any;
  private async generatePdf(node: NodeEntry) {
    try {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        THSarabunNew: {
          normal: 'THSarabunNew.ttf',
          bold: 'THSarabunNew Bold.ttf',
          italics: 'THSarabunNew Italic.ttf',
          bolditalics: 'THSarabunNew BoldItalic.ttf'
        }
      };

      this.contentData = [
        {
          text: 'Audit-Trail Information',
          fontSize: 25,
          bold: true,
          margin: [0, 0, 0, 5]
        }
      ];

      await this.auditEntriesService.getNodes(node.entry.id).subscribe({
        next: (data) => {
          if (data.entry) {
            console.log('data.entry ', data.entry);
            const createdByUser = data.entry.createdByUser.displayName;
            const createdAtStr = data.entry.createdAt;
            const timeZone = moment.tz(createdAtStr, 'Asia/Bangkok');
            const createdAt = timeZone.format('DD/MM/YYYY HH:mm:ss');
            const fileName = data.entry.name;
            const nodeId = node.entry.id;
            const versionLabel = data.entry.properties['cm:versionLabel'];
            const sizeInBytes = data.entry.content.sizeInBytes;
            const mimeType = data.entry.content.mimeType;

            // Header
            this.contentData.push(
              { text: '\n' },
              {
                text: 'Created By: ' + createdByUser,
                fontSize: 15,
                bold: true,
                margin: [0, 0, 0, 5]
              },
              {
                text: 'Created At: ' + createdAt,
                fontSize: 15,
                bold: true,
                margin: [0, 0, 0, 5]
              },
              // FileName
              {
                text: 'Document Name: ' + fileName,
                fontSize: 15,
                bold: true,
                margin: [0, 0, 0, 5]
              },
              // NodeId
              {
                text: 'NodeId: ' + nodeId,
                fontSize: 15,
                bold: true,
                margin: [0, 0, 0, 5]
              },
              {
                text: 'Version: ' + versionLabel,
                fontSize: 15,
                bold: true,
                margin: [0, 0, 0, 5]
              },
              {
                text: 'Document Size: ' + sizeInBytes + ' bytes',
                fontSize: 15,
                bold: true,
                margin: [0, 0, 0, 5]
              },
              {
                text: 'Document Type: ' + mimeType,
                fontSize: 15,
                bold: true,
                margin: [0, 0, 0, 5]
              },
              { text: '\n' }
            );

            this.exportPDF(this.contentData, node.entry.id);
          }
        },
        error: (error) => {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  private async exportPDF(contentDatax: any, nodeId: any) {
    await this.auditEntriesService.getAuditData(nodeId).subscribe({
      next: (data) => {
        console.log(' data items ', data);
        data.list.entries.forEach((entry) => {
          const createdAtStr = entry.entry.createdAt;
          const createdByUserId = entry.entry.createdByUser.id;
          const createdByUserDisplayName = entry.entry.createdByUser.displayName;
          const subActions = entry.entry.values['/alfresco-access/transaction/sub-actions'];
          const path = entry.entry.values['/alfresco-access/transaction/path'];
          const action = entry.entry.values['/alfresco-access/transaction/action'];
          const type = entry.entry.values['/alfresco-access/transaction/type'];
          const user = entry.entry.values['/alfresco-access/transaction/user'];
          const timeZone = moment.tz(createdAtStr, 'Asia/Bangkok');
          const createdAt = timeZone.format('DD/MM/YYYY HH:mm:ss');
          console.log('createdAt:', createdAt);
          console.log('createdByUserId', createdByUserId);
          console.log('createdByUserDisplayName:', createdByUserDisplayName);
          console.log('subActions:', subActions);
          console.log('path:', path);
          console.log('action:', action);
          console.log('type:', type);
          console.log('user:', user);
          contentDatax.push(
            { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 515, y2: 5, lineWidth: 0.5 }] },
            { text: '\n' },
            {
              text: 'Actions: ' + action,
              fontSize: 15,
              bold: false,
              margin: [0, 0, 0, 5]
            },
            {
              text: 'Sub Actions: ' + subActions,
              fontSize: 15,
              bold: false,
              margin: [0, 0, 0, 5]
            },
            {
              text: 'Actions By: ' + createdByUserId,
              fontSize: 15,
              bold: false,
              margin: [0, 0, 0, 5]
            },
            {
              text: 'Actions At: ' + createdAt,
              fontSize: 15,
              bold: false,
              margin: [0, 0, 0, 5]
            }
          );
        }); // END Loop

        const documentDefinition = {
          content: contentDatax,
          defaultStyle: {
            font: 'THSarabunNew'
          }
        };
        pdfMake.createPdf(documentDefinition).download('audit_trail_' + nodeId + '.pdf');
      },
      error: (error) => {
        console.log(error.error.message);
      }
    });
  }
}
