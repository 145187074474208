import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewEncapsulation } from '@angular/core';
// import { FormControl, FormGroup } from '@angular/forms';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgIf, JsonPipe } from '@angular/common';
// import { MatTable } from '@angular/material/table';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
// import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { InternalShareService } from '../services/internal-share.service';


export interface PeriodicElement {
  name: string;
  id: string;
  email: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
];


@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgIf,
    JsonPipe,
    MatTableModule,
    MatAutocompleteModule,
    MatIconModule,
    MatCheckboxModule
  ],
  templateUrl: './add-user.dialog.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./add-user.dialog.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  host: { class: 'aca-adduser-dialog' }
})
export class AddUserComponent {

  searchControl = new FormControl();
  searchResults: any[] = [];
  searchTerm = '';
  displayedColumns: string[] = [
    'select',
    'name',
    'email',
    'id'
  ];

  // dataSource = new MatTableDataSource<any>();
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);


  constructor(
    private searchService: InternalShareService,
    private apiService: AlfrescoApiService,
    public dialogRef: MatDialogRef<AddUserComponent>
  ) {


    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => {
          if (term.length >= 3) {
            return this.searchService.getPeople(term, this.apiService.getInstance().contentAuth.ticket);
          } else {
            return of({ list: { entries: [] } });
          }
        }),
      )
      .subscribe((response: any) => {
        this.dataSource = response.list.entries.map(entry => entry.entry);
      });
  }

  isAllSelected(): boolean {
    return this.dataSource.data && this.selection.selected.length === this.dataSource.data.length;
  }


  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;


  }


  showSelectedRowsData() {
    console.log('Function showSelectedRowsData is called.');
    console.log(this.selection.selected);
  }

  handleCheckboxClick(_event: MouseEvent, _row: any) {
    this.showSelectedRowsData();
  }

  isValid(): boolean {
    return this.selection.selected.length > 0 ? false : true
  }



  isOk() {
    this.dialogRef.close(this.selection.selected);
  }

}
