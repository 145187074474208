export const data: object[] = [

  {
    OrderID: 10256
  },
  {
    OrderID: 10257
  },
  {
    OrderID: 10258
  },
  {
    OrderID: 10259
  },
  {
    OrderID: 10260
  },
  {
    OrderID: 10261
  },
  {
    OrderID: 10262
  }];


export const data2: object[] = [
  {
    groupId: 1, groupName: 'แผนกบัญชี', subGroup: 'ขอซึ้อ', processName: 'คอมพิวเตอร์และอุปกรณ์มากกว่า 10,001 บาท'
  },
  {
    groupId: 2, groupName: 'แผนกบัญชี', subGroup: 'ขอซึ้อ', processName: 'คอมพิวเตอร์และอุปกรณ์มากกว่า 20,001 บาท'
  },
  {
    groupId: 3, groupName: 'แผนกบัญชี', subGroup: 'ขอซึ้อ', processName: 'คอมพิวเตอร์และอุปกรณ์มากกว่า 30,001 บาท'
  },
  {
    groupId: 4, groupName: 'แผนกบัญชี', subGroup: 'ขอซึ้อ', processName: 'คอมพิวเตอร์และอุปกรณ์มากกว่า 40,001 บาท'
  }
];
