<mat-accordion multi="true" displayMode="flat">
  <!-- <button
    *ngIf="displayResetButton && facetFiltersService.responseFacets"
    mat-button
    color="primary"
    matTooltip="{{ 'SEARCH.FILTER.BUTTONS.RESET-ALL.TOOLTIP' | translate }}"
    matTooltipPosition="right"
    adf-reset-search
  >
    {{ 'SEARCH.FILTER.BUTTONS.RESET-ALL.LABEL' | translate }}
  </button> -->

  <mat-expansion-panel
    *ngFor="let category of queryBuilder.categories"
    [attr.data-automation-id]="'expansion-panel-' + category.name"
    [(expanded)]="category.expanded"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="adf-search-filter-header-title">
        {{ category.name | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <adf-search-filter-card2 [category]="category"> </adf-search-filter-card2>
  </mat-expansion-panel>

  <!-- <ng-container *ngIf="facetFiltersService.tabbedFacet && showContextFacets">
    <mat-expansion-panel [attr.data-automation-id]="'expansion-panel-'+facetFiltersService.tabbedFacet.label"
      [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="adf-search-filter-header-title">{{ facetFiltersService.tabbedFacet.label | translate
          }}</mat-panel-title>
      </mat-expansion-panel-header>
      <adf-search-facet-tabbed-content [tabbedFacet]="facetFiltersService.tabbedFacet"
        [attr.data-automation-id]="'expansion-panel-'+facetFiltersService.tabbedFacet.label">
      </adf-search-facet-tabbed-content>
    </mat-expansion-panel>
  </ng-container>

  <ng-container *ngIf="facetFiltersService.responseFacets && showContextFacets">
    <mat-expansion-panel [attr.data-automation-id]="'expansion-panel-'+field.label"
      *ngFor="let field of facetFiltersService.responseFacets" [expanded]="shouldExpand(field)">
      <mat-expansion-panel-header>
        <mat-panel-title class="adf-search-filter-header-title">{{ field.label | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <adf-search-facet-field [field]="field"></adf-search-facet-field>
    </mat-expansion-panel>
  </ng-container> -->
</mat-accordion>
