/* eslint-disable max-len */

import { AlfrescoApiService } from '@alfresco/adf-core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';



interface Group {
  group_id: string;
  group_name: string;
  remark: string;
}

interface SubGroup {
  sub_group_id: string;
  sub_group_name: string;
  group_id: string;
  group_name: string;
}

interface ConfigWorkflow {
  config_manager_workflow_id: string;
  group_id: string;
  group_name: string;
  sub_group_id: string;
  sub_group_name: string;
  process_name: string;
}



interface ConfigWorkflowUser {
  config_manager_workflow_id: string;
  listno: string;
  user_id: string;
  display_name: string;
  email: string;
}


interface GroupResponse {
  message: string;
  data: Group[];
}

interface SubGroupResponse {
  message: string;
  data: SubGroup[];
}

interface ConfigWorkflowResponse {
  message: string;
  data: ConfigWorkflow;
}

interface ConfigWorkflowResponseList {
  message: string;
  data: ConfigWorkflow[];
}

interface ConfigWorkflowUserList {
  message: string;
  data: ConfigWorkflowUser[];
}


@Injectable({
  providedIn: 'root'
})
export class ApprovalWorkflowService {

  headers: any;
  service = 'approval-workflow-backend'
  url = ''

  constructor(private httpClient: HttpClient, @Inject(DOCUMENT) private document: Document, private apiService: AlfrescoApiService) {

    if (this.getBaseUrl().startsWith('http://localhost')) {
      this.url = 'http://localhost:3805'
    }
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });

  }


  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  private callCustomApi(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callCustomApi(path, httpMethod, ...params);
  }


  getBaseUrl(): string {
    return this.document.location.origin;
  }


  // =============================================== API Backend ===================================================

  getGroup(): Observable<GroupResponse> {
    return this.httpClient.get<GroupResponse>(`${this.url}/${this.service}/config_group/data`);
  }


  createGroup(data: any): Observable<GroupResponse> {
    return this.httpClient.post<GroupResponse>(`${this.url}/${this.service}/config_group/create`, data);
  }


  deleteGroup(groupId: any): Observable<GroupResponse> {
    return this.httpClient.delete<GroupResponse>(`${this.url}/${this.service}/config_group/delete/${groupId}`);
  }

  updateGroup(data: any): Observable<GroupResponse> {
    return this.httpClient.post<GroupResponse>(`${this.url}/${this.service}/config_group/update`, data);
  }



  createSubGroup(data: any) {
    return this.httpClient.post(`${this.url}/${this.service}/config_sub_group/create`, data);
  }


  updateSubGroup(data: any): Observable<SubGroupResponse> {
    return this.httpClient.post<SubGroupResponse>(`${this.url}/${this.service}/config_sub_group/update`, data);
  }

  deleteSubGroup(groupId: any): Observable<SubGroupResponse> {
    return this.httpClient.delete<SubGroupResponse>(`${this.url}/${this.service}/config_sub_group/delete/${groupId}`);
  }


  getSubGroup(groupId: any): Observable<SubGroupResponse> {
    return this.httpClient.get<SubGroupResponse>(`${this.url}/${this.service}/config_sub_group/data/${groupId}`);
  }

  createConfigWorkflow(data: any): Observable<ConfigWorkflowResponse> {
    return this.httpClient.post<ConfigWorkflowResponse>(`${this.url}/${this.service}/config_manager_workflow/create`, data);
  }

  updateConfigWorkflow(data: any, configWorkflowId: any): Observable<ConfigWorkflowResponse> {
    return this.httpClient.put<ConfigWorkflowResponse>(`${this.url}/${this.service}/config_manager_workflow/update/${configWorkflowId}`, data);
  }

  getConfigWorkflow(): Observable<ConfigWorkflowResponseList> {
    return this.httpClient.get<ConfigWorkflowResponseList>(`${this.url}/${this.service}/config_manager_workflow/data`);
  }

  getConfigWorkflowBySupGroupId(subGroupId: any): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/${this.service}/config_manager_workflow/data/${subGroupId}`);
  }


  getConfigWorkflowUser(configWorkflowId: any): Observable<ConfigWorkflowUserList> {
    return this.httpClient.get<ConfigWorkflowUserList>(`${this.url}/${this.service}/config_workflow_user/data/${configWorkflowId}`);
  }

  deleteConfigWorkflow(configWorkflowId: any): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/${this.service}/config_manager_workflow/delete_workflow/${configWorkflowId}`);
  }

  createRequestProcess(data: any): Observable<ConfigWorkflowResponse> {
    return this.httpClient.post<ConfigWorkflowResponse>(`${this.url}/${this.service}/request_process/create`, data);
  }

  createRequestProcessDraft(data: any): Observable<ConfigWorkflowResponse> {
    return this.httpClient.post<ConfigWorkflowResponse>(`${this.url}/${this.service}/request_process_draft/create`, data);
  }

  updateRequestProcessDraft(data: any): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/${this.service}/request_process_draft/update`, data);
  }

  updateCompleteRequestProcess(data: any): Observable<HttpResponse<any>> {
    return this.httpClient.put<any>(`${this.url}/${this.service}/request_process/update_status`, data, { observe: 'response' });
  }


  deleteDraftProcess(reqProcessDraftId: any): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/${this.service}/request_process_draft/${reqProcessDraftId}`);
  }

  getRequestProcess(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/${this.service}/request_process`, data);
  }

  getRequestProcessDraft(userId: any): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/${this.service}/request_process_draft/${userId}`);
  }


  getRequestProcessUser(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/${this.service}/request_process_user`, data);
  }

  getRequestProcessUserDraft(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/${this.service}/request_process_user_draft`, data);
  }

  getRequestProcessFile(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/${this.service}/request_process_file`, data);
  }

  getRequestProcessFileDraft(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/${this.service}/request_process_file_draft`, data);
  }

  createTasks(data: any): Observable<GroupResponse> {
    return this.httpClient.post<GroupResponse>(`${this.url}/${this.service}/tasks/create`, data);
  }


  updateTasks(data: any, tasksId: any): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/${this.service}/tasks/status/${tasksId}`, data);
  }


  getDataTasks(reqProcessId: any): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/${this.service}/tasks/data/${reqProcessId}`);
  }


  getProcessComplete(userId: any): Observable<any> {

    if ('admin' === userId) {
      return this.httpClient.get<any>(`${this.url}/${this.service}/request_process/data/complete`);
    }

    return this.httpClient.get<any>(`${this.url}/${this.service}/request_process/data/complete/${userId}`);
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 7); // Adjust for Thai time (UTC+7)
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  // =============================================== API Alfresco ===================================================
  getPeople(): Observable<any> {
    const pathService = `/people?skipCount=0&maxItems=10000`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }



  createProcess(data: any): Observable<any> {
    const apiPath = '/alfresco/api/-default-/public/workflow/versions/1/processes'
    const body = data;
    return from(this.callCustomApi(
      apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  getTasks(userId: any): Observable<any> {
    let pathService = `/alfresco/api/-default-/public/workflow/versions/1/tasks?where=(assignee='${userId}')`
    if ('admin' === userId) {
      pathService = `/alfresco/api/-default-/public/workflow/versions/1/tasks`
    }
    const httpMethod = 'GET';
    return from(this.callCustomApi(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }


  approveTasks(taskId: string): Observable<any> {
    const apiPath = `/alfresco/api/-default-/public/workflow/versions/1/tasks/${taskId}?select=state,variables`
    const body = {
      'state': 'completed',
      'variables': [
        {
          'name': 'wf_reviewOutcome',
          'value': 'Approve',
          'scope': 'local'
        }
      ]
    }
    return from(this.callCustomApi(
      apiPath,
      'PUT',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  // getProcess() {
  //   // add filter completed = false
  //   const pathService = `/alfresco/api/-default-/public/workflow/versions/1/processes?completed=false`
  //   const httpMethod = 'GET';
  //   return from(this.callCustomApi(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  // }

  getProcess(userId: any) {
    let pathService = `/alfresco/api/-default-/public/workflow/versions/1/processes?where=(startUserId='${userId}')`
    if ('admin' === userId) {
      pathService = `/alfresco/api/-default-/public/workflow/versions/1/processes`
    }
    const httpMethod = 'GET';
    return from(this.callCustomApi(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }


}
