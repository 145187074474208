import { Inject, Injectable } from '@angular/core';
import { Node } from '@alfresco/js-api';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DocumentExpireService {
  constructor(@Inject(DOCUMENT) private document: Document) { }

  onActionTakeOwnership(_node: Node): void {
  }

  getBaseUrl(): string {
    return this.document.location.origin;
  }


}
