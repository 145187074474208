import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { PageComponent, PageLayoutModule, ToolbarComponent } from '@alfresco/aca-shared';
import { TextBoxComponent, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ContextMenuItemModel, ContextMenuService, FilterService, GridModule, GroupService, PageService, PageSettingsModel, SortService } from '@syncfusion/ej2-angular-grids';

import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { GroupAlfService } from '../services/group-alf.service';
import { CheckBoxSelectionService, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { MatDialog } from '@angular/material/dialog';
import { CreateGroupComponent } from './create-group/create-group.component';


export let data: Object[] = [];

@Component({
    selector: 'lib-group-manager',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        PageLayoutModule,
        ToolbarComponent,
        TextBoxModule,
        GridModule,
        FormsModule,
        ButtonModule,
        MultiSelectModule
    ],
    providers: [
        PageService,
        SortService,
        FilterService,
        GroupService,
        CheckBoxSelectionService,
        ContextMenuService
    ],
    templateUrl: './group-manager.component.html',
    styleUrls: ['./group-manager.component.scss']
})
export class GroupManagerComponent extends PageComponent implements OnInit {

    public pageSettings?: PageSettingsModel;
    public data?: object[];

    public contextMenuItems?: ContextMenuItemModel[];

    constructor(private groupAlfService: GroupAlfService, public dialog: MatDialog,) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.getGroup();


        this.contextMenuItems = [
            { text: 'Edit', target: '.e-content', id: 'update', iconCss: 'e-icons e-edit' }
        ];
    }

    @ViewChild('searchBox') searchBox: TextBoxComponent;

    async getGroup() {
        const resultGroups = await this.groupAlfService.getGroups().toPromise();
        const formattedEntry = resultGroups.list.entries
            .map(entry => ({
                isRoot: entry.entry.isRoot,
                displayName: entry.entry.displayName,
                id: entry.entry.id
            }));
        this.data = formattedEntry;
        this.pageSettings = { pageSize: 50 };
    }

    async filterGroup() {
        await this.getGroup();
        const searchTerm = this.searchBox.value.toLowerCase();
        if (searchTerm) {
            this.data = this.data.filter((group: any) => group.displayName.toLowerCase().includes(searchTerm));
        }
    }

    createGroup() {
        const dialogRef = this.dialog.open(CreateGroupComponent, {
            width: '500px',
            autoFocus: false,
            disableClose: false,
            data: null
        });
        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.getGroup();
            }
        });
    }



    contextMenuClick(args: any): void {
        if (args.item.id === 'update') {
            const id = (args.rowInfo.rowData.id);
            const displayName = (args.rowInfo.rowData.displayName);
            const data = {
                id: id,
                displayName: displayName
            }
            const dialogRef = this.dialog.open(CreateGroupComponent, {
                width: '500px',
                autoFocus: false,
                disableClose: false,
                data: data
            });
            dialogRef.afterClosed().subscribe((data) => {
                if (data) {
                    this.getGroup();
                }
            });
        }
    }



}
