/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  Validators,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TextAreaComponent, TextAreaModule, TextBoxModule, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ButtonModule, ChipListModule } from '@syncfusion/ej2-angular-buttons';
import { BreadcrumbModule } from '@syncfusion/ej2-angular-navigations';
import { CommonModule, DatePipe } from '@angular/common';
import { ContentNodeSelectorComponent, ContentNodeSelectorComponentData, PeopleContentService } from '@alfresco/adf-content-services';
import { AppConfigService, TranslationService } from '@alfresco/adf-core';
import { Store } from '@ngrx/store';

import {
  AppStore,
  SnackbarErrorAction,
  SnackbarInfoAction,
} from '@alfresco/aca-shared/store';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { TimelineAllModule, TimelineModule } from '@syncfusion/ej2-angular-layouts';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { ApprovalWorkflowService } from '../../service/approval-workflow.service';
import { BeflexWorkflowService } from '../../service/beflex-workflow.service';
import { Subject } from 'rxjs';
import { Node, Site, SitePaging, SitePagingList } from '@alfresco/js-api';
import { TranslateModule } from '@ngx-translate/core';

export interface Section {
  name: string;
}

@Component({
  standalone: true,
  imports: [
    MatDialogModule,
    DropDownListModule,
    DatePickerModule,
    TranslateModule,
    UploaderModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TextBoxModule,
    ButtonModule,
    BreadcrumbModule,
    ChipListModule,
    TextAreaModule,
    CommonModule,
    GridModule,
    TimelineModule, TimelineAllModule,
    MatListModule,
    MatDividerModule,
    DatePipe,
  ],
  selector: 'app-review-dialog',
  templateUrl: './review-dialog.component.html',
  styleUrls: ['./review-dialog.component.scss'],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Add this if mat-form-field is a Web Component
  ]
})
export class ReviewDialogComponent implements OnInit {

  @ViewChild('remark') txtRemark: TextAreaComponent;



  approvalTasks: Section[] = [];

  userId: any;
  userDisplayName: any;




  public dataPriority: { [key: string]: Object }[] = [];
  public fieldsPriority: Object = { text: 'priority_name', value: 'priority_id' };
  public textPriority = '';
  public selectedPriorityId: any;
  public selectedPriorityName: any;

  processDefinitionKey: any;

  public _requestProcessData: any;
  public _requestProcessDataUser: any;
  public _requestProcessDataFile: any;
  public _stateProcess: any;
  public _dataTemp: any;

  public lastActive = false;

  dataConfigProcess: any;

  public documentRejected = false;


  listNodeUpload: any;
  listNodeUploadActive = false;

  requestForm: FormGroup;

  titlePage = 'Review';

  constructor(
    private fb: FormBuilder,
    private peopleContentService: PeopleContentService,
    private appConfigService: AppConfigService,
    private store: Store<AppStore>,
    private dialogRef: MatDialogRef<ReviewDialogComponent>,
    private backendService: ApprovalWorkflowService,
    private beflexWorkflowService: BeflexWorkflowService,
    private dialogRefSelectedFile: MatDialog,
    private translation: TranslationService,
    @Inject(MAT_DIALOG_DATA) public _data: any,

  ) {
    this.dataPriority = [
      { priority_id: '1', priority_name: 'Low' },
      { priority_id: '2', priority_name: 'Medium' },
      { priority_id: '3', priority_name: 'High' },
    ];
    this._dataTemp = _data;

    if (this._dataTemp?.titleDialog) {
      this.titlePage = this._dataTemp.titleDialog;
    }
  }

  async ngOnInit() {
    if (this._dataTemp) {
      this._requestProcessData = this._dataTemp.requestProcessData;
      this._requestProcessDataUser = this._dataTemp.requestProcessDataUser;
      this._requestProcessDataFile = this._dataTemp.requestProcessDataFile
      const stepName = this._requestProcessData.stepName
      if (stepName && stepName !== 'Document Rejected') {
        const stepNumber = parseFloat(stepName.match(/\d+(\.\d+)?/)[0]);
        const maxListno = Math.max(...this._requestProcessDataUser.map(u => parseInt(u.listno, 10)));
        const isGreaterOrEqual = stepNumber >= maxListno;
        this.lastActive = isGreaterOrEqual;
      }
      if (stepName === 'Document Rejected') {
        this.documentRejected = true;
      }
      this.requestForm = this.fb.group({
        subject: [this._requestProcessData.request_subject, Validators.required],
        priority: [this._requestProcessData.request_priority_id, Validators.required],
        dueDate: [this._requestProcessData.request_due_date, Validators.required],
        description: [this._requestProcessData.request_description, Validators.required],
      });

    }

    if (!this._dataTemp?.titleDialog) {
      this.titlePage =  this.translation.instant('APPROVAL-WORKFLOW.MY-TASKS.REVIEW')
    }

    this.selectedPriorityId = this._requestProcessData.request_priority_id
    this.processDefinitionKey = this.appConfigService.get('appoveWorkflow.processDefinitionKey');
    this.userDisplayName = await this.loadEcmUserInfo(this._requestProcessData.workflow_start_user_id);
    this._requestProcessData.workflow_start_user_display_name = this.userDisplayName;
    const userAssignName = await this.loadEcmUserInfo(this._requestProcessData.assign_user_id);
    this._requestProcessData.assign_user_display_name = userAssignName;
    this.dataConfigProcess = [
      { 'processName': this._requestProcessData.group_name },
      { 'processName': this._requestProcessData.sub_group_name },
      { 'processName': this._requestProcessData.process_name }
    ];
    if (!this._requestProcessData.group_name) {
      this.dataConfigProcess = null;
    }

    if (this._requestProcessDataFile) {
      if (this._requestProcessDataFile.length > 0) {
        this.listNodeUploadActive = true;
        this.listNodeUpload = this._requestProcessDataFile;
      }
    }
    await this.getTask();
    await this.getCurrentUser();
  }

  public async loadEcmUserInfo(userId: any): Promise<string> {
    if (!userId) {
      return null;
    }
    const userInfo = await this.peopleContentService.getPerson(userId).toPromise();
    const firstName = userInfo.firstName;
    const lastName = userInfo.lastName ?? '';
    const displayName = `${firstName} ${lastName}`.trim();
    return displayName;
  }

  lockAgreeAction = true;
  public async getCurrentUser() {
    await this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {
      this.userId = userInfo.id;

      if (this._requestProcessData.assign_user_id) {
        if (this._requestProcessData.assign_user_id.trim() === this.userId.trim()) {
          this.lockAgreeAction = false;
        } else {
          console.log('this.lockAgreeAction ', this.lockAgreeAction);
        }
      }
    });
  }


  public async getTask() {
    try {
      const dataTask = await this.backendService.getDataTasks(this._requestProcessData.request_process_id).toPromise();
      this.approvalTasks = dataTask.data.map(task => ({
        name: `Review at: ${this.backendService.formatDate(task.created_at)}, User: ${task.tasks_user_display_name}, Status: ${task.tasks_status}, Remark: ${task.tasks_remark}`
      }));
    } catch (error) {
      console.log(error);
    }
  }

  // =================== Action ===================
  public async onReview() {
    try {
      let updateRequestProcess = null;
      if (this.documentRejected) {
        const priorityValueId = this.requestForm.get('priority').value;
        const priorityValueName = this.dataPriority.find(item => item.priority_id === priorityValueId)?.priority_name;
        const subjectValue = this.requestForm.get('subject').value;
        const dueDateValue = this.requestForm.get('dueDate').value;
        const descriptionValue = this.requestForm.get('description').value;
        if (!subjectValue) {
          this.alertError('Please enter subject', null);
          return
        }
        if (!dueDateValue) {
          this.alertError('Please enter due date', null);
          return
        }
        if (!descriptionValue) {
          this.alertError('Please enter description', null);
          return
        }
        updateRequestProcess = {
          'request_subject': subjectValue,
          'request_priority_id': priorityValueId,
          'request_priority_name': priorityValueName,
          'request_due_date': dueDateValue,
          'request_description': descriptionValue,
          'request_process_file': this.listNodeUpload,
        }
      }

      if (!this.txtRemark.value) {
        this.alertError('Please enter remark', null);
        return
      }


      const param = {
        'tasks_id': this._requestProcessData.taskId,
        'request_process_id': this._requestProcessData.request_process_id,
        'workflow_id': this._requestProcessData.workflow_id,
        'tasks_user_id': this._requestProcessData.assign_user_id,
        'tasks_user_display_name': this._requestProcessData.assign_user_display_name,
        'tasks_status': 'agree',
        'tasks_remark': this.txtRemark.value,
        'last_active': this.lastActive,
        'updateRequestProcess': updateRequestProcess
      }
      // 1.Call Api Backend
      await this.backendService.createTasks(param).subscribe(async () => {
        // 2.Call Api Alfresco Workflow
        const res = await this.beflexWorkflowService.approveTasks(this._requestProcessData.taskId).toPromise();
        console.log('res ', res);
        this.alertInfo('Agree Tasks Success');
        this.dialogRef.close(true);
      }, error => {
        this.alertError('Agree Tasks Error', error);
        console.log('error ', error)
      });
      console.log(this.txtRemark.value);
    } catch (error) {
      console.log(error);
    }
  }



  public async onRevise() {
    try {

      if (!this.txtRemark.value) {
        this.alertError('Please enter remark', null);
        return
      }

      const param = {
        'tasks_id': this._requestProcessData.taskId,
        'request_process_id': this._requestProcessData.request_process_id,
        'workflow_id': this._requestProcessData.workflow_id,
        'tasks_user_id': this._requestProcessData.assign_user_id,
        'tasks_user_display_name': this._requestProcessData.assign_user_display_name,
        'tasks_status': 'revise',
        'tasks_remark': this.txtRemark.value,
        'last_active': this.lastActive
      }


      // 1.Call Api Backend
      // 1.1 create tasks
      // 1.2 update request process
      // 1.3 update request process file

      await this.backendService.createTasks(param).subscribe(async () => {
        // 2.Call Api Alfresco Workflow
        const res = await this.beflexWorkflowService.rejectTasks(this._requestProcessData.taskId).toPromise();
        console.log('res ', res);
        this.alertInfo('Revise Tasks Success');
        this.dialogRef.close(true);
      }, error => {
        this.alertError('Revise Tasks Error', error);
        console.log('error ', error)
      });
      console.log(this.txtRemark.value);
    } catch (error) {
      console.log(error);
    }
  }


  // =================== Selector File ===================
  openSelectorDialog() {
    const select = new Subject<Node[]>();
    select.subscribe({
      complete: this.close.bind(this)
    });
    const customDropdown = new SitePaging({
      list: {
        entries: [
          {
            entry: {
              guid: '-my-',
              title: this.translation.instant('APP.BROWSE.PERSONAL.SIDENAV_LINK.LABEL')
            } as Site
          },
          {
            entry: {
              guid: '-mysites-',
              title: this.translation.instant('APP.BROWSE.LIBRARIES.MENU.MY_LIBRARIES.SIDENAV_LINK.LABEL')
            } as Site
          }
        ]
      } as SitePagingList
    });

    const data: ContentNodeSelectorComponentData = {
      selectionMode: 'multiple',
      title: 'Select file',
      dropdownHideMyFiles: true,
      currentFolderId: '-my-',
      dropdownSiteList: customDropdown,
      showSearch: true,
      showDropdownSiteList: true,
      select: select
    };

    this.dialogRefSelectedFile.open(
      ContentNodeSelectorComponent,
      {
        data, panelClass: 'adf-content-node-selector-dialog',
        width: '630px'
      }
    );

    data.select.subscribe((selections: Node[]) => {
      // Use or store selection...
      console.log('selections ', selections);

      const sectionData = selections.map((item, index) => ({
        node_id: item.id,
        node_name: item.name,
        node_path: '',
        mode_remark: '',
        listno: index + 1
      }));
      this.listNodeUpload = sectionData;
      this.listNodeUploadActive = true;

      console.log('listNodeUpload ', this.listNodeUpload);
    },
      (error) => {
        console.log('error ', error);
      },
      () => {
        this.dialogRefSelectedFile.closeAll();
      });
  }

  close() {
    this.dialogRefSelectedFile.closeAll();
  }

  onView(nodeId: any) {
    window.open(`#/Appoval-Workflow/Preview?param=${nodeId}`, '_blank');
  }

  onRemove(nodeId: any) {
    this.listNodeUpload = this.listNodeUpload.filter(
      node => node.node_id !== nodeId
    );
  }


  // ===================  Alert  ===================

  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }

  onSubmit() {
  }


}
