<form #internalShareForm="ngForm" [formGroup]="myForm" class="example-form">
  <div class="dialog-title">INTERNAL-SHARE</div>

  <mat-card class="adf-permission-card" id="adf-permission-manager-card">

    <!-- ROLE -->
    <mat-form-field class="example-full-width">
      <mat-label>SELECT ROLE</mat-label>
      <mat-select [(ngModel)]="selectedValue" name="optionRole" formControlName="optionrole" name="optionrole"
        id="optionrole">
        <mat-option *ngFor="let optionRole of optionRole" [value]="optionRole.value"> {{optionRole.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div style="margin-bottom: 25px"></div>

    <!-- DATE-EXPIRE -->
    <mat-form-field class="example-full-width">
      <mat-datetimepicker-toggle #matDatetimepickerToggle="matDatetimepickerToggle" [for]="datetimePicker" matSuffix>
      </mat-datetimepicker-toggle>
      <mat-datetimepicker #datetimePicker type="date" [timeInterval]="1"> </mat-datetimepicker>
      <input #dateTimePickerInput matInput [min]="minDate" formControlName="date" placeholder="SELECT DATE EXPIRE"
        [matDatetimepicker]="datetimePicker" />
    </mat-form-field>

    <div style="margin-bottom: 25px"></div>

    <!-- TABLE -->
    <div class="table-wrapper">

      <!-- ADD & CLEAR -->
      <div>
        <button mat-raised-button color="primary" (click)="addData()" style="margin-right: 3px;">
          ADD
        </button>
        <button mat-raised-button style="margin-left: 3px;" (click)="clearData()">
          CLEAR
        </button>
      </div>

      <table mat-table [dataSource]="dataSource" class="maxtable">
        <ng-container matColumnDef="fullname">
          <th mat-header-cell *matHeaderCellDef>FULLNAME</th>
          <td mat-cell *matCellDef="let element">{{element.displayName}}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>EMAIL</th>
          <td mat-cell *matCellDef="let element">{{element.email}}</td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="remove-cell">
            <button mat-icon-button (click)="removeRow(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>


    </div>

    <div style="text-align: right;">
      <p style="display: inline-block;">QTY {{dataSource?.length}} ROWS</p>
    </div>



  </mat-card>

  <!-- Dialog-Actions -->
  <div mat-dialog-actions align="center">
    <button mat-raised-button color="primary" [disabled]="!isFormComplete()" (click)="completeForm(internalShareForm)">COMPLETE</button>
    <button mat-raised-button mat-dialog-close>CANCEL</button>
  </div>
  <div style="margin-bottom: 15px"></div>
</form>
