import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-watermark',
  template: `
    <div class="watermark-container">
      <div class="watermark-row" *ngFor="let row of rows">
        <div class="watermark-cell" *ngFor="let col of columns">
          <div class="watermark-content">
            <div>{{ userName }}</div>
            <div class="watermark-date">{{ currentDate }}</div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .watermark-container {
      position: fixed;
      top: 15%;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 9999;
    }
    .watermark-row {
      display: flex;
      justify-content: space-between;
      height: 20%;
    }
    .watermark-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33.33%;
      color: rgba(0, 0, 0, 0.2);
      font-size: 20px;
      transform: rotate(-45deg);
    }
    .watermark-content {
      text-align: center;
    }
    .watermark-date {
      text-align: center;
    }
    @media (max-height: 1000px) {
      .watermark-container {
        top: 30%;
      }
      .watermark-content {
        margin-top: 0; /* หรือตั้งค่า margin-top ตามต้องการ */
      }
    }
    @media (max-height: 600px) {
      .watermark-container {
        top: 70%;
      }
    }
  `]
})
export class WatermarkComponent implements OnInit {
  rows: any[] = [];
  columns = new Array(3);

  @Input() userName = '';
  @Input() currentDate = '';

  ngOnInit() {
    this.updateRows(window.innerHeight);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateRows((<Window>event.target).innerHeight);
  }

  updateRows(height: number) {
    console.log('height ', height);
    if (height <= 768) {
      this.rows = new Array(3);
    } else {
      this.rows = new Array(4);
    }
  }
}
