import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OptionsRoleInterface } from '../data/options-role.interface';


@Injectable({
  providedIn: 'root'
})
export class DataConfigService {

  constructor(private http: HttpClient) {
  }


  configData: any;
  config: any;

  async loadConfig(): Promise<void> {
    try {
      const data = await this.getConfig();
      this.configData = data['contract-management'];
      this.config = this.configData;
    } catch (error) {
      console.log('error loadConfig() :', error);
    }
  }

  async getConfig(): Promise<any> {
    try {
      const data = await this.http.get('./contract-management.config.json').toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching configuration:', error);
      throw error;
    }
  }


  async getDepartmentOptions(): Promise<OptionsRoleInterface[]> {
    try {
      await this.loadConfig();
      const temp = this.config?.options?.[0]?.department || [];
      return temp;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }

  async getDocTypeOptions(): Promise<OptionsRoleInterface[]> {
    try {
      await this.loadConfig();
      const temp = this.config?.options?.[0]?.doctype || [];
      return temp;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }

  async getSignatureTypeOptions(): Promise<OptionsRoleInterface[]> {
    try {
      await this.loadConfig();
      const temp = this.config?.options?.[0]?.signature || [];
      return temp;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }

  async getStatusOptions(): Promise<OptionsRoleInterface[]> {
    try {
      await this.loadConfig();
      const temp = this.config?.options?.[0]?.status || [];
      return temp;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }

  async getResponsiblePersonOptions(): Promise<OptionsRoleInterface[]> {
    try {
      await this.loadConfig();
      const temp = this.config?.options?.[0]?.responsible_person || [];
      return temp;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }

  async getWatermark(): Promise<any> {
    try {
      await this.loadConfig();
      const temp = this.config?.watermark || [];
      return temp;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }

  // this.signatureType = await this.configService.getSignatureTypeOptions();
  // this.status = await this.configService.getStatusOptions();
  // this.responsiblePerson = await this.configService.getResponsiblePersonOptions();


}
