<ejs-chart
  style="display: block"
  [useGroupingSeparator]="groupingSeparator"
  [primaryXAxis]="primaryXAxis"
  [primaryYAxis]="primaryYAxis"
  [tooltip]="tooltip"
>
  <e-series-collection>
    <e-series
      *ngFor="let data of dataArray"
      [groupName]="data.groupName"
      [dataSource]="data.dataSource"
      type="Column"
      xName="x"
      columnWidth="0.6"
      yName="y"
      [name]="data.name"
      width="1"
      [marker]="marker"
    >
    </e-series>
  </e-series-collection>
</ejs-chart>
