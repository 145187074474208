
import { ContractManagementService } from '../services/contract-management.service';
import { CommonModule, JsonPipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { debounceTime } from 'rxjs/operators';
import { FormattingService } from '../utils/formatting.service';
import { DecimalPipe } from '@angular/common';
import { OptionsRoleInterface } from '../data/options-role.interface';
import { CONTRACTYPE_OPTIONS } from '../data/options-data';
import { FileModel, PeopleContentService, UploadService } from '@alfresco/adf-content-services';
// import moment from 'moment';
import moment from 'moment-timezone'; // ใช้ import สำหรับ ES6 module

import { HttpClient } from '@angular/common/http';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Store } from '@ngrx/store';
import {
  AppStore,
  SnackbarErrorAction,
  SnackbarInfoAction,
} from '@alfresco/aca-shared/store';
import { DataConfigService } from '../services/data-config.service';
import { createFormImproveContract, createFormImproveContractEnabled } from '../utils/form-definitions';


import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from '../utils/date-format';


export interface Type {
  id: string;
  name: string;
  path: string;
  fileName: string;
  fileNameDraft: string;
  fileNameApproved: string;
  depositRunNo: string;
  items: SmartItem[];
}

export interface SmartItem {
  id: string;
  title: string;
  placeholder: string;
  field: string;
  required: boolean;
  type: string;
  options: any[];
}

export interface SmartObject {
  types: Type[];
}


@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgIf,
    JsonPipe,
    MatTableModule,
    MatDatetimepickerModule,
    MatIconModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatGridListModule,
    MatToolbarModule,
    RadioButtonModule
  ],
  selector: 'app-improve-contract',
  templateUrl: './improve-contract.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [FormattingService, DecimalPipe,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }],
  styleUrls: ['./improve-contract.component.scss']
})
export class ImproveContractComponent implements OnInit {

  public myForm: FormGroup;

  selectedDocType: string;
  docType: OptionsRoleInterface[] = [];

  selectedContractType: string;
  contractType: OptionsRoleInterface[] = CONTRACTYPE_OPTIONS

  selectedDepartment: string;
  department: OptionsRoleInterface[] = [];


  selectedSignatureType: string;
  signatureType: OptionsRoleInterface[] = []

  selectedStatus: string;
  status: OptionsRoleInterface[] = []

  selectedResponsiblePerson: string;
  responsiblePerson: OptionsRoleInterface[] = []

  selectedNotificationActive: string;

  // selectedContractStatusProcess: string;

  selectedContractOriginalActive: any;

  fileUploaded = false;
  fileUploadedHiden = false;

  userId: any;
  userFullName: any;
  userEmail: any;

  contractNo = '';
  contractAutoTypeFix = 'เลขที่สัญญา TNI';
  depositRunNo = '';


  contractDocumentNo: any;
  contractItemId: any;
  contractDocumentId: any;
  contractItemNo: any;
  nodeName: any;

  groupComplianceActive = false;


  selectedContractStatusProcess = 'Draft';
  statusProcess = '';


  constructor(
    private dialogRef: MatDialogRef<ImproveContractComponent>,
    private formattingService: FormattingService,
    private contractManagementService: ContractManagementService,
    private peopleContentService: PeopleContentService,
    private uploadService: UploadService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppStore>,
    private configService: DataConfigService,
  ) {

    console.log('# ImproveContractComponent');



    this.contractDocumentNo = this.data.contractDocumentNo;
    this.contractItemId = this.data.contractItemId;
    this.groupComplianceActive = this.data.groupComplianceActive;

    if (this.groupComplianceActive) {
      this.myForm = createFormImproveContractEnabled();
    } else {
      this.myForm = createFormImproveContract();
    }

    console.log('base_url: ', this.contractManagementService.getBaseUrl());



  }

  async ngOnInit() {

    await this.loadConfig();

    await this.formOptions();

    await this.loadDepositRunNo();

    this.loadEcmUserInfo();

    await this.feedData();

    this.formValueChanges();
  }

  private async loadDepositRunNo() {
    this.depositRunNo = await this.contractManagementService.generateItemDepositNo();
  }

  private async formOptions() {
    this.department = await this.configService.getDepartmentOptions();
    this.docType = await this.configService.getDocTypeOptions();
    this.signatureType = await this.configService.getSignatureTypeOptions();
    this.status = await this.configService.getStatusOptions();
    this.responsiblePerson = await this.configService.getResponsiblePersonOptions();
  }

  private formValueChanges() {

    if (!this.myForm) {
      console.error('Form is not initialized');
      return;
    }



    if (this.myForm.get('department')) {
      this.myForm.get('department').valueChanges.pipe(
        debounceTime(300)
      ).subscribe(async () => {
        if (this.myForm.get('contractType').value === this.contractAutoTypeFix) {
        } else {
          this.onChanged();
        }
      });
    }


    if (this.myForm.get('contractType')) {
      this.myForm.get('contractType').valueChanges.pipe(
        debounceTime(300)
      ).subscribe(async (contractTypeValue) => {
        const departmentValue = this.myForm.get('department').value;
        if (contractTypeValue === this.contractAutoTypeFix && departmentValue) {
        } else {
          this.onChanged();
        }
      });
    }

    if (this.myForm.get('contractNoManual')) {
      this.myForm.get('contractNoManual').valueChanges.pipe(
        debounceTime(300)
      ).subscribe(async () => {
        this.onChanged()
      });
    }


    if (this.myForm.get('contractStatusProcess')) {
      this.myForm.get('contractStatusProcess').valueChanges.pipe(
        debounceTime(300)
      ).subscribe(async (contractStatusProcessValue) => {

        console.log('this.statusProcess ', this.statusProcess);

        console.log('contractStatusProcessValue ', contractStatusProcessValue);


        if (this.statusProcess === 'Expired') {
          this.myForm.get('depositNo').disable();
          return;
        }

        if (contractStatusProcessValue === 'Approved') {
          this.myForm.patchValue({ depositNo: this.depositRunNo }, { emitEvent: false });
          this.myForm.get('depositNo').disable();
        } else {
          this.myForm.patchValue({ depositNo: '' }, { emitEvent: false });
          this.myForm.get('depositNo').disable();
        }

      });
    }


    if (this.myForm.get('contractEffectiveDate')) {
      this.myForm.get('contractEffectiveDate')?.valueChanges.subscribe(() => {
        this.calculateDuration();
      });
    }


    if (this.myForm.get('contractEndDate')) {
      this.myForm.get('contractEndDate')?.valueChanges.subscribe(() => {
        this.calculateDuration();
      });
    }

    this.myForm.get('docType').valueChanges.subscribe(value => {
      const docTypeManualControl = this.myForm.get('docTypeManual');
      if (value === 'อื่น ๆ ระบุ') {
        docTypeManualControl.setValidators([Validators.required]);
      } else {
        docTypeManualControl.clearValidators();
      }
      docTypeManualControl.updateValueAndValidity();
    });


  }

  private calculateDuration(): void {
    const start = this.myForm.get('contractEffectiveDate')?.value;
    const end = this.myForm.get('contractEndDate')?.value;
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const duration = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
      this.myForm.get('contractDuration')?.setValue(duration);
    } else {
      this.myForm.get('contractDuration')?.setValue(null);
    }
  }

  private getValueFromView(viewValue: string): string {
    const departmentOption = this.department.find(option => option.viewValue === viewValue);
    return departmentOption ? departmentOption.value : '';
  }

  async getConfig(): Promise<any> {
    try {
      const data = await this.http.get('./contract-management.config.json').toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching configuration:', error);
      throw error;
    }
  }

  isConfig = false;
  smartObject: SmartObject;
  async loadConfig(): Promise<void> {
    try {
      const data = await this.getConfig();
      this.smartObject = data['contract-management'];
      this.currentType = this.smartObject.types[0];
      this.isConfig = true;
    } catch (error) {
      console.log('error loadConfig() :', error);
    }
  }


  public contractValueChanges() {
    const contractValueData = this.myForm.get('contractValue').value;
    const formattedValue = this.formattingService.getFormattedValue(contractValueData);
    this.myForm.patchValue({ contractValue: formattedValue }, { emitEvent: false });
  }

  private loadEcmUserInfo() {
    this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {
      this.userId = userInfo.id;
      this.userFullName = userInfo.firstName + ' ' + (userInfo.lastName ? userInfo.lastName : '');
      this.userEmail = userInfo.email;
    });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  async generateDocumentNo(departmentValue) {
    const docNo = await this.contractManagementService.generateItemNo(departmentValue);
    this.myForm.patchValue({ contractNoAuto: docNo }, { emitEvent: false });
    this.onChanged();
  }


  private async createContractData(contractValueForm, nodeId: any, nodeName: any, nodePath: any, contractDocumentId, contractDocumentNo) {

    const aa = contractValueForm;


    const contractData = await this.buildContractData(contractDocumentId, contractDocumentNo, aa, nodeId, nodeName, nodePath);

    return contractData;

  }

  convertDate(date: Date): any {

    if (typeof date === 'string') {
      date = new Date(date);
    }
    console.log('date ', date);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const dayStr = day < 10 ? `0${day}` : `${day}`;
    const monthStr = month < 10 ? `0${month}` : `${month}`;
    const dateStr = `${year}-${monthStr}-${dayStr}`;
    return dateStr;
  }


  private async buildContractData(contractDocumentId, contractDocumentNo, aa, nodeId: any, nodeName: any, nodePath: any) {

    let contractItemNo = '';
    if (aa.contractType === this.contractAutoTypeFix) {
      contractItemNo = aa.contractNoAuto
    } else {
      contractItemNo = aa.contractNoManual;
    }


    return {
      'contract_document_id': contractDocumentId,
      'contract_document_no': contractDocumentNo,
      'contract_item_no': contractItemNo,
      'contract_date': moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
      'contract_date_edit': moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
      'node_id': nodeId,
      'node_name': nodeName,
      'node_path': nodePath,
      'user_create_id': this.userId,
      'user_create_fullname': this.userFullName,
      'user_create_email': this.userEmail,
      'remark1': '',
      'remark2': '',
      'remark3': '',
      'app_version': this.contractManagementService.getVersionApp(),
      'contract_original_active': aa.contractOriginalActive,
      'contract_ref_no': aa.contractRefNo,
      'doc_type': aa.docType,
      'doc_type_manual': aa.docTypeManual,
      'contract_name': aa.contractName,
      'contract_type': aa.contractType,
      'contract_no_auto': aa.contractType === this.contractAutoTypeFix ? aa.contractNoAuto : '',
      'contract_no_manual': aa.contractType !== this.contractAutoTypeFix ? aa.contractNoManual : '',
      'contract_pair_name': aa.contractPairName,
      'department_no': this.getValueFromView(aa.department),
      'department_name': aa.departmentName,
      'deposit_name': aa.depositorName,
      'deposit_no': aa.depositNo,
      'contract_sender_email': aa.contractSenderEmail,
      'contract_manager_name': aa.contractManagerName,
      'contract_manager_email': aa.contractManagerEmail,
      'contract_signing_date': aa.contractSigningDate ? this.convertDate(aa.contractSigningDate) : null,
      'contract_effective_date': aa.contractEffectiveDate ? this.convertDate(aa.contractEffectiveDate) : null,
      'notification_active': aa.notificationActive === '2' ? true : false,
      'contract_end_date': aa.notificationActive === '2' ? this.convertDate(aa.contractEndDate) : null,
      'contract_duration': aa.contractDuration,
      'contract_value': aa.contractValue ? parseFloat(aa.contractValue.replace(/,/g, '')) : 0,
      'contract_collateral': aa.contractCollateral,
      'signature_type': aa.signatureType,
      'contract_status': aa.status,
      'contract_status_process': aa.contractStatusProcess,
      'storage_duration': aa.storageDuration,
      'compliance_deposit_date': aa.complianceDepositDate ? aa.complianceDepositDate : null,
      'storage_location': aa.storageLocation,
      'responsible_person': aa.responsiblePerson,
      'send_email_active': false,
      'send_email_date': null,
    };
  }


  logInvalidFields(form: FormGroup) {
    const invalidFields = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalidFields.push(name);
      }
    }
    console.log('Invalid fields:', invalidFields);
  }

  async completeForm(contractValueForm) {

    console.log('contractValueForm.invalid ', contractValueForm.invalid);
    console.log('islockCompleteActive ', this.islockCompleteActive);


    if (this.statusProcess === 'Expired') {
      this.alertError('เนื่องจากเอกสาร Expired ไม่สามารถอัพเดทได้', null);
      return;
    }

    const maxContractItemId = await this.contractManagementService.getMaxContractItemId(this.contractDocumentId);
    if (maxContractItemId !== this.contractItemId) {
      this.alertError('ข้อมูลนี้ถูกปรับปรุงไปแล้ว กรุณาตรวจสอบข้อมูลอีกครั้ง', null)
      this.dialogRef.close(this.contractDocumentId);
      return;
    }

    if (this.myForm.getRawValue().contractStatusProcess === 'Approved' && this.statusProcess !== 'Expired') {
      this.depositRunNo = await this.contractManagementService.generateItemDepositNo();
      this.myForm.patchValue({ depositNo: this.depositRunNo }, { emitEvent: false });
    }

    Swal.fire({
      title: 'Loading',
      width: 300,
      html: 'LOADING...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          const nodeId = await this.uploadFileToAlfresco(contractValueForm.getRawValue());
          const nodeName = this.currentFileName;
          const nodePath = this.contractManagementService.getBaseUrl() + `/#/Contact-Management/Preview?param=${nodeId}`
          if (nodeId) {
            const contractDocumentId = this.contractDocumentId;
            const contractDocumentNo = this.contractDocumentNo;
            const contractData = await this.createContractData(contractValueForm.getRawValue(), nodeId, nodeName, nodePath, contractDocumentId, contractDocumentNo);
            const data = {
              'contract_item': contractData
            };
            await this.contractManagementService.insertContractItemAndUpdateDeposit(data).subscribe(response => {
              console.log('data ', data);
              console.log('response ', response);
              this.alertInfo('บันทึกข้อมูลสำเร็จ');
              Swal.close();
              this.dialogRef.close(this.contractDocumentId);
            }, error => {
              this.alertError('บันทึกข้อมูลไม่สำเร็จ', error)
              Swal.close();
            });
          } else {
            this.alertError('กรุณาอัปโหลดไฟล์ก่อนบันทึกข้อมูล', null)
            Swal.close();
          }
        } catch (error) {
          this.alertError('บันทึกข้อมูลไม่สำเร็จ', error)
          Swal.close();
        }
      }
    });

  }

  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 2000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 2000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 2000 }));
  }



  islockCompleteActive = false;
  // ============================= FEEDDATA =============================
  async feedData() {
    // 1.Get From API
    // 2.Set Value to Form
    const data = {
      'contract_item_id': this.contractItemId,
    }


    await this.contractManagementService.searchContractItemImproveData(data).subscribe(response => {
      if (response) {
        response.forEach(async item => {

          this.contractDocumentId = item.contract_document_id;
          this.contractItemNo = item.contract_item_no;
          this.nodeName = item.node_name;

          this.statusProcess = item.contract_status_process;

          this.myForm.setValue({
            contractOriginalActive: item.contract_original_active,
            contractRefNo: item.contract_ref_no,
            docType: item.doc_type,
            depositNo: item.deposit_no,
            docTypeManual: item.doc_type_manual,
            contractName: item.contract_name,
            contractType: item.contract_type,
            contractNoAuto: item.contract_no_auto,
            contractNoManual: item.contract_no_manual,
            contractNo: item.contract_document_no,
            contractPairName: item.contract_pair_name,
            department: item.department_name,
            departmentName: item.department_name,
            depositorName: item.deposit_name,
            contractSenderEmail: item.contract_sender_email,
            contractManagerName: item.contract_manager_name,
            contractManagerEmail: item.contract_manager_email,
            contractSigningDate: item.contract_signing_date ? item.contract_signing_date : null,
            contractEffectiveDate: item.contract_effective_date ? item.contract_effective_date : null,
            notificationActive: item.notification_active ? '2' : '1',
            contractEndDate: item.contract_end_date ? item.contract_end_date : null,
            contractDuration: item.contract_duration ? item.contract_duration : 0,
            contractValue: item.contract_value ? this.formattingService.getFormattedValue(item.contract_value) : 0,
            contractCollateral: item.contract_collateral,
            signatureType: item.signature_type,
            status: item.contract_status,
            storageDuration: item.storage_duration ? item.storage_duration : 0,
            complianceDepositDate: item.compliance_deposit_date ? item.compliance_deposit_date : null,
            storageLocation: item.storage_location,
            responsiblePerson: item.responsible_person,
            fileNameOriginal: item.node_name,
            contractStatusProcess: item.contract_status_process
          });




          const maxContractItemId = await this.contractManagementService.getMaxContractItemId(this.contractDocumentId);
          if (maxContractItemId !== this.contractItemId) {
            this.islockCompleteActive = true;
          }
          console.log('maxContractItemId ', maxContractItemId);

          console.log('this.contractItemId ', this.contractItemId);

          console.log('this.islockCompleteActive ', this.islockCompleteActive);


        });
      }
    }, error => {
      console.error('error filterData() :', error);
    });







  }

  // ============================= UPLOAD ===============================

  currentType: Type;
  currentFile: FileModel[];
  currentPath = '';
  currentFileName = '';
  fileTemps: any;
  extensionFile = '';
  values: any;

  transform(value: string): string {
    const regex = /[^\w\s.-]/g;
    return value.replace(regex, '_');
  }

  template(tpl: any, args: any) {
    return tpl.replace(/\${([A-Za-z0-9.,:-|]*)}/g, (_: any, v: any) => {
      const d = v.split('|');
      if (d.length > 1) {
        return args[d[0]] instanceof moment
          ? args[d[0]]
            ? args[d[0]].format(d[1])
            : v
          : v;
      } else {
        return args[v] ? args[v] : v;
      }
    });
  }

  renameFile(file: File, newFilename: string): File {
    return new File([file], newFilename, { type: file.type });
  }

  async onChanged() {
    if (this.myForm.get('contractType').value === this.contractAutoTypeFix) {
      this.myForm.patchValue({
        contractNo: this.myForm.get('contractNoAuto').value,
      });
    } else {
      this.myForm.patchValue({
        contractNo: this.myForm.get('contractNoManual').value,
      });
    }
    this.currentPath = this.template(this.currentType.path, {
      ...this.myForm.getRawValue(),
      created: moment()
    });
    const date_yyyy = moment().format('YYYY');
    const date_mm = moment().format('MM');
    const date_dd = moment().format('DD');
    const extension = this.extensionFile;


    if (this.myForm.getRawValue().contractStatusProcess === 'Draft') {
      const countItem = await this.contractManagementService.getCountContractItem(this.contractDocumentId);
      const formatFile = this.currentType.fileNameDraft;
      this.currentFileName = this.template(formatFile, { ...this.myForm.getRawValue(), date_yyyy, date_mm, date_dd, countItem, extension });
    } else if (this.myForm.getRawValue().contractStatusProcess === 'Approved') {
      const formatFile = this.currentType.fileNameApproved;
      this.currentFileName = this.template(formatFile, { ...this.myForm.getRawValue(), date_yyyy, date_mm, date_dd, extension });
    }


  }


  allowUploadFile = false;
  extensionFileStr: any;

  onUploadFile(e: any) {
    e.stopPropagation();
    e.preventDefault();
    const fileName = e.currentTarget.files[0].name;
    let file = e.currentTarget.files[0];
    this.fileTemps = file;
    const dotIndex = fileName.lastIndexOf('.');
    const extension = fileName.substring(dotIndex + 1);

    this.extensionFileStr = extension;

    const allowedExtensions = ['pdf', 'doc', 'docx'];
    if (allowedExtensions.includes(extension)) {
      console.log('Condition Extension:', extension);
      this.allowUploadFile = true;
    } else {
      console.log('Condition Extension:', extension);
      this.alertError(`ไม่สามารถอัปโหลดประเภทไฟล์: ${extension} ได้`, null);
      return;
    }



    this.extensionFile = extension;
    this.onChanged();
    file = this.renameFile(file, 'new_filename.pdf');
    (<AbstractControl>this.myForm.controls['fileNameOriginal']).setValue(fileName);
    const reader = new FileReader();
    this.currentFile = [
      new FileModel(file, {
        parentId: '-root-',
        path: this.currentPath,
        nodeType: 'cm:content'
      })
    ];
    reader.readAsText(file);
    this.fileUploaded = true;
  }

  async uploadFileToAlfresco(model: any): Promise<any> {
    try {
      await this.onChanged();
      // this.currentFileName = this.transform(this.currentFileName);
      model.name = this.currentFileName;
      const file = this.renameFile(this.fileTemps, this.currentFileName);
      const reader = new FileReader();
      this.currentFile = [
        new FileModel(file, {
          parentId: '-root-',
          path: this.currentPath,
          nodeType: 'cm:content'
        })
      ];
      reader.readAsText(file);

      this.values = model;
      if (this.currentFile && this.currentFile.length > 0) {
        this.currentFile.forEach(e => {
          e.options = { ...e.options, ...model };
          e.options.path = this.template(this.currentPath, {
            ...model,
            created: moment()
          });
        });

        if (this.currentFile.length > 0) {
          this.uploadService.addToQueue(...this.currentFile);
          this.uploadService.uploadFilesInTheQueue();
          return new Promise((resolve) => {
            const subscription = this.uploadService.fileUploadComplete.subscribe((completed) => {
              const nodeId = completed.data.entry.id;
              subscription.unsubscribe();
              resolve(nodeId);
            });
          });
        }
      }
      return false;
    } catch (error) {
      console.log('error uploadFileToAlfresco: ', error);
      Swal.close();
      return false;
    }
  }

}
