import { Component, ViewEncapsulation, ViewChild, Input, OnInit, OnDestroy, CUSTOM_ELEMENTS_SCHEMA, HostListener } from '@angular/core';
import {
  PdfViewerComponent,
  LinkAnnotationService,
  BookmarkViewService,
  MagnificationService,
  ThumbnailViewService,
  ToolbarService,
  NavigationService,
  TextSearchService,
  TextSelectionService,
  PrintService,
  AnnotationService,
  FormFieldsService,
  FormDesignerService,
  LoadEventArgs,
  // PageOrganizerService,
  CustomToolbarItemModel
  // PageOrganizerService,
} from '@syncfusion/ej2-angular-pdfviewer';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ToolbarModule, MenuModule, ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { MessageModule } from '@syncfusion/ej2-angular-notifications';
import { SpeedDialItemEventArgs, SpeedDialItemModel, SpeedDialModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { PdfViewerModule } from '@syncfusion/ej2-angular-pdfviewer';
import { FabModule } from '@syncfusion/ej2-angular-buttons';
import { SwitchComponent } from '@syncfusion/ej2-angular-buttons';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
// import { WebViewerClass, WebViewerUpdateClass } from './models/webviewer.model';
import { NetworkService } from './services/network.service';
// import { DialogUtility } from '@syncfusion/ej2-popups';
import { Node } from '@alfresco/js-api';
import {
  // AlfrescoApiService,
  AppConfigService
} from '@alfresco/adf-core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { ViewerExtensionInterface } from '@alfresco/adf-extensions';
import { ActivatedRoute } from '@angular/router';

// import { ToolItemSaveAnnotation, ToolItemSaveSign, ToolItemDownloadAnnotation, ToolItemDownloadOriginal, ToolItemOpenHistory } from './utils/toolbar-items';
import { Toolbar_Annotation, Toolbar_NoAnnotation } from './utils/toolbar-settings';

// import { PACKAGE_JSON } from '@alfresco/aca-content/about';

// import { roleSelector } from './utils/permission-role-config';

import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { FormsModule } from '@angular/forms';
import { WebviewerExtService } from './webviewer-ext.service';
import { WebViewerClass, WebViewerUpdateClass } from './models/webviewer.model';
import { NotificationService } from '@alfresco/adf-core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import moment from 'moment';
import { ConfirmDialogComponent, PeopleContentService } from '@alfresco/adf-content-services';

import * as pako from 'pako';

import { PdfDocument } from '@syncfusion/ej2-pdf';

@Component({
  standalone: true,
  imports: [
    PdfViewerModule,
    FormsModule,
    MenuModule,
    ToolbarModule,
    DialogModule,
    MessageModule,
    SwitchModule,
    GridModule,
    ButtonModule,
    CommonModule,
    SpeedDialModule
  ],
  selector: 'lib-webviewer-ext',
  templateUrl: 'webviewer-ext.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService,
    NavigationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
    AnnotationService,
    FormFieldsService,
    FormDesignerService,
    FabModule
    // PageOrganizerService
  ],

  styleUrls: ['webviewer-ext.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WebviewerExtComponent implements OnInit, OnDestroy, ViewerExtensionInterface {
  // @ViewChild('pdfviewer')
  // public pdfviewerControl: PdfViewerComponent;

  @ViewChild('pdfviewer') public pdfviewerControl: PdfViewerComponent;

  @ViewChild('switch')
  public switch: SwitchComponent;

  @Input()
  url: string;

  @Input()
  nodeId: string;

  @Input()
  nodeRef: any;

  @Input()
  node: Node;

  // public document = '';
  public isShareLink = false;

  public document: string = '';
  public resource: string = '';

  // public document: string = 'https://cdn.syncfusion.com/content/pdf/pdf-succinctly.pdf';
  // public resource:string = "https://cdn.syncfusion.com/ej2/23.2.6/dist/ej2-pdfviewer-lib";

  globalConfigAnnotation: any;

  toolbarSettings = Toolbar_NoAnnotation;

  // Disable config Annontation
  toolbarSettings2 = Toolbar_NoAnnotation;

  userId: any;
  userInfo: any;

  selectorPermission: any;
  annotationData: any;
  nodeInfo: any;
  nodeName: any;

  public initialRender = 2;
  deleyTimeout: any;
  isFirstLoad = false;

  public items: SpeedDialItemModel[] = [
    {
      id: 'UseEditAnnotation',
      text: 'Save Annotation',
      iconCss: 'e-icon icons-save-annotation',
      disabled: false
    },
    {
      id: 'DownloadAnnotation',
      text: 'Download With Annotation',
      iconCss: 'e-icon icons-download-with-annotation',
      disabled: false
    },
    {
      id: 'DownloadWatermark',
      text: 'Download With Watermark',
      iconCss: 'e-icon icons-download-with-watermark',
      disabled: false
    },
    {
      id: 'DownloadOriginal',
      text: 'Download Original',
      iconCss: 'e-icon icons-download-original',
      disabled: false
    },
    {
      id: 'HideAnnotation',
      text: 'Hide Annotation',
      iconCss: 'e-icon icons-hide-annotation',
      disabled: false
    },
    {
      id: 'UnhideAnnotation',
      text: 'UnHide Annotation',
      iconCss: 'e-icon icons-unhide-annotation',
      disabled: false
    }
  ];

  public roleSelector = Object.freeze({
    role: {
      Preview: {
        matchRole: ['SITEPREVIEW', 'PREVIEW'],
        Preview: true,
        Upload: false,
        Edit: false,
        DownloadOriginal: false,
        DownloadWatermark: false,
        Delete: false,
        UseEditAnnotation: false,
        UseEditAnnotationAll: false,
        PreviewAnnotation: true,
        DownloadAnnotation: false,
        HideAnnotation: false,
        UnhideAnnotation: false
      },
      DownloadWatermark: {
        matchRole: ['SITECANDOWNLOADWATERMARK', 'CANDOWNLOADWATERMARK'],
        Preview: true,
        Upload: false,
        Edit: false,
        DownloadOriginal: false,
        DownloadWatermark: true,
        Delete: false,
        UseEditAnnotation: false,
        UseEditAnnotationAll: false,
        PreviewAnnotation: true,
        DownloadAnnotation: false,
        HideAnnotation: false,
        UnhideAnnotation: false
      },
      Consumer: {
        matchRole: ['SITECONSUMER', 'CONSUMER'],
        Preview: true,
        Upload: false,
        Edit: false,
        DownloadOriginal: true,
        DownloadWatermark: true,
        Delete: false,
        UseEditAnnotation: false,
        UseEditAnnotationAll: false,
        PreviewAnnotation: true,
        DownloadAnnotation: true,
        HideAnnotation: true,
        UnhideAnnotation: true
      },
      Contributor: {
        matchRole: ['SITECONTRIBUTOR', 'CONTRIBUTOR'],
        Preview: true,
        Upload: true,
        Edit: true,
        DownloadOriginal: true,
        DownloadWatermark: true,
        Delete: true,
        UseEditAnnotation: true,
        UseEditAnnotationAll: false,
        PreviewAnnotation: true,
        DownloadAnnotation: true,
        HideAnnotation: true,
        UnhideAnnotation: true
      },
      Editor: {
        matchRole: ['SITEEDITOR', 'EDITOR'],
        Preview: true,
        Upload: false,
        Edit: true,
        DownloadOriginal: true,
        DownloadWatermark: true,
        Delete: true,
        UseEditAnnotation: true,
        UseEditAnnotationAll: true,
        PreviewAnnotation: true,
        DownloadAnnotation: true,
        HideAnnotation: true,
        UnhideAnnotation: true
      },
      Collaborator: {
        matchRole: ['SITECOLLABORATOR', 'COLLABORATOR'],
        Preview: true,
        Upload: true,
        Edit: true,
        DownloadOriginal: true,
        DownloadWatermark: true,
        Delete: true,
        UseEditAnnotation: true,
        UseEditAnnotationAll: true,
        PreviewAnnotation: true,
        DownloadAnnotation: true,
        HideAnnotation: true,
        UnhideAnnotation: true
      },
      Coordinator: {
        matchRole: ['SITEMANAGER', 'COORDINATOR'],
        Preview: true,
        Upload: true,
        Edit: true,
        DownloadOriginal: true,
        DownloadWatermark: true,
        Delete: true,
        UseEditAnnotation: true,
        UseEditAnnotationAll: true,
        PreviewAnnotation: true,
        DownloadAnnotation: true,
        HideAnnotation: true,
        UnhideAnnotation: true
      }
    }
  });

  // versionApp: any;
  // public packageJson? = inject(PACKAGE_JSON, { optional: true });

  public pageOrganizerSettings = { canDelete: false, canInsert: false, canRotate: true, canCopy: false, canRearrange: true, canImport: false };

  constructor(
    private route: ActivatedRoute,
    public trans: TranslateService,
    public networkService: NetworkService,
    private webviewerExtService: WebviewerExtService,
    private appConfig: AppConfigService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    // private apiAlfrescoService: AlfrescoApiService,
    private httpClient: HttpClient,
    private peopleContentService: PeopleContentService
  ) {
    const flag = appConfig.get<boolean | string>('canAnnotation.enabled');
    this.globalConfigAnnotation = flag;
    // this.versionApp = this.packageJson.version;
  }

  nameFile: string;

  // Custom Rotate Toolbar Item
  public toolItem1: CustomToolbarItemModel = {
    prefixIcon: 'e-icons e-transform-left',
    id: 'rotateAnticlockwise',
    tooltipText: 'Custom toolbar item'
  };
  public toolItem2: CustomToolbarItemModel = {
    prefixIcon: 'e-icons e-transform-right',
    id: 'rotateClockwise',
    tooltipText: 'Custom toolbar item'
  };
  public toolItem3: CustomToolbarItemModel = {
    text: 'Rotate All',
    id: 'rotateall',
    tooltipText: 'Custom toolbar item'
  };

  async ngOnInit() {
    console.log('# WebviewerExtComponent');
    // =============================== Setting ===============================
    createSpinner({
      target: document.getElementById('spinner'),
      width: '70px',
      label: 'Loading.'
    });

    document.addEventListener('keydown', this.disableCtrlP);

    this.deleyTimeout = this.appConfig.get<boolean | any>('deleyTimeoutWebviewer.value');
    if (!this.deleyTimeout) {
      this.deleyTimeout = 1000;
    }

    // =============================== Logic ===============================
    const userInfo = await this.peopleContentService.getCurrentUserInfo().toPromise();
    this.userId = userInfo.id;
    this.userInfo = userInfo;

    this.route.params.subscribe(async (_params) => {
      if (!this.userId) {
        const userInfo = await this.peopleContentService.getCurrentUserInfo().toPromise();
        this.userId = userInfo.id;
        this.userInfo = userInfo;
      }

      this.nodeId = _params.nodeId;

      console.log('this.nodeId = ', this.nodeId);

      let versionId = '';
      if (_params.versionId) {
        versionId = _params.versionId;
      }

      const urlDocument = this.url.replace(/^\./, '');
      const extractedValue = this.extractValueBetween(urlDocument, '/nodes', '/content');

      if (!this.nodeId && extractedValue) {
        this.nodeId = extractedValue;
      }

      if (this.nodeId) {
        this.nodeInfo = await this.networkService.getNodeInfo(this.nodeId);

        this.nodeName = this.nodeInfo.entry.name;

        console.log('this.nodeInfo ', this.nodeInfo);

        console.log('this.userId ', this.userId);

        const userPermission = await this.webviewerExtService.getUserPermissions(this.nodeInfo, this.userId);

        console.log('userPermission', userPermission);

        this.selectorPermission = await this.webviewerExtService.getSelectorPermissions(userPermission, this.roleSelector.role, this.userId);

        console.log('this.seletorPermission ', this.selectorPermission);

        this.fetchAndProcessAnnotation();

        const renId = 'pdf';
        const isNotPdf = await this.networkService.getNonPdfActive(this.nodeId);
        if (isNotPdf) {
          showSpinner(document.getElementById('spinner'));
          let statusCheck = await this.networkService.getCheckRenditions(this.nodeId, renId);
          if (statusCheck) {
            this.loadRenditionContent(renId);
            return;
          }
          // Create rendition once
          const statusCreate = await this.networkService.createRenditionData(this.nodeId, renId);
          if (!statusCreate) {
            console.log('Failed to create rendition', statusCreate);
            return;
          }

          // Retry checks with delay
          const maxRetriesStatusCheck = 10;
          for (let attempt = 0; attempt < maxRetriesStatusCheck; attempt++) {
            statusCheck = await this.networkService.getRetryCheckRenditions(this.nodeId, renId);
            if (statusCheck) {
              this.loadRenditionContent(renId);
              return;
            }
            await this.sleep(5000); // 5 second delay
          }
        } else {
          this.loadDocument(versionId);
        }
      } else {
        if (urlDocument.includes('shared-links')) {
          this.isShareLink = true;
          await setTimeout(async () => {
            this.document = urlDocument;
          }, this.deleyTimeout);
        }
      }
    });
  }

  // =============================== Orther ===============================

  private sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  template(tpl: any, args: any) {
    return tpl.replace(/\${([A-Za-z0-9.,:-]*)}/g, (_: any, v: any) => {
      return args[v] ? args[v] : '';
    });
  }

  private extractValueBetween(originalString: string, startDelimiter: string, endDelimiter: string): string {
    const startIndex = originalString.indexOf(startDelimiter);
    const endIndex = originalString.indexOf(endDelimiter);

    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
      let extracted = originalString.substring(startIndex + startDelimiter.length, endIndex);
      extracted = extracted.trim().replace(/^\//, '').replace(/\/$/, '');
      return extracted;
    } else {
      return '';
    }
  }

  alertInfo(infoTxt: string) {
    this.notificationService.showInfo(infoTxt, '', { duration: 1000 }, true);
  }

  alertWarning(infoTxt: string) {
    this.notificationService.showError(infoTxt, '', { duration: 1000 }, true);
  }
  decodeBinaryValue(binaryValue: string): string {
    return atob(binaryValue);
  }

  isLockAnnotation = true;

  remarkAnnotation: any;
  private async fetchAndProcessAnnotation() {
    await this.networkService.getAnnotation(this.nodeId).subscribe(
      (data) => {
        this.remarkAnnotation = data.remark;
        this.annotationData = data.annotation_text;

        console.log('this.annotationData ', this.annotationData);

        if (this.annotationData) {
          const userSave = data.user_save;
          let isLock = true;
          if (this.selectorPermission.UseEditAnnotation && userSave === this.userId) {
            isLock = false;
          }
          if (this.selectorPermission.UseEditAnnotationAll) {
            isLock = false;
          }
          if (isLock) {
            this.selectorPermission.UseEditAnnotation = false;
          }
          this.isLockAnnotation = isLock;
          this.setToobarAnnotation();
          this.setButtonActions();
        } else {
          this.annotationData = null;
          let isLock = true;
          if (this.userId === this.nodeInfo.entry.createdByUser.id && this.selectorPermission.UseEditAnnotation) {
            isLock = false;
          }
          if (this.selectorPermission.UseEditAnnotationAll) {
            isLock = false;
          }
          if (this.selectorPermission.UseEditAnnotation) {
            isLock = false;
          }
          if (isLock) {
            this.selectorPermission.UseEditAnnotation = false;
          }
          this.isLockAnnotation = isLock;
          this.setToobarAnnotation();
          this.setButtonActions();
        }
      },
      (_error) => {
        this.annotationData = null;

        let isLock = true;

        if (this.userId === this.nodeInfo.entry.createdByUser.id && this.selectorPermission.UseEditAnnotation) {
          isLock = false;
        }

        if (this.selectorPermission.UseEditAnnotationAll) {
          isLock = false;
        }

        if (this.selectorPermission.UseEditAnnotation) {
          isLock = false;
        }

        if (isLock) {
          this.selectorPermission.UseEditAnnotation = false;
        }

        this.isLockAnnotation = isLock;

        this.setToobarAnnotation();
        this.setButtonActions();
      }
    );
  }

  private setToobarAnnotation() {
    console.log('this.isLockAnnotation ', this.isLockAnnotation);
    if (!this.isLockAnnotation) {
      this.toolbarSettings = Toolbar_Annotation;
    }
  }

  private setButtonActions() {
    if (this.selectorPermission) {
      const filteredItems = this.items.filter((item) => this.selectorPermission[item.id] !== false && item.id !== 'UnhideAnnotation');
      this.items = filteredItems;
    }
  }

  templateWatermark(tpl: any, args: any) {
    return tpl.replace(/\${([A-Za-z0-9.,:-]*)}/g, (_: any, v: any) => {
      return args[v] ? args[v] : '';
    });
  }

  loadContent = false;
  // =============================== Load document to display ===============================
  private async loadRenditionContent(renId: any) {
    await setTimeout(async () => {
      const base64Data = await this.networkService.getRenditionContent(this.nodeId, renId);
      if (base64Data) {
        hideSpinner(document.getElementById('spinner'));
        // var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
        // viewer.load(base64Data);
        this.base64String = base64Data;
        this.loadContent = true;
      }
    }, this.deleyTimeout);
  }

  public base64String: string | undefined;

  private async loadDocument(versionId: any) {
    showSpinner(document.getElementById('spinner'));

    if (versionId) {
      return new Promise(() => {
        setTimeout(async () => {
          const base64Data = await this.networkService.downloadNodeVersion(this.nodeId, versionId);
          if (base64Data) {
            hideSpinner(document.getElementById('spinner'));
          }
          // const viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
          // viewer.load(base64Data);
          this.base64String = base64Data;
        }, this.deleyTimeout);
      });
    }

    return new Promise(async () => {
      const base64Data = await this.networkService.downloadNode(this.nodeId);
      if (base64Data) {
        hideSpinner(document.getElementById('spinner'));
      }
      // const viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
      // viewer.load(base64Data);
      this.base64String = base64Data;
      // console.log('globalConfigAnnotation ', this.globalConfigAnnotation);
    });
  }

  // =============================== Annotation ===============================
  importAnnotaion(_e: LoadEventArgs): void {
    if (this.annotationData) {
      try {
        var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
        viewer.annotationSettings = {
          author: this.userId,
          minHeight: 0,
          minWidth: 0,
          maxWidth: 0,
          maxHeight: 0,
          isLock: this.isLockAnnotation,
          skipPrint: false,
          skipDownload: false,
          allowedInteractions: ['None']
        };

        console.log('# importAnnotaion');

        if (this.remarkAnnotation === 'BINARY') {
          const decompressedAnnotations = pako.inflate(this.annotationData, { to: 'string' });
          this.annotationData = JSON.parse(decompressedAnnotations);
        } else if (this.remarkAnnotation === 'ENDCODE') {
          const decompressedAnnotations = this.decompressAnnotation(this.annotationData);

          console.log('Decode 1 :', this.annotationData.length);
          console.log('Decode 2 :', decompressedAnnotations.length);

          viewer.importAnnotation(JSON.parse(decompressedAnnotations));
        } else {
          viewer.importAnnotation(JSON.parse(this.annotationData));
        }
      } catch (error) {
        console.error('Failed to import annotation:', error);
        this.alertWarning('Failed to import annotation');
      }
    }
  }

  private export(): Promise<any> {
    return new Promise((resolve, reject) => {
      var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
      viewer
        .exportAnnotationsAsObject()
        .then((value) => {
          resolve(value);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  encodeAnnotation(data: any): string {
    try {
      // Convert data to JSON string
      const jsonString = JSON.stringify(data);
      // Convert JSON string to Uint8Array
      const uint8Array = new TextEncoder().encode(jsonString);
      // Compress data
      const compressedData = pako.deflate(uint8Array);
      // Convert Uint8Array to binary string
      let binaryString = '';
      for (let i = 0; i < compressedData.length; i++) {
        binaryString += String.fromCharCode(compressedData[i]);
      }
      // Convert binary string to base64
      return btoa(binaryString);
    } catch (error) {
      console.error('Failed to encode annotation:', error);
      throw error;
    }
  }

  decompressAnnotation(base64String: string): any {
    try {
      // Convert base64 to binary string
      const binaryString = atob(base64String);
      const length = binaryString.length;
      const uint8Array = new Uint8Array(length);
      // Convert binary string to Uint8Array
      for (let i = 0; i < length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }
      // Decompress data
      const decompressedData = pako.inflate(uint8Array);
      // Convert back to string and parse JSON
      const jsonString = new TextDecoder().decode(decompressedData);
      return JSON.parse(jsonString);
    } catch (error) {
      console.error('Failed to decompress annotation:', error);
      throw error;
    }
  }

  private async saveAnnotation() {
    var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
    viewer.annotation.setAnnotationMode('None');

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Save Annotation',
        message: `Are you sure you want to save annotation?`
      },
      minWidth: '250px'
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        showSpinner(document.getElementById('spinner'));

        const annontationTxt = await this.export();

        if (!annontationTxt) {
          this.alertWarning('No Annotation to Save');
          return;
        }

        // Compress the annotation text
        // const compressed = this.encodeAnnotation(annontationTxt);

        // console.log('Endcode 1 :', annontationTxt.length)
        // console.log('Endcode 2 :', compressed.length)

        const compressed = annontationTxt;

        const dataContextInsert: WebViewerClass = {
          nodeid: this.nodeId,
          nodename: this.nodeInfo.entry.name,
          user_save: this.userId,
          user_update: '',
          annotation_text: compressed,
          value1: '',
          value2: '',
          value3: '',
          value4: '',
          value5: '',
          remark: ''
          // remark: 'ENDCODE'
        };

        const dataContextUpdate: WebViewerUpdateClass = {
          nodeid: this.nodeId,
          nodename: this.nodeInfo.entry.name,
          user_update: this.userId,
          annotation_text: compressed,
          value1: '',
          value2: '',
          value3: '',
          value4: '',
          value5: '',
          remark: ''
          // remark: 'ENDCODE'
        };

        if (!this.annotationData) {
          this.networkService.addAnnotation(dataContextInsert).subscribe({
            next: () => this.alertInfo('Save Annotation Success'),
            error: (error) => console.log(error.error.message)
          });
          hideSpinner(document.getElementById('spinner'));
        } else {
          this.networkService.updateAnnotation(this.nodeId, dataContextUpdate).subscribe({
            next: () => this.alertInfo('Update Annotation Success'),
            error: (error) => console.log(error.error.message)
          });
          hideSpinner(document.getElementById('spinner'));
        }
      }
    });
  }

  // =============================== Unload ===============================

  ngOnDestroy() {
    this.unLoad();
    document.removeEventListener('keydown', this.disableCtrlP);
  }

  unLoad() {
    var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
    viewer.unload();
  }

  // =============================== Event ===============================

  public clicked(args: SpeedDialItemEventArgs) {
    switch (args.item.id) {
      case 'UseEditAnnotation':
        this.saveAnnotation();
        break;
      case 'DownloadAnnotation':
        this.downloadWithAnnotation();
        break;
      case 'DownloadWatermark':
        this.downloadWithWatermark();
        break;
      case 'DownloadOriginal':
        this.downloadOriginal();
        break;
      case 'HideAnnotation':
        this.hideAnnotation();
        break;
      case 'UnhideAnnotation':
        this.unHideAnnotation();
        break;
      default:
        break;
    }
  }

  annotationUnhideValue: any;

  public Jsondata: any;

  OnExportJson() {
    var proxy = this;
    this.pdfviewerControl.exportAnnotationsAsObject().then(function (value) {
      proxy.Jsondata = value;
    });
  }

  OnImportJson() {
    this.pdfviewerControl.importAnnotation(JSON.parse(this.Jsondata));
  }

  // private async hideAnnotation() {
  //   this.annotationUnhideValue = null;

  //   // const value = await this.export();

  //   var proxy = this;
  //   this.pdfviewerControl.exportAnnotationsAsObject().then(function (value) {
  //     proxy.Jsondata = value;
  //   });

  //   const value = this.Jsondata;

  //   if (!value) {
  //     this.alertWarning('No Annotation to Hide');
  //     return;
  //   }

  //   this.annotationUnhideValue = value;

  //   var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
  //   if (viewer.annotationSettings.isLock) {
  //     this.alertWarning('You do not have permission to hide annotation');
  //     return;
  //   }

  //   // const listAnnontation = viewer.annotationCollection;
  //   // let i = 0;
  //   // do {
  //   //   const annotation = listAnnontation[i];
  //   //   viewer.annotationModule.deleteAnnotationById(annotation.annotationId);
  //   // } while (listAnnontation.length > 0);

  //   viewer.exportAnnotationsAsObject().then(function () {
  //     viewer.deleteAnnotations();
  //   });

  //   // เอา item ที่มี id = 'HideAnnotation' ออก
  //   this.items = this.items.filter((item) => item.id !== 'HideAnnotation');

  //   // เพิ่ม item ที่มี id = 'UnhideAnnotation'
  //   this.items.push({
  //     id: 'UnhideAnnotation',
  //     text: 'UnHide Annotation',
  //     iconCss: 'e-icon icons-unhide-annotation',
  //     disabled: false
  //   });

  // }

  private async hideAnnotation() {
    this.annotationUnhideValue = null;

    var proxy = this;
    this.pdfviewerControl.exportAnnotationsAsObject().then(function (value) {
      proxy.Jsondata = value;

      if (!value) {
        this.alertWarning('No Annotation to Hide');
        return;
      }

      proxy.annotationUnhideValue = proxy.Jsondata;

      console.log('proxy.annotationUnhideValue ', proxy.annotationUnhideValue);

      var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
      if (viewer.annotationSettings.isLock) {
        proxy.alertWarning('You do not have permission to hide annotation');
        return;
      }

      viewer.exportAnnotationsAsObject().then(function () {
        viewer.deleteAnnotations();
      });

      // เอา item ที่มี id = 'HideAnnotation' ออก
      proxy.items = proxy.items.filter((item) => item.id !== 'HideAnnotation');
      // เพิ่ม item ที่มี id = 'UnhideAnnotation'
      proxy.items.push({
        id: 'UnhideAnnotation',
        text: 'UnHide Annotation',
        iconCss: 'e-icon icons-unhide-annotation',
        disabled: false
      });
    });
  }

  private async unHideAnnotation() {
    if (!this.annotationUnhideValue) {
      this.alertWarning('No Annotation to UnHide');
      return;
    }

    // var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
    // viewer.importAnnotation(JSON.parse(this.annotationUnhideValue));

    this.pdfviewerControl.importAnnotation(JSON.parse(this.annotationUnhideValue));

    this.annotationUnhideValue = null;

    // เอา item ที่มี id = 'UnhideAnnotation' ออก
    this.items = this.items.filter((item) => item.id !== 'UnhideAnnotation');
    // เพิ่ม item ที่มี id = 'HideAnnotation'
    this.items.push({
      id: 'HideAnnotation',
      text: 'Hide Annotation',
      iconCss: 'e-icon icons-hide-annotation',
      disabled: false
    });
  }

  private downloadWithAnnotation() {
    var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];

    viewer
      .saveAsBlob()
      .then((blob: Blob) => {
        // const url = window.URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.style.display = 'none';
        // link.download = this.nodeInfo.entry.name;
        // link.href = url;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);

        // ==========================
        const formData = new FormData();
        formData.append('File', blob, this.nodeInfo.entry.name);
        const headers = new HttpHeaders({
          accept: '*/*'
        });
        this.httpClient.post(`/api/Pdf/ConvertPdfToImage`, formData, { headers, responseType: 'blob' }).subscribe(
          (watermarkedBlob: Blob) => {
            const url = window.URL.createObjectURL(watermarkedBlob);
            const link = document.createElement('a');
            link.style.display = 'none';
            link.download = this.nodeInfo.entry.name;
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.alertInfo('Download With Annotation Success');
          },
          (error: any) => {
            console.error('Error sending file to API:', error);
          }
        );
      })
      .catch((error: any) => {
        console.error('Error exporting PDF as Blob:', error);
      });
  }

  lineOne: any;
  lineTwo: any;
  displayFormat: any;

  private downloadWithWatermark() {
    var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
    const date = moment().format('DD/MM/YYYY');
    const time = moment().format('hh:mm:ss');
    const lineOne = this.appConfig.get<string>('watermark.lineOne') || '';
    const lineTwo = this.appConfig.get<string>('watermark.lineTwo') || '';
    this.lineOne = this.template(lineOne, { ...this.userInfo, date, time });
    this.lineTwo = this.template(lineTwo, { ...this.userInfo, date, time });
    const displayFormat = this.appConfig.get<string>('watermark.displayFormat') || 'center';
    this.displayFormat = displayFormat;
    viewer
      .saveAsBlob()
      .then((blob: Blob) => {
        const formData = new FormData();
        formData.append('File', blob, this.nodeInfo.entry.name);
        const headers = new HttpHeaders({
          accept: '*/*'
        });
        this.httpClient
          .post(`/api/Pdf/Watermark?PlacementFile=${displayFormat}&FirstLine=${this.lineOne}&SecondLine=${this.lineTwo}`, formData, {
            headers,
            responseType: 'blob'
          })
          .subscribe(
            (watermarkedBlob: Blob) => {
              const formData2 = new FormData();
              formData2.append('File', watermarkedBlob, this.nodeInfo.entry.name);
              this.httpClient.post(`/api/Pdf/ConvertPdfToImage`, formData2, { headers, responseType: 'blob' }).subscribe(
                (watermarkedBlob2: Blob) => {
                  const url = window.URL.createObjectURL(watermarkedBlob2);
                  const link = document.createElement('a');
                  link.style.display = 'none';
                  link.download = this.nodeInfo.entry.name;
                  link.href = url;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  this.alertInfo('Download With Annotation Success');
                },
                (error: any) => {
                  console.error('Error sending file to API #1:', error);
                }
              );
            },
            (error: any) => {
              console.error('Error sending file to API #2:', error);
            }
          );
      })
      .catch((error: any) => {
        console.error('Error exporting PDF as Blob #3:', error);
      });
  }

  private downloadOriginal() {
    this.networkService
      .downloadContent(this.nodeId)
      .then((blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.download = this.nodeInfo.entry.name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.alertInfo('Download Original File Success');
      })
      .catch((error) => {
        console.error('Download failed:', error);
      });
  }

  //  =============================== DEV ===============================

  public exportObject: any;
  public OnExportAnnotationsClick() {
    var pdfviewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
    var _this = this;
    pdfviewer.exportAnnotationsAsObject().then(function (value) {
      _this.exportObject = value;
    });
  }

  public OnImportAnnotationsClick() {
    var pdfviewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
    pdfviewer.importAnnotation(JSON.parse(this.exportObject));
  }

  public downloadStart(args): void {
    args.cancel = true;
  }

  @HostListener('window:keydown', ['$event'])
  disableCtrlP(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
      event.preventDefault();
      console.log('Ctrl+P or Command+P has been disabled');
    }
  }

  onToolbarClick(event: any): void {
    console.log('Toolbar button clicked:', event);
    // ตรวจสอบว่าปุ่มที่กดคือปุ่มอะไร
    if (event.item && event.item.id === 'PdfViewer_organize') {
      console.log('Organize PDF button clicked!');
      // เพิ่ม logic ที่คุณต้องการเมื่อกดปุ่มนี้
    }
  }

  public toolbarClick(args: ClickEventArgs): void {
    //var pdfViewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
    var proxy = this;
    if (this.pdfviewerControl) {
      if (args.item && args.item.id === 'rotateClockwise') {
        this.pdfviewerControl.saveAsBlob().then(function (value) {
          var reader = new FileReader();
          reader.readAsDataURL(value);
          reader.onload = () => {
            var data: any = reader.result;
            var base64 = data.split('base64,')[1];
            let pdfDocument = new PdfDocument(base64);
            let page = pdfDocument.getPage(proxy.pdfviewerControl.currentPageNumber - 1); // Provide the required page number index here
            var rotation = page.rotation + 1;
            if (rotation > 4) {
              rotation = 0;
            }
            page.rotation = rotation;
            pdfDocument.saveAsBlob().then((value) => {
              var reader = new FileReader();
              reader.readAsDataURL(value.blobData);
              reader.onload = () => {
                var base64data: any = reader.result;
                proxy.pdfviewerControl.load(base64data, '');
              };
            });
          };
        });
      } else if (args.item && args.item.id === 'rotateall') {
        this.pdfviewerControl.saveAsBlob().then(function (value) {
          var reader = new FileReader();
          reader.readAsDataURL(value);
          reader.onload = () => {
            var data: any = reader.result;
            var base64 = data.split('base64,')[1];
            let pdfDocument = new PdfDocument(base64);

            for (var i = 0; i < proxy.pdfviewerControl.pageCount; i++) {
              let page = pdfDocument.getPage(i);
              var rotation = page.rotation + 1;
              if (rotation > 4) {
                rotation = 0;
              }
              page.rotation = rotation;
            }
            pdfDocument.saveAsBlob().then((value) => {
              var reader = new FileReader();
              reader.readAsDataURL(value.blobData);
              reader.onload = () => {
                var base64data: any = reader.result;
                proxy.pdfviewerControl.load(base64data, '');
              };
            });
          };
        });
      } else if (args.item && args.item.id === 'rotateAnticlockwise') {
        this.pdfviewerControl.saveAsBlob().then(function (value) {
          var reader = new FileReader();
          reader.readAsDataURL(value);
          reader.onload = () => {
            var data: any = reader.result;
            var base64 = data.split('base64,')[1];
            let pdfDocument = new PdfDocument(base64);
            let page = pdfDocument.getPage(proxy.pdfviewerControl.currentPageNumber - 1); // Provide the required page number index here
            var rotation = page.rotation - 1;
            if (rotation < 0) {
              rotation = 3;
            }
            page.rotation = rotation;
            pdfDocument.saveAsBlob().then((value) => {
              var reader = new FileReader();
              reader.readAsDataURL(value.blobData);
              reader.onload = () => {
                var base64data: any = reader.result;
                proxy.pdfviewerControl.load(base64data, '');
              };
            });
          };
        });
      }
    } else {
      console.error('pdfviewerControl is not defined');
    }
  }

  // ----------------- Export and Import Annotation For Test -----------------

  // exportTest(): void {
  //   var proxy = this;
  //   console.log('# exportTest');
  //   var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
  //   viewer.exportAnnotationsAsObject().then(function (value) {
  //     proxy.exportObject = value;
  //     console.log('exportObject ', proxy.exportObject);
  //   });
  // }

  // importTest(): void {
  //   var proxy = this;
  //   var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
  //   console.log('# importTest');
  //   console.log('exportObject ', proxy.exportObject);
  //   viewer.importAnnotation(JSON.parse(proxy.exportObject));
  // }

  // HidePdf() {
  //   var proxy = this;
  //   var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
  //   viewer.exportAnnotationsAsObject().then(function (value) {
  //     proxy.exportObject = value;
  //     viewer.deleteAnnotations();
  //   });
  // }

  // UnHidePdf() {
  //   var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
  //   viewer.importAnnotation(JSON.parse(this.exportObject));
  // }
}
