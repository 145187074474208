/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ContentMetadataConfig, OrganisedPropertyGroup,
    PropertyGroupContainer
} from '../../interfaces/content-metadata.interfaces';

export class IndifferentConfigService implements ContentMetadataConfig {

    isGroupAllowed(): boolean {
        return true;
    }

    reorganiseByConfig(propertyGroups: PropertyGroupContainer): OrganisedPropertyGroup[] {
        return Object.keys(propertyGroups)
            .map((groupName) => {
                const propertyGroup = propertyGroups[groupName];
                const properties = propertyGroup.properties;

                return Object.assign({}, propertyGroup, {
                    properties: Object.keys(properties).map((propertyName) => properties[propertyName])
                });
            });
    }

    filterExcludedPreset(propertyGroups: OrganisedPropertyGroup[]): OrganisedPropertyGroup[] {
        return propertyGroups;
    }

    appendAllPreset(): OrganisedPropertyGroup[] {
        return[];
    }

    isIncludeAllEnabled(): boolean {
        return true;
    }
}
