/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { FilesComponent } from './components/files/files.component';
import { LibrariesComponent } from './components/libraries/libraries.component';
import { FavoriteLibrariesComponent } from './components/favorite-libraries/favorite-libraries.component';
import { SearchResultsComponent } from './components/search/search-results/search-results.component';
import { SearchLibrariesResultsComponent } from './components/search/search-libraries-results/search-libraries-results.component';
import { AppSharedRuleGuard, GenericErrorComponent, ExtensionRoute, ExtensionsDataLoaderGuard } from '@alfresco/aca-shared';
import { AuthGuard } from '@alfresco/adf-core';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { RecentFilesComponent } from './components/recent-files/recent-files.component';
import { SharedFilesComponent } from './components/shared-files/shared-files.component';
import { DetailsComponent } from './components/details/details.component';
import { HomeComponent } from './components/home/home.component';
import { ViewProfileComponent } from './components/view-profile/view-profile.component';
import { ViewProfileRuleGuard } from './components/view-profile/view-profile.guard';
import { Route } from '@angular/router';
import { SharedLinkViewComponent } from './components/shared-link-view/shared-link-view.component';
import { TrashcanComponent } from './components/trashcan/trashcan.component';
import { ShellLayoutComponent } from '@alfresco/adf-core/shell';

// CustomApp
import { WorkSpaceReportComponent } from './components/audit/workspace-report/workspace-report.component';
import { SystemReportComponent } from './components/audit/system-report/system-report.component';
import { AboutComponent } from '@alfresco/aca-content/about';
import { UserGuideComponent } from './components/user-guide/user-guide.component';
import { AllLibrariesComponent } from './components/all-libraries/all-libraries.component';
import { SearchResultsAdComponent } from './components/search/search-results-ad/search-results-ad.component';
import { ContractReportComponent } from './components/contract-management/report/contract-report.component';
import { PreviewContractComponent } from './components/contract-management/preview/preview-contract.component';
import { AnalyticReportComponent } from './components/analytic-report/analytic-report.component';
import { LibraryReportComponent } from './components/library-report/library-report.component';
import { WorkflowConfigComponent } from './components/approval-workflow/components/workflow-config/workflow-config.component';
import { MyTasksComponent } from './components/approval-workflow/components/my-tasks/my-tasks.component';
import { MyRequestComponent } from './components/approval-workflow/components/my-request/my-request.component';
import { CompletedRequestComponent } from './components/approval-workflow/components/completed-reuqest/completed-request.component';
import { ViewerNodeComponent } from './components/approval-workflow/components/viewer-node/viewer-node.component';
import { CreateRequestComponent } from './components/approval-workflow/components/create-request/create-request.component';


import { GroupManagerComponent } from './components/account-settings/group-manager/group-manager.component';
import { UserManagerComponent } from './components/account-settings/user-manager/user-manager.component';


// import { PreviewComponent } from '@alfresco/aca-content/preview';


export const CONTENT_ROUTES: ExtensionRoute[] = [
  {
    path: 'preview/s/:id',
    children: [
      {
        path: '',
        component: SharedLinkViewComponent,
        data: {
          title: 'APP.PREVIEW.TITLE'
        }
      }
    ]
  },
  {
    path: 'view',
    component: ShellLayoutComponent,
    children: [
      {
        path: ':nodeId',
        outlet: 'viewer',
        children: [
          {
            path: '',
            loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
          }
        ]
      }
    ]
  },
  {
    path: 'Contact-Management/Preview',
    children: [
      {
        path: '',
        canActivate: [ViewProfileRuleGuard],
        component: PreviewContractComponent,
        data: {
          title: 'Contact-Preview'
        }
      }
    ]
  },
  {
    path: 'Appoval-Workflow/Preview',
    children: [
      {
        path: '',
        canActivate: [ViewProfileRuleGuard],
        component: ViewerNodeComponent,
        data: {
          title: 'Appoval-Workflow-Preview'
        }
      }
    ]
  },
];


export const CONTENT_LAYOUT_ROUTES: Route = {
  path: '',
  canActivate: [ExtensionsDataLoaderGuard],
  children: [
    {
      path: 'profile',
      canActivate: [ViewProfileRuleGuard],
      component: ViewProfileComponent
    },
    {
      path: '',
      component: HomeComponent
    },
    {
      path: 'personal-files',
      children: [
        {
          path: '',
          component: FilesComponent,
          data: {
            sortingPreferenceKey: 'personal-files',
            title: 'APP.BROWSE.PERSONAL.TITLE',
            defaultNodeId: '-my-'
          }
        },
        {
          path: 'details/:nodeId',
          children: [
            {
              path: '',
              component: DetailsComponent,
              data: {
                navigateSource: 'personal-files'
              }
            },
            {
              path: ':activeTab',
              component: DetailsComponent,
              data: {
                title: 'APP.BROWSE.PERSONAL.PERMISSIONS.TITLE',
                navigateSource: 'personal-files'
              }
            }
          ]
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'personal-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'personal-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'personal-files/:folderId',
      children: [
        {
          path: '',
          component: FilesComponent,
          data: {
            title: 'APP.BROWSE.PERSONAL.TITLE',
            sortingPreferenceKey: 'personal-files'
          }
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'personal-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'personal-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'libraries',
      children: [
        {
          path: '',
          component: LibrariesComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.MENU.MY_LIBRARIES.TITLE',
            sortingPreferenceKey: 'libraries'
          }
        }
      ]
    },
    {
      path: 'all-libraries',
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'libraries'
        },
        {
          path: 'libraries',
          component: AllLibrariesComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.MENU.ALL_LIBRARIES.TITLE',
            sortingPreferenceKey: 'all-libraries'
          }
        }
      ]
    },
    {
      path: 'libraries/:folderId',
      children: [
        {
          path: '',
          component: FilesComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.MENU.MY_LIBRARIES.TITLE',
            sortingPreferenceKey: 'libraries-files'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'libraries'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ],
          data: {
            navigateSource: 'libraries'
          }
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'libraries'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'favorite',
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'libraries'
        },
        {
          path: 'libraries',
          component: FavoriteLibrariesComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.MENU.FAVORITE_LIBRARIES.TITLE',
            sortingPreferenceKey: 'favorite-libraries'
          }
        }
      ]
    },
    {
      path: 'favorite/libraries/:folderId',
      children: [
        {
          path: '',
          component: FilesComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.MENU.FAVORITE_LIBRARIES.TITLE',
            sortingPreferenceKey: 'libraries-files'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'libraries'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'libraries'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'favorites',
      data: {
        sortingPreferenceKey: 'favorites'
      },
      children: [
        {
          path: '',
          component: FavoritesComponent,
          data: {
            title: 'APP.BROWSE.FAVORITES.TITLE',
            sortingPreferenceKey: 'favorites'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'favorites'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'favorites'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'recent-files',
      data: {
        sortingPreferenceKey: 'recent-files'
      },
      children: [
        {
          path: '',
          component: RecentFilesComponent,
          data: {
            title: 'APP.BROWSE.RECENT.TITLE'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'recent-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'recent-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'shared',
      children: [
        {
          path: '',
          data: {
            title: 'APP.BROWSE.SHARED.TITLE',
            sortingPreferenceKey: 'shared-files'
          },
          component: SharedFilesComponent
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'shared'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'shared'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ],
      canActivateChild: [AppSharedRuleGuard],
      canActivate: [AppSharedRuleGuard]
    },
    {
      path: 'trashcan',
      children: [
        {
          path: '',
          component: TrashcanComponent,
          data: {
            title: 'APP.BROWSE.TRASHCAN.TITLE',
            sortingPreferenceKey: 'trashcan'
          }
        }
      ]
    },
    {
      path: 'System-Report',
      children: [
        {
          path: '',
          component: SystemReportComponent,
          data: {
            title: 'System-Report'
          }
        }
      ]
    },
    {
      path: 'Workspace-Report',
      children: [
        {
          path: '',
          component: WorkSpaceReportComponent,
          data: {
            title: 'Workspace-Report'
          }
        }
      ]
    },
    {
      path: 'About',
      children: [
        {
          path: '',
          component: AboutComponent,
          data: {
            title: 'About'
          }
        }
      ]
    },
    {
      path: 'Contact-Management',
      children: [
        {
          path: '',
          component: ContractReportComponent,
          data: {
            title: 'Contact-Management'
          }
        }
      ]
    },
    {
      path: 'Analytic-Report',
      children: [
        {
          path: '',
          component: AnalyticReportComponent,
          data: {
            title: 'Analytic Report'
          }
        }
      ]
    },
    {
      path: 'Library-Report',
      children: [
        {
          path: '',
          component: LibraryReportComponent,
          data: {
            title: 'Library Report'
          }
        }
      ]
    },
    {
      path: 'workflow-configuration',
      children: [
        {
          path: '',
          component: WorkflowConfigComponent,
          data: {
            title: 'Workflow Configuration'
          }
        }
      ]
    },
    {
      path: 'Groups-Management',
      children: [
        {
          path: '',
          component: GroupManagerComponent,
          data: {
            title: 'Groups-Management'
          }
        }
      ]
    },
    {
      path: 'Users-Management',
      children: [
        {
          path: '',
          component: UserManagerComponent,
          data: {
            title: 'Users-Management'
          }
        }
      ]
    },
    {
      path: 'my-tasks',
      children: [
        {
          path: '',
          component: MyTasksComponent,
          data: {
            title: 'My Tasks'
          }
        }
      ]
    },
    {
      path: 'my-requests',
      children: [
        {
          path: '',
          component: MyRequestComponent,
          data: {
            title: 'My Requests'
          }
        }
      ]
    },
    {
      path: 'completed-requests',
      children: [
        {
          path: '',
          component: CompletedRequestComponent,
          data: {
            title: 'Completed Requests'
          }
        }
      ]
    },
    {
      path: 'my-requests/create',
      children: [
        {
          path: '',
          component: CreateRequestComponent,
          data: {
            title: 'Create Requests'
          }
        }
      ]
    },
    {
      path: 'User-Guide',
      children: [
        {
          path: '',
          component: UserGuideComponent,
          data: {
            title: 'User-Guide'
          }
        }
      ]
    },
    {
      path: 'search',
      children: [
        {
          path: '',
          component: SearchResultsComponent,
          data: {
            title: 'APP.BROWSE.SEARCH.TITLE',
            reuse: true
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'search'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'search'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'ad-search',
      children: [
        {
          path: '',
          component: SearchResultsAdComponent,
          data: {
            title: 'APP.BROWSE.SEARCH.TITLE',
            reuse: true
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'ad-search'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'ad-search'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'search-libraries',
      children: [
        {
          path: '',
          component: SearchLibrariesResultsComponent,
          data: {
            title: 'APP.BROWSE.SEARCH.TITLE'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'search'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'nodes/:nodeId',
      children: [
        {
          path: '',
          loadChildren: () => import('@alfresco/aca-content/folder-rules').then((m) => m.AcaFolderRulesModule)
        }
      ]
    },
    {
      path: '**',
      component: GenericErrorComponent
    }
  ],
  canActivateChild: [AuthGuard]
};



