
import { Component, OnInit } from '@angular/core';
import { AnalyticService } from '../../services/analytic-service.service';
import { AccumulationTheme, ChartAnnotationSettingsModel, IAccLoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';



interface FileData {
  list: {
    context: {
      facetQueries: Array<{
        label: string;
        count: number;
      }>;
    };
  };
}

interface ChartDataPoint {
  x: string;
  y: number;
  text: string;
}

@Component({
  selector: 'app-chart-pie',
  templateUrl: './chart-pie.component.html',
  styleUrls: ['./chart-pie.component.scss']
})
export class ChartPieComponent implements OnInit {

  public legendSettings: object = {
    visible: true,
  };


  public title: string = Browser.isDevice ? 'Browser Market Share' : '';
  public innerRadius = '43%'
  public startAngle = 30
  public radius = '80%'
  public dataLabel: object = {
    name: 'text',
    visible: true,
    font: {
      fontWeight: '600',
      size: '10px',
      color: '#ffffff'
    }
  };
  public annotations: ChartAnnotationSettingsModel[] = [
    {
      content: Browser.isDevice ? ' ' : "<div style='font-Weight:600;font-size:12px;text-align:center;'>จำนวน<br>ไฟล์<br>ที่สร้างแต่ละปี</div>", region: 'Series',
      x: '51%', y: '50%'
    }
  ];

  // custom code start
  public load(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    // eslint-disable-next-line max-len
    args.accumulation.theme = <AccumulationTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, 'Dark').replace(/contrast/i, 'Contrast').replace(/-highContrast/i, 'HighContrast');
  }
  public tooltip: object = {
    enable: true,
    format: '<b>${point.x}</b><br>จำนวนไฟล์: <b>${point.y}</b>', header: ''
  };

  public data: any;
  constructor(private analyticService: AnalyticService) { }

  loadSuccess = false;
  async ngOnInit(): Promise<void> {
    try {
      await this.loadFileCreationData();
    } catch (error) {
      console.error('Error fetching file creation data:', error);
      this.loadSuccess = false;
    }
  }

  private async loadFileCreationData(): Promise<void> {
    const fileData$ = await this.analyticService.filesCreatedByYear();
    fileData$.subscribe({
      next: (response: FileData) => {
        this.data = this.transformToChartData(response);
        this.loadSuccess = true;
      },
      error: (error) => {
        console.error('Error loading file data:', error);
        this.loadSuccess = false;
      }
    });
  }

  private transformToChartData(data: FileData): ChartDataPoint[] {
    return data.list.context.facetQueries.map(item => ({
      x: `ปี ${item.label}`,
      y: item.count,
      text: `${item.label}<br>${item.count.toLocaleString()} ไฟล์`
    }));
  }
}


