/* eslint-disable @typescript-eslint/no-shadow */

import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  LinkAnnotationService, BookmarkViewService, MagnificationService, ThumbnailViewService,
  ToolbarService,
  NavigationService, AnnotationService, TextSearchService, TextSelectionService,
  PrintService, FormDesignerService, FormFieldsService,
  PdfViewerModule,
  // StampSettings,
  // DynamicStampItem,
  LoadEventArgs
} from '@syncfusion/ej2-angular-pdfviewer';

import { MenuModule, ToolbarModule } from '@syncfusion/ej2-angular-navigations';
// import { AppConfigService } from '@alfresco/adf-core';

import { CommonModule } from '@angular/common';
import { PeopleContentService } from '@alfresco/adf-content-services';
// import { DataConfigService } from '../services/data-config.service';
import moment from 'moment';



import {
  createSpinner,
  showSpinner,
  hideSpinner,
} from '@syncfusion/ej2-popups';
import { AppConfigService } from '@alfresco/adf-core';
import { ViewerNodeService } from './viewer-node.service';

@Component({
  standalone: true,
  imports: [CommonModule, PdfViewerModule, MenuModule, ToolbarModule],
  selector: 'aca-viewer-node',
  templateUrl: './viewer-node.component.html',
  styleUrls: ['./viewer-node.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line no-sparse-arrays
  providers: [
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService
    , NavigationService,
    AnnotationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
    FormDesignerService,
    FormFieldsService]
})


export class ViewerNodeComponent implements OnInit {
  public document = '';

  nodeId: string;
  toolbarSettings: any;

  homeText = '';

  // Watermark
  lineOne = '';
  lineTwo = '';

  lineOneStyle = 'color: black; opacity: 0.3; font-size: 17px';
  lineTwoStyle = 'color: black; opacity: 0.3; font-size: 17px';
  defaultStyle =
    'white-space: nowrap;text-overflow: ellipsis;overflow: hidden;';


  deleyTimeout: any;


  _isNotPdf: any;

  constructor(
    private route: ActivatedRoute,
    private viewerNodeService: ViewerNodeService,
    private peopleContentService: PeopleContentService,
    private appConfig: AppConfigService,
  ) {
    this.toolbarSettings = this.viewerNodeService.getToolbarSetting();
  }

  async ngOnInit(): Promise<void> {

    createSpinner({
      target: document.getElementById('spinner'),
      width: '70px',
      label: 'Loading.',
    });

    this.deleyTimeout = this.appConfig.get<boolean | any>('deleyTimeoutWebviewer.value');
    if (!this.deleyTimeout) {
      this.deleyTimeout = 3000;
    }



    this.route.queryParams.subscribe(params => {
      this.nodeId = params['param'];
    });

    this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {
      this.lineOne = userInfo.firstName + ' ' + (userInfo.lastName ? userInfo.lastName : '');
      const currentDate = moment();
      const formattedDate = currentDate.format('DD-MM-YYYY HH:mm');
      this.lineTwo = formattedDate;
    });

    // setTimeout(() => {
    //   this.loadDocFromAPI();
    // }, 2000);

    const renId = 'pdf';
    const isNotPdf = await this.viewerNodeService.getNonPdfActive(this.nodeId);
    this._isNotPdf = isNotPdf;
    console.log('_isNotPdf ', this._isNotPdf);
    if (isNotPdf) {
      showSpinner(document.getElementById('spinner'));
      const maxRetries = 1;

      let statusCheck = await this.viewerNodeService.getCheckRenditions(this.nodeId, renId, maxRetries);


      if (statusCheck) {
        this.loadRenditionContent(renId);
      } else {
        let statusCreate = false;
        do {
          // 2. Create Rendition
          statusCreate = await this.viewerNodeService.createRenditionData(this.nodeId, renId);
          if (statusCreate) {

            // 3. Retry Check Status
            statusCheck = await this.viewerNodeService.getRetryCheckRenditions(this.nodeId, renId, maxRetries);
          }
        } while (!statusCheck && statusCreate);

        if (statusCheck) {
          this.loadRenditionContent(renId);
        }
      }

    } else {
      this.loadDocument();
    }

  }



  private async loadDocument() {
    console.log('# loadDocument');
    showSpinner(document.getElementById('spinner'));
    return new Promise(() => {
      setTimeout(async () => {
        const base64Data = await this.viewerNodeService.downloadNode(this.nodeId);
        if (base64Data) {
          hideSpinner(document.getElementById('spinner'));
        }
        const viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
        viewer.load(base64Data);
      }, this.deleyTimeout);
    });
  }



  private async loadRenditionContent(renId: any) {
    await setTimeout(async () => {
      const base64Data = await this.viewerNodeService.getRenditionContent(this.nodeId, renId);
      if (base64Data) {
        hideSpinner(document.getElementById('spinner'));
      }
      // eslint-disable-next-line no-var
      var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
      viewer.load(base64Data);
    }, this.deleyTimeout);
  }

  documentLoaded(_event: LoadEventArgs): void {
  }

}

