import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  ContextActionsDirective,

} from '@alfresco/aca-shared';


import { NgModule } from '@angular/core';
import { DropdownMultiSelectComponent } from './dropdown-multi-select.component';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownTreeModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';


@NgModule({
  declarations: [
    DropdownMultiSelectComponent,
  ],
  exports: [
    DropdownMultiSelectComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContextActionsDirective,
    FormsModule, ReactiveFormsModule, ButtonModule, DropDownTreeModule, MultiSelectAllModule, CheckBoxModule

  ]
})
export class DropdownMultiSelectedModule { }
