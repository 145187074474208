import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { DocumentExpireClass } from '../models/documentExpireClass.model';
import { NetworkService } from '../services/network.service';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentExpireResponseClass } from '../models/documentExpireClass.model';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@alfresco/adf-content-services';
import moment from 'moment';

import { TranslateService } from '@ngx-translate/core';

interface doctypes {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'lib-document-expire',
  templateUrl: './document-expire.component.html',
  styleUrls: ['./document-expire.component.scss']
})
export class DocumentExpireComponent implements OnInit {
  dataSource = new MatTableDataSource<DocumentExpireResponseClass>();

  public myForm: FormGroup;

  nodeID: any;
  nodeName: any;
  createdByUser: any;
  nodeType: any;

  updateActive: boolean = false;

  public insertActive: boolean = false;

  public cancelActive: boolean = false;

  public href: string = '';
  fileURL: any;

  isDisabled = false;

  isButtonClicked = false;
  isDatepickerDisabled = true;

  isElementsDisabled = true;

  public formDisabled = false;

  loadingTitle = '';
  loadingDetail = '';

  isChecked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DocumentExpireComponent>,
    private dialog: MatDialog,
    public trans: TranslateService,
    private networkService: NetworkService,
    // private dateAdapter: DateAdapter<Date>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dateAdapter.setLocale('en-GB');
    this.nodeID = this.data.action.id;
    this.nodeName = this.data.action.name;
    this.nodeType = this.data.action.nodeType;
    this.createdByUser = this.data.action.createdByUser.id;
  }

  toggleElements() {
    this.isElementsDisabled = !this.isElementsDisabled;
  }

  toggleDisabled() {
    this.isDisabled = !this.isDisabled;
  }

  toggleDatepicker() {
    if (!this.isButtonClicked) {
      this.isButtonClicked = true;
      this.isDatepickerDisabled = false;
    } else {
      this.isDatepickerDisabled = !this.isDatepickerDisabled;
    }
  }


  handleChange(event: any) {
    this.isChecked = event.checked;
  }

  toggleCancel() {
    this.cancelActive = !this.cancelActive;
  }

  doctypes: doctypes[] = [
    { value: 'Contract Document', viewValue: 'Contract Document' },
    { value: 'Extra Document', viewValue: 'Extra Document' },
    { value: 'News Document', viewValue: 'News Document' }
  ];

  ngOnInit() {

    // Tranlate variable
    this.trans.get('WORKSPACE-REPORT.LOADING.TITLE').subscribe((translation) => {
      this.loadingTitle = translation;
    });

    this.trans.get('WORKSPACE-REPORT.LOADING.DETAIL').subscribe((translation) => {
      this.loadingDetail = translation;
    });

    // Set urllink file for viewer
    this.href = this.router.url;


    this.fileURL = window.location.href + '/' + '(viewer:view' + '/' + this.nodeID + ')';
    console.log(' this.fileURL ', this.fileURL);


    // Cheked Folder
    this.getNodesData(this.nodeID);


    this.feedData(this.nodeID);
    this.myForm = new FormGroup({
      docname: new FormControl({ value: '', disabled: true }),
      expiredate: new FormControl('', [Validators.required]),
      numberofdays: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      removefile_active: new FormControl(false),
      removefile_days: new FormControl(0),
    });
  }

  public toggleFormState() {
    this.formDisabled = !this.formDisabled;
    const state = 'enable';
    Object.keys(this.myForm.controls).forEach((controlName) => {
      if (controlName != 'docname') {
        this.myForm.controls[controlName][state]();
      }
    });
  }


  remark: any;
  getNodesData(nodeId: any) {
    this.remark = 'file'
    this.networkService.getNodes(nodeId).subscribe({
      next: (data) => {
        if (data.entry) {
          const isFolder = data.entry.isFolder;
          if (isFolder) {
            const url = window.location.href;
            console.log('url ', url);
            const segments = url.split('/');
            const fileId = segments[segments.length - 1];
            let replacedString = '';
            if (fileId === 'personal-files') {
              replacedString = url + '/' + nodeId;
            } else {
              replacedString = url.replace(fileId, nodeId);
            }

            const urlFolder = replacedString
            this.fileURL = urlFolder;
            this.remark = 'folder'
          }
        }
      },
      error: (error) => {
        console.log(error.error.message);
      }
    });
  }

  public toggleFormStart() {
    const state = 'disable';
    Object.keys(this.myForm.controls).forEach((controlName) => {
      if (controlName != 'docname') {
        this.myForm.controls[controlName][state]();
      }
    });
  }

  public myError = (controlName: string, errorName: string) => {
    return this.myForm.controls[controlName].hasError(errorName);
  };

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6;
  };

  onSubmit(documentExpireForm) {
    if (documentExpireForm.invalid) {
      alert('กรุณาระบุข้อมูลให้ครบถ้วนก่อนบันทึก!..');
      return;
    }

    //Cancel
    this.cancelData(this.cancelActive);

    // UPDATE
    if (this.updateActive) {
      this.updateData(documentExpireForm);
      return;
    }

    // INSERT
    this.insertData(documentExpireForm);
  }

  onClick(event: Event) {
    event.preventDefault();
  }

  cancelData(cancelActive) {
    if (cancelActive) {
      const dialogConfirm = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'ยืนยันลบการแจ้งเตือนเอกสารหมดอายุ (Confirm Delete Expire Alert)',
          yesLabel: 'Confirm Delete',
          message: ' '
        },
        minWidth: '300px'
      });

      dialogConfirm.afterClosed().subscribe((result) => {
        if (result === true) {
          this.networkService.deleteDocumentExpire(this.nodeID).subscribe(
            (_data) => {
              alert('Cancel รายการเรียบร้อยแล้ว');
              this.dialogRef.close();
            },
            (error) => {
              console.log(JSON.stringify(error.error.message));
            }
          );
        }
      });
    }
  }

  updateData(documentExpireForm) {
    const aa = documentExpireForm.value;
    const dateObj = new Date(aa.expiredate);
    const momentObj = moment(dateObj);
    const expiredate = momentObj.format('YYYY-MM-DD');
    let dataContext: DocumentExpireClass = {
      doctypes: '',
      docname: this.nodeName,
      filename: this.nodeName,
      docowner: this.createdByUser,
      numberofdays: aa.numberofdays,
      email: aa.email,
      expiredate: expiredate,
      nodeid: this.nodeID,
      status: '2',
      senddate: '',
      remark: this.remark,
      path: this.nodeType,
      site: '',
      username: '',
      userid: '',
      fullname: '',
      urlfile: this.fileURL,
      removefile_active: this.isChecked,
      removefile_days: (this.isChecked ? aa.removefile_days : 0)
    };
    this.networkService.updateDocumentExpire(this.nodeID, dataContext).subscribe({
      next: (_data) => {
        this.dialogRef.close();
      },
      error: (error) => {
        console.log(error.error.message);
      }
    });
  }

  insertData(documentExpireForm) {
    const aa = documentExpireForm.value;
    // dateend: moment(this.enddate.value).format('YYYY-MM-DD'),
    const expiredate = moment(aa.expiredate).format('YYYY-MM-DD');

    let dataContext: DocumentExpireClass = {
      doctypes: '',
      docname: this.nodeName,
      filename: this.nodeName,
      docowner: this.createdByUser,
      numberofdays: aa.numberofdays,
      email: aa.email,
      expiredate: expiredate,
      nodeid: this.nodeID,
      status: '1',
      senddate: '',
      remark: this.remark,
      path: this.nodeType,
      site: '',
      username: '',
      userid: '',
      fullname: '',
      urlfile: this.fileURL,
      removefile_active: this.isChecked,
      removefile_days: (this.isChecked ? aa.removefile_days : 0)
    };

    this.networkService.addDocumentExpire(dataContext).subscribe({
      next: (_data) => {
        this.dialogRef.close();
      },
      error: (error) => {
        console.log(error.error.message);
      }
    });
  }

  feedData(nodeID: string) {
    const docname = this.nodeName;
    this.networkService.getDocumentExpireByNodeID(nodeID).subscribe(
      (data) => {
        const numberofdays = data.numberofdays;
        const email = data.email;
        const expiredate = data.expiredate;
        this.isButtonClicked = true;
        this.isDatepickerDisabled = true;
        this.remark = data.remark
        const removefile_active = data.removefile_active
        const removefile_days = data.removefile_days
        if (removefile_active) {
          this.isChecked = true;
        }
        this.myForm.setValue({
          docname,
          expiredate,
          numberofdays,
          email,
          removefile_active,
          removefile_days
        });

        this.updateActive = true;
        this.toggleFormStart();
      },
      (_error) => {
        this.insertActive = true;
        this.isDatepickerDisabled = false;
        const numberofdays = '';
        const email = '';
        const expiredate = '';
        const removefile_active = false;
        const removefile_days = 0;
        this.myForm.setValue({
          docname,
          expiredate,
          numberofdays,
          email,
          removefile_active,
          removefile_days
        });
      }
    );
  }
}
