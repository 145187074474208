import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageComponent, PageLayoutModule, ToolbarComponent } from '@alfresco/aca-shared';
import { TextBoxComponent, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ContextMenuItemModel, ContextMenuService, FilterService, FilterSettingsModel,
  GridModule, GroupService, PageService, PageSettingsModel, SortService } from '@syncfusion/ej2-angular-grids';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

import { CheckBoxSelectionService, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
// import { MatDialog } from '@angular/material/dialog';
import { UserAlfService } from '../services/user-alf.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserComponent } from './create-user/create-user.component';

export let data: Object[] = [];

@Component({
  selector: 'lib-group-manager',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PageLayoutModule,
    ToolbarComponent,
    TextBoxModule,
    GridModule,
    FormsModule,
    ButtonModule,
    MultiSelectModule
  ],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    CheckBoxSelectionService,
    ContextMenuService
  ],
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss']
})
export class UserManagerComponent extends PageComponent implements OnInit {

  public pageSettings?: PageSettingsModel;

  public data?: object[];

  @ViewChild('searchBox') searchBox: TextBoxComponent;

  public filterOptions?: FilterSettingsModel;

  // CONTEXT MENU
  public contextMenuItems?: ContextMenuItemModel[];

  constructor(private userAlfService: UserAlfService, public dialog: MatDialog) {
    super();
  }

  async ngOnInit(): Promise<void> {

    this.filterOptions = {
      type: 'Menu'
    };

    this.contextMenuItems = [
      { text: 'Edit', target: '.e-content', id: 'update', iconCss: 'e-icons e-edit' }
    ];

    this.searchUser();

  }

  // async getUser() {
  //   const resultGroups = await this.userAlfService.getUser().toPromise();
  //   const formattedEntry = resultGroups.list.entries
  //     .map(entry => ({
  //       userId: entry.entry.id,
  //       firstName: entry.entry.firstName,
  //       lastName: entry.entry.lastName,
  //       displayName: entry.entry.displayName,
  //       email: entry.entry.email,
  //     }));
  //   this.data = formattedEntry;
  //   this.pageSettings = { pageSize: 50 };
  // }

  async searchUser() {
    let searchTerm = '';
    if (this.searchBox) {
      searchTerm = this.searchBox.value;
    }
    const rsUser = await this.userAlfService.searchUser(searchTerm).toPromise();
    const formattedEntry = rsUser.people
      .map(entry => ({
        userId: entry.userName,
        firstName: entry.firstName,
        lastName: entry.lastName,
        displayName: entry.firstName + ' ' + entry.lastName,
        email: entry.email,
      }));
    this.data = formattedEntry;
    this.pageSettings = { pageSize: 50 };
  }

  async createUser() {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '850px',
      autoFocus: false,
      disableClose: false,
      data: null
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.searchUser();
      }
    });
  }

  async editUser(userId: any) {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '850px',
      autoFocus: false,
      disableClose: false,
      data: userId
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.searchUser();
      }
    });
  }

  contextMenuClick(args: any): void {
    if (args.item.id === 'update') {
      const userId = (args.rowInfo.rowData.userId);
      this.editUser(userId);
    } else if (args.item.id === 'delete') {
      // const userId = (args.rowInfo.rowData.userId);
    }
  }

}
