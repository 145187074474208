/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Pagination, ResultSetPaging } from '@alfresco/js-api';
import {
  ActivatedRoute,
  Params
} from '@angular/router';
import { AlfrescoViewerModule, DocumentListModule, SearchFilterService, SearchModule, SearchQueryBuilderService } from '@alfresco/adf-content-services';
import {
  infoDrawerPreview,
  SetInfoDrawerPreviewStateAction,
  SetInfoDrawerStateAction,
  showFacetFilter,
  ShowInfoDrawerPreviewAction,
  SnackbarErrorAction
} from '@alfresco/aca-shared/store';
import { AlfrescoApiService, DataTableModule, PaginationModule, TranslationService, ViewerModule } from '@alfresco/adf-core';
// import { combineLatest } from 'rxjs';
import {
  ContextActionsDirective,
  InfoDrawerComponent,
  PageComponent,
  PageLayoutComponent,
  PaginationDirective,
  ToolbarComponent
} from '@alfresco/aca-shared';
import { SearchSortingDefinition } from '@alfresco/adf-content-services/lib/search/models/search-sorting-definition.interface';
// import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SearchInputComponent } from '../search-input/search-input.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { DocumentListDirective } from '../../../directives/document-list.directive';
import { ThumbnailColumnComponent } from '../../dl-custom-components/thumbnail-column/thumbnail-column.component';
import { SearchActionMenuComponent } from '../search-action-menu/search-action-menu.component';
import { TagsColumnComponent } from '../../dl-custom-components/tags-column/tags-column.component';
import { MatIconModule } from '@angular/material/icon';
import { SearchResultsRowComponent } from '../search-results-row/search-results-row.component';
// import { AppConfigService } from '@alfresco/adf-core';
import { SearchApi } from '@alfresco/js-api';
import { AcaViewerDrawerModule } from '@alfresco/aca-content/viewer-drawer';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

import { ShowHeaderMode } from '@alfresco/adf-core';
import { SearchDropdownListComponent } from '../search-dropdown-list/search-dropdown-list.component';

import { SearchDateRangeComponent } from '../search-date-range/search-date-range.component';

import { SearchDropdownListCheckedComponent } from '../search-dropdown-list-checked/search-dropdown-list-checked.component';

import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchFilterComponent } from '../search-filter/search-filter.component';
import { HttpClient } from '@angular/common/http';

@Component({
  standalone: true,
  selector: 'aca-search-results',
  templateUrl: './search-results-ad.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./search-results-ad.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    SearchInputComponent,
    MatProgressBarModule,
    SearchModule,
    MatDividerModule,
    MatButtonModule,
    DocumentListModule,
    DocumentListDirective,
    ContextActionsDirective,
    DataTableModule,
    ThumbnailColumnComponent,
    SearchActionMenuComponent,
    TagsColumnComponent,
    PaginationModule,
    AlfrescoViewerModule,
    MatIconModule,
    InfoDrawerComponent,
    SearchResultsRowComponent,
    PaginationDirective,
    ViewerModule,
    PageLayoutComponent,
    ToolbarComponent,
    AcaViewerDrawerModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatMenuModule,
    MatAutocompleteModule,
    SearchFilterComponent
  ]
})


export class SearchResultsAdComponent extends PageComponent implements OnInit {
  showFacetFilter$ = this.store.select(showFacetFilter);
  infoDrawerPreview$ = this.store.select(infoDrawerPreview);

  @ViewChild('searchFilter')
  searchFilter: SearchFilterComponent;

  filterWidth = '366px';
  filterPadding = '5px';


  queryTemps: any;
  checkedSimpleSearchStatus: any;
  searchedWord: string;
  queryParamName = 'q';
  data: ResultSetPaging;
  sorting = ['name', 'asc'];
  isLoading = false;
  totalResults: number;

  selectedAdSearch: any;
  adsearch: Array<any>;
  columns: any[] = [];
  sortOptions: any;

  private _searchApi: SearchApi;

  get searchApi(): SearchApi {
    this._searchApi = this._searchApi ?? new SearchApi(this.alfrescoApiService.getInstance());
    return this._searchApi;
  }

  options = [
    { value: 'DEMO-1', viewValue: 'DEMO-1' },
    { value: 'DEMO-2', viewValue: 'DEMO-2' },
    { value: 'DEMO-3', viewValue: 'DEMO-3' }
  ];

  selectedOption = this.options[0].value; // Selecting the first option by default


  constructor(
    private http: HttpClient,
    private alfrescoApiService: AlfrescoApiService,
    // private config: AppConfigService,
    private queryBuilder: SearchQueryBuilderService,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    searchFilterService: SearchFilterService,
  ) {
    super();

    document.addEventListener('keydown', (_event) => {
      // const isCtrlPressed = event.ctrlKey || event.metaKey;
      this.isCommandCtrl = true;
      this.isRightNotClickActive = false
      setTimeout(() => {
        this.isCommandCtrl = false;
      }, 1000);
    });


    queryBuilder.paging = {
      skipCount: 0,
      maxItems: 25
    };

    searchFilterService.widgets['dropdown-list'] = SearchDropdownListComponent;
    searchFilterService.widgets['dropdown-list-checked'] = SearchDropdownListCheckedComponent
    searchFilterService.widgets['date-range'] = SearchDateRangeComponent;

    this.loadConfig();

    // this.adsearch = this.config.get<any[]>('advancedSearch');

    if (this.adsearch && this.adsearch.length > 0) {
      console.log('============== 1 =============');
      this.selectedAdSearch = this.adsearch[0];
      this.columns = this.selectedAdSearch.rowTemplate || [
        {
          id: 'app.files.thumbnail',
          key: '$thumbnail',
          type: 'image',
          class: 'adf-image-table-cell',
          sortable: false,
          desktopOnly: false
        },
        {
          template: 'default',
          desktopOnly: false
        }
      ];
      this.showHeader = ShowHeaderMode.Data;
    }

  }

  async getConfig(): Promise<any> {
    try {
      const data = await this.http.get('./ad-search.config.json').toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching configuration:', error);
      throw error;
    }
  }

  receiveDataFromChild(_data: any) {

  }

  isConfig = false;
  async loadConfig(): Promise<void> {
    try {
      const data = await this.getConfig();
      this.adsearch = data.advancedSearch;
      console.log('this.adsearch ', this.adsearch);
    } catch (error) {
      console.error('Error fetching configuration:', error);
    }
  }


  ngOnInit() {

    super.ngOnInit();


    console.log('# SearchResultsAdComponent');

    console.log('this.adsearch 2 ', this.adsearch);

    this.queryBuilder.resetToDefaults();
    this.sorting = this.getSorting();
    this.subscriptions.push(
      this.queryBuilder.updated.subscribe((query) => {
        this.queryTemps = query.query.query;
        if (query) {
          this.sorting = this.getSorting();
          this.isLoading = true;
        }
      }),
      this.queryBuilder.executed.subscribe((data) => {
        let dataTemps = data;
        if (this.queryTemps === `(TYPE:'cm:content')`) {
          dataTemps = {
            'list': {
              'pagination': {
                'totalItems': 0
              },
              'entries': []
            }
          };
        }
        this.queryBuilder.paging.skipCount = 0;
        this.onSearchResultLoaded(dataTemps);
        this.isLoading = false;
      }),
      this.queryBuilder.error.subscribe((err: any) => {
        this.onSearchError(err);
      })
    );

    if (this.route) {
      this.route.params.forEach((params: Params) => {
        this.searchedWord = params.hasOwnProperty(this.queryParamName) ? params[this.queryParamName] : null;
        const checkedSimpleSearch = params.hasOwnProperty(this.queryParamName) ? params[this.queryParamName] : null;
        this.checkedSimpleSearchStatus = checkedSimpleSearch ? true : false;
        if (this.searchedWord) {
          this.queryBuilder.update();
        } else {
          this.queryBuilder.userQuery = null;
          this.queryBuilder.executed.next({
            list: { pagination: { totalItems: 0 }, entries: [] }
          });
        }
      });
    }
  }




  onNodeUnselect(_event: Event) {

  }

  onSearchError(error: { message: any }) {
    const { statusCode } = JSON.parse(error.message).error;

    const messageKey = `APP.BROWSE.SEARCH.ERRORS.${statusCode}`;
    let translated = this.translationService.instant(messageKey);

    if (translated === messageKey) {
      translated = this.translationService.instant(`APP.BROWSE.SEARCH.ERRORS.GENERIC`);
    }

    this.store.dispatch(new SnackbarErrorAction(translated));
  }

  private isOperator(input: string): boolean {
    if (input) {
      input = input.trim().toUpperCase();

      const operators = ['AND', 'OR'];
      return operators.includes(input);
    }
    return false;
  }

  private formatFields(fields: string[], term: string): string {
    let prefix = '';
    let suffix = '*';

    if (term.startsWith('=')) {
      prefix = '=';
      suffix = '';
      term = term.substring(1);
    }

    return '(' + fields.map((field) => `${prefix}${field}:"${term}${suffix}"`).join(' OR ') + ')';
  }


  formatSearchQuery(userInput: string, fields = ['cm:name']) {
    if (!userInput) {
      return null;
    }

    if (/^http[s]?:\/\//.test(userInput)) {
      return this.formatFields(fields, userInput);
    }

    userInput = userInput.trim();

    if (userInput.includes(':') || userInput.includes('"')) {
      return userInput;
    }

    const words = userInput.split(' ');

    if (words.length > 1) {
      const separator = words.some(this.isOperator) ? ' ' : ' AND ';

      return words
        .map((term) => {
          if (this.isOperator(term)) {
            return term;
          }

          return this.formatFields(fields, term);
        })
        .join(separator);
    }

    return this.formatFields(fields, userInput);
  }

  onSearchResultLoaded(nodePaging: ResultSetPaging) {
    console.log('nodePaging ', nodePaging);
    this.data = nodePaging;
    this.totalResults = this.getNumberOfResults();
  }

  getNumberOfResults() {
    if (this.data?.list?.pagination) {
      return this.data.list.pagination.totalItems;
    }
    return 0;
  }

  onPaginationChanged(pagination: Pagination) {
    this.queryBuilder.paging = {
      maxItems: pagination.maxItems,
      skipCount: pagination.skipCount
    };
    this.queryBuilder.update();
  }

  private getSorting(): string[] {
    const primary = this.queryBuilder.getPrimarySorting();

    if (primary) {
      return [primary.key, primary.ascending ? 'asc' : 'desc'];
    }

    return ['name', 'asc'];
  }

  onNodeClicked(_event: Event) {

    this.isRightNotClickActive = false;
    setTimeout(() => {
      if (this.isRightClick || this.isCommandCtrl) {
        this.isRightNotClickActive = false;
      } else {
        this.isRightNotClickActive = true;
      }
    }, 100);

  }

  isCommandCtrl = false;
  isRightClick = false;
  isRightNotClickActive = false;
  onRightClick() {
    this.isRightClick = true;
    this.isRightNotClickActive = false
    setTimeout(() => {
      this.isRightClick = false;
    }, 1000);
  }
  onNameClicked(_event: Event) {
    console.log('====== onNameClicked =====');
  }


  onClickDemo() {
    console.log('====== onClickDemo =====');
  }

  onPreviewClosed() {
    console.log('# onPreviewClosed');
    this.store.dispatch(new ShowInfoDrawerPreviewAction());
  }

  onDrawerClosed() {
    console.log('# onDrawerClosed');
    this.store.dispatch(new SetInfoDrawerPreviewStateAction(false));
    this.store.dispatch(new SetInfoDrawerStateAction(false));
  }

  onSearchSortingUpdate(option: SearchSortingDefinition) {
    this.queryBuilder.sorting = [{ ...option, ascending: option.ascending }];
    this.queryBuilder.update();
  }

  isSelector = false;
  isShowContent = false;
  adSelectorChange(event) {

    console.log('event ', event);

    if (event.isUserInput) {
      this.queryBuilder.categories = event.source.value.categories;
      this.queryBuilder.userQuery = event.source.value.userQuery;
      this.columns = event.source.value.rowTemplate || [
        {
          id: 'app.files.thumbnail',
          key: '$thumbnail',
          type: 'image',
          class: 'adf-image-table-cell',
          sortable: false,
          desktopOnly: false
        },
        {
          template: 'default',
          desktopOnly: false
        }
      ];
      this.isSelector = true;
      this.isShowContent = true;
      this.showHeader = ShowHeaderMode.Data;
      this.sortOptions = event.source.value.sorting ? event.source.value.sorting.options || undefined : undefined;
    }

    if (!this.isShowContent && !event.isUserInput) {
      this.queryBuilder.categories = event.source.value.categories;
      this.queryBuilder.userQuery = event.source.value.userQuery;
      this.columns = event.source.value.rowTemplate || [
        {
          id: 'app.files.thumbnail',
          key: '$thumbnail',
          type: 'image',
          class: 'adf-image-table-cell',
          sortable: false,
          desktopOnly: false
        },
        {
          template: 'default',
          desktopOnly: false
        }
      ];

      this.isSelector = true;
      this.isShowContent = true;
      this.showHeader = ShowHeaderMode.Data;
      this.sortOptions = event.source.value.sorting ? event.source.value.sorting.options || undefined : undefined;
    }

  }
  clearResult() {
    this.queryBuilder.executed.next({
      list: { pagination: { totalItems: 0 }, entries: [] }
    });
  }
}
