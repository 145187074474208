/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';

import { Query } from '@syncfusion/ej2-data';


@Component({
  selector: 'aca-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss']
})
export class DropdownListComponent implements OnInit {

  @Input() receiveData: any;
  @Input() receiveDefaultData: any;
  @Input() enabled: any;
  @Input() label: any;
  @Input() dropdownId: any;
  @Input() placeholder: any;

  // eslint-disable-next-line @typescript-eslint/ban-types
  public data: { [key: string]: Object }[] = [];
  public dataValue: any;
  public fields: object = { text: "Display", value: "Id", groupBy: 'Category' };
  public onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
    let query = new Query();
    query = (e.text != "") ? query.where("Display", "startswith", e.text, true) : query;
    e.updateData(this.data, query);
  };

  constructor() {

  }
  ngOnInit(): void {
    const matchedData = this.receiveData.find(item => item.id === this.dropdownId);
    if (matchedData) {
      this.data = matchedData.data;
    }
    const matchedDefaultData = this.receiveDefaultData.find(item => item.id === this.dropdownId);
    if (matchedDefaultData) {
      this.dataValue = matchedDefaultData.default;
    }
  }

  @Output() selectionChange = new EventEmitter<any>();

  selectedValue: any;

  onSelectionChange(event: any) {
    this.selectedValue = event.value;
    this.selectionChange.emit(this.selectedValue);
  }

}
