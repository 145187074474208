/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import { AlfrescoApiService } from '@alfresco/adf-core';
// import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LibraryReportService {


  apiPath: any;


  constructor(
    private apiService: AlfrescoApiService,
  ) {
    this.apiPath = `/alfresco/api/-default-/public/search/versions/1/search`;
  }


  private customApi(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callCustomApi(path, httpMethod, ...params);
  }

  private publicApi(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }



  async getCountPerLibrary(): Promise<Observable<any>> {
    const body = {
      "query": {
        "query": "cm:name:* AND TYPE:\"cm:content\""
      },
      "facetFields": {
        "facets": [
          {
            "field": "SITE",
            "label": "site"
          }
        ]
      },
      "paging": {
        "maxItems": 1
      }
    };
    return from(this.customApi(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  async getSite(siteId: any): Promise<Observable<any>> {
    const pathService = `sites/${siteId}`
    const httpMethod = 'GET';
    return from(this.publicApi(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  async getTotalSizePerLibrary(sietName: any, dateRang: any): Promise<Observable<{ sum: number; countValues: number; }>> {
    // Seleted All
    let body = {
      "query": {
        "query": `SITE:"${sietName}" AND TYPE:"cm:content"`
      },
      "stats": [
        {
          "field": "content.size",
          "label": "size",
          "min": false,
          "max": false,
          "stddev": false,
          "missing": false,
          "sum": true,
          "countValues": true,
          "sumOfSquares": false,
          "distinctValues": false,
          "countDistinct": false,
          "cardinality": false,
          "cardinalityAccuracy": 0.1
        }
      ],
      "paging": {
        "maxItems": 1
      }
    };


    // Seleted from DateRage
    if (dateRang) {
      body = {
        "query": {
          "query": `SITE:"${sietName}" AND TYPE:"cm:content" AND cm:created:['${dateRang[0]}' TO '${dateRang[1]}'] `
        },
        "stats": [
          {
            "field": "content.size",
            "label": "size",
            "min": false,
            "max": false,
            "stddev": false,
            "missing": false,
            "sum": true,
            "countValues": true,
            "sumOfSquares": false,
            "distinctValues": false,
            "countDistinct": false,
            "cardinality": false,
            "cardinalityAccuracy": 0.1
          }
        ],
        "paging": {
          "maxItems": 1
        }
      };
    }

    return from(this.customApi(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    )).pipe(
      map((response: any) => {
        const metrics = response.list.context.facets[0].buckets[0].metrics;
        const sumMetric = metrics.find((metric: any) => metric.type === 'sum');
        const countValuesMetric = metrics.find((metric: any) => metric.type === 'countValues');

        const sum = sumMetric ? sumMetric.value.sum : 0;
        const countValues = countValuesMetric ? countValuesMetric.value.countValues : 0;

        return { sum, countValues };
      })
    );
  }

  async getMimeTypeFile(sietName: any, mimeTypes: any, dateRang: any): Promise<Observable<any>> {
    console.log(mimeTypes);
    console.log(sietName);

    const facetQueries = mimeTypes.map((type: { value: string, label: string }) => ({
      query: `content.mimetype:'${type.value}'`,
      label: type.label
    }));


    let body = {
      "query": {
        "query": `SITE:"${sietName}" AND TYPE:"cm:content"`
      },
      "facetQueries": facetQueries,
      "paging": {
        "maxItems": 1
      }
    };

    if (dateRang) {
      body = {
        "query": {
          "query": `SITE:"${sietName}" AND TYPE:"cm:content" AND cm:created:['${dateRang[0]}' TO '${dateRang[1]}']`
        },
        "facetQueries": facetQueries,
        "paging": {
          "maxItems": 1
        }
      };
    }


    return from(this.customApi(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }


  async getCreatedByMonth(sietName: any, dateRang: any): Promise<Observable<any>> {
    const currentDate = new Date();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const facetQueries = [];

    for (let i = 0; i < 12; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      const year = date.getFullYear();
      const month = date.getMonth();
      const startDate = `${year}-${String(month + 1).padStart(2, '0')}-01`;
      const endDate = new Date(year, month + 1, 1);
      const endDateString = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-01`;
      facetQueries.push({
        query: `created:[${startDate} TO ${endDateString}]`,
        label: `${months[month]} ${year}`
      });
    }

    let body = {
      query: {
        query: `SITE:"${sietName}" AND TYPE:"cm:content"`
      },
      facetQueries: facetQueries,
      paging: {
        maxItems: 1
      }
    };

    if (dateRang) {
      body = {
        query: {
          query: `SITE:"${sietName}" AND TYPE:"cm:content" AND cm:created:['${dateRang[0]}' TO '${dateRang[1]}'] `
        },
        facetQueries: facetQueries,
        paging: {
          maxItems: 1
        }
      };
    }


    return from(this.customApi(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }


}
