
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import {
  // AppService,
  ContextActionsDirective,
  GenericErrorComponent,
  PageComponent,
  PageLayoutComponent,
  PaginationDirective,
  ToolbarComponent
} from '@alfresco/aca-shared';

import { PageLayoutModule } from '@alfresco/aca-shared';

import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts'
import {
  PieSeriesService,
  AccumulationLegendService,
  AccumulationTooltipService,
  AccumulationAnnotationService,
  AccumulationDataLabelService
} from '@syncfusion/ej2-angular-charts'
import { CategoryService, LineSeriesService } from '@syncfusion/ej2-angular-charts'
import { ChartDimensionsModule } from './components/chart-dimensions/chart-dimensions.module';
import { ChartPieModule } from './components/chart-pie/chart-pie.module';
import { ChartBarcolumsModule } from './components/chart-barcolums/chart-barcolums.module';
import { ChartAreaModule } from './components/chart-area/chart-area.module';
import { MatIconModule } from '@angular/material/icon';
import { ChartSemiPieModule } from './components/chart-semipie/chart-semipie.module';
import { ChartDonutModule } from './components/chart-donut/chart-donut.module';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { IconModule } from '@alfresco/adf-core';
import { AnalyticService } from './services/analytic-service.service';
import { Observable } from 'rxjs';

import { FileData, DataConfigStructure } from './data/data-structures';
import { HttpClient } from '@angular/common/http';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ChartModule,
    ContextActionsDirective,
    PageLayoutComponent,
    PaginationDirective,
    ToolbarComponent,
    DashboardLayoutModule,
    AccumulationChartModule,
    ChartDimensionsModule,
    ChartPieModule,
    ChartBarcolumsModule,
    ChartAreaModule,
    PageLayoutModule,
    MatIconModule,
    GenericErrorComponent,
    ChartSemiPieModule,
    ChartDonutModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    IconModule

  ],
  selector: 'app-analytic-report',
  templateUrl: './analytic-report.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./analytic-report.component.scss'],
  providers: [PieSeriesService, AccumulationLegendService, AccumulationTooltipService, AccumulationDataLabelService,
    AccumulationAnnotationService, CategoryService, LineSeriesService]
})
export class AnalyticReportComponent extends PageComponent implements OnInit, OnDestroy {

  public title: any;
  public cellSpacing: number[] = [10, 10];

  public myForm: FormGroup;

  fileData: DataConfigStructure;
  viewValue: string;

  constructor(private analyticService: AnalyticService, private httpClient: HttpClient
  ) {
    super();
  }

  ngOnDestroy() {
    console.log('#ngOnDestroy');
  }


  sumTotal: any;

  countValues: any;

  public cellAspectRatio: number = 100 / 85;

  filesCreatedByYearTitle: any;
  filesCreatedByMonthTitle: any;
  fileCountByTypeTitle: any;
  fileCountBySizeTitle: any;
  fileCountPerLibraryTitle: any;
  displayTotalStorageTitle: any;
  fileAspectByPropetiesTitle: any;

  totalFileSizeTitle: any;
  totalQtyFileTitle: any;

  async loadFileData(): Promise<void> {
    const observableData: Observable<DataConfigStructure> = await this.analyticService.getConfigReport();
    observableData.subscribe(data => {
      this.fileData = data;
      this.filesCreatedByYearTitle = this.getViewValue(this.fileData.filesCreatedByYear);
      this.filesCreatedByMonthTitle = this.getViewValue(this.fileData.filesCreatedByMonth);
      this.fileCountByTypeTitle = this.getViewValue(this.fileData.fileCountByType);
      this.fileCountBySizeTitle = this.getViewValue(this.fileData.fileCountBySize);
      this.fileCountPerLibraryTitle = this.getViewValue(this.fileData.fileCountPerLibrary);
      this.displayTotalStorageTitle = this.getViewValue(this.fileData.displayTotalStorage);
      this.fileAspectByPropetiesTitle = this.getViewValue(this.fileData.fileAspectByPropeties);
      this.totalFileSizeTitle = this.getTotalFileSize(this.fileData.displayTotalStorage)
      this.totalQtyFileTitle = this.getTotalQtyFile(this.fileData.displayTotalStorage)
    });
  }

  getViewValue(data: FileData[]): string {
    if (data && data.length > 0) {
      return data[0].viewValue;
    }
    return '';
  }

  getTotalFileSize(data: FileData[]): string {
    if (data && data.length > 0) {
      return data[0].totalFileSize;
    }
    return '';
  }


  getTotalQtyFile(data: FileData[]): string {
    if (data && data.length > 0) {
      return data[0].totalQtyFile;
    }
    return '';
  }



  async ngOnInit() {
    console.log('#AnalyticComponent');

    await this.loadFileData();

    await (await this.analyticService.displayTotalStorage()).subscribe(
      data => {
        data.list.context.facets[0].buckets[0].metrics.map((item, _index) => {
          const values = Object.values(item.value);

          const numericValue = values.find(value => typeof value === 'number') as number;

          if (item.type === 'sum') {
            console.log('numericValue ', numericValue);
            const megabytes = numericValue / (1024 * 1024);  // 1 MB = 1024 * 1024 bytes
            this.sumTotal = megabytes.toFixed(2);
          }
          if (item.type === 'countValues') {
            this.countValues = numericValue.toLocaleString();
          }
        });
      },
      error => {
        console.error('Error fetching data', error);
      }
    );


    const config = await this.loadConfig();
    console.log('config ', config);
  }


  async getConfig(): Promise<any> {
    try {
      const data = await this.httpClient.get('./analytic-report.config.json').toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching configuration:', error);
      throw error;
    }
  }

  async loadConfig(): Promise<any> {
    try {
      const data = await this.getConfig();
      const configData = data['analytic-report'];
      const config = configData
      return config
    } catch (error) {
      console.log('error loadConfig() :', error);
    }
  }


}
