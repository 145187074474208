<div class="dialog-title">Contract Management</div>
<div style="margin-bottom: 15px"></div>

<form #CreateContract="ngForm" [formGroup]="myForm" class="example-form">
  <div class="example-card">
    <div style="margin-bottom: 25px"></div>
    <mat-slide-toggle
      [(ngModel)]="selectedContractOriginalActive"
      formControlName="contractOriginalActive"
      name="contractOriginalActive"
      id="contractOriginalActive"
      >ต่อสัญญาเดิม</mat-slide-toggle
    >
    <div style="margin-bottom: 12px"></div>

    <!--
    <mat-form-field class="full-width" *ngIf="selectedContractOriginalActive === true">
      <input matInput placeholder="กรอกเลขที่สัญญาเพื่ออ้างอิง" formControlName="contractRefNo" name="contractRefNo" id="contractRefNo" />
    </mat-form-field> -->

    <mat-form-field class="full-width" *ngIf="selectedContractOriginalActive === true">
      <input
        matInput
        placeholder="กรอกเลขที่สัญญาเพื่ออ้างอิง"
        formControlName="contractRefNo"
        name="contractRefNo"
        id="contractRefNo"
        [matAutocomplete]="auto"
        (blur)="onInputBlur()"
      />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
        <mat-option *ngFor="let option of filteredOptions" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>ประเภทสัญญา/เอกสาร</mat-label>
      <mat-select [(ngModel)]="selectedDocType" name="docType" formControlName="docType" name="docType" id="docType">
        <mat-option *ngFor="let docType of docType" [value]="docType.value"> {{ docType.viewValue }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="selectedContractOriginalActive === true">
      <input matInput placeholder="เลขที่รับฝาก" formControlName="depositNo" name="depositNo" id="depositNo" />
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="myForm.get('docType').value === 'อื่น ๆ ระบุ'">
      <input matInput placeholder="กรุณาระบุ" formControlName="docTypeManual" name="docTypeManual" id="docTypeManual" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="ชื่อสัญญา" formControlName="contractName" name="contractName" id="contractName" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>เลขที่สัญญา</mat-label>
      <mat-select [(ngModel)]="selectedContractType" name="contractType" formControlName="contractType" name="contractType" id="contractType">
        <mat-option *ngFor="let contractType of contractType" [value]="contractType.value"> {{ contractType.viewValue }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="selectedContractType === 'เลขที่สัญญา TNI'">
      <input
        matInput
        placeholder="Auto Running (ระบบะจะแสดงเมื่อเลือกฝ่ายงาน/ส่วนงาน)"
        formControlName="contractNoAuto"
        name="contractNoAuto"
        id="contractNoAuto"
        readonly
      />
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="selectedContractType === 'เลขที่สัญญาของคู่ค้า'">
      <input matInput placeholder="กรุณากรอกเลขที่สัญญาของคู่ค้า" formControlName="contractNoManual" name="contractNoManual" id="contractNoManual" />
    </mat-form-field>

    <mat-form-field class="hidden">
      <input matInput placeholder="เลขที่เอกสาร(บันทึกลงใน DB)" formControlName="contractNo" name="contractNo" id="contractNo" [value]="contractNo" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="ชื่อคู่สัญญา" formControlName="contractPairName" name="contractPairName" id="contractPairName" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>ฝ่ายงาน/ส่วนงาน</mat-label>
      <mat-select [(ngModel)]="selectedDepartment" name="department" formControlName="department" name="department" id="department">
        <mat-option *ngFor="let department of department" [value]="department.viewValue"> {{ department.viewValue }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="hidden">
      <input matInput placeholder="ฝ่ายงาน/ส่วนงาน(บันทึกลงใน DB)" formControlName="departmentName" name="departmentName" id="departmentName" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="ชื่อผู้ฝากสัญญา" formControlName="depositorName" name="depositorName" id="depositorName" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="E-mail ผู้ฝากสัญญา" formControlName="contractSenderEmail" name="contractSenderEmail" id="contractSenderEmail" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input
        matInput
        placeholder="ชื่อผู้บริหารที่ฝากสัญญา"
        formControlName="contractManagerName"
        name="contractManagerName"
        id="contractManagerName"
      />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input
        matInput
        placeholder="E-mail ชื่อผู้บริหารที่ฝากสัญญา"
        formControlName="contractManagerEmail"
        name="contractManagerEmail"
        id="contractManagerEmail"
      />
    </mat-form-field>

    <!-- <mat-form-field class="full-width">
      <mat-datetimepicker-toggle
        #matDatetimepickerToggle_contractSigningDate="matDatetimepickerToggle"
        [for]="contractSigningDate"
        matSuffix
      ></mat-datetimepicker-toggle>
      <mat-datetimepicker #contractSigningDate type="date" [timeInterval]="1"></mat-datetimepicker>
      <input
        #contractSigningDate
        matInput
        formControlName="contractSigningDate"
        placeholder="วันที่ลงนามในสัญญา"
        [matDatetimepicker]="contractSigningDate"
      />
    </mat-form-field> -->

    <mat-form-field class="full-width">
      <mat-label>วันที่ลงนามในสัญญา</mat-label>
      <input matInput [matDatepicker]="picker3" placeholder="DD/MM/YYYY" formControlName="contractSigningDate" />
      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>

    <!-- <mat-form-field class="full-width">
      <mat-datetimepicker-toggle
        #matDatetimepickerToggle_contractEffectiveDate="matDatetimepickerToggle"
        [for]="contractEffectiveDate"
        matSuffix
      ></mat-datetimepicker-toggle>
      <mat-datetimepicker #contractEffectiveDate type="date" [timeInterval]="1"></mat-datetimepicker>
      <input
        #contractEffectiveDate
        matInput
        formControlName="contractEffectiveDate"
        placeholder="วันที่สัญญามีผล"
        [matDatetimepicker]="contractEffectiveDate"
      />
    </mat-form-field> -->

    <mat-form-field class="full-width">
      <mat-label>วันที่สัญญามีผล</mat-label>
      <input matInput [matDatepicker]="picker1" placeholder="DD/MM/YYYY" formControlName="contractEffectiveDate" />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    <mat-radio-group aria-label="Select an option" formControlName="notificationActive" [(ngModel)]="selectedNotificationActive">
      <mat-radio-button value="1">ไม่มีการแจ้งเตือน</mat-radio-button>
      <mat-radio-button value="2">มีการแจ้งเตือน</mat-radio-button>
    </mat-radio-group>

    <div style="margin-bottom: 15px"></div>

    <!-- <mat-form-field class="full-width" *ngIf="selectedNotificationActive === '2'">
      <mat-datetimepicker-toggle
        #matDatetimepickerToggle_contractEndDate="matDatetimepickerToggle"
        [for]="contractEndDate"
        matSuffix
      ></mat-datetimepicker-toggle>
      <mat-datetimepicker #contractEndDate type="date" [timeInterval]="1"></mat-datetimepicker>
      <input
        #contractEndDate
        matInput
        formControlName="contractEndDate"
        placeholder="วันที่สัญญาสิ้นผล"
        [matDatetimepicker]="contractEndDate"
        [min]="minDate"
      />
    </mat-form-field> -->

    <mat-form-field class="full-width" *ngIf="selectedNotificationActive === '2'">
      <mat-label>วันที่สัญญาสิ้นผล</mat-label>
      <input matInput [matDatepicker]="picker2" placeholder="DD/MM/YYYY" formControlName="contractEndDate" />
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="selectedNotificationActive === '2'">
      <input matInput type="number" name="contractDuration" id="contractDuration" formControlName="contractDuration" placeholder="ระยะเวลาสัญญา" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input
        matInput
        placeholder="มูลค่าสัญญา"
        formControlName="contractValue"
        name="contractValue"
        id="contractValue"
        (blur)="contractValueChanges()"
      />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="หลักประกันสัญญา" formControlName="contractCollateral" name="contractCollateral" id="contractCollateral" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>ประเภทการลงนาม</mat-label>
      <mat-select [(ngModel)]="selectedSignatureType" name="signatureType" formControlName="signatureType" name="signatureType" id="signatureType">
        <mat-option *ngFor="let signatureType of signatureType" [value]="signatureType.value"> {{ signatureType.viewValue }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>สถานะ</mat-label>
      <mat-select [(ngModel)]="selectedStatus" name="status" formControlName="status" name="status" id="status">
        <mat-option *ngFor="let status of status" [value]="status.value"> {{ status.viewValue }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput type="number" name="storageDuration" id="storageDuration" formControlName="storageDuration" placeholder="ระยะเวลาจัดเก็บ" />
    </mat-form-field>

    <!-- <mat-form-field class="full-width">
      <mat-datetimepicker-toggle
        #matDatetimepickerToggle_complianceDepositDate="matDatetimepickerToggle"
        [for]="complianceDepositDate"
        matSuffix
      ></mat-datetimepicker-toggle>
      <mat-datetimepicker #complianceDepositDate type="date" [timeInterval]="1"></mat-datetimepicker>
      <input
        #complianceDepositDate
        matInput
        formControlName="complianceDepositDate"
        placeholder="วันที่นำฝาก Compliance"
        [matDatetimepicker]="complianceDepositDate"
      />
    </mat-form-field> -->

    <mat-form-field class="full-width">
      <mat-label>วันที่นำฝาก Compliance</mat-label>
      <input matInput [matDatepicker]="picker4" placeholder="DD/MM/YYYY" formControlName="complianceDepositDate" />
      <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
      <mat-datepicker #picker4></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="สถานที่จัดเก็บ" formControlName="storageLocation" name="storageLocation" id="storageLocation" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>ผู้รับผิดชอบ</mat-label>
      <mat-select
        [(ngModel)]="selectedResponsiblePerson"
        name="responsiblePerson"
        formControlName="responsiblePerson"
        name="responsiblePerson"
        id="responsiblePerson"
      >
        <mat-option *ngFor="let responsiblePerson of responsiblePerson" [value]="responsiblePerson.value">
          {{ responsiblePerson.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div style="margin-bottom: 25px"></div>
  </div>

  <div style="margin-bottom: 5px"></div>

  <mat-form-field style="width: 100%" *ngIf="fileUploadedHiden">
    <input matInput formControlName="fileNameOriginal" type="text" matInput placeholder="Original Filename" readonly />
  </mat-form-field>

  <mat-form-field style="width: 100%" *ngIf="fileUploadedHiden">
    <input matInput type="text" matInput placeholder="New Filename" [value]="currentFileName" readonly />
  </mat-form-field>
  <mat-form-field style="width: 100%" *ngIf="fileUploadedHiden">
    <input matInput type="text" matInput placeholder="Path Destination" [value]="currentPath" readonly />
  </mat-form-field>

  <input hidden type="file" #fileInput (change)="onUploadFile($event)" />
  <button mat-raised-button style="width: 100%" (click)="fileInput.click()" [color]="fileUploaded ? 'primary' : ''">
    <mat-icon> {{ fileUploaded ? 'done' : 'file_upload' }} </mat-icon> Upload File
  </button>
</form>

<div style="margin-bottom: 5px"></div>
<div mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="completeForm(myForm)" [disabled]="myForm.invalid">UPLOAD</button>
  <button mat-raised-button mat-dialog-close (click)="closeDialog()">CANCEL</button>
</div>
