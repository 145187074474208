<!-- <div class="aca-content-header">
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">Document Expire Report</h1>
  </div>
</div> -->

<aca-page-layout>
  <div class="aca-page-layout-header">
    <!-- <h1 class="aca-page-title">Document Expire Report</h1> -->

    <h1 class="aca-page-title">{{ 'DOCUMENT-EXPIRE.REPORT.TITLE' | translate }}{{ isAdmin ? ' For Admin' : '' }}</h1>
  </div>

  <div class="aca-page-layout-content">
    <div class="page-main-content">
      <div class="example-container">
        <!-- filename -->

        <mat-form-field style="width: 200px" *ngIf="!isAdmin">
          <mat-label>{{ 'DOCUMENT-EXPIRE.REPORT.FILE_NAME' | translate }}</mat-label>
          <input [(ngModel)]="filename" matInput placeholder="file name" />
          <mat-icon matSuffix *ngIf="!filename"></mat-icon>
          <button (click)="filename = ''" mat-icon-button matSuffix *ngIf="filename">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- status dropdown -->
        <mat-form-field style="width: 170px" *ngIf="isAdmin">
          <mat-label>{{ 'DOCUMENT-EXPIRE.REPORT.STATUS' | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedStatus">
            <mat-option value="Selected">Selected</mat-option>
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Deleted">Deleted</mat-option>
            <mat-option value="Expired">Expired</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- startdate  -->
        <mat-form-field style="width: 170px" *ngIf="isAdmin">
          <span class="adf-fill-remaining-space"></span>
          <input matInput [matDatepicker]="picker1" [placeholder]="getStartDatePlaceholder()" [formControl]="startdate" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

        <mat-form-field style="width: 200px" *ngIf="!isAdmin">
          <span class="adf-fill-remaining-space"></span>
          <input matInput [matDatepicker]="picker1" placeholder="{{ 'DOCUMENT-EXPIRE.REPORT.START_DATE' | translate }}" [formControl]="startdate" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

        <!-- enddate  -->
        <mat-form-field style="width: 170px">
          <span class="adf-fill-remaining-space"></span>
          <input matInput [matDatepicker]="picker2" placeholder="{{ 'DOCUMENT-EXPIRE.REPORT.END_DATE' | translate }}" [formControl]="enddate" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

        <!-- Search -->
        <button mat-flat-button (click)="searchData()">
          <mat-icon>search</mat-icon>
          {{ 'DOCUMENT-EXPIRE.REPORT.SEARCH' | translate }}
        </button>

        <!-- Clear -->
        <button mat-flat-button (click)="clearAll()">
          <mat-icon>clear_all</mat-icon>
          {{ 'DOCUMENT-EXPIRE.REPORT.CLEAR' | translate }}
        </button>

        <!-- EXPORT -->
        <button mat-flat-button (click)="exportData()">
          <mat-icon>save_alt</mat-icon>
          {{ 'DOCUMENT-EXPIRE.REPORT.EXPORT' | translate }}
        </button>
      </div>

      <div class="report-table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
          <ng-container matColumnDef="no">
            <th mat-header-cell *matHeaderCellDef class="text-left">{{ 'DOCUMENT-EXPIRE.REPORT.TABLE.NO' | translate }}</th>
            <td mat-cell *matCellDef="let data" class="text-left">
              {{ data.no }}
            </td>
          </ng-container>

          <ng-container matColumnDef="filename">
            <th mat-header-cell *matHeaderCellDef class="text-left">{{ 'DOCUMENT-EXPIRE.REPORT.TABLE.FILE_NAME' | translate }}</th>
            <td mat-cell *matCellDef="let data" class="text-left">
              {{ data.filename }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="text-left">{{ 'DOCUMENT-EXPIRE.REPORT.TABLE.STATUS' | translate }}</th>
            <td mat-cell *matCellDef="let data" class="text-left">
              {{ data.status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="expiredate">
            <th mat-header-cell *matHeaderCellDef class="text-left">{{ 'DOCUMENT-EXPIRE.REPORT.TABLE.EXPIRE_DATE' | translate }}</th>
            <td mat-cell *matCellDef="let data" class="text-left">
              {{ data.expiredate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="removefile_date" *ngIf="isAdmin">
            <th mat-header-cell *matHeaderCellDef class="text-left">{{ 'DOCUMENT-EXPIRE.REPORT.TABLE.REMOVE_FILE_DATE' | translate }}</th>
            <td mat-cell *matCellDef="let data" class="text-left">
              {{ data.removefile_date }}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef class="text-left">{{ 'DOCUMENT-EXPIRE.REPORT.TABLE.NOTIFICATION_EMAIL' | translate }}</th>
            <td mat-cell *matCellDef="let data" class="text-left">
              {{ data.email }}
            </td>
          </ng-container>

          <ng-container matColumnDef="docowner">
            <th mat-header-cell *matHeaderCellDef class="text-left">{{ 'DOCUMENT-EXPIRE.REPORT.TABLE.DOCUMENT_OWNER' | translate }}</th>
            <td mat-cell *matCellDef="let data" class="text-left">
              {{ data.docowner }}
            </td>
          </ng-container>

          <ng-container matColumnDef="urlfile" *ngIf="!isAdmin">
            <th mat-header-cell *matHeaderCellDef class="text-uppercase text-center">
              {{ 'DOCUMENT-EXPIRE.REPORT.TABLE.VIEW_DOCUMENT' | translate }}
            </th>
            <td mat-cell *matCellDef="let data" class="text-left">
              <ng-container *ngIf="data.status !== 'Deleted'; else deleteTemplate">
                <button mat-icon-button (click)="openURL(data.urlfile)">
                  <mat-icon class="material-icons-outlined">visibility</mat-icon>
                </button>
              </ng-container>
              <ng-template #deleteTemplate>
                <button mat-icon-button>
                  <mat-icon class="material-icons-outlined">close</mat-icon>
                </button>
              </ng-template>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <mat-paginator [pageSizeOptions]="[200, 500, 1000]" [showFirstLastButtons]="true"></mat-paginator>
    </div>
  </div>
</aca-page-layout>
