import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AlfrescoApiService } from '@alfresco/adf-core';

@Injectable({
  providedIn: 'root'
})
export class UserAlfService {

  constructor(private apiService: AlfrescoApiService) {
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  private privateApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callCustomApi(path, httpMethod, ...params);
  }


  getUser(): Observable<any> {
    const pathService = `people?skipCount=0&maxItems=100`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }


  searchUser(searchTerm: string): Observable<any> {

    if (!searchTerm) {
      searchTerm = '';
    }
    
    const pathService = `/alfresco/s/api/people?sortBy=fullName&dir=asc&filter=${searchTerm}&maxResults=250`
    const httpMethod = 'GET';
    return from(this.privateApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  createUser(body: any): Observable<any> {
    const pathService = `people`
    return from(this.publicApiCall(
      pathService,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  updateUser(body: any, userId: any): Observable<any> {
    const pathService = `people/${userId}`
    return from(this.publicApiCall(
      pathService,
      'PUT',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  addUserToGroup(userId: string, groupId: string): Observable<any> {
    const pathService = `groups/${groupId}/members`;
    const body = { id: userId, memberType: 'PERSON' }; // Add memberType
    return from(this.publicApiCall(
      pathService,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }




}
