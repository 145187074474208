/*!
 * @license
 * Copyright 2019 Alfresco Software, Ltd.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Component, Inject, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
  // MatDialogRef,
  // MatDialog
} from '@angular/material/dialog';

import * as moment from 'moment';

import { CommonModule, DOCUMENT } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import {
  // Subscription,
  Observable, from, throwError
} from 'rxjs';
// import { catchError, distinctUntilChanged, mergeMap, skip } from 'rxjs/operators';

import { NodesApiService, PeopleContentService } from '@alfresco/adf-content-services';

import { SharedLinksApiService } from '@alfresco/adf-content-services';

import { AppStore, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { AlfrescoApiService, ClipboardModule } from '@alfresco/adf-core';

import Swal from 'sweetalert2/dist/sweetalert2.js';
// import { BeflexWorkspaceService } from '../../service/beflex-workspace.service';


@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatDatetimepickerModule,
    ClipboardModule
  ],
  selector: 'aca-share-dialog',
  templateUrl: './content-node-share.dialog.html',
  styleUrls: ['./content-node-share.dialog.scss'],
  host: { class: 'adf-share-dialog' },
  encapsulation: ViewEncapsulation.None
})
export class ShareExtenalDialogComponent implements OnInit, OnDestroy {
  // private subscriptions: Subscription[] = [];

  minDate = moment().add(1, 'd');
  sharedId: string;
  fileName: string;
  baseShareUrl: string;
  isFileShared = false;
  isDisabled = false;
  form: FormGroup = new FormGroup({
    sharedUrl: new FormControl(''),
    time: new FormControl({ value: '', disabled: false }, [Validators.required]),
    email: new FormControl('', [Validators.required])
  });

  nodeID: string;

  @ViewChild('matDatetimepickerToggle')
  matDatetimepickerToggle;

  @ViewChild('slideToggleExpirationDate')
  slideToggleExpirationDate;

  @ViewChild('dateTimePickerInput')
  dateTimePickerInput;

  public unShareActive = false;

  public nextActive = false;

  headers: any;
  apiURL: any;

  constructor(
    public trans: TranslateService,
    private sharedLinksApiService: SharedLinksApiService,
    private dialogRef: MatDialogRef<ShareExtenalDialogComponent>,
    //   private dialog: MatDialog,
    //   private nodesApiService: NodesApiService,
    private store: Store<AppStore>,

    private apiService: AlfrescoApiService,
    private nodesApiService: NodesApiService,
    @Inject(DOCUMENT) private document: any,
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private beflexWorkspaceService: BeflexWorkspaceService,
    private peopleContentService: PeopleContentService
  ) {
    this.apiURL = '';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic Uk9MRV9USUNLRVQ6VElDS0VUXzQ1NzE4MWEwOTRmNzM2ZDRjYjM2NTVkMTBmZGM5OTUwYjljMWNiNWM=',
      'Access-Control-Allow-Origin': '*'
    });
  }
  ngOnDestroy(): void {
  }


  fullName: any;
  ngOnInit() {

    console.log('# ShareExtenalDialogComponent');

    this.getTranlator();

    if (!this.canUpdate) {
      this.form.controls['time'].disable();
    }


    if (this.data.node && this.data.node.entry) {
      console.log('#1');
      this.fileName = this.data.node.entry.name;
      this.baseShareUrl = this.data.baseShareUrl;
      this.fileName = this.data.node.entry.name;
      this.baseShareUrl = this.data.baseShareUrl;
      const properties = this.data.node.entry.properties;
      this.nodeID = this.data.node.entry.id;
      if (!properties || !properties['qshare:sharedId']) {
        console.log('#2');
      } else {
        console.log('#3');
        this.sharedId = properties['qshare:sharedId'];
        this.isFileShared = true;
        this.updateForm();
      }
    }

    this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {

      if (userInfo.lastName) {
        this.fullName = userInfo.firstName + ' ' + userInfo.lastName;
      } else {
        this.fullName = userInfo.firstName;
      }
      console.log('this.fullName', this.fullName);
    });


  }

  // @ViewChild('sharedLinkInput') sharedLinkInput: ElementRef<HTMLInputElement>;


  onSubmit(sharedLinksForm) {
    if (sharedLinksForm.invalid) {
      alert('กรุณาระบุข้อมูลให้ครบถ้วนก่อนบันทึก!..');
      return;
    }
  }

  public async createToken() {
    try {
      const emailsString = this.form.get('email').value;
      const emailsArray = emailsString.split(',');
      for (const email of emailsArray) {
        const bodyData = {
          tokenText: 'xxx',
          sharedId: this.sharedId,
          email: email,
          fullName: this.fullName,
        };



        const tokenURL = '/token';
        this.createTokenService(tokenURL, bodyData).subscribe(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          (_response) => {
            this.store.dispatch(new SnackbarInfoAction('Email Sent Successfully', { duration: 2000 }));
            console.log('createTokenService response :', _response);
          },
          (error) => {
            console.error('createToken error 1 :', error);
          }
        );
      }
    } catch (error) {
      console.log('createToken error 2', error);
    }
  }

  public createTokenService(url: any, data: any): Observable<any> {
    try {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      });

      return this.httpClient.post<any>(url, data, {
        headers: this.headers
      });
    } catch (error) {
      console.error('createTokenService error', error);
      return throwError(error); // Re-throw the error
    }
  }
  get canUpdate() {
    return this.data.permission;
  }

  onToggleExpirationDate(slideToggle) {
    if (slideToggle.checked) {
      this.matDatetimepickerToggle.datetimepicker.open();
    } else {
      this.matDatetimepickerToggle.datetimepicker.close();
      this.form.controls.time.setValue(null);
    }
  }
  onDatetimepickerClosed() {
    this.dateTimePickerInput.nativeElement.blur();
    if (!this.form.controls.time.value) {
      this.slideToggleExpirationDate.checked = false;
    }
  }

  private async updateNode(email: any, date: any): Promise<Observable<any>> {
    return this.nodesApiService.updateNode(this.data.node.entry.id, {
      properties: {
        'qshare:expiryDate': date,
        'qshareData:email': email
      }
    });
  }

  private async updateEntryExpiryDate(email: any, date: any) {
    console.log('# updateEntryExpiryDate');
    const { properties } = this.data.node.entry;
    properties['qshare:expiryDate'] = date;
    properties['qshareData:email'] = email;
  }


  private async updateForm() {
    const { entry } = this.data.node;
    const expiryDate = entry.properties['qshare:expiryDate'];
    const email = entry.properties['qshareData:email'];
    this.unShareActive = true;
    const hostname = this.document.location.hostname;
    const baseShareLink = `https://${hostname}/quickshared/?sharedId=`;


    console.log('expiryDate ', expiryDate);

    this.form.setValue({
      sharedUrl: `${baseShareLink}${this.sharedId}`,
      time: expiryDate,
      email: email
    });
  }

  mssWarningTitle: any;
  mssWarningText: any;
  mssExampleEmail: any;

  getTranlator() {
    this.trans.get('SHARE.WARNING.TITLE').subscribe((translation) => {
      this.mssWarningTitle = translation;
    });

    this.trans.get('SHARE.WARNING.TEXT').subscribe((translation) => {
      this.mssWarningText = translation;
    });

    this.trans.get('SHARE.EMAIL.PLACEHOLDER').subscribe((translation) => {
      this.mssExampleEmail = translation;
    });
  }

  public async toggleFormState() {
    if (!this.unShareActive) {
      const dateExp = this.dateTimePickerInput.nativeElement.value;

      const email = this.form.get('email').value
      if (!dateExp || !email) {
        Swal.fire({
          title: this.mssWarningTitle,
          text: this.mssWarningText,
          icon: 'warning'
        });
        return;
      }
    }
    this.unShareActive = !this.unShareActive;
    if (!this.unShareActive) {
      // UNSHARE Functions
      this.deleteSharedLink(this.sharedId);
    } else {
      // SHARE Functions
      const bodyData = {
        nodeId: this.nodeID
      };
      this.createSharedLink2(bodyData).subscribe(
        async (response) => {
          if (response.entry) {
            this.sharedId = response.entry.id;
            if (this.data.node.entry.properties) {
              this.data.node.entry.properties['qshare:sharedId'] = this.sharedId;
            } else {
              this.data.node.entry.properties = {
                'qshare:sharedId': this.sharedId
              };
            }
            this.isDisabled = false;
            this.isFileShared = true;
            const dateExp = moment(this.dateTimePickerInput.nativeElement.value);
            const email = this.form.get('email').value;
            const time = this.form.get('time').value;
            await this.updateNode(email, dateExp)
            await this.updateEntryExpiryDate(email, dateExp);
            await this.setForm(email, time);


            await this.createToken();
          }
        },
        (error) => {
          console.error('createSharedLink2 Error :', error);
        }
      );
    }
  }

  public async setForm(email: any, expDate: any) {
    const hostname = this.document.location.hostname;
    const baseShareLink = `https://${hostname}/quickshared/?sharedId=`;
    this.form.setValue({
      sharedUrl: `${baseShareLink}${this.sharedId}`,
      time: expDate,
      email: email
    });
  }



  private deleteSharedLink(sharedId: string) {
    this.isDisabled = true;
    this.sharedLinksApiService.deleteSharedLink(sharedId).subscribe((response: any) => {
      if (response instanceof Error) {
        this.isDisabled = false;
        this.isFileShared = true;
        this.showError(response);
      } else {
        this.data.node.entry.properties['qshare:sharedId'] = null;
        this.data.node.entry.properties['qshare:expiryDate'] = null;
        this.dialogRef.close(this.data.node);
      }
    });
  }

  private showError(response) {
    let message;
    const statusCode = JSON.parse(response.message).error.statusCode;
    if (statusCode === 403) {
      message = 'SHARED_LINK.UNSHARE_PERMISSION_ERROR';
    }

    this.store.dispatch(new SnackbarErrorAction(message));
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }


  public createSharedLink2(data: any): Observable<any> {
    const pathService = `/shared-links2`;
    const httpMethod = 'POST';
    return from(this.publicApiCall(
      pathService,
      httpMethod,
      [null, null, null, null, data, ['application/json'], ['application/json']]
    ));
  }
}



