/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';

import { ContentNodeSelectorPanelComponent } from './content-node-selector-panel.component';
import { ContentNodeSelectorComponent } from './content-node-selector.component';
import { SitesDropdownModule } from '@alfresco/adf-content-services';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { SearchModule } from '@alfresco/adf-content-services';
import { CoreModule } from '@alfresco/adf-core';
import { DocumentListModule } from '@alfresco/adf-content-services';
import { NameLocationCellComponent } from './name-location-cell/name-location-cell.component';
import { UploadModule } from '@alfresco/adf-content-services';
import { SearchQueryBuilderService } from '@alfresco/adf-content-services';
import { ContentDirectiveModule } from '@alfresco/adf-content-services';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        CommonModule,
        MaterialModule,
        SitesDropdownModule,
        BreadcrumbModule,
        SearchModule,
        DocumentListModule,
        UploadModule,
        ContentDirectiveModule
    ],
    exports: [
        ContentNodeSelectorPanelComponent,
        NameLocationCellComponent,
        ContentNodeSelectorComponent
    ],
    declarations: [
        ContentNodeSelectorPanelComponent,
        NameLocationCellComponent,
        ContentNodeSelectorComponent
    ],
    providers: [SearchQueryBuilderService]
})
export class ContentNodeSelectorModule2 {}
