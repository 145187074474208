import { NgModule } from '@angular/core';
import { WebviewerExtComponent } from './webviewer-ext.component';
import { BrowserModule } from '@angular/platform-browser';
import { ExtensionService } from '@alfresco/adf-extensions';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';



// export function components() {
//   return [WebviewerExtComponent];
// }

import {
  PdfViewerModule,
  LinkAnnotationService,
  BookmarkViewService,
  MagnificationService,
  ThumbnailViewService,
  ToolbarService,
  NavigationService,
  TextSearchService,
  TextSelectionService,
  PrintService
} from '@syncfusion/ej2-angular-pdfviewer';

import { FormsModule } from '@angular/forms';
import { CoreModule } from '@alfresco/adf-core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ContentModule } from '@alfresco/adf-content-services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// ============================ CODE ============================

@NgModule({
  // declarations: components(),
  imports: [
    ContentModule,
    CoreModule.forChild(),
    ContentModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule
  ],
  // bootstrap: [components()],
  providers: [
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService,
    NavigationService,
    TextSearchService,
    TextSelectionService,
    PrintService
  ]
  // exports: components(),
  // entryComponents: components()
})
export class WebviewerExtModule {
  constructor(extensions: ExtensionService) {
    extensions.setComponents({
      'webviewer-ext.main.component': WebviewerExtComponent
    });
  }
}
