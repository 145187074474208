<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">{{ 'APPROVAL-WORKFLOW.MY-TASKS.TITLE' | translate }}</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>

    <div class="button-container">
      <button ejs-button class="rounded-button" cssClass="e-info" (click)="onCreateAdhoc()">
        {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.NEW_ADHOC_REQUEST' | translate }}
      </button>
      <button ejs-button class="rounded-button" cssClass="e-info" (click)="onCreate()">
        {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.NEW_REQUEST' | translate }}
      </button>
    </div>
  </div>

  <div class="aca-page-layout-content">
    <ejs-grid
      *ngIf="loading"
      #grid
      [allowExcelExport]="true"
      width="100%"
      height="100%"
      [rowHeight]="30"
      [dataSource]="dataTemp"
      [pageSettings]="pageSettings"
      [enableHover]="false"
      [allowResizing]="true"
      [allowSorting]="true"
      allowPaging="true"
      (rowSelected)="onRowSelected($event)"
    >
      <e-columns>
        <e-column field="reqProcessId" headerText="REQ ID" width="50" textAlign="Right" [visible]="false"></e-column>
        <e-column field="workflowId" headerText="WF ID" width="50" textAlign="Right" [visible]="false"></e-column>
        <e-column field="taskId" headerText=" {{ 'APPROVAL-WORKFLOW.MY-TASKS.GRID_TASKS_ID' | translate }}" width="100" textAlign="Right"></e-column>
        <e-column field="subject" headerText=" {{ 'APPROVAL-WORKFLOW.MY-TASKS.GRID_SUBJECT' | translate }}" width="180"></e-column>
        <e-column field="createDate" headerText=" {{ 'APPROVAL-WORKFLOW.MY-TASKS.GRID_CREATE_DATE' | translate }}" width="110"></e-column>
        <e-column field="creator" headerText=" {{ 'APPROVAL-WORKFLOW.MY-TASKS.GRID_CREATE_USER' | translate }}" width="110"></e-column>
        <e-column field="assign" headerText=" {{ 'APPROVAL-WORKFLOW.MY-TASKS.GRID_ASSIGN' | translate }}" width="100"></e-column>

        <e-column field="priority" headerText=" {{ 'APPROVAL-WORKFLOW.MY-TASKS.GRID_PRIORITY' | translate }}" width="100">
          <ng-template #template let-data>
            <ejs-chiplist>
              <e-chips>
                <e-chip [text]="data.priority" [cssClass]="getPriorityClass(data.priority)"></e-chip>
              </e-chips>
            </ejs-chiplist>
          </ng-template>
        </e-column>

        <e-column field="actions" headerText=" {{ 'APPROVAL-WORKFLOW.MY-TASKS.GRID_ACTION' | translate }}" width="120">
          <ng-template #template let-data>
            <button ejs-button  iconCss="e-icons e-medium e-edit-notes" class="rounded-button-item" (click)="onReview(data)">
              <!-- <i class="fa-solid fa-pen-to-square"></i> -->
            </button>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</aca-page-layout>
