// import { BeflexWorkspaceService } from './../../service/beflex-workspace.service';
// import { ContentApi } from '@alfresco/js-api';
import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AlfrescoApiService } from '@alfresco/adf-core';
import * as moment from 'moment';
import { PeopleContentService } from '@alfresco/adf-content-services';
// import { map } from 'rxjs/operators';
// import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  environment: any;
  apiURL: any;
  // headers: any;
  userName: any;

  constructor(private api: AlfrescoApiService, private apiService: AlfrescoApiService,
    private peopleContentService: PeopleContentService
  ) {
    this.apiURL = '';
    // this.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Access-Control-Allow-Origin': '*'
    // });
  }

  userId: any;
  loadEcmUserInfo() {
    this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {
      this.userId = userInfo.id;
    });
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  private privateApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callCustomApi(path, httpMethod, ...params);
  }



  // UserGroups
  getGroups(personId: string): Observable<any> {
    const pathService = `/people/${personId}/groups?skipCount=0&maxItems=100`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }



  // System Report
  getAuditData(buildQuery: string): Observable<any> {
    const pathService = `/audit-applications/share-site-access/audit-entries?skipCount=0&orderBy=createdAt%20DESC&maxItems=200000&include=values${buildQuery}`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  // Workspace Report
  getAuditAppData(buildQuery: string): Observable<any> {
    const pathService = `/alfresco/s/share-stats/query-audits${buildQuery}`
    const httpMethod = 'GET';
    return from(this.privateApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  // Insert Audit
  public createAuditShareStats(nodeId: string, nodeName: string, action: string) {
    this.loadEcmUserInfo();
    const body = {
      id: 0,
      auditSite: `_repository , ${nodeName} `,
      auditAppName: 'document',
      auditObject: `workspace://SpacesStore/${nodeId}`,
      auditActionName: action,
      auditUserId: this.userId,
      auditTime: moment().valueOf(),
      auditNodeName: nodeName
    };
    this.callService(`/alfresco/s/share-stats/insert-audit`, 'POST', body);
  }

  public createAuditShareStatsList(Nodes: any, action: string) {
    this.loadEcmUserInfo();
    Nodes.forEach((node) => {
      console.log('nodeId ', node.id);
      console.log('nodeName ', node.name);
      const body = {
        id: 0,
        auditSite: `_repository , ${node.name} `,
        auditAppName: 'document',
        auditObject: `workspace://SpacesStore/${node.id}`,
        auditActionName: action,
        auditUserId: this.userId,
        auditTime: moment().valueOf(),
        auditNodeName: node.name
      };
      this.callService(`/alfresco/s/share-stats/insert-audit`, 'POST', body);
    });
  }

  private callService(path: string, httpMethod: string, body: object = {}): Promise<any> {
    const params = [{}, {}, {}, {}, body, ['application/json'], ['application/json']];
    return this.api.getInstance().contentClient.callCustomApi(path, httpMethod, ...params);
  }

}
