<ng-container *ngIf="selectionState">


  <ng-container *ngIf="!data.iconButton">
    <button mat-menu-item data-automation-id="share-action-button" (click)="editSharedNode(selectionState, '.adf-context-menu-source')">
      <mat-icon>link</mat-icon>
      <ng-container *ngIf="isShared; else not_shared">
        <span>{{ 'APP.ACTIONS.SHARE_EDIT_STANDARD' | translate }}</span>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="data.iconButton">
    <button
      mat-icon-button
      data-automation-id="share-action-button"
      (click)="editSharedNode(selectionState, '#share-action-button')"
      [attr.aria-label]="selectionLabel | translate"
      [attr.title]="selectionLabel | translate"
      id="share-action-button"
    >
      <mat-icon>link</mat-icon>
    </button>
  </ng-container>

  
</ng-container>

<ng-template #not_shared>
  <span>{{ 'APP.ACTIONS.SHARE_STANDARD' | translate }}</span>
</ng-template>
