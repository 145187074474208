<div class="grid-container">
  <div class="grid-item">
    <h4>{{ 'APPROVAL-WORKFLOW.CONFIG.HEADER_GRID_GROUP' | translate }}</h4>
    <ejs-grid
      #gridGroup
      [dataSource]="dataGroup"
      width="95%"
      style="margin-top: -10px"
      [toolbar]="toolbarGroup"
      allowPaging="true"
      [pageSettings]="pageSettings"
      [editSettings]="editSettingsGroup"
      (actionBegin)="actionBeginGroup($event)"
      (actionComplete)="actionCompleteGroup($event)"
      (rowSelected)="rowSelectedGroup($event)"
    >
      <e-columns>
        <e-column field="group_id" headerText="Group ID" [visible]="false"></e-column>
        <e-column field="group_name" headerText="{{ 'APPROVAL-WORKFLOW.CONFIG.HEADER_GRID_GROUP' | translate }}"></e-column>
      </e-columns>
    </ejs-grid>
  </div>

  <div class="grid-item">
    <h4>{{ 'APPROVAL-WORKFLOW.CONFIG.HEADER_GRID_SUB_GROUP' | translate }}</h4>
    <ejs-grid
      #gridSubGroup
      [dataSource]="dataSubGroup"
      width="95%"
      style="margin-top: -10px"
      [toolbar]="toolbarSubGroup"
      [editSettings]="editSettingsSubGroup"
      (actionBegin)="actionBeginSubGroup($event)"
      (actionComplete)="actionCompleteSubGroup($event)"
    >
      <e-columns>
        <e-column
          field="sub_group_name"
          headerText="{{ 'APPROVAL-WORKFLOW.CONFIG.HEADER_GRID_SUB_GROUP' | translate }}"
          [validationRules]="requiredRules"
        ></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
