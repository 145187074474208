import { Component, OnInit } from '@angular/core';
import { AnalyticService } from '../../services/analytic-service.service';

@Component({
  selector: 'app-chart-barcolums',
  templateUrl: './chart-barcolums.component.html',
  styleUrls: ['./chart-barcolums.component.scss']
})
export class ChartBarcolumsComponent implements OnInit {



  public groupingSeparator = true; // Add this line

  public primaryXAxis = {
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    interval: 1,
    lineStyle: { width: 0 },
    valueType: 'Category'
  }

  public primaryYAxis = {
    title: '',
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    majorGridLines: { width: 1 },
    minorGridLines: { width: 1 },
  }


  public data1?: object[];
  public data2?: object[];
  public data3?: object[];
  public title?: string;


  public marker = {
    visible: true, dataLabel: {
      visible: true,
      position: 'Outer',
    }
  };

  public tooltip: object = {
    enable: true
  };

  public legend: object = {
    visible: true,
  }

  dataArray: any;

  public palettes: string[] = ['#86a1db', '#ff6f69', '#fed766'];


  constructor(
    private analyticService: AnalyticService
  ) { }

  async ngOnInit() {
    await (await this.analyticService.fileCountByType()).subscribe(
      data => {
        this.dataArray = data.list.context.facetQueries.map((item, _index) => {
          return {
            groupName: item.label,
            dataSource: [{ x: '', y: item.count }],
            name: item.label,
          };
        });
      },
      error => {
        console.error('Error fetching data', error);
      }
    );

  }

}
