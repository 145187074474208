<div class="dialog-title">การปรับปรุงสัญญา</div>
<div style="margin-bottom: 5px"></div>

<div style="margin-bottom: 25px"></div>

<!-- <ejs-grid #grid [dataSource]="dataTempItem" allowPaging="true">
  <e-columns>
    <e-column field="contractItemId" headerText="Order ID" width="120" textAlign="Right"> </e-column>
    <e-column field="contractItemNo" headerText="Customer Name" width="150"></e-column>
  </e-columns>
</ejs-grid> -->

<ejs-grid #grid [dataSource]="dataTempItem" allowPaging="true" (rowSelecting)="rowSeleted($event)" [selectionSettings]="selectionOptions">
  <e-columns>
    <e-column type="checkbox" width="70">
      <ng-template #headerTemplate> </ng-template>
    </e-column>
    <e-column field="contractItemId" headerText="id" [visible]="false"> </e-column>
    <e-column field="contractDocumentNo" headerText="no" [visible]="false"></e-column>
    <e-column field="contractItemNo" headerText="itemNo" [visible]="false"></e-column>
    <e-column field="contractStatusProcess" headerText="status" [visible]="false"></e-column>
    <e-column field="nodeName" headerText="ชื่อไฟล์"></e-column>
    <e-column field="contractDate" headerText="วันที่"></e-column>
  </e-columns>
</ejs-grid>

<div style="margin-bottom: 30px"></div>

<div style="margin-bottom: 15px"></div>
<div mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="onComplete()">Next</button>
  <button mat-raised-button mat-dialog-close (click)="onClose()">Cancel</button>
</div>
