<div class="dialog-header">
  <h2 class="dialog-title">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.NEW_ADHOC_REQUEST' | translate }}</h2>
</div>

<form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
  <div class="dialog-content">
    <div class="input-container-main">
      <div class="e-card" style="padding: 8px; margin-top: 5px">
        <!-- START SECTION -->

        <!-- # 1 -->
        <div class="input-container">
          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.APPROVAL_STEP' | translate }}</h4>

            <button ejs-button iconCss="e-icons e-circle-add" (click)="addUser()">
              {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.ADD_USER' | translate }}
            </button>

            <ejs-grid
              #gridUser
              [dataSource]="dataUser"
              height="200px"
              width="570px"
              allowPaging="true"
              [pageSettings]="pageSettings"
              (rowSelected)="onRowSelected($event)"
              style="margin-top: 10px"
            >
              <e-columns>
                <e-column field="listNo" headerText="NO" width="70" [visible]="false"></e-column>
                <e-column field="userId" headerText="ID" [visible]="false"></e-column>
                <e-column
                  field="displayName"
                  headerText="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GRID_USER_DISPLAYNAME' | translate }}"
                  width="180"
                ></e-column>

                <e-column
                  field="email"
                  headerText="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GRID_USER_EMAIL' | translate }}"
                  width="200"
                  [visible]="false"
                ></e-column>

                <e-column field="Action" headerText="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GRID_USER_ACTION' | translate }}" width="130">
                  <ng-template #template let-data>
                    <button
                      ejs-button
                      iconCss="e-icons e-chevron-up"
                      class="rounded-button-item"
                      (click)="moveUp(data)"
                      [disabled]="isFirstRow(data)"
                    ></button>

                    <button
                      ejs-button
                      iconCss="e-icons e-chevron-down-thin"
                      class="rounded-button-item"
                      (click)="moveDown(data)"
                      [disabled]="isLastRow(data)"
                    ></button>

                    <button ejs-button iconCss="e-icons e-circle-remove" class="rounded-button-item" (click)="remove(data)"></button>
                  </ng-template>
                </e-column>
              </e-columns>
            </ejs-grid>
          </div>
        </div>

        <!-- # 2 -->
        <div class="input-container">
          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.APPROVAL_STEP' | translate }}:</h4>
            <div class="process-breadcrumb">
              <div class="breadcrumb-items">
                <ng-container *ngFor="let user of dataUser; let last = last">
                  <span class="breadcrumb-item">{{ user.displayName }}</span>
                  <span *ngIf="!last" class="e-icons e-small e-chevron-right-small-2"></span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- # 3 -->
        <div class="input-container">
          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.SUBJECT' | translate }}:</h4>
            <ejs-textbox
              #subject
              id="subject"
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.SUBJECT_PLACHOLDER' | translate }}"
              formControlName="subject"
              [value]="_requestProcessData?.request_subject"
            ></ejs-textbox>
          </div>

          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.PRIORITY' | translate }}:</h4>
            <ejs-dropdownlist
              #priority
              id="priority"
              [dataSource]="dataPriority"
              [fields]="fieldsPriority"
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.PRIORITY_PLACHOLDER' | translate }}"
              formControlName="priority"
              [value]="selectedPriorityId"
            ></ejs-dropdownlist>
          </div>

          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.DUE_DATE' | translate }}:</h4>
            <ejs-datepicker
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.DUE_DATE_PLACHOLDER' | translate }}"
              formControlName="dueDate"
              [value]="_requestProcessData?.request_due_date"
            ></ejs-datepicker>
          </div>
        </div>

        <!-- # 4 -->
        <div class="input-container">
          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.DESCRIPTION' | translate }}:</h4>
            <ejs-textarea
              id="description"
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.DESCRIPTION_PLACHOLDER' | translate }}"
              resizeMode="None"
              rows="3"
              width="700px"
              formControlName="description"
              [value]="_requestProcessData?.request_description"
            ></ejs-textarea>
          </div>
        </div>

        <!-- # 5 -->
        <div class="input-section">
          <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.ATTACHMENT_FILES' | translate }}:</h4>

          <button ejs-button iconCss="e-icons e-file-new" class="rounded-button" (click)="openSelectorDialog()">
            {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.SELETED_FILES' | translate }}
          </button>

          <!-- List File -->
          <div class="selected-files-list" *ngIf="listNodeUploadActive" style="margin-top: 10px">
            <div class="file-item" *ngFor="let node of listNodeUpload; let last = last">
              <div class="file-content">
                <div class="file-info">
                  <span class="file-name">{{ node.node_name }}</span>

                  <div class="e-section-control">
                    <!-- To render ButtonGroup. -->
                    <div class="e-btn-group">
                      <button ejs-button (click)="onView(node.node_id)">
                        <!-- <i class="fas fa-eye"></i>  -->
                        {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.VIEW' | translate }}
                      </button>
                      <button ejs-button (click)="onRemove(node.node_id)">
                        <!-- <i class="fas fa-trash"> </i>-->
                        {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.REMOVE' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </div>
          </div>
        </div>
        <!-- END SECTION -->
      </div>

      <div style="text-align: right; margin-top: 7px">
        <button ejs-button iconCss="e-icons e-circle-check" class="rounded-button" style="margin-left: 5px" cssClass="e-info" (click)="onCreate()">
          {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.START' | translate }}
        </button>

        <button ejs-button iconCss="e-icons e-save-as" class="rounded-button" style="margin-left: 5px" type="button" (click)="onSaveDraft()">
          {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.SAVE_DRAFT' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
