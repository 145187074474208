/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit } from '@angular/core';
import {
  AccumulationTheme,
  // AccumulationTheme,
  ChartAnnotationSettingsModel,
  IAccLoadedEventArgs,
  // IAccLoadedEventArgs
} from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { AnalyticService } from '../../services/analytic-service.service';

interface FileCountData {
  list: {
    context: {
      facetQueries: Array<{
        label: string;
        count: number;
      }>;
    };
  };
}

interface ChartDataPoint {
  Browser: string;
  Users: number;
  text: string;
  tooltipMappingName: number;
}



@Component({
  selector: 'app-chart-semipie',
  templateUrl: './chart-semipie.component.html',
  styleUrls: ['./chart-semipie.component.css']
})
export class ChartSemipieComponent implements OnInit {


  public annotations: ChartAnnotationSettingsModel[] = [
    {
      // eslint-disable-next-line max-len
      content: Browser.isDevice ? "<div style='font-Weight:700; font-size:11px;'>จำนวนไฟล์</div>" : "<div style='font-Weight:600; font-size:14px;'>จำนวนไฟล์</div>", region: 'Series', x: Browser.isDevice ? "52%" : "51%", y: Browser.isDevice ? '85%' : '85%'
    },

  ];

  public palette: string[] = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#A133FF']; // Modern color palette


  // Initializing Legend
  public legendSettings: Object = {
    visible: true,
    toggleVisibility: true,
    position: 'Right',
    textWrap: 'Wrap',
  };
  // Initializing DataLabel
  public dataLabel: Object = {
    visible: true,
    position: 'Inside',
    enableRotation: true,
    connectorStyle: { length: '20px', type: 'Curve' },
    name: 'text',
    font: { fontWeight: '600', size: '12px', color: '#ffffff' },

  };
  // custom code start
  public load(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.accumulation.theme = <AccumulationTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast').replace(/-highContrast/i, 'HighContrast');
  }

  // custom code end
  public radius = '110%';
  public enableAnimation = false;
  public startAngle = 270;
  public endAngle = 90;
  public tooltip: Object = { enable: true, format: "<b>${point.x}</b><br>qty: <b>${point.tooltip}</b>", header: '' };





  constructor(private analyticService: AnalyticService) { }


  public data?: ChartDataPoint[];


  private readonly SIZE_LABELS = {
    SMALL: 'ขนาด Small (น้อยกว่า 1 MB)',
    MEDIUM: 'ขนาด Medium (1 - 10 MB)',
    LARGE: 'ขนาด Large (มากกว่า 10 MB)'
  } as const;

  private readonly LABEL_ORDER = [
    this.SIZE_LABELS.SMALL,
    this.SIZE_LABELS.MEDIUM,
    this.SIZE_LABELS.LARGE
  ];

  loading = false;
  error: string | null = null;
  loadSuccess = false;

  async ngOnInit(): Promise<void> {
    try {
      await this.loadFileCountData();
    } catch (error) {
      console.error('Error fetching file count data:', error);
      this.loadSuccess = false;
    }
  }

  private async loadFileCountData(): Promise<void> {
    this.loading = true;
    try {
      const fileData$ = await this.analyticService.fileCountBySize();
      fileData$.subscribe({
        next: (response: FileCountData) => {
          this.data = this.transformToChartData(response);
          this.loadSuccess = true;
        },
        error: (error) => {
          this.error = 'Failed to load chart data';
          console.error('Error loading file data:', error);
          this.loadSuccess = false;
        },
        complete: () => {
          this.loading = false;
        }
      });
    } finally {
      this.loading = false;
    }
  }

  private transformToChartData(data: FileCountData): ChartDataPoint[] {
    return data.list.context.facetQueries
      .map(item => ({
        Browser: item.label,
        Users: item.count,
        text: `${item.count.toLocaleString()} ไฟล์`,
        tooltipMappingName: item.count
      }))
      .sort((a, b) =>
        this.LABEL_ORDER.indexOf(a.Browser as typeof this.LABEL_ORDER[number]) -
        this.LABEL_ORDER.indexOf(b.Browser as typeof this.LABEL_ORDER[number])
      );
  }

}
