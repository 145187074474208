import { GridGroupModule } from './grid-group/grid-group.module';
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit, ViewChild } from '@angular/core';

import { ContextActionsDirective, PageComponent, PageLayoutComponent, PaginationDirective, ToolbarComponent } from '@alfresco/aca-shared';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TabModule, ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {

  ContextMenuItemModel, ContextMenuService, EditService,
  EditSettingsModel,
  FilterService, GridComponent, GridModule, PageService, ResizeService, SortService, ToolbarItems, ToolbarService
} from '@syncfusion/ej2-angular-grids';

import { data } from './datasource';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule, ChipListModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { MatDialog } from '@angular/material/dialog';
import { ManagerWorkflowDialogComponent } from './manager-workflow-dialog/manager-workflow-dialog.component';
import { ApprovalWorkflowService } from '../../service/approval-workflow.service';
import { FormsModule } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@alfresco/adf-content-services';


import {
  SnackbarErrorAction,
  SnackbarInfoAction,
} from '@alfresco/aca-shared/store';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule,
    ContextActionsDirective,
    PaginationDirective,
    PageLayoutComponent,
    ToolbarComponent,
    TabModule,
    MatIconModule,
    MatButtonModule,
    GridModule,
    TextBoxModule,
    DropDownListModule,
    ButtonModule,
    DialogModule,
    GridGroupModule,
    ToolbarModule,
    ChipListModule
  ],
  providers: [PageService, SortService, FilterService, ToolbarService, EditService, ContextMenuService, ResizeService],

  selector: 'app-workflow-config',
  templateUrl: './workflow-config.component.html',
  styleUrls: ['./workflow-config.component.scss']
})
export class WorkflowConfigComponent extends PageComponent implements OnInit {




  public headerText: Object = [{ text: 'HTML' }, { text: 'C Sharp(C#)' }, { text: 'Java' }, { text: 'VB.Net' },
  { text: 'Xamarin' }, { text: 'ASP.NET' }, { text: 'ASP.NET MVC' }, { text: 'JavaScript' }];


  public data?: object[];
  public pageSettings: Object;
  public filterSettings: Object;
  public orderidrules: Object;
  public customeridrules: Object;
  public freightrules: Object;
  public toolbar?: ToolbarItems[];


  public editSettings?: EditSettingsModel;

  @ViewChild('grid')
  public grid: GridComponent;


  // CUSTOM
  dataConfigWorkflow: any[] = [];


  // DROPDOWN
  public listSubGroup: string[] = ['Snooker', 'Tennis', 'Cricket', 'Football', 'Rugby'];


  // CONTEXT MENU
  public contextMenuItems?: ContextMenuItemModel[];

  txtEdit = 'APPROVAL-WORKFLOW.CONFIG.CONTEXT_EDIT';
  txtDelete = 'APPROVAL-WORKFLOW.CONFIG.CONTEXT_DELETE';

  constructor(private dialogRef: MatDialog, private workflowService: ApprovalWorkflowService, public translateService: TranslateService) {
    super();

    this.txtEdit = this.translateValue(this.txtEdit);
    this.txtDelete = this.translateValue(this.txtDelete);

  }


  translateValue(key: string): string {
    let translatedValue = '';
    this.translateService.get(key)
      .pipe(take(1)) // Take only first emission and auto-complete
      .subscribe(value => {
        translatedValue = value;
      });
    return translatedValue;
  }




  async ngOnInit() {
    console.log('# WorkflowConfigComponent');




    this.data = data;
    this.orderidrules = { required: true, number: true };
    this.customeridrules = { required: true };
    this.freightrules = { required: true };
    this.pageSettings = { pageCount: 5 };
    this.filterSettings = { type: 'Excel' };


    this.contextMenuItems = [
      { text: this.txtEdit, target: '.e-content', id: 'update', iconCss: 'e-icons e-edit' },
      { text: this.txtDelete, target: '.e-content', id: 'delete', iconCss: 'e-icons e-trash' }
    ];

    this.toolbar = ['Add', 'Edit', 'Delete'];

    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };


    await this.onFilter();
    this.grid.refresh();
  }



  // ================================== MANAGER WORKFLOW ==========================================


  onCreate() {
    console.log('# onCreate');
    const dialogRef = this.dialogRef.open(ManagerWorkflowDialogComponent, {
      data: null,
      width: '850px',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        console.log('result ', result);
        await this.onFilter();
        this.grid.refresh();
      }
    });

  }

  onUpdate(listItem: any) {
    console.log('# onUpdate');
    const dialogRef = this.dialogRef.open(ManagerWorkflowDialogComponent, {
      data: listItem,
      width: '850px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        console.log('result ', result);
        await this.onFilter();
        this.grid.refresh();
      }
    });
  }


  onDelete(configWorkflowId: any) {
    const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to delete this config-workflow?'
      },
      minWidth: '250px'
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.workflowService.deleteConfigWorkflow(configWorkflowId)
            .pipe(
              map(response => {
                console.log('Status code:', response);
                return response.body;
              })
            )
            .subscribe({
              next: async (response) => {
                console.log('Success:', response);
                this.alertInfo('Cancel draft success.');
                await this.onFilter();
                this.grid.refresh();
              },
              error: (error) => {
                console.log('Error status:', error.status);
                this.alertError(error, null);
              }
            });
        } catch (error) {
          this.alertError(error.message, null);
        }
      }
    });

  }



  async onFilter() {
    try {
      this.dataConfigWorkflow = [];
      const response = await this.workflowService.getConfigWorkflow().toPromise();
      if (response) {
        const promises = response.data.map(async item => {
          const response2 = await this.workflowService.getConfigWorkflowUser(item.config_manager_workflow_id).toPromise();
          const sortedDisplayNames = response2.data.map(user => user.display_name);
          this.dataConfigWorkflow.push({
            configWorkflowId: item.config_manager_workflow_id,
            groupId: item.group_id,
            groupName: item.group_name,
            subGroupId: item.sub_group_id,
            subGroupName: item.sub_group_name,
            processName: item.process_name,
            user: sortedDisplayNames.join(', ')
          });
        });

        await Promise.all(promises);

        // Sort dataConfigWorkflow by configWorkflowId in descending order
        this.dataConfigWorkflow.sort((a, b) => b.configWorkflowId - a.configWorkflowId);
      }
    } catch (error) {
      console.log('error ', error);
    }
  }

  contextMenuClick(args: any): void {
    if (args.item.id === 'update') {
      const configWorkflowId = (args.rowInfo.rowData.configWorkflowId);
      const groupId = (args.rowInfo.rowData.groupId);
      const groupName = (args.rowInfo.rowData.groupName);
      const subGroupId = (args.rowInfo.rowData.subGroupId);
      const subGroupName = (args.rowInfo.rowData.subGroupName);
      const processName = (args.rowInfo.rowData.processName);


      // eslint-disable-next-line max-len
      const listItem = {
        'configWorkflowId': configWorkflowId,
        'groupId': groupId,
        'groupName': groupName,
        'subGroupId': subGroupId,
        'subGroup': subGroupName,
        'processName': processName,
      };
      this.onUpdate(listItem);
    }

    if (args.item.id === 'delete') {
      const configWorkflowId = (args.rowInfo.rowData.configWorkflowId);
      console.log('configWorkflowId ', configWorkflowId);

      if (configWorkflowId) {
        // Delete Config Wokflow
        this.onDelete(configWorkflowId);
      }

    }
  }

  async actionComplete(args: any) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      // const dialog = (args as any).dialog;
      // dialog.showCloseIcon = true;
      // dialog.height = 210;
      // dialog.width = 400;
      // dialog.header = 'Edit Form';
    }

  }


  // ===================  Alert  ===================
  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }



}
