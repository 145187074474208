import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { WebViewerResponseClass, WebViewerClass, WebViewerUpdateClass } from '../models/webviewer.model';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  apiURL: any;

  headers: any;

  constructor(
    private httpClient: HttpClient,
    private apiService: AlfrescoApiService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.apiURL = '';
    if (this.getBaseUrl().startsWith('http://localhost')) {
      this.apiURL = 'http://localhost:3802';
    }

    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  getBaseUrl(): string {
    return this.document.location.origin;
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  // ======================================== SELECT ========================================

  // get all
  getWebViewer(): Observable<WebViewerResponseClass[]> {
    return this.httpClient.get<WebViewerResponseClass[]>(`${this.apiURL}/webviewer-backend`);
  }

  // get data by nodeID
  getAnnotation(nodeID: string): Observable<WebViewerResponseClass> {
    return this.httpClient.get<WebViewerResponseClass>(`${this.apiURL}/webviewer-backend/${nodeID}`);
  }

  // get data for report
  getWebViewerReportData(reportData: WebViewerResponseClass): Observable<WebViewerResponseClass[]> {
    return this.httpClient.post<WebViewerResponseClass[]>(`${this.apiURL}/webviewer-backend/reportdata`, reportData);
  }

  // ======================================== INSERT ========================================
  addAnnotation(webViewerClass: WebViewerClass): Observable<WebViewerResponseClass> {
    console.log('webViewerClass', webViewerClass);
    return this.httpClient.post<WebViewerResponseClass>(`${this.apiURL}/webviewer-backend/insert`, webViewerClass);
  }

  makeFormData(aa: WebViewerClass): FormData {
    const formData = new FormData();
    formData.append('nodeid', aa.nodeid);
    formData.append('nodename', aa.nodename);
    formData.append('user_save', aa.user_save);
    formData.append('user_update', aa.user_update);
    formData.append('annotation_text', aa.annotation_text);
    formData.append('value1', aa.value1);
    formData.append('value2', aa.value2);
    formData.append('value3', aa.value3);
    formData.append('value4', aa.value4);
    formData.append('value5', aa.value5);
    formData.append('remark', aa.remark);
    return formData;
  }

  makeFormDataUpdate(aa: WebViewerUpdateClass): FormData {
    const formData = new FormData();
    formData.append('nodeid', aa.nodeid);
    formData.append('nodename', aa.nodename);
    formData.append('user_update', aa.user_update);
    formData.append('annotation_text', aa.annotation_text);
    formData.append('value1', aa.value1);
    formData.append('value2', aa.value2);
    formData.append('value3', aa.value3);
    formData.append('value4', aa.value4);
    formData.append('value5', aa.value5);
    formData.append('remark', aa.remark);
    return formData;
  }

  // ======================================== UPDATE ========================================
  updateAnnotation(nodeID: string, webViewerUpdateClass: WebViewerUpdateClass): Observable<WebViewerResponseClass> {
    return this.httpClient.put<WebViewerResponseClass>(
      `${this.apiURL}/webviewer-backend/update/${nodeID}`,
      this.makeFormDataUpdate(webViewerUpdateClass)
    );
  }

  // ======================================== DELETE ========================================
  deleteAnnotation(nodeID: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.apiURL}/webviewer-backend/delete/${nodeID}`);
  }

  // ======================================== API Alfresco ========================================

  getNodes(nodeID: string): Observable<any> {
    const pathService = `/nodes/${nodeID}?include=permissions`;
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  async getNodeInfo(nodeId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.getNodes(nodeId).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          console.log('error getNodeInfo: ', error);
          reject(false);
        }
      });
    });
  }

  async getMimeType(nodeId: string, mimType: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.getNodes(nodeId).subscribe({
        next: (data) => {
          const mimeType = data.entry.content.mimeType;
          resolve(mimeType === mimType);
        },
        error: (error) => {
          console.log('error getMimeType: ', error);
          reject(false);
        }
      });
    });
  }

  async getNonPdfActive(nodeId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.getNodes(nodeId).subscribe({
        next: (data) => {
          const fileName = data.entry.name;

          const fileExtension = fileName.split('.').pop();

          const isNotPdf = fileExtension !== 'pdf';

          resolve(isNotPdf);
        },
        error: (error) => {
          console.log('error getMimeType: ', error);
          reject(false);
        }
      });
    });
  }

  getRenditionsInfo(nodeId: string, renId: string): Observable<any> {
    const pathService = `/nodes/${nodeId}/renditions/${renId}`;
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  async getRetryCheckRenditions(nodeId: string, renId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const attemptGetRenditionsInfo = () => {
        this.getRenditionsInfo(nodeId, renId).subscribe({
          next: (data) => {
            const status = data.entry.status;
            console.log('getRetryCheckRenditions status: ', status);
            if (status === 'CREATED') {
              resolve(true);
            } else {
              resolve(false); // Resolve false after max retries
            }
          },
          error: (error) => {
            console.log('error getMimeType: ', error);
            reject(false);
          }
        });
      };
      attemptGetRenditionsInfo(); // Initial attempt
    });
  }

  async getCheckRenditions(nodeId: string, renId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const attemptGetRenditionsInfo = () => {
        this.getRenditionsInfo(nodeId, renId).subscribe({
          next: (data) => {
            const status = data.entry.status;
            console.log('getCheckRenditions status: ', status);
            if (status === 'CREATED') {
              resolve(true);
            } else {
              resolve(false); // Resolve false after max retries
            }
          },
          error: (error) => {
            console.log('error getMimeType: ', error);
            reject(false);
          }
        });
      };
      attemptGetRenditionsInfo(); // Initial attempt
    });
  }

  createRendition(nodeId: string, renId: string): Observable<any> {
    const pathService = `/nodes/${nodeId}/renditions`;
    const httpMethod = 'POST';
    const body = { id: renId };
    return from(this.publicApiCall(pathService, httpMethod, [null, null, null, null, body, ['application/json'], ['application/json']]));
  }

  async createRenditionData(nodeId: string, renId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const attemptCreateRendition = () => {
        this.createRendition(nodeId, renId).subscribe({
          next: (data) => {
            if (data.status === 202) {
              console.log('createRenditionData success:', data);
              resolve(true);
            } else {
              console.log('createRenditionData acepted');
              resolve(true);
            }
          },
          error: (error) => {
            if ([400, 401, 403, 404, 501].includes(error.status)) {
              console.error('Error occurred with status', error.status, ':', error.error);
              reject(error);
            } else if (error.status === 409) {
              console.error('All renditions requested already exist:', error.error);
              resolve(true);
            } else {
              console.error('Error occurred:', error);
              setTimeout(() => {
                attemptCreateRendition(); // Retry after 3 seconds
              }, 3000);
            }
          }
        });
      };
      attemptCreateRendition(); // Initial attempt
    });
  }

  getRenditionContent(nodeId: string, renId: string): Promise<any> {
    const pathService = `/nodes/${nodeId}/renditions/${renId}/content?attachment=true&placeholder=false`;
    const httpMethod = 'GET';
    return this.publicApiCall(pathService, httpMethod, [null, null, null, null, null, ['application/json'], ['blob'], null, null, 'blob']).then(
      (response: any) => {
        if (response instanceof Blob) {
          return this.blobToBase64(response);
        } else {
          return Promise.reject(new Error('Response is not of type Blob'));
        }
      }
    );
  }

  getPeople(): Observable<any> {
    const pathService = `/people/-me-`;
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  getGroups(personId: string): Observable<any> {
    const pathService = `/people/${personId}/groups?skipCount=0&maxItems=100`;
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  updateNodeContent(nodeId: string, contentData: any): Observable<any> {
    const pathService = `/nodes/${nodeId}/content?majorVersion=true`;
    const httpMethod = 'PUT';
    return from(this.publicApiCall(pathService, httpMethod, [null, null, null, null, contentData, ['application/json'], ['application/json']]));
  }

  downloadNode(nodeId: string): Promise<any> {
    const pathService = `/nodes/${nodeId}/content?attachment=true`;
    const httpMethod = 'GET';
    return this.publicApiCall(pathService, httpMethod, [null, null, null, null, null, ['application/json'], ['blob'], null, null, 'blob']).then(
      (response: any) => {
        if (response instanceof Blob) {
          return this.blobToBase64Edit(response);
        } else {
          return Promise.reject(new Error('Response is not of type Blob'));
        }
      }
    );
  }

  downloadNodeVersion(nodeId: string, versionId: string): Promise<any> {
    const pathService = `/nodes/${nodeId}/versions/${versionId}/content?attachment=true`;
    const httpMethod = 'GET';
    return this.publicApiCall(pathService, httpMethod, [null, null, null, null, null, ['application/json'], ['blob'], null, null, 'blob']).then(
      (response: any) => {
        if (response instanceof Blob) {
          return this.blobToBase64Edit(response);
        } else {
          return Promise.reject(new Error('Response is not of type Blob'));
        }
      }
    );
  }

  // downloadNode(nodeId: string): Promise<any> {
  //   return this.httpClient.get<any>(
  //     `/alfresco/api/-default-/public/alfresco/versions/1/nodes/${nodeId}/content?attachment=true&alf_ticket=${this.apiService.getInstance().contentAuth.ticket
  //     }`,
  //     { headers: this.headers, responseType: 'blob' as 'json' }
  //   ).toPromise().then((blob: Blob) => this.blobToBase64(blob));
  // }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        const base64Content = base64String.split(';base64,')[1];
        resolve(base64Content);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }

  private async blobToBase64Edit(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        resolve(base64String);
      };

      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Failed to read the blob.'));
      };

      reader.readAsDataURL(blob);
    });
  }

  public downloadContent(nodeId: string): Promise<any> {
    const pathService = `/nodes/${nodeId}/content?attachment=true`;
    const httpMethod = 'GET';
    return this.publicApiCall(pathService, httpMethod, [null, null, null, null, null, ['application/json'], ['blob'], null, null, 'blob']).then(
      (response: any) => {
        if (response instanceof Blob) {
          return response; // Return the Blob directly
        } else {
          return Promise.reject(new Error('Response is not of type Blob'));
        }
      }
    );
  }

  async blobToBase64ForDownload(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Failed to read the blob.'));
      };
      reader.readAsDataURL(blob);
    });
  }

  getGroupMembers(personId: string): Observable<any> {
    const pathService = `/people/${personId}/groups?skipCount=0&maxItems=1000`;
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  // public downloadContentWatermark(nodeId: string): Promise<any> {
  //   const pathService = `/api/Pdf/Watermark?PlacementFile=full&FirstLine=aaa&SecondLine=bbb`;
  //   const httpMethod = 'POST';

  //   const formData: FormData = new FormData();
  //   formData.append('File', file);

  //   return this.publicApiCall(pathService, httpMethod, [null, null, null, null, null, ['application/json'], ['blob'], null, null, 'blob'])
  //     .then((response: any) => {
  //       if (response instanceof Blob) {
  //         return response; // Return the Blob directly
  //       } else {
  //         return Promise.reject(new Error('Response is not of type Blob'));
  //       }
  //     });
  // }

  // async blobToBase64(blob) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result);
  //     reader.onerror = reject;
  //     reader.readAsDataURL(blob);
  //   });
  // }

  // async downloadNodeInChunks(nodeId, chunkSize = 1024 * 1024) {
  //   let offset = 0;
  //   let base64Data = '';
  //   let moreData = true;

  //   while (moreData) {
  //     const pathService = `/nodes/${nodeId}/content?attachment=true&offset=${offset}&limit=${chunkSize}`;
  //     const response = await this.publicApiCall(pathService, 'GET', [null, null, null, null, null, ['application/json'], ['blob'], null, null, 'blob']);

  //     if (response instanceof Blob && response.size > 0) {
  //       const chunkBase64 = await this.blobToBase64(response);
  //       base64Data += chunkBase64;
  //       offset += chunkSize;
  //       moreData = response.size === chunkSize;
  //     } else {
  //       moreData = false;
  //     }
  //   }

  //   return base64Data;
  // }
}
