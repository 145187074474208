import { FormGroup, FormControl, Validators } from '@angular/forms';

export function createFormImproveContract(): FormGroup {
  return new FormGroup({
    'contractOriginalActive': new FormControl({ value: false, disabled: false }),
    'contractRefNo': new FormControl({ value: '', disabled: false }),
    'docType': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'depositNo': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'docTypeManual': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'contractName': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'contractType': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'contractNoAuto': new FormControl({ value: '', disabled: true }),
    'contractNoManual': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'contractNo': new FormControl({ value: '', disabled: true }),
    'contractPairName': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'department': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'departmentName': new FormControl({ value: '', disabled: true }),
    'depositorName': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'contractSenderEmail': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'contractManagerName': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'contractManagerEmail': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'contractSigningDate': new FormControl({ value: '', disabled: false }),
    'contractEffectiveDate': new FormControl({ value: '', disabled: false }),
    'notificationActive': new FormControl({ value: '1', disabled: false }),
    'contractEndDate': new FormControl({ value: '', disabled: false }),
    'contractDuration': new FormControl({ value: 0, disabled: false }),
    'contractValue': new FormControl({ value: 0, disabled: false }),
    'contractCollateral': new FormControl({ value: '', disabled: false }),
    'signatureType': new FormControl({ value: '', disabled: false }),
    'status': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'storageDuration': new FormControl({ value: 0, disabled: false }),
    'complianceDepositDate': new FormControl({ value: '', disabled: false }),
    'storageLocation': new FormControl({ value: '', disabled: false }),
    'responsiblePerson': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'fileNameOriginal': new FormControl({ value: '', disabled: false }),
    'contractStatusProcess': new FormControl({ value: 'Draft', disabled: false }),
  });
}


export function createFormImproveContractEnabled(): FormGroup {
  return new FormGroup({
    'contractOriginalActive': new FormControl({ value: false, disabled: false }),
    'contractRefNo': new FormControl({ value: '', disabled: false }),
    'docType': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'depositNo': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'docTypeManual': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'contractName': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'contractType': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'contractNoAuto': new FormControl({ value: '', disabled: true }),
    'contractNoManual': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'contractNo': new FormControl({ value: '', disabled: true }),
    'contractPairName': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'department': new FormControl({ value: '', disabled: true }, [Validators.required]),
    'departmentName': new FormControl({ value: '', disabled: false }),
    'depositorName': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'contractSenderEmail': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'contractManagerName': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'contractManagerEmail': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'contractSigningDate': new FormControl({ value: '', disabled: false }),
    'contractEffectiveDate': new FormControl({ value: '', disabled: false }),
    'notificationActive': new FormControl({ value: '1', disabled: false }),
    'contractEndDate': new FormControl({ value: '', disabled: false }),
    'contractDuration': new FormControl({ value: 0, disabled: false }),
    'contractValue': new FormControl({ value: 0, disabled: false }),
    'contractCollateral': new FormControl({ value: '', disabled: false }),
    'signatureType': new FormControl({ value: '', disabled: false }),
    'status': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'storageDuration': new FormControl({ value: 0, disabled: false }),
    'complianceDepositDate': new FormControl({ value: '', disabled: false }),
    'storageLocation': new FormControl({ value: '', disabled: false }),
    'responsiblePerson': new FormControl({ value: '', disabled: false }, [Validators.required]),
    'fileNameOriginal': new FormControl({ value: '', disabled: false }),
    'contractStatusProcess': new FormControl({ value: 'Draft', disabled: false }),
  });
}
