import { CustomToolbarItemModel } from '@syncfusion/ej2-pdfviewer';

export const Toolbar_NoAnnotation = {
  showTooltip: true,
  showText: true,
  toolbarItems: [
    // 'OpenOption',
    'UndoRedoTool',
    'PageNavigationTool',
    'MagnificationTool',
    'PanTool',
    'SelectionTool',
    'SubmitForm',
    'FreeTextAnnotationOption',
    'InkAnnotationOption',
    'ShapeAnnotationOption',
    'StampAnnotation',
    'SignatureOption',
    'SearchOption',
    {
      prefixIcon: 'e-icons e-transform-left',
      id: 'rotateAnticlockwise',
      tooltipText: 'Custom toolbar item',
    } as CustomToolbarItemModel,
    {
      prefixIcon: 'e-icons e-transform-right',
      id: 'rotateClockwise',
      tooltipText: 'Custom toolbar item',
    } as CustomToolbarItemModel,
    {
      text: 'Rotate All',
      id: 'rotateall',
      tooltipText: 'Custom toolbar item',
    } as CustomToolbarItemModel,
  ],
  annotationToolbarItems: [
    'HighlightTool',
    'UnderlineTool',
    'StrikethroughTool',
    'ColorEditTool',
    'OpacityEditTool',
    'AnnotationDeleteTool',
    'StampAnnotationTool',
    'HandWrittenSignatureTool',
    'InkAnnotationTool',
    'ShapeTool',
    'CalibrateTool',
    'StrokeColorEditTool',
    'ThicknessEditTool',
    'FreeTextAnnotationTool',
    'FontFamilyAnnotationTool',
    'FontSizeAnnotationTool',
    'FontStylesAnnotationTool',
    'FontAlignAnnotationTool',
    'FontColorAnnotationTool',
    'CommentPanelTool'
  ]
};

export const Toolbar_Annotation = {
  showTooltip: true,
  showText: true,
  toolbarItems: [
    'CommentTool',
    'AnnotationEditTool',
    'UndoRedoTool',
    'PageNavigationTool',
    'MagnificationTool',
    'PanTool',
    'SelectionTool',
    'SubmitForm',
    'FreeTextAnnotationOption',
    'InkAnnotationOption',
    'ShapeAnnotationOption',
    'StampAnnotation',
    'SignatureOption',
    'SearchOption',
    // 'DownloadOption',
    {
      prefixIcon: 'e-icons e-transform-left',
      id: 'rotateAnticlockwise',
      tooltipText: 'Custom toolbar item',
    } as CustomToolbarItemModel,
    {
      prefixIcon: 'e-icons e-transform-right',
      id: 'rotateClockwise',
      tooltipText: 'Custom toolbar item',
    } as CustomToolbarItemModel,
    {
      text: 'Rotate All',
      id: 'rotateall',
      tooltipText: 'Custom toolbar item',
    } as CustomToolbarItemModel,
  ],
  annotationToolbarItems: [
    'HighlightTool',
    'UnderlineTool',
    'StrikethroughTool',
    'ColorEditTool',
    'OpacityEditTool',
    'AnnotationDeleteTool',
    'StampAnnotationTool',
    'HandWrittenSignatureTool',
    'InkAnnotationTool',
    'ShapeTool',
    'CalibrateTool',
    'StrokeColorEditTool',
    'ThicknessEditTool',
    'FreeTextAnnotationTool',
    'FontFamilyAnnotationTool',
    'FontSizeAnnotationTool',
    'FontStylesAnnotationTool',
    'FontAlignAnnotationTool',
    'FontColorAnnotationTool',
    'CommentPanelTool'
  ]
};


export const ToolbarSettings = {
  showTooltip: true,
  showText: true,
  toolbarItems: [
    'UndoRedoTool',
    'PageNavigationTool',
    'MagnificationTool',
    'PanTool',
    'SelectionTool',
    'SubmitForm',
    'FreeTextAnnotationOption',
    'InkAnnotationOption',
    'ShapeAnnotationOption',
    'StampAnnotation',
    'SignatureOption',
    'SearchOption',
    {
      prefixIcon: 'e-icons e-transform-left',
      id: 'rotateAnticlockwise',
      tooltipText: 'Custom toolbar item',
    } as CustomToolbarItemModel,
    {
      prefixIcon: 'e-icons e-transform-right',
      id: 'rotateClockwise',
      tooltipText: 'Custom toolbar item',
    } as CustomToolbarItemModel,
    {
      text: 'Rotate All',
      id: 'rotateall',
      tooltipText: 'Custom toolbar item',
    } as CustomToolbarItemModel,
  ],
  annotationToolbarItems: [
    'HighlightTool',
    'UnderlineTool',
    'StrikethroughTool',
    'ColorEditTool',
    'OpacityEditTool',
    'AnnotationDeleteTool',
    'StampAnnotationTool',
    'HandWrittenSignatureTool',
    'InkAnnotationTool',
    'ShapeTool',
    'CalibrateTool',
    'StrokeColorEditTool',
    'ThicknessEditTool',
    'FreeTextAnnotationTool',
    'FontFamilyAnnotationTool',
    'FontSizeAnnotationTool',
    'FontStylesAnnotationTool',
    'FontAlignAnnotationTool',
    'FontColorAnnotationTool',
    'CommentPanelTool'
  ]
};
