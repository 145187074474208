/*!
 * Copyright 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import {
  AppStore,
  ReloadDocumentListAction,
  SetCurrentNodeVersionAction,
  //  SetCurrentNodeVersionAction,
  // SetSelectedNodesAction,
  getAppSelection
} from '@alfresco/aca-shared/store';
import { ProfileState, SelectionState } from '@alfresco/adf-extensions';
import { Node, VersionsApi } from '@alfresco/js-api';
import { Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AlfrescoApiService, AppConfigService } from '@alfresco/adf-core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { getUserProfile } from '@alfresco/aca-shared/store';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { PeopleContentService } from '@alfresco/adf-content-services';
import { ConfigExtensionService } from '../../../../../src/lib/components/service/config-extension.service';
// import { CommunicationService } from 'projects/aca-content/src/lib/services/communication.service';
// import { ContentApiService } from '@alfresco/aca-shared';

@Component({
  selector: 'aca-viewer-drawer',
  templateUrl: './viewer-drawer.component.html',
  styleUrls: ['./viewer-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-viewer-drawer' }
})
export class AcaViewerDrawerComponent implements OnInit, OnDestroy, OnChanges {
  private _versionsApi: VersionsApi;
  get versionsApi(): VersionsApi {
    this._versionsApi = this._versionsApi ?? new VersionsApi(this.apiService.getInstance());
    return this._versionsApi;
  }

  onDestroy$ = new Subject<boolean>();

  @Input()
  nodeId: string;
  @Input()
  node?: Node;
  @Input()
  nodeRef: any;

  // nodeId: string = null;
  // node: Node;
  // @Input()
  // nodeRef: any;

  fileName: string;
  folderId: string = null;
  versionId: string = null;
  selection: SelectionState;
  infoDrawerOpened$: Observable<boolean>;
  allowFullScreen = false;

  lineOneStyle = 'color: black; opacity: 0.1; font-size: 30px';
  lineTwoStyle = 'color: black; opacity: 0.1; font-size: 30px';
  defaultStyle = 'white-space: nowrap;text-overflow: ellipsis;overflow: hidden;';

  profile$: Observable<ProfileState>;

  // Watermark
  lineOne = 'DO NOT COPY';
  lineTwo = '';
  maxFontSizeLineOne = 25;
  maxFontSizeLineTwo = 20;
  colorLineOne = '#818589';
  colorLineTwo = '#A9A9A9';
  opacityLineOne = 0.4;
  opacityLineTwo = 0.4;
  displayLayout = '';
  maxColumns = 3;
  rightTabOpen = false;

  constructor(
    private store: Store<AppStore>,
    private apiService: AlfrescoApiService,
    private peopleContentService: PeopleContentService,
    // private contentApi: ContentApiService,
    private appConfigService: AppConfigService,
    private configExtensionService: ConfigExtensionService
  ) {
    this.profile$ = this.store.select(getUserProfile);
  }

  featureOption = 'review';
  getValueSetting(lineOneStyle: any, optionStyle: any) {
    const styles = lineOneStyle.split(';');
    let resultStyle;
    for (const style of styles) {
      if (style.trim().startsWith(optionStyle)) {
        resultStyle = style.trim();
        break;
      }
    }
    if (optionStyle === 'color') {
      const color = resultStyle.replace(optionStyle, 'color') + ';';
      return color;
    } else if (optionStyle === 'opacity') {
      const opacity = resultStyle.replace(optionStyle, 'opacity') + ';';
      return opacity;
    } else if (optionStyle === this.featureOption + '-font-size') {
      const fontSize = resultStyle.replace(optionStyle, 'font-size');
      return fontSize;
    }

    return resultStyle;
  }

  safeLineOneStyleCss() {
    const lineOneStyle = this.appConfigService.get<string>('watermark.lineOneStyle');
    const color = this.getValueSetting(lineOneStyle, 'color');
    const opacity = this.getValueSetting(lineOneStyle, 'opacity');
    const fontSize = this.getValueSetting(lineOneStyle, this.featureOption + '-font-size');
    const resultLineOne = color + opacity + fontSize;

    this.lineOneStyle = resultLineOne ? resultLineOne : this.lineOneStyle;
    const combinedStyle = `${this.defaultStyle}${this.lineOneStyle}`;
    return combinedStyle;
  }

  safeLineTwoStyleCss() {
    const lineOneStyle = this.appConfigService.get<string>('watermark.lineTwoStyle');
    const color = this.getValueSetting(lineOneStyle, 'color');
    const opacity = this.getValueSetting(lineOneStyle, 'opacity');
    const fontSize = this.getValueSetting(lineOneStyle, this.featureOption + '-font-size');
    const resultLineTwo = color + opacity + fontSize;
    this.lineTwoStyle = resultLineTwo ? resultLineTwo : this.lineTwoStyle;
    const combinedStyle = `${this.defaultStyle}${this.lineTwoStyle}`;
    return combinedStyle;
  }

  displayFormat: any;
  private getSettingWatermark() {
    const flag = this.appConfigService.get<boolean | string>('watermark.displayFormat');
    if (flag === 'full') {
      this.displayFormat = flag;
    } else {
      this.displayFormat = 'center';
    }
  }

  template(tpl: any, args: any) {
    return tpl.replace(/\${([A-Za-z0-9.,:-]*)}/g, (_: any, v: any) => {
      return args[v] ? args[v] : '';
    });
  }

  generateWatermark(value: any) {
    const date = moment().format('DD/MM/YYYY');
    const time = moment().format('hh:mm:ss');
    // const lineOne = this.appConfigService.get<string>('watermark.lineOne') || '';
    // const lineTwo = this.appConfigService.get<string>('watermark.lineTwo') || '';
    this.lineOne = this.template(this.lineOne, { ...value, date, time });
    this.lineTwo = this.template(this.lineTwo, { ...value, date, time });
  }

  ngOnChanges(): void {
    if (this.nodeRef) {
      this.nodeId = this.nodeRef.entry.id;
    }
  }

  onViewerVisibilityChanged() {
    this.store.dispatch(new ReloadDocumentListAction());
  }

  configWaterEnable = false;
  async ngOnInit() {
    console.log('# AcaViewerDrawerComponent');

    try {
      const data = await this.configExtensionService.getWatermark();
      console.log('data ', data);
      if (data?.review) {
        this.maxFontSizeLineOne = data.review.maxFontSizeLineOne;
        this.maxFontSizeLineTwo = data.review.maxFontSizeLineTwo;
        this.colorLineOne = data.review.colorLineOne;
        this.colorLineTwo = data.review.colorLineTwo;
        this.opacityLineOne = data.review.opacityLineOne;
        this.opacityLineTwo = data.review.opacityLineTwo;
        this.displayLayout = data.review.displayLayout;
        this.maxColumns = data.review.maxColumns;
        this.rightTabOpen = false;
        this.configWaterEnable = true;
        this.lineOne = data.lineOne;
        this.lineTwo = data.lineTwo;
      }
    } catch (error) {
      console.error('Error getting watermark config:', error);
    }

    this.getSettingWatermark();
    this.store
      .select(getAppSelection)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((selection) => {
        this.selection = selection;
      });

    this.peopleContentService.getCurrentUserInfo().subscribe((userInfo) => {
      console.log('this.nodeRef ', this.nodeRef.entry);
      console.log('this.nodeRef.properties ', this.nodeRef.entry.properties);
      console.log('this.nodeRef.name ', this.nodeRef.entry.name);
      this.generateWatermark({ ...userInfo, ...this.nodeRef.entry.properties, 'cm:name': this.nodeRef.entry.name });
    });

    // this.profile$.pipe(take(1)).subscribe(value => {
    //   this.generateWatermark(value);
    // });
  }

  ngOnDestroy() {
    this.store.dispatch(new SetCurrentNodeVersionAction(null));
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
