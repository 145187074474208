<aca-page-layout>
  <div class="aca-page-layout-header">
    <aca-search-input></aca-search-input>
    <div class="aca-search-toolbar-spacer"></div>
    <aca-toolbar [items]="actions"></aca-toolbar>
  </div>

  <div class="aca-page-layout-content">
    <div class="main-content">
      <div class="adf-search-results">
        <!-- AD Serarch (Config) -->
        <div
          style="height: calc(100vh - 120px); overflow: auto; -webkit-transition: width 0.3s; transition: width 0.3s"
          [style.width]="filterWidth"
          [style.padding]="filterPadding"
        >
          <mat-card>
            <mat-card-content>
              <div class="selector-container">
                <mat-form-field>
                  <mat-label>Select an option</mat-label>
                  <mat-select [value]="selectedAdSearch">
                    <mat-option (onSelectionChange)="adSelectorChange($event)" *ngFor="let search of adsearch" [value]="search">
                      {{ search.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>

          <div style="padding-bottom: 10px"></div>

          <adf-search-filter2 *ngIf="isSelector" #searchFilter></adf-search-filter2>
          
        </div>

        <div class="adf-search-results__content">
          <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate" aria-live="polite"> </mat-progress-bar>

          <!-- DocumentList -->
          <adf-document-list
            #documentList
            acaDocumentList
            acaContextActions
            [selectionMode]="'multiple'"
            [multiselect]="false"
            [currentFolderId]="node?.id"
            [showHeader]="showHeader"
            [node]="$any(data)"
            [allowDropFiles]="false"
            [navigate]="false"
            [sorting]="sorting"
            [imageResolver]="imageResolver"
            [headerFilters]="true"
            (name-click)="onNameClicked($event)"
            (node-click)="onNodeClicked($event)"
            (node-unselect)="onNodeUnselect($event)"
            (contextmenu)="onRightClick()"
          >
            <data-columns>
              <data-column key="$thumbnail" type="image" [sr-title]="'ADF-DOCUMENT-LIST.LAYOUT.THUMBNAIL'" [sortable]="false">
                <ng-template let-context>
                  <aca-custom-thumbnail-column [context]="context"></aca-custom-thumbnail-column>
                </ng-template>

                <adf-data-column-header>
                  <ng-template>
                    <aca-search-action-menu (sortingSelected)="onSearchSortingUpdate($event)"></aca-search-action-menu>
                  </ng-template>
                </adf-data-column-header>
              </data-column>

              <!-- <data-column key="name" class="adf-ellipsis-cell adf-expand-cell-5" title="APP.DOCUMENT_LIST.COLUMNS.NAME">
                <ng-template let-context>
                  <aca-search-results-row key="name" [context]="context"></aca-search-results-row>
                </ng-template>
              </data-column> -->

              <!--
              <data-column class="adf-ellipsis-cell adf-expand-cell-5" title="APP.DOCUMENT_LIST.COLUMNS.NAME">
                <ng-template let-context>
                  <aca-search-results-row [context]="context"> </aca-search-results-row>
                </ng-template>
              </data-column> -->

              <data-column key type="text" class="adf-ellipsis-cell adf-expand-cell-5" title="APP.DOCUMENT_LIST.COLUMNS.NAME" [sortable]="false">
                <ng-template let-context>
                  <aca-search-results-row (dataEvent)="receiveDataFromChild($event)" [context]="context"></aca-search-results-row>
                </ng-template>
              </data-column>

              <data-column key="properties" title="Description" class="adf-expand-cell-3" [sortable]="false" *ngIf="!isSmallScreen">
                <ng-template let-context>
                  <span class="adf-datatable-cell-value adf-ellipsis-cell">
                    {{ context.row?.node?.entry?.properties && context.row?.node?.entry?.properties['cm:description'] }}
                  </span>
                </ng-template>
              </data-column>

              <data-column
                key="content.sizeInBytes"
                type="fileSize"
                title="APP.DOCUMENT_LIST.COLUMNS.SIZE"
                class="adf-no-grow-cell adf-ellipsis-cell"
                [sortable]="false"
                *ngIf="!isSmallScreen"
              ></data-column>
              <data-column
                key="modifiedAt"
                type="date"
                title="APP.DOCUMENT_LIST.COLUMNS.MODIFIED_ON"
                class="adf-no-grow-cell adf-ellipsis-cell"
                format="timeAgo"
                [sortable]="false"
                *ngIf="!isSmallScreen"
              ></data-column>
              <data-column
                key="modifiedByUser.displayName"
                title="APP.DOCUMENT_LIST.COLUMNS.MODIFIED_BY"
                class="adf-no-grow-cell adf-ellipsis-cell"
                [sortable]="false"
                *ngIf="!isSmallScreen"
              ></data-column>
              <data-column
                key="$tags"
                type="text"
                title="APP.DOCUMENT_LIST.COLUMNS.TAGS"
                class="adf-no-grow-cell adf-ellipsis-cell"
                [sortable]="false"
              >
                <ng-template let-context>
                  <aca-tags-column [context]="context"></aca-tags-column>
                </ng-template>
              </data-column>
            </data-columns>
            <adf-custom-empty-content-template>
              <ng-container *ngIf="data">
                <div class="empty-search__block" aria-live="polite">
                  <p class="empty-search__text">
                    {{ 'APP.BROWSE.SEARCH.NO_RESULTS' | translate }}
                  </p>
                </div>
              </ng-container>
            </adf-custom-empty-content-template>
          </adf-document-list>

          <adf-pagination *ngIf="totalResults > 0" acaPagination [target]="documentList" (change)="onPaginationChanged($event)"> </adf-pagination>
        </div>
      </div>
    </div>

    <div class="aca-sidebar" *ngIf="infoDrawerOpened$ | async">
      <aca-info-drawer [node]="selection.last"></aca-info-drawer>
    </div>

    <div class="preview-sidebar" *ngIf="isRightNotClickActive && !isOutletPreviewUrlActive && selection.file && !(infoDrawerOpened$ | async)">
      <aca-viewer-drawer [nodeRef]="selection.file"></aca-viewer-drawer>
    </div>
  </div>
</aca-page-layout>
