import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AlfrescoApiService } from '@alfresco/adf-core';


@Injectable({
  providedIn: 'root'
})
export class AuditEntriesService {
  environment: any;

  apiURL: any;

  headers: any;

  constructor( @Inject(DOCUMENT) private document: Document,
  private apiService: AlfrescoApiService) {


    this.apiURL = ''
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });


  }

  getBaseUrl(): string {
    return this.document.location.origin;
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  getAuditData(nodeId: string): Observable<any> {
    const pathService = `/nodes/${nodeId}/audit-entries?include=values`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  getNodes(nodeID: string): Observable<any> {
    const pathService = `/nodes/${nodeID}`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }


}
