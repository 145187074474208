<adf-login
  [copyrightText]="'application.copyright' | adfAppConfig | translate"
  providers="ECM"
  [successRoute]="'application.LandingPage' | adfAppConfig"
  logoImageUrl={{myCustomLogo}}
  backgroundImageUrl={{myCustomBg}}
  [showRememberMe]="false"
  [showLoginActions]="false"
>
</adf-login>
