import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppConfigService } from '@alfresco/adf-core';

// import { Observable, from } from 'rxjs';
// import {  map } from 'rxjs/operators';

interface AppConfig {
  watermark?: string[];
  // Add other config properties as needed
}

@Injectable({
  providedIn: 'root'
})
export class ConfigExtensionService {
  private config: AppConfig | null = null;
  private readonly configUrl = './app-extension.config.json';

  constructor(private readonly httpClient: HttpClient, private appConfigService: AppConfigService) {}

  async loadExtensionConfig(): Promise<AppConfig> {
    try {
      if (this.config) {
        return this.config;
      }
      this.config = await this.fetchConfig();
      return this.config;
    } catch (error) {
      console.error('Failed to load configuration:', error);
      return {};
    }
  }

  private async fetchConfig(): Promise<AppConfig> {
    return this.httpClient
      .get<AppConfig>(this.configUrl)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of({});
          }
          throw error;
        })
      )
      .toPromise();
  }

  async getWatermark(): Promise<any> {
    try {
      // ปิดไว้ก่อน
      // const config = await this.loadExtensionConfig();
      // if (config?.watermark) {
      //   return config.watermark;
      // }
      return this.appConfigService.get<any>('watermark');
    } catch (error) {
      console.error('Failed to get watermark configuration:', error);
      return this.appConfigService.get<any>('watermark');
    }
  }
}
