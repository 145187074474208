/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { SearchWidgetContainerComponent } from '@alfresco/adf-content-services';
import { SearchCategory } from '@alfresco/adf-content-services';

import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';

import { SearchModule } from '@alfresco/adf-content-services';


@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, MatMenuModule, MatIconModule,
    MatButtonModule, MatFormFieldModule, MatAutocompleteModule,
    ReactiveFormsModule, MatInputModule, MatCardModule, MatTooltipModule, MatExpansionModule, SearchModule],
  selector: 'adf-search-filter-card2',
  templateUrl: './search-filter-card.component.html',
  styleUrls: ['./search-filter-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchFilterCardComponent {
  @Input()
  category: SearchCategory;

  @ViewChild(SearchWidgetContainerComponent, { static: false })
  widgetContainerComponent: SearchWidgetContainerComponent;


  clear() {
    this.widgetContainerComponent.resetInnerWidget();
  }

  apply() {
    this.widgetContainerComponent.applyInnerWidget();
  }
}
