/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FacetFieldBucket, IsIncludedPipe, SearchInputComponent, SearchQueryBuilderService } from '@alfresco/adf-content-services';
import { FacetField } from '@alfresco/adf-content-services';
import { SearchFacetFiltersService } from '@alfresco/adf-content-services';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { SearchModule } from '@alfresco/adf-content-services';
import { SearchFilterCardComponent } from './search-filter-card/search-filter-card.component';

import { CoreModule, SearchTextModule } from '@alfresco/adf-core';
import { TabLabelsPipe } from '../../pipes';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatTooltipModule,
    MatExpansionModule,
    SearchModule,
    SearchFilterCardComponent,
    CoreModule,
    SearchTextModule,
    SearchInputComponent,
    IsIncludedPipe,
    TabLabelsPipe
  ],
  selector: 'adf-search-filter2',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchFilterComponent {
  /** Toggles whether to show or not the context facet filters. */
  @Input()
  showContextFacets = true;

  facetQueriesLabel = 'Facet Queries';
  facetExpanded = {
    default: false
  };
  displayResetButton: boolean;

  constructor(
    public queryBuilder: SearchQueryBuilderService,
    public facetFiltersService: SearchFacetFiltersService
  ) {
    
    if (queryBuilder.config?.facetQueries) {
      this.facetQueriesLabel = queryBuilder.config.facetQueries.label || 'Facet Queries';
      this.facetExpanded['query'] = queryBuilder.config.facetQueries.expanded;
    }

    if (queryBuilder.config?.facetFields) {
      this.facetExpanded['field'] = queryBuilder.config.facetFields.expanded;
    }

    if (queryBuilder.config?.facetIntervals) {
      this.facetExpanded['interval'] = queryBuilder.config.facetIntervals.expanded;
    }

    this.displayResetButton = this.queryBuilder.config && !!this.queryBuilder.config.resetButton;

    console.log('queryBuilder.categories ', queryBuilder.categories);

  }

  shouldExpand(field: FacetField): boolean {
    return this.facetExpanded[field.type] || this.facetExpanded['default'];
  }

  getBucketCountDisplay(bucket: FacetFieldBucket): string {
    return bucket.count === null ? '' : `(${bucket.count})`;
  }

}
