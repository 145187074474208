/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { Component, Input, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NodeEntry } from '@alfresco/js-api';
import { ViewNodeAction, NavigateToFolder } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { NodesApiService } from '@alfresco/adf-content-services';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AcaFileAutoDownloadService } from '@alfresco/aca-shared';
import { CommonModule } from '@angular/common';
import { LocationLinkComponent } from '../../common/location-link/location-link.component';
import { MatDialogModule } from '@angular/material/dialog';
import {
  // AppConfigService,
  PipeModule
} from '@alfresco/adf-core';

// import { PipeModule2 } from '../../../../../pipes'

import { DirectiveModule } from '@alfresco/adf-core';
import { SearchNavigationService } from '../search-navigation.service';
import { NodeViewerService } from '../../../services/node-viewer.service';

import { TruncatePipe } from './truncate.pipe';

@Component({
  standalone: true,
  imports: [CommonModule, LocationLinkComponent, MatDialogModule, PipeModule, DirectiveModule, TruncatePipe],
  selector: 'aca-search-results-row',
  templateUrl: './search-results-row.component.html',
  styleUrls: ['./search-results-row.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'aca-search-results-row' }
})
export class SearchResultsRowComponent implements OnInit, OnDestroy {
  private node: NodeEntry;
  private onDestroy$ = new Subject<boolean>();

  @Input()
  context: any;

  name$ = new BehaviorSubject<string>('');
  title$ = new BehaviorSubject<string>('');

  listName: any;

  isFile = false;

  @Input()
  searchedWord: any;


  @Output() dataEvent = new EventEmitter<any>();


  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private store: Store<any>,
    private nodesApiService: NodesApiService,
    private router: Router,
    private fileAutoDownloadService: AcaFileAutoDownloadService,
    private dataService: SearchNavigationService,
    private nodeViewerService: NodeViewerService,
    // private appConfig: AppConfigService,
  ) { }

  ngOnInit() {
    this.updateValues();



    this.nodesApiService.nodeUpdated.pipe(takeUntil(this.onDestroy$)).subscribe((node) => {
      const row = this.context.row;

      if (row) {
        const { entry } = row.node;
        if (entry.id === node.id) {
          entry.name = node.name;
          entry.properties = Object.assign({}, node.properties);
          this.updateValues();
        }
      }
    });
  }

  sendDataToParent(data: any) {
    this.dataEvent.emit(data);
  }

  private updateValues() {
    this.node = this.context.row.node;

    this.isFile = this.node.entry.isFile;

    const { name, properties } = this.node.entry;
    const title = properties ? properties['cm:title'] : '';

    this.listName = name;

    this.name$.next(name);



    if (title !== name) {
      this.title$.next(title ? `( ${title} )` : '');
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }


  isClick: any;

  showPreview(event: Event) {
    this.sendDataToParent(true);

    event.stopPropagation();
    if (this.fileAutoDownloadService.shouldFileAutoDownload(this.node.entry.content.sizeInBytes)) {
      this.fileAutoDownloadService.autoDownloadFile(this.node);
    } else {
      this.isClick = true;
      this.dataService.changeMessage(this.isClick);
      this.nodeViewerService.setNode(this.node);
      this.store.dispatch(new ViewNodeAction(this.node.entry.id, { location: this.router.url }));
    }
  }

  navigate(event: Event) {
    event.stopPropagation();
    this.store.dispatch(new NavigateToFolder(this.node));
  }

  get rudeWordList() {
    return this.searchedWord;
  }

  get name() {
    return this.getValue('name');
  }

  get title() {
    return this.getValue('properties["cm:title"]');
  }

  get description() {
    return this.getValue('properties["cm:description"]');
  }

  // get description() {
  //   const desc = this.getValue('properties["cm:description"]');
  //   if (desc) {
  //     return desc.length > 30 ? desc.substring(0, 30) + '..' : desc;
  //   }
  //   return null;
  // }

  get modifiedAt() {
    return this.getValue('modifiedAt');
  }

  get size() {
    return this.getValue('content.sizeInBytes');
  }

  get user() {
    return this.getValue('modifiedByUser.displayName');
  }

  get hasDescription() {
    return this.description;
  }

  // get hasDescription() {
  //   if (this.description) {
  //     return this.description.length > 20 ? this.description.substring(0, 20) + '..' : this.description;
  //   }
  //   return null;
  // }

  get hasTitle() {
    return this.title;
  }

  get showTitle() {
    return this.name !== this.title;
  }

  get hasSize() {
    return this.size;
  }

  get content() {
    const highlight = this.getValue('search.highlight');
    if (highlight) {
      const title = highlight.find((element) => {
        return element.field === 'cm:content';
      });
      return title ? title.snippets[0] : null;
    } else {
      return null;
    }
  }

  // get content() {
  //   let highlight = this.appConfig.config['search'][0].highlight || {};
  //   if (!Array.isArray(highlight)) {
  //     highlight = [highlight];
  //   }
  //   if (highlight.length > 0) {
  //     console.log('highlight ', highlight);
  //     for (const item of highlight) {
  //       if (item.fields) {
  //         const title = item.fields.find((element) => {
  //           console.log('element ', element);
  //           return element.field === 'cm:content';
  //         });

  //         console.log('title ', title);
  //         if (title && title.snippets && title.snippets.length > 0) {
  //           return title.snippets[0];
  //         }
  //       }
  //     }
  //   }

  //   return null;
  // }

  get hasContent() {
    return this.content;
  }

  private getValue(path) {

    return path
      .replace('["', '.')
      .replace('"]', '')
      .replace('[', '.')
      .replace(']', '')
      .split('.')
      .reduce((acc, part) => (acc ? acc[part] : null), this.node.entry);
  }
}
