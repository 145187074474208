/* eslint-disable @typescript-eslint/ban-types */

import { AnalyticService } from './../../services/analytic-service.service';
import { Component, OnInit } from '@angular/core';




@Component({
  selector: 'app-chart-area',
  templateUrl: './chart-area.component.html',
  styleUrls: ['./chart-area.component.scss']
})
export class ChartAreaComponent implements OnInit {



  public chartData: object[] = [
    // { label: 'abc', count: 61},
    // { label: 'def', count: 1},
  ];

  public title?: string;
  primaryXAxis = {
    valueType: 'Category',
    // lablePlacement: 'OnTicks',
    // edgelabelPlacement: 'Shift',
    // isIndexed: true,
  };


  public tooltip: object = {
    enable: true
  };
  public legend: object = {
    visible: true,
  }
  public marker = {
    visible: true, height: 10, width: 10, dataLabel: {
      visible: true,
      position: 'Outer',
      margin: { top: 70 },
    }
  };

  public palettes: string[] = ['#b3f2ff', '#ffe599', '#C152D2',
    '#8854D9', '#3D4EB8', '#00BCD7', '#4472c4', '#ed7d31', '#ffc000', '#70ad47', '#5b9bd5', '#c1c1c1', '#6f6fe2', '#e269ae', '#9e480e', '#997300'];


  public groupingSeparator: Boolean = true;

  public dataArray: any;

  constructor(private analyticService: AnalyticService) { }

  async ngOnInit() {

    const data = await this.analyticService.fileAspectByPropetiesData();
    this.chartData = data;



  }

}
