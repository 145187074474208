/*!
 * @license
 * Copyright 2019 Alfresco Software, Ltd.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { SearchListOption } from '@alfresco/adf-content-services';
import { SearchWidget } from '@alfresco/adf-content-services';
import { SearchWidgetSettings } from '@alfresco/adf-content-services';
import { SearchQueryBuilderService } from '@alfresco/adf-content-services';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownTreeModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
// import { EmitType } from '@syncfusion/ej2-base';
// import { Query } from '@syncfusion/ej2-data';

import { ContentMetadataService } from '../../content-metadata';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, MatMenuModule, MatIconModule,
    MatButtonModule, MatFormFieldModule, MatAutocompleteModule,
    ReactiveFormsModule, MatInputModule, MatTooltipModule,
    ButtonModule, CheckBoxModule, DropDownTreeModule, MultiSelectAllModule
  ],
  selector: 'adf-search-dropdown-list-checked',
  templateUrl: './search-dropdown-list-checked.component.html',
  styleUrls: ['./search-dropdown-list-checked.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'adf-search-dropdown-list-checked'
  }
})
export class SearchDropdownListCheckedComponent implements SearchWidget, OnInit {

  constructor(
    private contentMetadataService: ContentMetadataService,

  ) {

  }



  // =========================================================================================================
  public mode?: string;
  searchData: { [key: string]: object; }[] | string[] | number[] | boolean[] | undefined;

  public data: object[] = [];

  public fields: object = { text: 'display', value: 'id' };

  public placeholder = 'Select';

  public maximumSelectionLength = '1';

  onChangeOnBlur(event) {
    console.log('onChipSelection event ', event);
  }

  onSelectionChange(event) {

    if (this.id && this.context) {
      const value = event.value;
      console.log('# this.id ', this.id);
      if (value && value.length > 0) {
        const transformedData = value.join(' OR ');
        this.context.queryFragments[this.id] = transformedData;
        this.context.update();
      } else {
        this.context.queryFragments[this.id] = null;
        this.context.update();
      }
    }
  }

  valueChange(event) {
    if (this.id && this.context) {
      const value = event;
      console.log('value ', value);
      if (value && value.length > 0) {
        const transformedData = value.join(' OR ');
        this.context.queryFragments[this.id] = transformedData;
        this.context.update();
      } else {
        this.context.queryFragments[this.id] = null;
        this.context.update();
      }
    }
  }

  // =========================================================================================================


  isActive?: boolean;
  startValue: any;
  displayValue$: Subject<string>;

  submitValues(): void {
    throw new Error('Method not implemented.');
  }
  hasValidValue(): boolean {
    throw new Error('Method not implemented.');
  }
  getCurrentValue() {
    throw new Error('Method not implemented.');
  }

  setValue(value: any) {
    console.log('value ', value);
    throw new Error('Method not implemented.');
  }


  myControl = new FormControl();

  id: string;
  settings: SearchWidgetSettings;
  context: SearchQueryBuilderService;
  options: SearchListOption[];
  filteredOptions: Observable<SearchListOption[]>;

  async ngOnInit() {
    this.mode = 'CheckBox';
    await this.loadData();
    await this.setData();
  }

  updateQuery(value: string, name: string) {
    if (this.id && this.context) {
      console.log('name ', name);
      this.context.queryFragments[this.id] = value;
      this.context.update();
    }
  }

  reset() {
    this.myControl.reset();
    this.updateQuery(null, null);
  }

  modelTypeData: any;

  async loadData() {
    await this.contentMetadataService.login().toPromise();
    this.modelTypeData = await this.contentMetadataService.getModels().toPromise();
    const _status = this.settings;
    console.log('_status ', _status);



  }

  async setData() {
    const mapResponseName = this.settings.options[0].mapResponeName;
    const propertiesName = this.settings.options[0].properties;
    const iDisplay = this.settings.options[0].display;
    this.placeholder = this.settings.placeholder;
    const mutilple = this.settings.mutilple;
    if (mutilple) {
      this.maximumSelectionLength = '99'
    }
    const matchedData = this.modelTypeData.find(item => item.name === mapResponseName);
    console.log('matchedData ', matchedData);
    if (matchedData) {
      const responData = matchedData.properties;
      console.log('iDisplay ', iDisplay);
      //  'Display': prop[iDisplay],
      const transformedData = responData.map(property => ({
        id: `${propertiesName}: '${property.dropdownName}'`,
        display: property[iDisplay]
        // display: property.dropdownName
      }));
      this.data = transformedData
    }






  }

}
