import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  ContextActionsDirective,

} from '@alfresco/aca-shared';


import { NgModule } from '@angular/core';
import { DropdownTreeComponent } from './dropdown-tree.component';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownTreeModule } from '@syncfusion/ej2-angular-dropdowns';


@NgModule({
  declarations: [
    DropdownTreeComponent,
  ],
  exports: [
    DropdownTreeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContextActionsDirective,
    FormsModule, ReactiveFormsModule, ButtonModule, DropDownTreeModule
  ]
})
export class DropdownTreeSelectedModule { }
