<h1 mat-dialog-title class="adf-content-node-selector-dialog-title" data-automation-id="content-node-selector-title">{{title}}</h1>
<ng-container *ngIf="canPerformLocalUpload(); else contentNodePanel">
    <mat-tab-group class="adf-content-node-selector-dialog-content"
                   mat-align-tabs="start"
                   (selectedIndexChange)="onTabSelectionChange($event)">
        <mat-tab label="{{ 'NODE_SELECTOR.REPOSITORY' | translate }}">
            <ng-container *ngTemplateOutlet='contentNodePanel'></ng-container>
        </mat-tab>
        <mat-tab [disabled]="isNotAllowedToUpload()">
            <div class="adf-content-node-selector-local-upload-container">
                <div class="adf-content-node-selector-local-upload-header">
                    <adf-toolbar>
                        <adf-toolbar-title>
                            <adf-dropdown-breadcrumb
                              class="adf-content-node-selector-content-breadcrumb"
                              [folderNode]="breadcrumbFolderNode"
                              [rootId]="data?.currentFolderId"
                              [readOnly]="true"
                              data-automation-id="content-node-selector-upload-breadcrumb"
                            ></adf-dropdown-breadcrumb>
                            <ng-container *ngIf="isCounterVisible()" [adf-node-counter]="getSelectedCount()"></ng-container>
                        </adf-toolbar-title>
                    </adf-toolbar>
                    <ng-template mat-tab-label>
                        {{ 'NODE_SELECTOR.UPLOAD_FROM_DEVICE' | translate }}
                        <mat-icon *ngIf="hasUploadError()"
                                  data-automation-id="adf-content-node-selector-disabled-tab-info-icon"
                                  matTooltip="{{ getWarningMessage() | translate }}">info
                        </mat-icon>
                    </ng-template>
                </div>
                <div class="adf-content-node-selector-local-upload-content">
                    <adf-upload-drag-area [rootFolderId]="currentDirectoryId">
                        <div [class.adf-upload-dialog-container]="uploadStarted">
                            <adf-file-uploading-dialog [alwaysVisible]="true"></adf-file-uploading-dialog>
                        </div>
                        <adf-empty-list data-automation-id="adf-empty-list" *ngIf="!uploadStarted">
                            <div class="adf-empty-list_template adf-empty-folder">
                                <div class="adf-empty-folder-drag-drop">{{ 'ADF-DATATABLE.EMPTY.DRAG-AND-DROP.TITLE' | translate }}</div>
                                <div class="adf-empty-folder-any-files-here-to-add">{{ 'ADF-DATATABLE.EMPTY.DRAG-AND-DROP.SUBTITLE' | translate }}</div>
                                <img [alt]="'ADF-DATATABLE.EMPTY.DRAG-AND-DROP.TITLE' | translate" class="adf-empty-folder-image"
                                     [src]="emptyFolderImageUrl">
                            </div>
                        </adf-empty-list>
                    </adf-upload-drag-area>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</ng-container>

<ng-template #contentNodePanel>
    <adf-content-node-selector-panel2
      [currentFolderId]="data?.currentFolderId"
      [restrictRootToCurrentFolderId]="data?.restrictRootToCurrentFolderId"
      [dropdownHideMyFiles]="data?.dropdownHideMyFiles"
      [dropdownSiteList]="data?.dropdownSiteList"
      [rowFilter]="data?.rowFilter"
      [imageResolver]="data?.imageResolver"
      [isSelectionValid]="data?.isSelectionValid"
      [breadcrumbTransform]="data?.breadcrumbTransform"
      [excludeSiteContent]="data?.excludeSiteContent"
      [selectionMode]="data?.selectionMode"
      [where]="data?.where"
      [showSearch]="data?.showSearch"
      [showDropdownSiteList]="data?.showDropdownSiteList"
      [showFilesInResult]="data?.showFilesInResult"
      [showNodeCounter]="isCounterVisible()"
      (currentFolder)="onCurrentFolder($event)"
      (folderLoaded)="onFolderLoaded()"
      (select)="onSelect($event)"
      (showingSearch)="onShowingSearch($event)"
      (siteChange)="onSiteChange($event)"
      (navigationChange)="onNavigationChange($event)">
    </adf-content-node-selector-panel2>
</ng-template>


<mat-dialog-actions class="adf-content-node-selector-dialog-actions">
    <div>
        <ng-container *ngIf="isUploadEnabled()">
            <adf-upload-button
                [staticTitle]="'FORM.FIELD.UPLOAD' | translate "
                [multipleFiles]="isMultipleSelection()"
                [rootFolderId]="currentDirectoryId"
                [disabled]="isNotAllowedToUpload()"
                (error)="onError($event)">
            </adf-upload-button>
        </ng-container>
    </div>
    <div>
        <button
            mat-button
            (click)="close()"
            data-automation-id="content-node-selector-actions-cancel">{{ 'NODE_SELECTOR.CANCEL' | translate }}
        </button>

        <button mat-button
                [disabled]="isChooseButtonDisabled()"
                class="adf-choose-action"
                (click)="onClick()"
                data-automation-id="content-node-selector-actions-choose">{{ buttonActionName | translate }}
        </button>
    </div>
</mat-dialog-actions>
