<ejs-chart
  [primaryXAxis]="primaryXAxis"
  [tooltip]="tooltip"
  [useGroupingSeparator]="groupingSeparator"
  [primaryYAxis]="primaryYAxis"
  (axisLabelRender)="onAxisLabelRender($event)"
  (tooltipRender)="onTooltipRender($event)"
>

<!-- <e-annotations>
  <e-annotation content='<div style="color: red;">คำอธิบายที่นี่</div>' x="50%" y="10%"></e-annotation>
</e-annotations> -->

  <e-series-collection>
    <e-series [dataSource]="chartData" type="Line" xName="month" yName="qty" [marker]="marker"></e-series>
    <e-series [dataSource]="chartData" type="Spline" xName="month" yName="difference" [marker]="marker" fill="orange"></e-series>
  </e-series-collection>
  
</ejs-chart>




