<aca-page-layout [hasError]="!isValidPath">
  <div class="aca-page-layout-header">
    <adf-breadcrumb-custom [root]="title" [folderNode]="node" [maxItems]="maxVisibleFolder" (navigate)="onBreadcrumbNavigate($event)">
    </adf-breadcrumb-custom>
    <aca-toolbar [items]="actions"></aca-toolbar>
  </div>

  <div class="aca-page-layout-error">
    <aca-generic-error></aca-generic-error>
  </div>

  <aca-page-layout-content>
    <div class="files-content">
      <adf-upload-drag-area
        [rootFolderId]="node?.id"
        [disabled]="!canUpload"
        (updateFileVersion)="onUploadNewVersion($event)"
        (beginUpload)="onBeginUpload($event)"
        [versioning]="overwriteUploadActive"
        [majorVersion]="isMajorVersion()"
      >

        <div style="display: flex; align-items: center">
          <ejs-textbox
            #searchBox
            placeholder="{{ 'SEARCH.FILTER.PLACEHOLDER' | translate }}"
            floatLabelType="Auto"
            style="width: 250px; margin-left: 10px"
            suffixIcon="e-search"
            (keydown)="onKeydown($event)"
          ></ejs-textbox>
          <button ejs-button #myButton (click)="onSearch()" style="margin-left: 10px; margin-top: 12px">Filter</button>
        </div>

        <adf-document-list
          #documentList
          acaDocumentList
          acaContextActions
          [selectionMode]="'multiple'"
          [multiselect]="true"
          [currentFolderId]="node?.id"
          [loading]="true"
          [showHeader]="showHeader"
          [allowDropFiles]="true"
          [navigate]="false"
          [sorting]="['name', 'ASC']"
          [imageResolver]="imageResolver"
          (contextmenu)="onRightClick()"
          (node-click)="onNodeClicked($event)"
          (name-click)="onNameClicked($event)"
          (node-unselect)="onNodeUnselect($event)"
          (sorting-changed)="onSortingChangeds($event)"
          (filterSelection)="onFilterSelected($event)"
          (error)="onError()"
          [sortingMode]="sortingMode"
          [stickyHeader]="false"
          [headerFilters]="true"
          [rowFilter]="rowsFilter"
          [filterValue]="queryParams"
          [node]="nodeResult"
        >
          <data-columns>
            <ng-container *ngFor="let column of columns; trackBy: trackByColumnId">
              <ng-container *ngIf="column.template && !(column.desktopOnly && isSmallScreen)">
                <data-column
                  [key]="column.key"
                  [title]="column.title"
                  [type]="column.type"
                  [format]="column.format"
                  [class]="column.class"
                  [sortable]="column.sortable"
                  [sortingKey]="column.sortingKey || column.key"
                >
                  <ng-template let-context>
                    <adf-dynamic-column [id]="column.template" [context]="context"> </adf-dynamic-column>
                  </ng-template>
                </data-column>
              </ng-container>

              <ng-container *ngIf="!column.template && !(column.desktopOnly && isSmallScreen)">
                <data-column
                  [key]="column.key"
                  [title]="column.title"
                  [type]="column.type"
                  [format]="column.format"
                  [class]="column.class"
                  [sortable]="column.sortable"
                  [sortingKey]="column.sortingKey || column.key"
                >
                </data-column>
              </ng-container>
            </ng-container>
          </data-columns>

          <adf-custom-empty-content-template *ngIf="isFilterHeaderActive">
            <ng-container>
              <div class="empty-search__block" aria-live="polite">
                <p class="empty-search__text">
                  {{ 'APP.BROWSE.SEARCH.NO_FILTER_RESULTS' | translate }}
                </p>
              </div>
            </ng-container>
          </adf-custom-empty-content-template>
        </adf-document-list>

        <!-- <adf-pagination acaPagination [target]="documentList"> </adf-pagination> -->

        <adf-pagination acaPagination [target]="documentList" (change)="onPaginationChanged($event)"> </adf-pagination>
      </adf-upload-drag-area>
    </div>

    <div class="aca-sidebar" *ngIf="infoDrawerOpened$ | async">
      <aca-info-drawer [node]="selection.last"></aca-info-drawer>
    </div>

    <div class="preview-sidebar" *ngIf="isRightNotClickActive && !isOutletPreviewUrlActive && selection.file && !(infoDrawerOpened$ | async)">
      <aca-viewer-drawer [nodeRef]="selection.file"></aca-viewer-drawer>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
