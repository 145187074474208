<h2 mat-dialog-title>New User</h2>
<mat-dialog-content>
  <form id="formId" [formGroup]="reactForm" #formDir="ngForm" class="form-horizontal form-scrollable" novalidate>
    <div style="margin: 5px">
      <!-- Info -->
      <div tabindex="0" class="e-card" id="basic">
        <div class="e-card-header">
          <div class="e-card-header-caption">
            <div class="e-card-title">Info</div>
          </div>
        </div>
        <div class="e-card-content">
          <div class="form-group">
            <div>
              <label for="firstName">First Name:</label>
              <ejs-textbox id="firstName" name="firstName" formControlName="firstName"></ejs-textbox>
            </div>

            <div *ngIf="firstName.errors">
              <div *ngIf="firstName.errors.required" class="e-error">This field is required.</div>
            </div>
          </div>

          <div class="form-group">
            <div>
              <label for="lastName">Last Name:</label>
              <ejs-textbox id="lastName" name="lastName" formControlName="lastName"></ejs-textbox>
            </div>

            <div *ngIf="lastName.errors">
              <div *ngIf="lastName.errors.required" class="e-error">This field is required.</div>
            </div>
          </div>

          <div class="form-group">
            <div>
              <label for="email">Email:</label>
              <ejs-textbox id="email" name="email" formControlName="email"></ejs-textbox>
            </div>

            <div *ngIf="email.errors">
              <div *ngIf="email.errors.required" class="e-error">This field is required.</div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 5px"></div>

      <!-- About User -->
      <div tabindex="0" class="e-card" id="basic">
        <div class="e-card-header">
          <div class="e-card-header-caption">
            <div class="e-card-title">About User</div>
          </div>
        </div>
        <div class="e-card-content">
          <div class="form-group">
            <div>
              <label for="userId">User Name:</label>
              <ejs-textbox id="userId" name="userId" formControlName="userId"></ejs-textbox>
            </div>
            <div *ngIf="userId.errors">
              <div *ngIf="userId.errors.required" class="e-error">This field is required.</div>
            </div>
          </div>

          <div class="form-group">
            <div>
              <label for="password">Password:</label>
              <ejs-textbox id="password" name="password" formControlName="password"></ejs-textbox>
            </div>
            <div *ngIf="password.errors">
              <div *ngIf="password.errors.required" class="e-error">This field is required.</div>
            </div>
          </div>

          <div class="form-group">
            <div>
              <label for="vertifyPassword">Vertify Password:</label>
              <ejs-textbox id="vertifyPassword" name="vertifyPassword" formControlName="vertifyPassword"></ejs-textbox>
            </div>

            <div *ngIf="vertifyPassword.errors">
              <div *ngIf="vertifyPassword.errors.required" class="e-error">This field is required.</div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 5px"></div>

      <!-- Table -->
      <div tabindex="0" class="e-card" id="basic">
        <div class="e-card-header">
          <div class="e-card-header-caption">
            <div class="e-card-title">Groups:</div>
          </div>
        </div>
        <div class="e-card-content">
          <!-- GRID -->
          <div class="group-grid-data">
            <ejs-grid
              [dataSource]="dataGroup"
              width="100%"
              height="100%"
              [rowHeight]="25"
              [toolbar]="toolbar"
              [pageSettings]="pageSettings"
              [enableHover]="false"
              [allowResizing]="true"
              [allowSorting]="true"
              allowPaging="true"
            >
              <e-columns>
                <e-column field="displayName" headerText="DISPLAYNAME" width="80"></e-column>
                <e-column field="Action" headerText="" width="20">
                  <ng-template #template let-data>
                    <button ejs-button class="e-btn-icon e-icons e-plus small-button" (click)="addChip(data.displayName)"></button>
                  </ng-template>
                </e-column>
              </e-columns>
            </ejs-grid>
          </div>
          <ejs-chiplist id="chip" enableDelete="true" (delete)="removeChip($event)">
            <e-chips>
              <e-chip *ngFor="let chip of chips" [text]="chip" trailingIconCss="e-dlt-btn"></e-chip>
            </e-chips>
          </ejs-chiplist>
        </div>
      </div>
    </div>
  </form>

  <div style="margin-bottom: 25px"></div>

  <div mat-dialog-actions style="text-align: right">
    <button *ngIf="!updateUserId" ejs-button class="rounded-button" (click)="createUser()" [disabled]="reactForm.invalid">Create</button>
    <button *ngIf="updateUserId" ejs-button class="rounded-button" (click)="onUpdate()" [disabled]="reactForm.invalid">Update</button>
    <button ejs-button class="rounded-button" (click)="onCancel()">Cancel</button>
  </div>
</mat-dialog-content>
