<ng-container *ngIf="!item.children">
  <div class="item">
    <button acaActiveLink="action-button--active" [action]="item" [attr.aria-label]="item.title | translate"
      [id]="item.id" [attr.data-automation-id]="item.id" [attr.title]="item.description | translate" mat-button
      class="action-button full-width">
      <adf-icon *ngIf="item.icon" [value]="item.icon"></adf-icon>
      <span class="action-button__label2">{{ item.title | translate }}</span>
    </button>
  </div>
</ng-container>



<ng-container *ngIf="item.children && item.children.length">
  <mat-expansion-panel [expanded]="true" [acaExpansionPanel]="item" [@.disabled]="true">
    <mat-expansion-panel-header expandedHeight="32px" collapsedHeight="32px" role="group">
      <mat-panel-title>
        <div class="item">
          <span [attr.aria-label]="item.title | translate" [id]="item.id" [attr.title]="item.description | translate"
            [attr.data-automation-id]="item.id" class="action-button full-width">
            <adf-icon *ngIf="item.icon" [value]="item.icon"></adf-icon>
            <span class="action-button__label">{{ item.title | translate }}</span>
          </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngFor="let child of item.children; trackBy: trackById" class="item">
      <button acaActiveLink="action-button--active" [action]="child" [attr.aria-label]="child.title | translate"
        [id]="child.id" [attr.data-automation-id]="child.id" [attr.title]="child.description | translate" mat-button
        class="action-button full-width">
        <adf-icon *ngIf="child.icon" [value]="child.icon"></adf-icon>
        <span class="action-button__label">{{ child.title | translate }}</span>
      </button>
    </div>
  </mat-expansion-panel>
</ng-container>