<ng-container>
  <div id="spinner"></div>

  <!-- [Viewer] Annontation With Role  -->
  <div class="full-size" *ngIf="globalConfigAnnotation && !isShareLink">
    <div class="full-size">
      <ng-container *ngIf="base64String">
        <ejs-pdfviewer
          #pdfviewer
          id="pdfViewer"
          enableDownload="false"
          enableTextSelection="true"
          [documentPath]="base64String"
          [initialRenderPages]="initialRender"
          (documentLoad)="importAnnotaion($event)"
          [downloadFileName]="nodeName"
          [isSignatureEditable]="true"
          (downloadStart)="downloadStart($event)"
          (PrintStart)="downloadStart($event)"
          [toolbarSettings]="toolbarSettings"
          (toolbarClick)="toolbarClick($event)"
          class="full-size block-display"
        ></ejs-pdfviewer>
      </ng-container>
    </div>
  </div>

  <!-- [Viewer] No Annotion -->
  <div class="full-size" *ngIf="!globalConfigAnnotation && !isShareLink">
    <div class="full-size">
      <ng-container *ngIf="base64String">
        <ejs-pdfviewer
          #pdfviewer
          id="pdfViewer"
          enableDownload="false"
          enableTextSelection="true"
          [documentPath]="base64String"
          [initialRenderPages]="initialRender"
          [downloadFileName]="nodeName"
          [toolbarSettings]="toolbarSettings2"
          (downloadStart)="downloadStart($event)"
          (PrintStart)="downloadStart($event)"
          (toolbarClick)="toolbarClick($event)"
          class="full-size block-display"
        ></ejs-pdfviewer>
      </ng-container>
    </div>
  </div>

  <!-- [Share Preview] No Annotation -->
  <div class="full-size" *ngIf="isShareLink">
    <div class="full-size">
      <ejs-pdfviewer
        *ngIf="isShareLink"
        #pdfviewer
        id="pdfViewer"
        [documentPath]="document"
        [initialRenderPages]="initialRender"
        [toolbarSettings]="toolbarSettings2"
        (downloadStart)="downloadStart($event)"
        (PrintStart)="downloadStart($event)"
        (toolbarClick)="toolbarClick($event)"
        class="full-size block-display"
      >
      </ejs-pdfviewer>
    </div>
  </div>

  <div class="parent-container">
    <button
      class="move-up"
      *ngIf="items && items.length > 0 && !isShareLink && globalConfigAnnotation"
      ejs-speeddial
      id="speeddial1"
      target="#target"
      itemTemplate="#itemTemplate"
      content="Action"
      openIconCss="speeddial-icons speeddial-icon-edit"
      (clicked)="clicked($event)"
      [items]="items"
    >
      <ng-template #itemTemplate let-items="">
        <div class="itemlist">
          <span class="icon {{ items.iconCss }}" style="padding: 2px; margin-top: 5px"></span>
          <span class="text">{{ items.text }}</span>
        </div>
      </ng-template>
    </button>
  </div>
</ng-container>
