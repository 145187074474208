import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { DocumentExpireResponseClass, DocumentExpireReportClass, DocumentExpireClass } from '../models/documentExpireClass.model';
import { DocumentExpireService } from '../dialog/document-expire.service';
import { AlfrescoApiService } from '@alfresco/adf-core';

import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  apiURL: any;
  apiURLAlfresco: any;
  headers: any;


  constructor(@Inject(DOCUMENT) private document: Document, private httpClient: HttpClient, private docExpService: DocumentExpireService, private apiService: AlfrescoApiService) {

    this.apiURL = '';
    this.apiURLAlfresco = '';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (this.docExpService.getBaseUrl().startsWith("http://localhost")) {
      this.apiURL = 'http://localhost:3800'
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }


  }

  getBaseUrl(): string {
    return this.document.location.origin;
  }

  // ======================================== SELECT ========================================

  // get all
  getDocumentExpire(): Observable<DocumentExpireResponseClass[]> {
    return this.httpClient.get<DocumentExpireResponseClass[]>(`${this.apiURL}/documentexpire`);
  }

  // get data by nodeID
  getDocumentExpireByNodeID(nodeID: string): Observable<DocumentExpireResponseClass> {
    return this.httpClient.get<DocumentExpireResponseClass>(`${this.apiURL}/documentexpire/${nodeID}`);
  }

  // get data for report
  getDocumentExpireReportData(reportData: DocumentExpireReportClass): Observable<DocumentExpireResponseClass[]> {
    return this.httpClient.post<DocumentExpireResponseClass[]>(`${this.apiURL}/documentexpire/reportdata`, reportData);
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  // ======================================== API Alfresco ========================================
  getNodes(nodeID: string): Observable<any> {
    const pathService = `/nodes/${nodeID}`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  getPeople(): Observable<any> {
    const pathService = `/people/-me-`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  // ======================================== INSERT ========================================
  addDocumentExpire(documentExpireClass: DocumentExpireClass): Observable<DocumentExpireResponseClass> {
    console.log('documentExpireClass', documentExpireClass);
    return this.httpClient.post<DocumentExpireResponseClass>(`${this.apiURL}/documentexpire/insert`, this.makeFormData(documentExpireClass));
  }


  makeFormData(aa: DocumentExpireClass): FormData {
    const formData = new FormData();
    formData.append('doctypes', aa.doctypes);
    formData.append('docname', aa.docname);
    formData.append('docowner', aa.docowner);
    formData.append('filename', aa.filename);
    formData.append('numberofdays', aa.numberofdays);
    formData.append('email', aa.email);
    formData.append('expiredate', aa.expiredate);
    formData.append('nodeid', aa.nodeid);
    formData.append('path', aa.path);
    formData.append('urlfile', aa.urlfile);
    formData.append('status', aa.status);
    formData.append('remark', aa.remark);
    formData.append('removefile_active', aa.removefile_active.toString());
    formData.append('removefile_days', aa.removefile_days);
    return formData;
  }

  // ======================================== UPDATE ========================================
  updateDocumentExpire(nodeID: string, documentExpireClass: DocumentExpireClass): Observable<DocumentExpireResponseClass> {
    return this.httpClient.put<DocumentExpireResponseClass>(`${this.apiURL}/documentexpire/update/${nodeID}`, this.makeFormData(documentExpireClass));
  }

  // ======================================== DELETE ========================================
  deleteDocumentExpire(nodeID: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.apiURL}/documentexpire/delete/${nodeID}`);
  }
}
