<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">{{ 'APPROVAL-WORKFLOW.CREATE-REQUEST.TITLE' | translate }}</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>

    <div class="button-container">
      <button ejs-button class="rounded-button" cssClass="e-info">Start</button>
      <button ejs-button class="rounded-button" cssClass="e-info">Save Draft</button>
    </div>
  </div>

  <div class="aca-page-layout-content">
    xxx
  </div>
</aca-page-layout>
