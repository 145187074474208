<form #internalShareForm="ngForm" class="example-form">
  <!-- <div class="dialog-title">
    กรุณาระบุข้อมูล properties ให้ครบ
    <mat-icon>edit</mat-icon>
  </div> -->

  <div class="dialog-title">
    <mat-icon class="edit-icon">edit</mat-icon>
    <span>กรุณาระบุข้อมูล properties ให้ครบ</span>
  </div>

  <mat-card class="adf-permission-card" id="adf-permission-manager-card">
    <!-- ROLE -->

    <!-- TABLE -->
    <div class="table-wrapper">
      <div *ngIf="loadSuccess">
        <table mat-table [dataSource]="dataSource" class="maxtable">
          <ng-container matColumnDef="Value">
            <th mat-header-cell *matHeaderCellDef>Display</th>
            <td mat-cell *matCellDef="let element">{{element.Value}}</td>
          </ng-container>
          <ng-container matColumnDef="Propeties">
            <th mat-header-cell *matHeaderCellDef>Propeties</th>
            <td mat-cell *matCellDef="let element">{{element.Propeties}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </mat-card>
  <!-- Dialog-Actions -->
  <div mat-dialog-actions class="align-right">
    <button mat-raised-button mat-dialog-close>CANCEL</button>
  </div>
  <div style="margin-bottom: 15px"></div>
</form>
