/*!
 * @license
 * Copyright 2019 Alfresco Software, Ltd.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { SearchListOption } from '@alfresco/adf-content-services';
// import { SearchWidget } from './search-widget.interface';
import { SearchWidget } from '@alfresco/adf-content-services';
import { SearchWidgetSettings } from '@alfresco/adf-content-services';
import { SearchQueryBuilderService } from '@alfresco/adf-content-services';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { MatInputModule } from '@angular/material/input';

import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, MatMenuModule, MatIconModule,
    MatButtonModule, MatFormFieldModule, MatAutocompleteModule,
    ReactiveFormsModule, MatInputModule, MatTooltipModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'adf-search-dropdown-list',
  templateUrl: './search-dropdown-list.component.html',
  styleUrls: ['./search-dropdown-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'adf-search-dropdown-list'
  }
})
export class SearchDropdownListComponent implements SearchWidget, OnInit {
  isActive?: boolean;
  startValue: any;
  displayValue$: Subject<string>;

  submitValues(): void {
    throw new Error('Method not implemented.');
  }
  hasValidValue(): boolean {
    throw new Error('Method not implemented.');
  }
  getCurrentValue() {
    throw new Error('Method not implemented.');
  }

  setValue(value: any) {
    console.log('value ', value);
    throw new Error('Method not implemented.');
  }

  /** The content of the text box. */
  myControl = new FormControl();

  id: string;
  settings: SearchWidgetSettings;
  context: SearchQueryBuilderService;
  options: SearchListOption[];
  filteredOptions: Observable<SearchListOption[]>;

  ngOnInit() {

    if (this.settings) {
      if (this.settings.options && this.settings.options.length > 0) {
        this.options = this.settings.options;
      }
    }

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

  }

  _filter(value: string): SearchListOption[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.options.filter(o => o.name.toLowerCase().includes(filterValue));
    } else {
      this.options = this.settings.options;
      // ใช้สำหรับส่งข้อมูลโดยการ set ค่าจากข้างนอก
      // const xx: SearchListOption[] = [
      //   {
      //     'name': 'ABC1',
      //     'value': "cm:name:'.pdf'",
      //     'checked': false
      //   },
      //   {
      //     'name': 'ABC2',
      //     'value': "cm:name:'.png'",
      //     'checked': false
      //   },
      //   {
      //     'name': 'ABC3',
      //     'value': "cm:name:'.txt'",
      //     'checked': false
      //   }
      // ];
      // this.options = xx;
      return this.options;
    }
  }

  updateQuery(value: string, name: string) {
    if (this.id && this.context) {
      console.log('name ', name);
      this.context.queryFragments[this.id] = value;
      this.context.update();
    }
  }

  reset() {
    this.myControl.reset();
    this.updateQuery(null, null);
  }

}
