/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit } from '@angular/core';
import { AccumulationTheme, IAccLoadedEventArgs, IPointRenderEventArgs } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { AnalyticService } from '../../services/analytic-service.service';


@Component({
  selector: 'app-chart-donut',
  templateUrl: './chart-donut.component.html',
  styleUrls: ['./chart-donut.component.css']
})
export class ChartDonutComponent implements OnInit {


  public groupingSeparator: Boolean = true;

  public data: object[] = [
    { x: 'Chrome', y: 61.3, DataLabelMappingName: Browser.isDevice ? 'Chrome: <br> 61.3%' : 'Chrome: 61.3%' },
    { x: 'Safari', y: 24.6, DataLabelMappingName: Browser.isDevice ? 'Safari: <br> 24.6%' : 'Safari: 24.6%' },
    { x: 'Edge', y: 5.0, DataLabelMappingName: 'Edge: 5.0%' },
    { x: 'Samsung Internet', y: 2.7, DataLabelMappingName: Browser.isDevice ? 'Samsung Internet: <br> 2.7%' : 'Samsung Internet: 2.7%' },
    { x: 'Firefox', y: 2.6, DataLabelMappingName: Browser.isDevice ? 'Firefox: <br> 2.6%' : 'Firefox: 2.6%' },
    { x: 'Others', y: 3.6, DataLabelMappingName: Browser.isDevice ? 'Others: <br> 3.6%' : 'Others: 3.6%' }
  ];
  public pointRender(args: IPointRenderEventArgs): void {
    this.SetTheme(args);
  }
  public SetTheme(args: IPointRenderEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    const seriesColor = ['#FFE066', '#FAB666', '#F68F6A', '#F3646A', '#CC555A', '#9C4649'];
    if (selectedTheme === 'fluent' || selectedTheme === 'bootstrap5') {
      args.fill = seriesColor[args.point.index % 10];
    }
    if (selectedTheme.indexOf('dark') > -1) {
      if (selectedTheme.indexOf('material') > -1) {
        args.border.color = '#303030';
      } else if (selectedTheme.indexOf('bootstrap5') > -1) {
        args.border.color = '#212529';
      } else if (selectedTheme.indexOf('bootstrap') > -1) {
        args.border.color = '#1A1A1A';
      } else if (selectedTheme.indexOf('tailwind') > -1) {
        args.border.color = '#1F2937';
      } else if (selectedTheme.indexOf('fluent') > -1) {
        args.border.color = '#252423';
      } else if (selectedTheme.indexOf('fabric') > -1) {
        args.border.color = '#201f1f';
      } else {
        args.border.color = '#222222';
      }
    } else if (selectedTheme.indexOf('highcontrast') > -1) {
      args.border.color = '#000000';
    } else if (selectedTheme.indexOf('fluent2') > -1) {
      args.fill = seriesColor[args.point.index % 10];
    } else if (selectedTheme.indexOf('fluent2-highcontrast') > -1) {
      args.fill = seriesColor[args.point.index % 10];
    } else {
      args.border.color = '#FFFFFF';
    }
  }
  // Initializing Legend
  public legendSettings: object = {
    visible: true,
    toggleVisibility: true,
    position: 'Right',
    textWrap: 'Wrap',
  };

  // Initializing DataLabel
  public dataLabel: object = {
    visible: true,
    name: 'DataLabelMappingName',
    position: 'Outside',
    font: {
      fontWeight: '600',
    },
    connectorStyle: {
      length: '10px',
      type: 'Curve'
    },
  };

  public qtySite = '';
  public centerLabel: object = {
    text: 'จำนวน<br>ไชต์<br>ทั้งหมด: ' + this.qtySite,
    hoverTextFormat: '${point.x}<br>ไฟล์จำนวน <br>${point.y}',
    textStyle: {
      fontWeight: '600',
      size: Browser.isDevice ? '7px' : '11px'
    },
  };
  public tooltip: object = {
    enable: false
  };
  public border: object = {
    width: 1
  };
  // custom code start
  public load(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    // eslint-disable-next-line max-len
    args.accumulation.theme = <AccumulationTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, 'Dark').replace(/contrast/i, 'Contrast').replace(/-highContrast/i, 'HighContrast');
  }
  // custom code end
  public radius: string = Browser.isDevice ? '40%' : '70%'
  public startAngle: number = Browser.isDevice ? 30 : 62;


  // ==============================================================================
  constructor(private analyticService: AnalyticService) { }


  async ngOnInit() {


    // this.legendSettings = { visible: true, width: '10' };

    await (await this.analyticService.fileCountPerLibrary()).subscribe(
      data => {

        const qtySite = data.list.context.facetsFields[0].buckets.length;
        this.qtySite = qtySite;

        this.centerLabel = {
          text: 'จำนวน<br>ทั้งหมด: ' + this.qtySite.toLocaleString() + ' ไชต์',
          hoverTextFormat: '${point.x}<br>ไฟล์จำนวน <br>${point.y}',
          textStyle: {
            fontWeight: '600',
            size: Browser.isDevice ? '7px' : '11px'
          },
        };

        let qty5Site = 0;
        const sortedBuckets = data.list.context.facetsFields[0].buckets.sort((a, b) => b.count - a.count).slice(0, 5);
        this.data = sortedBuckets.map((item, _index) => {
          qty5Site += item.count
          return { x: `${item.label} (${item.count.toLocaleString()})`, y: item.count, DataLabelMappingName: `${item.label}:${item.count.toLocaleString()}` };
        });


        // fix 5 site
        let qtyAllSite = 0;
        data.list.context.facetsFields[0].buckets.map((item, _index) => {
          qtyAllSite += item.count
        });

        const ortherSiteQty = qtyAllSite - qty5Site;
        this.data.push({ x: `Site อื่นๆ (${ortherSiteQty.toLocaleString()})`, y: ortherSiteQty, DataLabelMappingName: `Site อื่นๆ:${ortherSiteQty.toLocaleString()}` })

        // this.data = data.list.context.facetsFields[0].buckets.map((item, _index) => {
        //   return { x: `${item.label} (${item.count})`, y: item.count, DataLabelMappingName: `${item.label}:${item.count}` };
        // });
      },
      error => {
        console.error('Error fetching data', error);
      }
    );

  }

}
