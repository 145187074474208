<div *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate" [attr.aria-label]="'APP.INFO_DRAWER.DATA_LOADING' | translate"></mat-progress-bar>
</div>

<ng-container *ngIf="!isLoading && !!displayNode && isLoadingTab" ng-reflect-selected-index="0">
  <adf-info-drawer [title]="'APP.INFO_DRAWER.TITLE'" cdkTrapFocus cdkTrapFocusAutoCapture ng-reflect-selected-index="0">
    <aca-toolbar [items]="actions" info-drawer-buttons ng-reflect-selected-index="0"></aca-toolbar>

    <adf-info-drawer-tab *ngFor="let tab of tabs" [icon]="tab.icon" [label]="tab.title" ng-reflect-selected-index="0">
      <adf-dynamic-tab [node]="$any(displayNode)" [id]="tab.component" [attr.data-automation-id]="tab.component" ng-reflect-selected-index="0">
      </adf-dynamic-tab>
    </adf-info-drawer-tab>
  </adf-info-drawer>
</ng-container>
