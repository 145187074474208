/* eslint-disable curly */
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { EditService, FilterService, GridModule, PageService, SelectionSettingsModel, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@alfresco/adf-content-services';

import { Store } from '@ngrx/store';

import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, GridModule, TextBoxModule, DropDownListModule, ButtonModule, DialogModule],
  templateUrl: './dialog-password.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dialog-password.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [EditService, ToolbarService, PageService, FilterService],
  host: { class: 'aca-adduser' }
})
export class DialogPasswordComponent implements OnInit {
  public selectionOptions?: SelectionSettingsModel;

  zipPassword = '';
  zipFileName = '';
  submitted = false;

  role: any;

  public dataRole: string[] = ['SiteConsumer', 'SiteContributor', 'SiteCollaborator', 'SiteManager'];

  constructor(
    public dialogRef: MatDialogRef<DialogPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public dataSelected: any,
    private store: Store<AppStore>
  ) {}

  async ngOnInit(): Promise<void> {
    console.log('# EditUserComponent ');
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
    this.zipFileName = `workspace_content_${formattedDate}`;
  }

  isCancel(): void {
    this.dialogRef.close();
  }

  public selectRole = '';
  isOk() {
    this.submitted = true;

    if (!this.zipFileName || !this.zipPassword) {
      this.store.dispatch(new SnackbarErrorAction('Please fill in all required fields'));
      return;
    }

    // if (!this.zipFileName.toLowerCase().endsWith('.zip')) {
    //   this.alertError('File name must end with .zip extension', null);
    //   return;
    // }
    const data = {
      password: this.zipPassword,
      fileName: this.zipFileName + '.zip'
    };

    this.dialogRef.close(data);
  }

  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }
}
