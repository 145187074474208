<mat-table [dataSource]="data">
  <!-- <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
        <mat-header-cell *matHeaderCellDef>
            {{ column.header | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{ column.cell(row) }}</mat-cell>
    </ng-container> -->

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
    <mat-cell *matCellDef="let transaction"> {{ transaction.name }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
    <mat-cell *matCellDef="let transaction"> {{ transaction.description }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="url">
    <mat-header-cell *matHeaderCellDef> </mat-header-cell>
    <mat-cell *matCellDef="let transaction"> <a mat-button color="primary" [href]="transaction.url" target="_blank">OPEN</a> </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
