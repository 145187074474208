<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">User Guide</h1>
  </div>
  <div class="aca-page-layout-content scrollable">
    <div class="main-content">
      <ng-container>
        <article>
          <!-- <div *ngIf="userGuideEntries"> -->
          <app-user-guide-list [data]="userGuideEntries"></app-user-guide-list>
          <!-- </div> -->
        </article>
      </ng-container>
    </div>
  </div>
</aca-page-layout>
