/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit, ViewChild, ViewEncapsulation, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SyncfuctionsModule } from '../syncfuctions.module';
import {
  PageService,
  SortService,
  FilterService,
  GroupService,
  EditService,
  ToolbarService,
  ContextMenuService,
  ExcelExportService,
  VirtualScrollService,
  // ToolbarItems,
  GridComponent,
  FilterSettingsModel,
  IFilter,
  ExcelExportProperties,
  ToolbarItems,
  // SortEventArgs,
  // ActionEventArgs,
  // Column,
  // Filter
} from '@syncfusion/ej2-angular-grids';

import {
  // AppService,
  ContextActionsDirective,
  GenericErrorComponent,
  PageComponent,
  PageLayoutComponent,
  PageLayoutModule,
  PaginationDirective,
  ToolbarComponent
} from '@alfresco/aca-shared';
import { LibraryReportService } from './library-report.service';
import { MaterialModule } from '../material.module';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
// import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { FormsModule } from '@angular/forms';
import { CheckBoxSelectionService, DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { CommonModule } from '@angular/common';
// import { DetailSiteDialogComponent } from './detail-site-dialog/detail-site-dialog.component';
// import { MatDialog } from '@angular/material/dialog';
import { DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';
// import { createElement } from '@syncfusion/ej2-base';
import {
  SnackbarErrorAction,
  SnackbarInfoAction,
} from '@alfresco/aca-shared/store';
// import { ChartSiteDialogComponent } from './chart-site-dialog/chart-site-dialog.component';
import {
  AnimationSettingsModel,
  DialogComponent,
  DialogModule,
} from '@syncfusion/ej2-angular-popups';

import {
  ButtonComponent,
  ButtonModel,
  CheckBoxComponent,
  CheckBoxModule,
  ClickEventArgs,
} from '@syncfusion/ej2-angular-buttons';

import { ChartComponent, ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { AppConfigService } from '@alfresco/adf-core';


interface LibraryData {
  No: number;
  LibraryName: string;
  TotalFiles: number;
  TotalSpace: number;
  detail: string;
  static: string;
  Title: string;
  Description: string;
}

interface ChartData {
  month: string;
  year: number;
  monthYear: string;
  qty: number;
  difference?: number;
}


@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SyncfuctionsModule,
    PageLayoutModule,
    MaterialModule,
    PageLayoutComponent,
    ToolbarComponent,
    GenericErrorComponent,
    ContextActionsDirective,
    PaginationDirective,
    DialogModule, CheckBoxModule, ChartAllModule
  ],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    EditService,
    ToolbarService,
    ContextMenuService,
    ExcelExportService,
    VirtualScrollService,
    CheckBoxSelectionService
  ],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'app-library-report',
  templateUrl: './library-report.component.html',
  styleUrls: ['./library-report.component.scss']
})

export class LibraryReportComponent extends PageComponent implements OnInit {

  @ViewChild('overlay')
  public overlay: CheckBoxComponent;


  // ===================== Modal Chart =====================
  @ViewChild('chart')
  public chart: ChartComponent;
  @ViewChild('modalDialog')
  public modalDialog: DialogComponent;

  @ViewChild('modalButton')
  public modalButton: ButtonComponent;

  public target: string = '#modalTarget';
  public width: string = '800px';
  public header: string = 'สถิติการเพิ่มไฟล์ย้อนหลัง 12 เดือน';
  public isModal: Boolean = true;
  public animationSettings: AnimationSettingsModel = { effect: 'None' };


  // ===================== Modal Detail =====================


  @ViewChild('modalDialog_Detail')
  public modalDialog_Detail: DialogComponent;

  @ViewChild('modalButton_Detail')
  public modalButton_Detail: ButtonComponent;

  public target_Detail: string = '#modalTarget';
  public width_Detail: string = '800px';
  public header_Detail: string = 'ตารางแสดงจำนวนประเภทของไฟล์แต่ละ Library';
  public isModal_Detail: Boolean = true;
  public animationSettings_Detail: AnimationSettingsModel = { effect: 'None' };


  public dataTable?: object[];
  mimeTypes: any;


  @ViewChild('grid_Detail')
  public grid_Detail?: GridComponent;

  public toolbar?: ToolbarItems[];


  // ===================== Chart =====================

  public marker: object = {
    visible: true,
    width: 10,
    height: 10
  };

  public primaryXAxis = {
    valueType: 'Category',
    labelIntersectAction: 'Wrap'
  };

  public primaryYAxis = {
    labelFormat: 'n0',
    decimalPlaces: 2
  };

  public tooltip = { enable: true };

  public groupingSeparator = true;

  public chartData = []

  thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ];

  // ===================== Grid =====================
  public pageSettings?: PageSettingsModel;
  data: LibraryData[] = [];
  public toolbarOptions: object[];
  @ViewChild('grid') public grid?: GridComponent;
  public loadingIndicator?: any;
  public filterOptions?: FilterSettingsModel;
  public filter?: IFilter;
  public dropInstance?: DropDownList;
  public initialSort?: object;
  @ViewChild('daterangepicker')
  public DateRange?: DateRangePickerComponent;
  constructor(
    private libraryReportService: LibraryReportService,
    public appConfig: AppConfigService,
    // private dialogRef: MatDialog
  ) {
    super();

    this.mimeTypes = this.appConfig.config['mimeTypes'] || {};
  }
  async ngOnInit() {
    console.log('# LibraryReportComponent');

    this.filterOptions = {
      type: 'Menu',
    };
    this.toolbarOptions = [
      { text: 'ExcelExport', id: 'ExcelExport' },
      { text: 'CsvExport', id: 'CsvExport' },
      { template: '#toolbarTemplate' }
    ];
    this.pageSettings = { pageSize: 50 };
    this.loadingIndicator = { indicatorType: 'Spinner' };

    this.toolbar = ['ExcelExport', 'CsvExport', 'Search']; // for grid modal_Detail


    await this.getLibraryReport();

  }
  // ========================= GetData - Grid =========================
  loadData = false;
  async getLibraryReport() {
    try {
      this.loadData = false;
      const response = await (await this.libraryReportService.getCountPerLibrary()).toPromise();



      this.data = [];
      const promises = response.list.context.facetsFields[0].buckets.map(async (bucket, index) => {

        const data = await (await this.libraryReportService.getTotalSizePerLibrary(bucket.label, this.dateRang)).toPromise();

        const siteId = bucket.label


        let description = '';
        let title = '';
        if (!siteId.startsWith('_')) {
          const data2 = await (await this.libraryReportService.getSite(siteId)).toPromise();
          if (data2) {
            description = data2.entry.description;
            title = data2.entry.title;
          }
        }


        const sumInMB = (data.sum / 1048576).toFixed(0);
        this.data.push({
          No: index + 1,
          LibraryName: bucket.label,
          Title: title,
          Description: description,
          TotalFiles: Number(data.countValues),
          TotalSpace: Number(sumInMB), // Update TotalSpace with the sum value
          detail: '',
          static: ''
        });
      });
      await Promise.all(promises);
      this.data.sort((a, b) => b.TotalFiles - a.TotalFiles);
      this.data.forEach((item, index) => {
        item.No = index + 1;
      });
      this.initialSort = {
        columns: [
          { field: 'TotalFiles', direction: 'Descending' },
          { field: 'TotalSpace', direction: 'Descending' },
        ],
      };
      this.loadData = true;
    } catch (error) {
      console.error('Error fetching data', error);
    }
  }

  // ========================= GetData - Chart =========================

  async fetchChartData(): Promise<void> {
    await (await this.libraryReportService.getCreatedByMonth(this.libraryName, this.dateRang)).subscribe(
      data => {
        this.processChartData(data);
      },
      error => {
        console.error('Error fetching data', error);
      }
    );
  }

  private processChartData(data: any): void {
    this.chartData = data.list.context.facetQueries.map(item => {
      const [month, year] = item.label.split(' ');
      const thaiMonth = this.thaiMonths[new Date(Date.parse(`1 ${month} 2000`)).getMonth()];
      return {
        month: `${thaiMonth} ${year}`,
        year: parseInt(year, 10),
        monthYear: `${thaiMonth} ${year}`,
        qty: parseInt(item.count, 10),
      } as ChartData;
    })
      .sort((a, b) => {
        if (a.year === b.year) {
          return this.thaiMonths.indexOf(a.month.split(' ')[0]) - this.thaiMonths.indexOf(b.month.split(' ')[0]);
        }
        return a.year - b.year;
      });

    for (let i = 1; i < this.chartData.length; i++) {
      this.chartData[i].difference = this.chartData[i].qty - this.chartData[i - 1].qty;
    }
  }

  // ========================= GetData - Detail =========================

  async getMimeTypeFile() {
    try {
      const response = await (await this.libraryReportService.getMimeTypeFile(this.libraryName, this.mimeTypes, this.dateRang)).toPromise();
      const transformedData = response.list.context.facetQueries
        .map(item => ({
          Mimetype: item.label,
          Qty: Number(item.count)
        }))
        .sort((a, b) => b.Qty - a.Qty);
      this.dataTable = transformedData;
    } catch (error) {
      console.error('Error fetching data', error);
    }
  }






  // ========================= Grid =========================
  libraryName: any;
  async onRecordClick(args: any) {
    const columnName = args.column.field;
    const libraryName = (args.rowData.LibraryName);
    this.libraryName = libraryName;

    if (columnName === 'detail') {
      await this.getMimeTypeFile();
      this.modalBtnClick_Detail();
    }

    if (columnName === 'static') {
      await this.fetchChartData();
      this.modalBtnClick();
    }

  }


  // ========================= Toolbar =========================
  dateRang: any;
  async onDateRangeChange(_event: any): Promise<void> {


    const date = (this.DateRange as DateRangePickerComponent).value as Date[];

    if (!date) {
      this.dateRang = null;
      await this.getLibraryReport();
      return;
    }

    const isoDates = date.map(d => {
      const localDate = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
      return localDate.toISOString().split('.')[0];
    });
    console.log('ISO Date Range: ', isoDates);
    const startDate = isoDates[0].split('T')[0];
    const endDate = isoDates[1].split('T')[0];

    console.log('Selected Date Range: ', startDate + ' - ' + endDate);
    this.dateRang = [startDate, endDate]

    await this.getLibraryReport();
  }

  exportToExcel() {
    this.openColumnSelectionDialog('EXCELEXPORT');
  }

  exportToCsv() {
    this.openColumnSelectionDialog('CSVEXPORT');
  }

  openColumnSelectionDialog(exportType: string): void {
    const selectedColumns = this.getSelectedColumns();
    if (exportType === 'EXCELEXPORT') {
      const excelExportProperties: ExcelExportProperties = {
        columns: selectedColumns
      };
      this.grid.excelExport(excelExportProperties);
      this.alertInfo('Export Excel สำเร็จ')
    } else if (exportType === 'CSVEXPORT') {
      const csvExportProperties: ExcelExportProperties = {
        columns: selectedColumns
      };
      this.grid.csvExport(csvExportProperties);
      this.alertInfo('Export CSV สำเร็จ')
    }
  }

  getSelectedColumns(): any[] {
    return [
      { field: 'No', headerText: 'No' },
      { field: 'LibraryName', headerText: 'Library Name' },
      { field: 'TotalFiles', headerText: 'Total Files' },
      { field: 'TotalSpace', headerText: 'TotalSpace(MB.)' }
    ];
  }

  // ========================= Alert =========================
  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }


  // ========================= Modal Chart =========================

  public modalBtnClick = (): void => {
    this.modalDialog.show(); // Show the modal dialog
  };

  public modalDlgClose = (): void => {
    this.modalButton.element.style.display = '';
  };

  public modalDlgOpen = (): void => {
    this.modalButton.element.style.display = 'none';
  };

  public dlgButtonClick = (): void => {
    this.modalDialog.hide(); // Hide the modal dialog
  };

  public open = (): void => {
    this.chart.refresh();
  };

  public overlayClick = (): void => {
    if (this.overlay) {
      if (this.overlay.checked) {
        this.modalDialog.hide(); // Hide the modal dialog on overlay click
      }
    }
  };

  public buttons: { [key: string]: ButtonModel }[] = [
    {
      click: this.dlgButtonClick.bind(this),
      buttonModel: { content: 'OK', isPrimary: true },
    },
  ];

  onAxisLabelRender(args: any): void {
    if (args.axis.name === 'primaryYAxis') {
      args.text = Number(args.value).toLocaleString('en-US');  // เพิ่ม comma ในตัวเลข
    }
  }

  onTooltipRender(args: any): void {
    if (args.series.type === 'Spline' && args.point.y < 0) {
      args.text = `ลดลง: ${args.point.y} ไฟล์`;
    } else if (args.series.type === 'Spline') {
      args.text = `เพิ่มขึ้น: ${args.point.y} ไฟล์`;
    }
  }

  // ========================= Modal Detail =========================

  public modalBtnClick_Detail = (): void => {
    this.modalDialog_Detail.show(); // Show the modal dialog
  };

  public modalDlgClose_Detail = (): void => {
    this.modalButton_Detail.element.style.display = '';
  };

  public modalDlgOpen_Detail = (): void => {
    this.modalButton_Detail.element.style.display = 'none';
  };

  public dlgButtonClick_Detail = (): void => {
    this.modalDialog_Detail.hide(); // Hide the modal dialog
  };

  public overlayClick_Detail = (): void => {
    if (this.overlay) {
      if (this.overlay.checked) {
        this.modalDialog_Detail.hide(); // Hide the modal dialog on overlay click
      }
    }
  };

  public buttons_Detail: { [key: string]: ButtonModel }[] = [
    {
      click: this.dlgButtonClick_Detail.bind(this),
      buttonModel: { content: 'OK', isPrimary: true },
    },
  ];

  toolbarClick(args: ClickEventArgs): void {
    const item = (args as any).item.properties;
    console.log(item);
    if (item && item.text === 'Excel Export') {
      (this.grid_Detail as GridComponent).excelExport();
      this.alertInfo('Export Excel สำเร็จ')
    }

    if (item && item.text === 'CSV Export') {
      (this.grid_Detail as GridComponent).csvExport();
      this.alertInfo('Export CSV สำเร็จ')
    }
  }



}
