import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ContextMenuItemModel, ContextMenuService, FilterService, GridModule, GroupService, PageService, PageSettingsModel, SortService, ToolbarItems, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AutoCompleteModule, CheckBoxSelectionService, DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ChipListModule } from '@syncfusion/ej2-angular-buttons'
import { UserLibService } from './user-library.service';
import { AddUserComponent } from './add-user/add-user.component';


import { Store } from '@ngrx/store';


import {
  AppStore,
  SnackbarErrorAction,
  SnackbarInfoAction,
} from '@alfresco/aca-shared/store';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { MatIconModule } from '@angular/material/icon';
import { AddGroupComponent } from './add-group/add-group.component';



interface DataGroup {
  memberGroups?: any;
  displayName: string;
  id: string;
}

@Component({
  selector: 'lib-create-user',
  standalone: true,
  imports: [
    CommonModule,
    TextBoxModule,
    GridModule,
    FormsModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ChipListModule,
    DropDownListModule,
    AutoCompleteModule,
    MultiSelectModule,
    TabModule,
    MatIconModule
  ],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    CheckBoxSelectionService,
    ToolbarService,
    ContextMenuService
  ],
  templateUrl: './user-library.component.html',
  styleUrls: ['./user-library.component.scss'],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UserLibraryComponent implements OnInit {

  reactForm: FormGroup;
  public pageSettings?: PageSettingsModel;
  public dataUser?: DataGroup[];
  public dataGroup?: DataGroup[];
  public toolbar?: ToolbarItems[];



  // CONTEXT MENU
  public contextMenuItems?: ContextMenuItemModel[];


  public siteId: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserLibraryComponent>,
    private userLibService: UserLibService,
    private dialogRef2: MatDialog,
    private store: Store<AppStore>) {

    this.siteId = data;

    console.log('this.siteId ', this.siteId);

  }

  async ngOnInit(): Promise<void> {
    await this.getListSiteMember();
    this.toolbar = ['Search'];
    this.contextMenuItems = [
      { text: 'Edit', target: '.e-content', id: 'update', iconCss: 'e-icons e-edit' },
      { text: 'Delete', target: '.e-content', id: 'delete', iconCss: 'e-icons e-trash' }
    ];
  }

  async getListSiteMember() {

    // User
    const rs = await this.userLibService.getListSiteMember(this.siteId).toPromise();
    const transformedData = rs.list.entries.map(entry => ({
      role: entry.entry.role,
      displayName: entry.entry.person.displayName,
      id: entry.entry.person.id,
      email: entry.entry.person.email,
      memberGroups: entry.entry.isMemberOfGroup
    }));
    this.dataUser = transformedData;
    console.log('this.dataUser ', this.dataUser);

    // Group
    const rsGroup = await this.userLibService.getListSiteGroupMember(this.siteId).toPromise();
    const transformedGroupData = rsGroup.map(entry => ({
      role: entry.role,
      displayName: entry.authority.displayName,
      id: entry.authority.fullName
    }));
    this.dataGroup = transformedGroupData;

  }

  addUser(): void {
    const dialogRef = this.dialogRef2.open(AddUserComponent, {
      data: null,
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        console.log('result ', result);
        for (const record of result.selectedRecords) {
          const userId = record.userId;
          const role = result.role;
          await (await this.userLibService.createSiteMember(this.siteId, userId, role)).subscribe(
            async data => {
              console.log('Create site member succesfuly..', data);
              this.alertInfo('Create site member succesfuly..');
              await this.getListSiteMember();
            },
            error => {
              console.error('Error fetching data', error);
              this.alertError('Error fetching data', error);
            }
          );
        }
      }
    });
  }


  addGroups(): void {
    const dialogRef = this.dialogRef2.open(AddGroupComponent, {
      data: null,
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        console.log('result ', result);
        for (const record of result.selectedRecords) {
          const groupId = record.userId;
          const role = result.role;
          await (await this.userLibService.createSiteGroupMember(this.siteId, groupId, role)).subscribe(
            async data => {
              console.log('Create site group member succesfuly..', data);
              this.alertInfo('Create site group member succesfuly..');
              await this.getListSiteMember();
            },
            error => {
              console.error('Error fetching data', error);
              this.alertError('Error fetching data', error);
            }
          );


        }
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }

  async contextMenuUserClick(args: any): Promise<void> {
    if (args.item.id === 'update') {
      const userId = (args.rowInfo.rowData.id);
      const role = (args.rowInfo.rowData.role);
      const data = {
        userId: userId,
        role: role
      }
      const dialogRef = this.dialogRef2.open(EditUserComponent, {
        data: data,
        width: '400px',
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        console.log('result ', result);
        if (result.role && result.userId) {
          await (await this.userLibService.updateSiteMember(this.siteId, result.userId, result.role)).subscribe(
            async data => {
              console.log('Update site member succesfuly..', data);
              this.alertInfo('Update site member succesfuly..');
              await this.getListSiteMember();
            },
            error => {
              console.error('Update fetching data', error);
              this.alertError('Update fetching data', error);
            }
          );
        }
      });
    } else if (args.item.id === 'delete') {

      const dialogRef = this.dialogRef2.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirmation',
          message: 'Are you sure you want to delete?'
        },
        minWidth: '250px'
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          const userId = (args.rowInfo.rowData.id);
          console.log('userId ', userId);
          await this.userLibService.deleteSiteMember(this.siteId, userId).subscribe(
            async data => {
              console.log('Delete site member succesfuly..', data);
              this.alertInfo('Delete site member succesfuly..');
              await this.getListSiteMember();
            },
            error => {
              console.error('Error fetching data', error);
              this.alertError('Error fetching data', error);
            }
          );
        }
      });







    }
  }


  async contextMenuGroupClick(args: any): Promise<void> {
    if (args.item.id === 'update') {
      const userId = (args.rowInfo.rowData.id);
      const role = (args.rowInfo.rowData.role);
      const data = {
        userId: userId,
        role: role
      }
      const dialogRef = this.dialogRef2.open(EditUserComponent, {
        data: data,
        width: '400px',
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        console.log('result ', result);
        if (result.role && result.userId) {
          await (await this.userLibService.updateSiteGroupMember(this.siteId, result.userId, result.role)).subscribe(
            async data => {
              console.log('Update site group-member succesfuly..', data);
              this.alertInfo('Update site group-member succesfuly..');
              await this.getListSiteMember();
            },
            error => {
              console.error('Update fetching data', error);
              this.alertError('Update fetching data', error);
            }
          );
        }
      });
    } else if (args.item.id === 'delete') {

      const dialogRef = this.dialogRef2.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirmation',
          message: 'Are you sure you want to delete?'
        },
        minWidth: '250px'
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          const userId = (args.rowInfo.rowData.id);
          await this.userLibService.deleteSiteGroupMember(this.siteId, userId).subscribe(
            async data => {
              console.log('Delete site group-member succesfuly..', data);
              this.alertInfo('Delete site group-member succesfuly..');
              await this.getListSiteMember();
            },
            error => {
              console.error('Error fetching data', error);
              this.alertError('Error fetching data', error);
            }
          );
        }
      });







    }
  }



}
