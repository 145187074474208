import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxJsonViewerModule } from 'ngx-json-viewer';


@Component({
  standalone: true,
  imports: [MatDialogModule , NgxJsonViewerModule],
  selector: 'audit-detail-dialog',
  templateUrl: 'audit-detail.component.html'
})
export class AuditDetailDialog implements OnInit {
  ngOnInit(): void {}
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
