import { PreviewContractService } from './preview-contract.service';
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  LinkAnnotationService, BookmarkViewService, MagnificationService, ThumbnailViewService,
  ToolbarService,
  NavigationService, AnnotationService, TextSearchService, TextSelectionService,
  PrintService, FormDesignerService, FormFieldsService,
  PdfViewerModule,
  // StampSettings,
  // DynamicStampItem,
  LoadEventArgs,
  CustomStampSettings
} from '@syncfusion/ej2-angular-pdfviewer';

import { ContractManagementService } from '../services/contract-management.service';
import { MenuModule, ToolbarModule } from '@syncfusion/ej2-angular-navigations';
// import { AppConfigService } from '@alfresco/adf-core';

import { CommonModule } from '@angular/common';
import { PeopleContentService } from '@alfresco/adf-content-services';
// import { DataConfigService } from '../services/data-config.service';
import moment from 'moment';
import { WatermarkModule } from '../watermark/watermark.module';


import {
  createSpinner,
  showSpinner,
  hideSpinner,
} from '@syncfusion/ej2-popups';
import { AppConfigService } from '@alfresco/adf-core';

import {
  AppStore,
} from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';

import { SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';

@Component({
  standalone: true,
  imports: [CommonModule, PdfViewerModule, MenuModule, ToolbarModule, WatermarkModule],
  selector: 'aca-contract-view',
  templateUrl: './preview-contract.component.html',
  styleUrls: ['./preview-contract.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line no-sparse-arrays
  providers: [
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService
    , NavigationService,
    AnnotationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
    FormDesignerService,
    FormFieldsService]
})


export class PreviewContractComponent implements OnInit {
  public document = '';

  nodeId: string;
  toolbarSettings: any;

  homeText = '';

  // Watermark
  lineOne = '';
  lineTwo = '';

  lineOneStyle = 'color: black; opacity: 0.3; font-size: 17px';
  lineTwoStyle = 'color: black; opacity: 0.3; font-size: 17px';
  defaultStyle =
    'white-space: nowrap;text-overflow: ellipsis;overflow: hidden;';


  deleyTimeout: any;


  _isNotPdf: any;

  constructor(
    private route: ActivatedRoute,
    private contractManagementService: ContractManagementService,
    private previewContractService: PreviewContractService,
    private peopleContentService: PeopleContentService,
    private appConfig: AppConfigService,
    private store: Store<AppStore>,
  ) {
    this.toolbarSettings = this.previewContractService.getToolbarSetting();
  }

  async ngOnInit(): Promise<void> {

    createSpinner({
      target: document.getElementById('spinner'),
      width: '70px',
      label: 'Loading.',
    });

    this.deleyTimeout = this.appConfig.get<boolean | any>('deleyTimeoutWebviewer.value');
    if (!this.deleyTimeout) {
      this.deleyTimeout = 3000;
    }

    this.route.queryParams.subscribe(params => {
      this.nodeId = params['param'];
    });

    this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {
      this.lineOne = userInfo.firstName + ' ' + (userInfo.lastName ? userInfo.lastName : '');
      const currentDate = moment();
      const formattedDate = currentDate.format('DD-MM-YYYY HH:mm');
      this.lineTwo = formattedDate;
    });


    const renId = 'pdf';
    const isNotPdf = await this.previewContractService.getNonPdfActive(this.nodeId);
    this._isNotPdf = isNotPdf;
    console.log('_isNotPdf ', this._isNotPdf);
    if (isNotPdf) {
      showSpinner(document.getElementById('spinner'));

      let statusCheck = await this.previewContractService.getCheckRenditions(this.nodeId, renId);
      if (statusCheck) {
        this.loadRenditionContent(renId);
        return;
      }

      // Create rendition once
      const statusCreate = await this.previewContractService.createRenditionData(this.nodeId, renId);
      if (!statusCreate) {
        this.alertError('Failed to create rendition', statusCreate);
        return;
      }

      // Retry checks with delay
      const maxRetriesStatusCheck = 10;
      for (let attempt = 0; attempt < maxRetriesStatusCheck; attempt++) {
        statusCheck = await this.previewContractService.getRetryCheckRenditions(this.nodeId, renId);
        if (statusCheck) {
          this.loadRenditionContent(renId);
          return;
        }
        await this.sleep(5000); // 5 second delay
      }
    } else {
      this.loadDocument();
    }

  }

  // async loadDocFromAPI() {
  //   Swal.fire({
  //     title: 'Loading',
  //     width: 300,
  //     html: 'LOADING...',
  //     allowEscapeKey: false,
  //     allowOutsideClick: false,
  //     timerProgressBar: true,
  //     didOpen: async () => {
  //       Swal.showLoading();
  //       try {
  //         const base64Data = await this.contractManagementService.downloadNodes(this.nodeId);
  //         const viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
  //         viewer.dataBind();
  //         viewer.load(base64Data);

  //         Swal.close();
  //       } catch (error) {
  //         console.error('Error:', error);
  //         Swal.close();
  //       }
  //     }
  //   });
  // }

  private sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async loadDocument() {
    console.log('# loadDocument');
    showSpinner(document.getElementById('spinner'));
    return new Promise(() => {
      setTimeout(async () => {
        const base64Data = await this.previewContractService.downloadNode(this.nodeId);
        if (base64Data) {
          hideSpinner(document.getElementById('spinner'));
        }
        const viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
        viewer.load(base64Data);
      }, this.deleyTimeout);
    });
  }

  private async loadRenditionContent(renId: any) {
    await setTimeout(async () => {
      const base64Data = await this.previewContractService.getRenditionContent(this.nodeId, renId);
      if (base64Data) {
        hideSpinner(document.getElementById('spinner'));
      }
      // eslint-disable-next-line no-var
      var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
      viewer.load(base64Data);
    }, this.deleyTimeout);
  }

  documentLoaded(event: LoadEventArgs): void {
    setTimeout(() => {
      const data = {
        'node_id': this.nodeId,
      }
      this.contractManagementService.getContractItemByNodeId(data).subscribe(response => {
        if (response) {
          response.forEach(item => {
            const contractStatusProcess = item.contract_status_process;
            const nodeName = item.node_name;
            this.homeText = nodeName;
            // eslint-disable-next-line no-var
            var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];

            const newNodeName = nodeName.replace(/\.(docx|doc)$/, '.pdf');
            viewer.downloadFileName = newNodeName;

            if (contractStatusProcess === 'Approved') {
              const eventData = event;
              const pageData = JSON.parse(eventData.pageData);
              const pageSizes = pageData.pageSizes;
              const page1Size = pageSizes['0'];
              const pageWidth = page1Size.width;
              const pageHeight = page1Size.height;
              // Convert pixels to IPoint
              const DPI = 96;
              // Convert calculatedHeight to IPoint
              const pageHeight_IPoint = pageHeight / (DPI / 72);
              const pageWidth_IPoint = pageWidth / (DPI / 72);
              const stampHeight = 60;
              const stampWidth = 120;
              const stampHeight_IPoint = stampHeight / (DPI / 72);
              // Convert 0.5 cm to points
              const cmToPoints = (cm) => (cm / 2.54) * 72;
              const offsetYFromBottomInPoints = cmToPoints(0.5);
              const offsetY = pageHeight_IPoint - offsetYFromBottomInPoints - stampHeight_IPoint;
              const offsetX = pageWidth_IPoint * 0.83 - stampWidth / (DPI / 72);
              viewer.annotation.addAnnotation('Stamp', {
                offset: { x: offsetX, y: offsetY },
                width: stampWidth,
                height: stampHeight,
                author: 'TEST',
                isLock: true,
                pageNumber: 1,
                customStamps: [
                  {
                    customStampName: 'Image',
                    customStampImageSource: this.previewContractService.getAppovedImage()
                  }
                ]
              } as CustomStampSettings);

            }
          });
        }
      }, error => {
        console.error('error filterData() :', error);
      });
    }, 500);
  }


  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }

}

