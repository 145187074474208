import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PeopleContentService } from '@alfresco/adf-content-services';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';



@Injectable({
  providedIn: 'root'
})
export class InternalShareService {
  environment: any;
  apiURL: any;
  apiURL_internalShare: any;
  headers: any;
  userName: any;



  constructor(private httpClient: HttpClient, private peopleContentService: PeopleContentService, @Inject(DOCUMENT) private document: Document
  ) {
    this.apiURL = '';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });

    this.apiURL_internalShare = '';
    if (this.getBaseUrl().startsWith('http://localhost')) {
      this.apiURL_internalShare = 'http://localhost:3803'
    }

  }

  getBaseUrl(): string {
    return this.document.location.origin;
  }

  userId: any;
  loadEcmUserInfo() {
    this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {
      this.userId = userInfo.id;
    });
  }

  getPeople(searchTerm: string, ticket: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.apiURL}/xxx/public/alfresco/versions/1/queries/people?term=${searchTerm}&skipCount=0&maxItems=100&alf_ticket=${ticket}`,
      { headers: this.headers }
    );
  }

  updateNode(nodeId: string, permissionsData: any, ticket: string): Observable<any> {
    return this.httpClient.put<any>(
      `${this.apiURL}/xxx/public/alfresco/versions/1/nodes/${nodeId}?include=permissions&alf_ticket=${ticket}`,
      permissionsData,
      { headers: this.headers }
    );
  }

  insertInternalShareData(data: any) {
    return this.httpClient.post(`${this.apiURL_internalShare}/internal-share/insert`, data, { headers: this.headers });
  }


}
