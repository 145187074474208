/* eslint-disable curly */
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import {
  EditService, FilterService, GridModule, PageService,
  SelectionSettingsModel, ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@alfresco/adf-content-services';

import { Store } from '@ngrx/store';


import {
  AppStore,
  SnackbarErrorAction,
  SnackbarInfoAction,
} from '@alfresco/aca-shared/store';




@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    GridModule,
    TextBoxModule,
    DropDownListModule,
    ButtonModule,
    DialogModule,
  ],
  templateUrl: './edit-user.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./edit-user.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [EditService, ToolbarService, PageService, FilterService],
  host: { class: 'aca-adduser' }
})
export class EditUserComponent implements OnInit {


  public selectionOptions?: SelectionSettingsModel;


  dataUserSelected: any;
  role: any;

  public dataRole: string[] = ['SitePreview', 'SiteCanDownloadWatermark','SiteConsumer', 'SiteContributor', 'SiteCollaborator', 'SiteManager'];

  constructor(
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public dataSelected: any,
    private store: Store<AppStore>
  ) {



    this.dataUserSelected = dataSelected.userId;
    this.role = dataSelected.role;
    this.selectRole = this.role;



  }

  async ngOnInit(): Promise<void> {
    console.log('# EditUserComponent ');

  }


  isCancel(): void {
    this.dialogRef.close();
  }


  public selectRole: string = '';
  isOk() {
    if (!this.selectRole) {
      this.alertError('Please select role', null)
      return;
    }

    const data = {
      userId: this.dataUserSelected,
      role: this.selectRole
    }
    this.dialogRef.close(data);
  }

  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }

}
