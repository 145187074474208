import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormValidators, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupAlfService } from '../../services/group-alf.service';

@Component({
  selector: 'lib-create-group',
  standalone: true,
  imports: [
    CommonModule,
    TextBoxModule,
    GridModule,
    FormsModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreateGroupComponent implements OnInit {

  reactForm: FormGroup;

  updateGroupId: any;
  updateGroupDisplay: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CreateGroupComponent>, private groupAlfService: GroupAlfService) {
    this.reactForm = new FormGroup({
      'identifier': new FormControl('', [FormValidators.required]),
      'displayName': new FormControl('', [FormValidators.required]),
    });

    if (data) {
      this.updateGroupId = data.id;
      this.updateGroupDisplay = data.displayName;
    }

  }

  ngOnInit(): void {
    this.reactForm.controls['identifier'].setValue(this.updateGroupId);
    this.reactForm.controls['displayName'].setValue(this.updateGroupDisplay);
  }

  async createGroup() {
    const identifier = this.identifier.value;
    const displayName = this.displayName.value;
    await (await this.groupAlfService.createGroup(identifier, displayName)).subscribe(
      data => {
        console.log('data', data);
        this.dialogRef.close(true);
      },
      error => {
        console.error('Error fetching data', error);
      }
    );

  }

  async onUpdate() {
    const identifier = this.identifier.value;
    const displayName = this.displayName.value;
    await (await this.groupAlfService.updateGroup(identifier, displayName)).subscribe(
      data => {
        console.log('data', data);
        this.dialogRef.close(true);
      },
      error => {
        console.error('Error fetching data', error);
      }
    );
  }



  onCancel(): void {
    this.dialogRef.close();
  }

  get identifier() { return this.reactForm.get('identifier'); }
  get displayName() { return this.reactForm.get('displayName'); }

}
