<div class="example-form">
  <div class="dialog-title">ADD-USERS</div>
  <div style="margin-bottom: 25px"></div>

  <mat-form-field class="example-full-width">
    <input matInput placeholder="Search for user to add" [formControl]="searchControl" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0 demo-table">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>SELECT</th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="handleCheckboxClick($event, row)" [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)" (change)="selection.toggle(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>NAME</th>
      <td mat-cell *matCellDef="let element">{{element.displayName}}</td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element">{{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>EMAIL</th>
      <td mat-cell *matCellDef="let element">{{element.email}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
 


</div>


<div mat-dialog-actions align="center">
  <button mat-raised-button color="primary" [disabled]="isValid()" (click)="isOk()">SELECT</button>
  <button mat-raised-button mat-dialog-close>CANCEL</button>
</div>

<div style="margin-bottom: 15px"></div>
