<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">Library Report</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>
  </div>

  <div class="aca-page-layout-content">
    <div class="main-content-lib">
      <!-- <div class="control-section"> -->
      <div id="modalTarget" #targetModal class="col-md-8 control-section">
        <!--  Grid -->
        <div class="grid-data-lib">
          <ejs-grid
            #grid
            [allowExcelExport]="true"
            width="100%"
            height="100%"
            [rowHeight]="30"
            [enableHover]="false"
            [allowResizing]="true"
            [allowSorting]="true"
            [sortSettings]="initialSort"
            allowPaging="true"
            [loadingIndicator]="loadingIndicator"
            [dataSource]="data"
            [pageSettings]="pageSettings"
            [toolbar]="toolbarOptions"
            [allowFiltering]="true"
            [filterSettings]="filterOptions"
            (recordClick)="onRecordClick($event)"
          >
            <e-columns>
              <e-column field="No" headerText="No" textAlign="Center" width="50" [allowFiltering]="false"></e-column>

              <e-column field="LibraryName" headerText="Library Name" width="110"></e-column>

              <e-column field="Title" headerText="Title" width="150"></e-column>
              <e-column field="Description" headerText="Description" width="120"></e-column>

              <e-column field="TotalFiles" headerText="Total Files" width="70" [allowFiltering]="false" format="N0"></e-column>
              <e-column field="TotalSpace" headerText="TotalSpace(MB.)" width="70" [allowFiltering]="false" format="N0"></e-column>

              <e-column field="detail" headerText="รายละเอียดประเภทไฟล์" width="85" textAlign="center" [allowFiltering]="false">
                <ng-template #template let-data>
                  <button mat-icon-button>
                    <mat-icon class="material-icons-outlined">open_in_new</mat-icon>
                  </button>
                </ng-template>
              </e-column>

              <e-column field="static" headerText="สถิติการเพิ่ม" width="85" textAlign="center" [allowFiltering]="false">
                <ng-template #template let-data>
                  <button mat-icon-button>
                    <mat-icon class="material-icons-outlined">insert_chart_outlined</mat-icon>
                  </button>
                </ng-template>
              </e-column>
            </e-columns>

            <ng-template #toolbarTemplate>
              <div class="toolbar-container">
                <ejs-daterangepicker
                  #daterangepicker
                  placeholder="Select a date range"
                  class="custom-daterangepicker"
                  (change)="onDateRangeChange($event)"
                ></ejs-daterangepicker>

                <button ejs-button (click)="exportToExcel()"><i class="fas fa-file-excel"></i> Excel Export</button>
                <button ejs-button (click)="exportToCsv()"><i class="fa fa-file-csv"></i> CSV Export</button>
              </div>
            </ng-template>
          </ejs-grid>
        </div>

        <!-- Modal Chart -->
        <ejs-dialog
          id="modalDialog"
          #modalDialog
          (open)="open()"
          (overlayClick)="overlayClick()"
          [isModal]="isModal"
          [header]="header"
          [target]="target"
          [width]="width"
          [buttons]="buttons"
          [animationSettings]="animationSettings"
          [visible]="false"
        >
          <!-- Chart -->
          <ng-template #content>
            <ejs-chart
              width="95%"
              height="50%"
              [tooltip]="tooltip"
              [useGroupingSeparator]="groupingSeparator"
              [primaryXAxis]="primaryXAxis"
              [primaryYAxis]="primaryYAxis"
              (axisLabelRender)="onAxisLabelRender($event)"
              (tooltipRender)="onTooltipRender($event)"
              #chart
            >
              <e-series-collection>
                <e-series [dataSource]="chartData" type="Line" xName="month" yName="qty" [marker]="marker"></e-series>
                <e-series [dataSource]="chartData" type="Spline" xName="month" yName="difference" [marker]="marker" fill="orange"></e-series>
              </e-series-collection>
            </ejs-chart>
          </ng-template>
        </ejs-dialog>

        <ejs-dialog
          id="modalDialog_Detail"
          #modalDialog_Detail
          (overlayClick)="overlayClick_Detail()"
          [isModal]="isModal_Detail"
          [header]="header_Detail"
          [target]="target_Detail"
          [width]="width_Detail"
          [buttons]="buttons_Detail"
          [animationSettings]="animationSettings_Detail"
          [visible]="false"
        >
          <ng-template #content>
            <ejs-grid
              [dataSource]="dataTable"
              [toolbar]="toolbar"
              (toolbarClick)="toolbarClick($event)"
              [allowExcelExport]="true"
              [allowSorting]="true"
              [allowResizing]="true"
              #grid_Detail
            >
              <e-columns>
                <e-column field="Mimetype" headerText="ประเภทไฟล์" textAlign="left" width="150"></e-column>
                <e-column field="Qty" headerText="จำนวนไฟล์" width="70" format="N0"></e-column>
              </e-columns>
            </ejs-grid>
          </ng-template>
        </ejs-dialog>
      </div>
      <!-- </div> -->
    </div>
  </div>
</aca-page-layout>
