/* eslint-disable @typescript-eslint/ban-types */
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TextAreaModule, TextBoxModule, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ButtonModule, ChipListModule } from '@syncfusion/ej2-angular-buttons';
import { BreadcrumbModule } from '@syncfusion/ej2-angular-navigations';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons'
import { EditService, FilterService, GridComponent, GridModule, PageService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { AddUserComponent } from '../workflow-config/add-user/add-user.component';
import { Subject } from 'rxjs';
import { Node, Site, SitePaging, SitePagingList } from '@alfresco/js-api';
import { AppConfigService, TranslationService } from '@alfresco/adf-core';
import { ContentNodeSelectorComponent, ContentNodeSelectorComponentData, PeopleContentService } from '@alfresco/adf-content-services';
import { ApprovalWorkflowService } from '../../service/approval-workflow.service';
import {
  AppStore,
  SnackbarErrorAction,
  SnackbarInfoAction,
} from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    MatDialogModule,
    DropDownListModule,
    DatePickerModule,
    TranslateModule,
    UploaderModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TextBoxModule,
    ButtonModule,
    BreadcrumbModule,
    ChipListModule,
    TextAreaModule,
    CommonModule,
    MatDividerModule,
    MatGridListModule,
    DropDownButtonModule,
    ButtonModule,
    GridModule
  ],
  providers: [PageService, SortService, FilterService, ToolbarService, EditService],
  selector: 'aca-adhoc-request-dialog',
  templateUrl: './adhoc-request-dialog.component.html',
  styleUrls: ['./adhoc-request-dialog.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdhocRequestDialogComponent implements OnInit {

  processDefinitionKey: any;
  public dataUser: any[] = [];
  @ViewChild('gridUser')
  public gridUser: GridComponent;
  public pageSettings = { pageSize: 10 };
  requestForm: FormGroup;
  listNodeUpload: any;
  listNodeUploadActive = false;
  userId: any;


  public dataPriority: { [key: string]: Object }[] = [];
  public fieldsPriority: Object = { text: 'priority_name', value: 'priority_id' };
  public textPriority = '';
  public selectedPriorityId: any;
  public selectedPriorityName: any;

  public _requestProcessData: any;
  public _requestProcessDataUser: any;
  public _requestProcessDataFile: any;
  public _dataTemp: any;


  constructor(
    private fb: FormBuilder,
    private dialogRefMain: MatDialogRef<AdhocRequestDialogComponent>,
    private dialogRefUser: MatDialog,
    private dialogRefSelectedFile: MatDialog,
    private translation: TranslationService,
    private appConfigService: AppConfigService,
    private peopleContentService: PeopleContentService,
    private workflowService: ApprovalWorkflowService,
    private store: Store<AppStore>,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {

    this.dataPriority = [
      { priority_id: '1', priority_name: 'Low' },
      { priority_id: '2', priority_name: 'Medium' },
      { priority_id: '3', priority_name: 'High' },
    ];

    this.requestForm = this.fb.group({
      subject: ['', Validators.required],
      priority: ['', Validators.required],
      dueDate: ['', Validators.required],
      description: ['', Validators.required],
    });
    this._dataTemp = _data;


  }

  async ngOnInit() {
    console.log('# AdhocRequestDialogComponent');


    this.processDefinitionKey = this.appConfigService.get('appoveWorkflow.processDefinitionKey');
    await this.loadEcmUserInfo();




    // Set Draft Value
    if (this._dataTemp) {

      this._requestProcessData = this._dataTemp.requestProcessData;
      this._requestProcessDataUser = this._dataTemp.requestProcessDataUser;
      this._requestProcessDataFile = this._dataTemp.requestProcessDataFile;


      const userApproval = this._requestProcessDataUser || [];
      this.dataUser = userApproval.map(item => ({
        listNo: item.listno,
        userId: item.user_id,
        displayName: item.user_display_name,
        email: item.user_email
      }));


      // Dropdown
      this.selectedPriorityId = this._requestProcessData.request_priority_id;



      // File
      if (this._requestProcessDataFile) {
        if (this._requestProcessDataFile.length > 0) {
          this.listNodeUploadActive = true;
          this.listNodeUpload = this._requestProcessDataFile;
        }
      }


    }


  }


  public async loadEcmUserInfo() {
    await this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {
      this.userId = userInfo.id;
    });
  }

  deleteUser() {
  }

  onSubmit() {
  }

  selectedRowData: any;
  onRowSelected(event: any) {
    this.selectedRowData = event.data;
  }

  // =================== Table User ===================
  addUser() {
    console.log('# addUser');
    const dialogRef = this.dialogRefUser.open(AddUserComponent, {
      data: this.dataUser,
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        result.forEach(row => {
          this.dataUser.push(row);
        });
        this.updateListNo();
        this.gridUser.refresh();
        console.log('Selected Records:', this.dataUser);
      }
    });
  }

  private updateListNo(): void {
    this.dataUser.forEach((item, index) => {
      item.listNo = index + 1;
    });
  }

  moveUp(currentRow: any): void {
    const currentIndex = this.dataUser.findIndex(item => item.listNo === currentRow.listNo);
    if (currentIndex > 0) {
      [this.dataUser[currentIndex - 1], this.dataUser[currentIndex]] =
        [this.dataUser[currentIndex], this.dataUser[currentIndex - 1]];
      this.updateListNo();
      this.gridUser.refresh();
    }
  }

  moveDown(currentRow: any): void {
    const currentIndex = this.dataUser.findIndex(item => item.listNo === currentRow.listNo);
    if (currentIndex < this.dataUser.length - 1) {
      [this.dataUser[currentIndex], this.dataUser[currentIndex + 1]] =
        [this.dataUser[currentIndex + 1], this.dataUser[currentIndex]];
      this.updateListNo();
      this.gridUser.refresh();
    }
  }

  remove(currentRow: any) {
    const listNo = currentRow.listNo;
    this.dataUser = this.dataUser.filter(user => user.listNo !== listNo);
    this.updateListNo();
    setTimeout(() => {
      this.gridUser.refresh();
    }, 1000);
  }

  isFirstRow(currentRow: any): boolean {
    const index = this.dataUser.findIndex(item => item.listNo === currentRow.listNo);
    return index === 0;
  }

  isLastRow(currentRow: any): boolean {
    const index = this.dataUser.findIndex(item => item.listNo === currentRow.listNo);
    return index === this.dataUser.length - 1;
  }

  // =================== Selector File ===================
  openSelectorDialog() {
    const select = new Subject<Node[]>();
    select.subscribe({
      complete: this.close.bind(this)
    });
    const customDropdown = new SitePaging({
      list: {
        entries: [
          {
            entry: {
              guid: '-my-',
              title: this.translation.instant('APP.BROWSE.PERSONAL.SIDENAV_LINK.LABEL')
            } as Site
          },
          {
            entry: {
              guid: '-mysites-',
              title: this.translation.instant('APP.BROWSE.LIBRARIES.MENU.MY_LIBRARIES.SIDENAV_LINK.LABEL')
            } as Site
          }
        ]
      } as SitePagingList
    });

    const data: ContentNodeSelectorComponentData = {
      selectionMode: 'multiple',
      title: 'Select file',
      dropdownHideMyFiles: true,
      currentFolderId: '-my-',
      dropdownSiteList: customDropdown,
      showSearch: true,
      showDropdownSiteList: true,
      select: select
    };

    this.dialogRefSelectedFile.open(
      ContentNodeSelectorComponent,
      {
        data, panelClass: 'adf-content-node-selector-dialog',
        width: '630px'
      }
    );

    data.select.subscribe((selections: Node[]) => {
      // Use or store selection...
      console.log('selections ', selections);

      const sectionData = selections.map((item, index) => ({
        node_id: item.id,
        node_name: item.name,
        node_path: '',
        mode_remark: '',
        listno: index + 1
      }));
      this.listNodeUpload = sectionData;
      this.listNodeUploadActive = true;

      console.log('listNodeUpload ', this.listNodeUpload);
    },
      (error) => {
        console.log('error ', error);
      },
      () => {
        this.dialogRefSelectedFile.closeAll();
      });
  }

  close() {
    this.dialogRefSelectedFile.closeAll();
  }

  onView(nodeId: any) {
    window.open(`#/Appoval-Workflow/Preview?param=${nodeId}`, '_blank');
  }

  onRemove(nodeId: any) {
    this.listNodeUpload = this.listNodeUpload.filter(
      node => node.node_id !== nodeId
    );
  }

  // ===================  Alert  ===================
  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }


  // ===================  Create  ===================
  public async onCreate() {
    try {


      if (this.dataUser.length <= 0 || this.dataUser === null) {
        this.alertError('Please select User', null);
        return;
      }

      const groupId = 0;
      const groupName = '';

      const subGroupId = 0
      const subGroupName = '';

      const configValueId = 0
      const configValueName = '';

      const priorityValueId = this.requestForm.get('priority').value;
      const priorityValueName = this.dataPriority.find(item => item.priority_id === priorityValueId)?.priority_name;
      const subjectValue = this.requestForm.get('subject').value;
      const dueDateValue = this.requestForm.get('dueDate').value;
      const descriptionValue = this.requestForm.get('description').value;
      const fields = [
        { value: subjectValue, message: 'Subject' },
        { value: priorityValueName, message: 'Priority' },
        { value: dueDateValue, message: 'Due Date' },
        { value: descriptionValue, message: 'Description' }
      ];
      const errorMessages = fields
        .filter(field => !field.value)
        .map(field => field.message);

      if (errorMessages.length > 0) {
        this.alertError(`Please input: ${errorMessages.join(', ')}`, null);
        return;
      }

      // 1. Create Process
      const transformedData = this.dataUser.map(item => ({
        no: parseInt(item.listNo, 10),
        assignee: item.userId
      }));

      const data = {
        'processDefinitionKey': `${this.processDefinitionKey}`,
        'variables': {
          'bpm_assignee': `${this.userId}`,
          'bpm_sendEMailNotifications': false,
          'bpm_workflowPriority': 0,
          'bpm_package': '',
          'bc_steps': JSON.stringify(transformedData)
        }
      }

      const response = await this.workflowService.createProcess(data).toPromise();
      console.log('Create Process', response);
      console.log('Create Process entry.id', response.entry.id);

      // 2. Insert Database to Backend

      let requestProcessDraftId = this._dataTemp?.requestProcessData?.request_process_draft_id
      if (!requestProcessDraftId) {
        requestProcessDraftId = null
      }
      const dataRequestProcess = {
        'request_process_draft_id': requestProcessDraftId,
        'workflow_id': response.entry.id,
        // 'workflow_id': 0,
        'workflow_def_key': this.processDefinitionKey,
        'workflow_start_user_id': this.userId,
        'group_id': groupId,
        'group_name': groupName,
        'sub_group_id': subGroupId,
        'sub_group_name': subGroupName,
        'config_manager_workflow_id': configValueId,
        'process_name': configValueName,
        'request_subject': subjectValue,
        'request_priority_id': priorityValueId,
        'request_priority_name': priorityValueName,
        'request_due_date': dueDateValue,
        'request_description': descriptionValue,
        'request_process_user': this.dataUser,
        'request_process_file': this.listNodeUpload,
        'remark': 'adhoc'
      }

      const resCreateRequestProcess = await this.workflowService.createRequestProcess(dataRequestProcess).toPromise();
      if (resCreateRequestProcess) {
        this.alertInfo('Create Request Process Success ');
        console.log('resCreateRequestProcess ', resCreateRequestProcess);
        this.dialogRefMain.close(true);
      }

    } catch (error) {
      console.log('error ', error);
      this.alertError('Create Request Process Error', error);
    }

  }

  // ===================  Save Draft  ===================

  public async onSaveDraft() {
    try {

      const groupId = 0;
      const groupName = '';
      const subGroupId = 0;
      const subGroupName = '';
      const configValueId = 0;
      const configValueName = '';
      const priorityValueId = this.requestForm.get('priority').value;
      const priorityValueName = this.dataPriority.find(item => item.priority_id === priorityValueId)?.priority_name;
      const subjectValue = this.requestForm.get('subject').value;
      const dueDateValue = this.requestForm.get('dueDate').value;
      const descriptionValue = this.requestForm.get('description').value;




      if (!this._dataTemp) {
        // 1. Insert Draft
        const dataRequestProcess = {
          'workflow_id': 0,
          'workflow_def_key': this.processDefinitionKey,
          'workflow_start_user_id': this.userId,
          'group_id': groupId,
          'group_name': groupName,
          'sub_group_id': subGroupId,
          'sub_group_name': subGroupName,
          'config_manager_workflow_id': configValueId,
          'process_name': configValueName,
          'request_subject': subjectValue,
          'request_priority_id': priorityValueId,
          'request_priority_name': priorityValueName,
          'request_due_date': dueDateValue,
          'request_description': descriptionValue,
          'request_process_user_draft': this.dataUser,
          'request_process_file_draft': this.listNodeUpload,
          'request_process_status': false,
          'remark': 'adhoc'
        }
        const resCreateRequestProcess = await this.workflowService.createRequestProcessDraft(dataRequestProcess).toPromise();
        if (resCreateRequestProcess) {
          this.alertInfo('Save Draft Request Process Success ');
          console.log('resCreateRequestProcess ', resCreateRequestProcess);
          this.dialogRefMain.close(true);
        }
      } else {
        // update request_process_draft
        // delete & insert request_process_user_draft
        // delete & insert request_process_file_draft
        const dataRequestProcess = {
          'request_process_draft_id': this._dataTemp.requestProcessData.request_process_draft_id,
          'workflow_id': 0,
          'workflow_def_key': this.processDefinitionKey,
          'workflow_start_user_id': this.userId,
          'group_id': groupId,
          'group_name': groupName,
          'sub_group_id': subGroupId,
          'sub_group_name': subGroupName,
          'config_manager_workflow_id': configValueId,
          'process_name': configValueName,
          'request_subject': subjectValue,
          'request_priority_id': priorityValueId,
          'request_priority_name': priorityValueName,
          'request_due_date': dueDateValue,
          'request_description': descriptionValue,
          'request_process_user_draft': this.dataUser,
          'request_process_file_draft': this.listNodeUpload,
          'request_process_status': false,
          'remark': 'adhoc'
        }
        const resUpdateRequestProcess = await this.workflowService.updateRequestProcessDraft(dataRequestProcess).toPromise();
        if (resUpdateRequestProcess) {
          this.alertInfo('Update Draft Request Process Success ');
          console.log('resCreateRequestProcess ', resUpdateRequestProcess);
          this.dialogRefMain.close(true);
        }

      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
      this.alertError('Save Draft Request Process Error', errorMessage);
    }
  }

}
