/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

// eslint-disable-next-line max-len
import {
  AppStore,
  DownloadNodeWithZipPassword,
  DownloadNodesAction,
  NodeActionTypes,
  NodeInfo,
  SnackbarInfoAction,
  getAppSelection,
  getCurrentVersion
} from '@alfresco/aca-shared/store';
// import { DownloadZipDialogComponent } from '@alfresco/adf-content-services';
import { NodeChildAssociationPaging, NodeEntry, NodesApi, Version } from '@alfresco/js-api';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { ContentApiService } from '@alfresco/aca-shared';
import { ContentUrlService } from '../../services/content-url.service';
import { NetworkService } from '../../components/audit/services/network.service';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { from, Observable } from 'rxjs';

import { DownloadZipDialogComponent2 } from '../../dialogs/download-zip/download-zip.dialog';
import { PeopleContentService } from '@alfresco/adf-content-services';
import { ContentManagementService } from '../../services/content-management.service';
import { DialogPasswordComponent } from '../../components/dialog-password/dialog-password.component';

@Injectable()
export class DownloadEffects {
  constructor(
    private store: Store<AppStore>,
    private actions$: Actions,
    private contentApi: ContentApiService,
    private dialog: MatDialog,
    private contentUrlService: ContentUrlService,
    private auditAPI: NetworkService,
    private apiService: AlfrescoApiService,
    private peopleContentService: PeopleContentService,
    private contentManagementService: ContentManagementService
  ) {}

  _nodesApi: NodesApi;
  get nodesApi(): NodesApi {
    this._nodesApi = this._nodesApi ?? new NodesApi(this.apiService.getInstance());
    return this._nodesApi;
  }

  downloadNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DownloadNodesAction>(NodeActionTypes.Download),
        map((action) => {
          console.log('action ', action);

          if (action.payload?.length > 0) {
            console.log('new condition 1');
            this.downloadNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.store
                    .select(getCurrentVersion)
                    .pipe(take(1))
                    .subscribe((version) => {
                      if (version) {
                        this.downloadFileVersion(selection.nodes[0].entry, version.entry);
                      } else {
                        console.log('new condition 2');
                        this.downloadNodes(selection.nodes, action.configuration?.focusedElementOnCloseSelector);
                      }
                    });
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  private downloadNodes(toDownload: Array<NodeEntry>, focusedElementSelector?: string) {
    // console.log('toDownload ', toDownload);

    // // Loop toDownload and create a list of nodes to download

    // const nodeData = toDownload.map((node) => {
    //   const { id, nodeId, name, isFile, isFolder } = node.entry as any;
    //   return {
    //     id: this.isSharedLinkPreview ? id : nodeId || id,
    //     name,
    //     isFile,
    //     isFolder
    //   };
    // });

    // console.log('nodeฏData ', nodeData);

    const nodes = toDownload.map((node) => {
      const { id, nodeId, name, isFile, isFolder } = node.entry as any;

      return {
        id: this.isSharedLinkPreview ? id : nodeId || id,
        name,
        isFile,
        isFolder
      };
    });

    if (!nodes || nodes.length === 0) {
      return;
    }

    this.auditAPI.createAuditShareStatsList(
      nodes.map((node) => node),
      'download'
    );

    if (nodes.length === 1) {
      this.downloadNode(nodes[0], focusedElementSelector);
    } else {
      this.downloadZip(nodes, focusedElementSelector);
    }
  }

  downloadNodeWithZipPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DownloadNodeWithZipPassword>(NodeActionTypes.DownloadNodeWithZipPassword),
        map(() => {
          console.log('# downloadNodeWithZipPassword$ ');
          this.store
            .select(getAppSelection)
            .pipe(take(1))
            .subscribe((selection) => {
              if (selection && !selection.isEmpty) {
                this.store
                  .select(getCurrentVersion)
                  .pipe(take(1))
                  .subscribe(() => {
                    // this.downloadNodes(selection.nodes, action.configuration?.focusedElementOnCloseSelector);
                    this.downloadNodesWithZipPassword(selection.nodes);
                  });
              }
            });
        })
      ),
    { dispatch: false }
  );

  private async downloadNodesWithZipPassword(toDownload: Array<NodeEntry>) {
    console.log('# downloadNodesWithZipPassword ');
    console.log('toDownload ', toDownload);

    const nodes = await Promise.all(
      toDownload.map(async (node) => {
        const { id, nodeId, name, isFile, isFolder } = node.entry as any;
        const nodeIdToUse = this.isSharedLinkPreview ? id : nodeId || id;
        let children = [];

        if (isFolder) {
          const data = await this.getNodeChildren(nodeIdToUse).toPromise();
          children = data.list.entries
            .filter((entry) => entry.entry.isFile)
            .map((entry) => ({
              id: entry.entry.id,
              name: entry.entry.name,
              isFile: entry.entry.isFile,
              isFolder: entry.entry.isFolder
            }));
        }

        return {
          id: nodeIdToUse,
          name,
          isFile,
          isFolder,
          children: isFolder ? children : null
        };
      })
    );

    const transformedNodes = nodes.map((node) => ({
      nodeId: node.id,
      nodeName: node.name,
      isFile: node.isFile,
      isFolder: node.isFolder,
      children: node.children
        ? node.children.map((child) => ({
            nodeId: child.id,
            nodeName: child.name,
            isFile: child.isFile,
            isFolder: child.isFolder
          }))
        : null
    }));

    const dialogRef = this.dialog.open(DialogPasswordComponent, {
      data: null,
      width: '400px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const bodyData = {
          password: result.password,
          fileName: result.fileName,
          nodeData: transformedNodes
        };
        this.contentManagementService.downloadNodesWithZipPassword(bodyData);
      }
    });
  }

  private downloadNode(node: NodeInfo, focusedElementSelector?: string) {
    if (node) {
      if (node.isFolder) {
        // this.downloadZip([node], focusedElementSelector);
        this.downloadZipCheckRole([node], focusedElementSelector);
      } else {
        this.downloadFile(node);
      }
    }
  }

  private downloadFile(node: NodeInfo) {
    if (node && !this.isSharedLinkPreview) {
      this.contentUrlService.getNodeContentUrl(node.id, true).subscribe((contentUrl) => {
        this.download(contentUrl, node.name);
      });
    }

    if (node && this.isSharedLinkPreview) {
      this.download(this.contentApi.getSharedLinkContent(node.id, false), node.name);
    }
  }

  private downloadFileVersion(node: NodeInfo, version: Version) {
    if (node && version) {
      this.contentUrlService.getVersionContentUrl(node.id, version.id, true).subscribe((contentUrl) => {
        this.download(contentUrl, node.name);
      });
    }
  }

  getNodeChildren(nodeId: string): Observable<NodeChildAssociationPaging> {
    const defaults = {
      include: ['isLocked', 'path', 'properties', 'allowableOperations', 'permissions']
    };
    const queryOptions = Object.assign(defaults);

    return from(this.nodesApi.listNodeChildren(nodeId, queryOptions));
  }

  async fetchAllNodeChildrenRecursively(nodes: any[]): Promise<{ allFile: any[]; allFolder: any[] }> {
    const allFile = [];
    const allFolder = [];

    const fetchChildren = async (id: string) => {
      const data = await this.getNodeChildren(id).toPromise();
      for (const entry of data.list.entries) {
        if (entry.entry.isFolder) {
          const folderEntry = {
            id: entry.entry.id,
            parentId: entry.entry.parentId,
            name: entry.entry.name,
            isFile: entry.entry.isFile,
            isFolder: entry.entry.isFolder,
            permissions: entry.entry.permissions
          };

          console.log('folderEntry ', folderEntry);

          const hasRestrictedPermission = folderEntry.permissions.locallySet.some(
            (permission) =>
              permission.authorityId === this.userId &&
              (permission.name === 'SiteCanDownloadWatermark' || permission.name === 'SitePreview' || permission.name === 'Preview')
          );

          if (!hasRestrictedPermission) {
            allFolder.push(folderEntry);
            await fetchChildren(entry.entry.id);
          }
        } else {
          const fileEntry = {
            id: entry.entry.id,
            parentId: entry.entry.parentId,
            name: entry.entry.name,
            isFile: entry.entry.isFile,
            isFolder: entry.entry.isFolder,
            permissions: entry.entry.permissions
          };

          allFile.push(fileEntry);
        }
      }
    };

    for (const node of nodes) {
      if (node.isFolder) {
        await fetchChildren(node.id);
      }
    }

    return { allFile, allFolder };
  }

  // async fetchChidenFile(nodes: any[]): Promise<{ allFile: any[] }> {
  //   const allFile = [];
  //   for (const node of nodes) {
  //     if (node.isFolder) {
  //       const data = await this.getNodeChildren(node.id).toPromise();
  //       for (const entry of data.list.entries) {
  //         if (!entry.entry.isFolder) {
  //           const hasRestrictedPermission = entry.entry.permissions.locallySet?.some(permission =>
  //             permission.authorityId === this.userId &&
  //             (permission.name === 'SiteCanDownloadWatermark' || permission.name === 'SitePreview' || permission.name === 'Preview')
  //           );
  //           if (!hasRestrictedPermission) {
  //             allFile.push(entry.entry.id);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return { allFile };
  // }

  async fetchChidenFile(nodes: any[]): Promise<{ allFile: any[] }> {
    const allFile = [];
    let pushCount = 0; // Initialize counter
    for (const node of nodes) {
      if (node.isFolder) {
        const data = await this.getNodeChildren(node.id).toPromise();

        for (const entry of data.list.entries) {
          if (!entry.entry.isFolder) {
            console.log('entry ', entry);
            const hasRestrictedPermission = entry.entry.permissions.locallySet?.some(
              (permission) =>
                permission.authorityId === this.userId &&
                (permission.name === 'SiteCanDownloadWatermark' ||
                  permission.name === 'SitePreview' ||
                  permission.name === 'Preview' ||
                  permission.name === 'CanDownloadWatermark')
            );
            if (!hasRestrictedPermission) {
              allFile.push(entry.entry.id);
              pushCount++; // Increment counter
              if (pushCount >= 50) {
                // Check if counter has reached 50
                break;
              }
            }
          }
        }
        if (pushCount >= 50) {
          // Check again to break outer loop
          break;
        }
      }
    }

    return { allFile };
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 5000 }));
  }

  userId: any;
  private async downloadZip(nodes: Array<NodeInfo>, focusedElementSelector?: string) {
    if (nodes && nodes.length > 0) {
      const nodeIds = nodes.map((node) => node.id);

      // await this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {
      //   this.userId = userInfo.id;
      // });

      // console.log('nodeIds ', nodeIds);
      // this.fetchAllNodeChildrenRecursively(nodes).then(data => {
      //   const nodeData = data;
      //   console.log('nodeData ', nodeData);
      //   if (nodeData.allFolder.length > 0) {
      //     console.log(' new condition ');
      //   } else {
      //     // ยังพบปัญหาอยุ่
      //     console.log(' old condition ');
      //   }

      // })

      this.dialog
        .open(DownloadZipDialogComponent2, {
          width: '600px',
          disableClose: true,
          data: {
            nodeIds
          }
        })
        .afterClosed()
        .subscribe(() => this.focusAfterClose(focusedElementSelector));
    }
  }

  private async downloadZipCheckRole(nodes: Array<NodeInfo>, focusedElementSelector?: string) {
    console.log('# downloadZipCheckRole');
    if (nodes && nodes.length > 0) {
      await this.peopleContentService.getCurrentUserInfo().subscribe((userInfo) => {
        this.userId = userInfo.id;
      });

      const nodeData = await this.fetchChidenFile(nodes);
      const nodeIds = nodeData.allFile;

      // eslint-disable-next-line max-len
      this.alertInfo(
        'การ Download ระบบสามารถ Download ได้สูงสุดที่จำนวน 50 File โดยจะดำเนินการ Download เฉพาะ File เท่านั้นไม่สามารถ Download Folder ได้ และหาก File ไหนท่านมีสิทธิ์ในระดับ Preview หรือ Download Watermark ท่านจะไม่ได้รับ File ดังกล่าว'
      );

      this.dialog
        .open(DownloadZipDialogComponent2, {
          width: '600px',
          disableClose: true,
          data: {
            nodeIds
          }
        })
        .afterClosed()
        .subscribe(() => this.focusAfterClose(focusedElementSelector));
    }
  }

  private download(url: string, fileName: string) {
    console.log('url ', url);
    if (url && fileName) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.download = fileName;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private get isSharedLinkPreview() {
    return location.href.includes('/preview/s/');
  }

  private focusAfterClose(focusedElementSelector: string): void {
    if (focusedElementSelector) {
      document.querySelector<HTMLElement>(focusedElementSelector)?.focus();
    }
  }
}
