<mat-card *ngIf="node" class="adf-content-metadata-card-view">
  <mat-card-content class="adf-content-metadata-card-view-content">
    <adf-content-metadata
      [displayDefaultProperties]="displayDefaultProperties"
      [expanded]="expanded"
      [node]="node"
      [displayEmpty]="displayEmpty"
      [readOnly]="!editable"
      [multi]="multi"
      [displayAspect]="displayAspect"
      [preset]="preset"
      [displayTags]="displayTags"
      [displayCategories]="displayCategories"
      [customPanels]="customPanels"
    >
    </adf-content-metadata>
  </mat-card-content>
  <mat-card-footer class="adf-content-metadata-card-footer adf-content-metadata-card-view-footer">
    <div>
      <button
        *ngIf="isEditAspectSupported()"
        mat-icon-button
        (click)="openAspectDialog()"
        [attr.title]="'CORE.METADATA.ACTIONS.EDIT_ASPECTS' | translate"
        [attr.aria-label]="'CORE.METADATA.ACCESSIBILITY.EDIT_ASPECTS' | translate"
        data-automation-id="meta-data-card-edit-aspect"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </mat-card-footer>
</mat-card>
