import { AlfrescoApiService } from '@alfresco/adf-core';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ViewerNodeService {

  constructor( private apiService: AlfrescoApiService) { }

  toolbarSettings = {
    showTooltip: true,
    showText: true,
    toolbarItems: [
      // "OpenOption",
      'UndoRedoTool',
      'PageNavigationTool',
      'MagnificationTool',
      'PanTool',
      'SelectionTool',
      'SubmitForm',
      'FreeTextAnnotationOption',
      'InkAnnotationOption',
      'ShapeAnnotationOption',
      'StampAnnotation',
      'SignatureOption',
      'SearchOption',
      'DownloadOption'
    ],
    annotationToolbarItems: [
      'HighlightTool',
      'UnderlineTool',
      'StrikethroughTool',
      'ColorEditTool',
      'OpacityEditTool',
      'AnnotationDeleteTool',
      'StampAnnotationTool',
      'HandWrittenSignatureTool',
      'InkAnnotationTool',
      'ShapeTool',
      'CalibrateTool',
      'StrokeColorEditTool',
      'ThicknessEditTool',
      'FreeTextAnnotationTool',
      'FontFamilyAnnotationTool',
      'FontSizeAnnotationTool',
      'FontStylesAnnotationTool',
      'FontAlignAnnotationTool',
      'FontColorAnnotationTool',
    ]
  };

  getToolbarSetting() {
    return this.toolbarSettings;
  }



  getPageWidth() {
    const pageWidth = 595;
    return pageWidth;
  }

  getPageHeight() {
    const pageHeight = 842;
    return pageHeight;
  }


  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }


  getNodes(nodeID: string): Observable<any> {
    const pathService = `/nodes/${nodeID}`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  async getNonPdfActive(nodeId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.getNodes(nodeId).subscribe({
        next: (data) => {
          const fileName = data.entry.name;

          const fileExtension = fileName.split('.').pop();

       

          const isNotPdf = fileExtension !== 'pdf';



          resolve(isNotPdf);
        },
        error: (error) => {
          console.log('error getMimeType: ', error);
          reject(false);
        }
      });
    });
  }

  getRenditionsInfo(nodeId: string, renId: string): Observable<any> {
    const pathService = `/nodes/${nodeId}/renditions/${renId}`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  async getCheckRenditions(nodeId: string, renId: string ,maxRetries: any ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {

      let attempts = 0;
      const attemptGetRenditionsInfo = () => {
        attempts++;
        this.getRenditionsInfo(nodeId, renId).subscribe({
          next: (data) => {
            const status = data.entry.status;
            console.log('getCheckRenditions status: ', status);
            if (status === 'CREATED') {
              resolve(true);
            } else if (attempts < maxRetries) {
              setTimeout(() => {
                attemptGetRenditionsInfo(); // Retry after 3 seconds
              }, 1000);
            } else {
              resolve(false); // Resolve false after max retries
            }
          },
          error: (error) => {
            console.log('error getMimeType: ', error);
            reject(false);
          }
        });
      };
      attemptGetRenditionsInfo(); // Initial attempt
    });
  }

  createRendition(nodeId: string, renId: string): Observable<any> {
    const pathService = `/nodes/${nodeId}/renditions`
    const httpMethod = 'POST';
    const body = { 'id': renId }
    return from(this.publicApiCall(
      pathService,
      httpMethod,
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  async createRenditionData(nodeId: string, renId: string): Promise<boolean> {

    return new Promise<boolean>((resolve, reject) => {
      const attemptCreateRendition = () => {
        this.createRendition(nodeId, renId).subscribe({
          next: (data) => {
            if (data.status === 202) {
              console.log('createRenditionData success:', data);
              resolve(true);
            } else {
              console.log('createRenditionData acepted');
              resolve(true);
            }
          },
          error: (error) => {
            if ([400, 401, 403, 404, 501].includes(error.status)) {
              console.error('Error occurred with status', error.status, ':', error.error);
              reject(error);
            } else if (error.status === 409) {
              console.error('All renditions requested already exist:', error.error);
              resolve(true);
            } else {
              console.error('Error occurred:', error);
              setTimeout(() => {
                attemptCreateRendition(); // Retry after 3 seconds
              }, 3000);
            }
          }
        });
      };
      attemptCreateRendition(); // Initial attempt
    });
  }


  async getRetryCheckRenditions(nodeId: string, renId: string ,maxRetries: any ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {

      let attempts = 0;
      const attemptGetRenditionsInfo = () => {
        attempts++;
        this.getRenditionsInfo(nodeId, renId).subscribe({
          next: (data) => {
            const status = data.entry.status;
            console.log('getRetryCheckRenditions status: ', status);
            if (status === 'CREATED') {
              resolve(true);
            } else if (attempts < maxRetries) {
              setTimeout(() => {
                attemptGetRenditionsInfo(); // Retry after 1 seconds
              }, 1000);
            } else {
              resolve(false); // Resolve false after max retries
            }
          },
          error: (error) => {
            console.log('error getMimeType: ', error);
            reject(false);
          }
        });
      };
      attemptGetRenditionsInfo(); // Initial attempt
    });
  }


  getRenditionContent(nodeId: string, renId: string): Promise<any> {
    const pathService = `/nodes/${nodeId}/renditions/${renId}/content?attachment=true&placeholder=false`;
    const httpMethod = 'GET';
    return this.publicApiCall(pathService, httpMethod, [null, null, null, null, null, ['application/json'], ['blob'], null, null, 'blob'])
      .then((response: any) => {
        if (response instanceof Blob) {
          return this.blobToBase64(response);
        } else {
          return Promise.reject(new Error('Response is not of type Blob'));
        }
      });
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        const base64Content = base64String.split(';base64,')[1];
        resolve(base64Content);
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(blob);
    });
  }


  downloadNode(nodeId: string): Promise<any> {
    const pathService = `/nodes/${nodeId}/content?attachment=true`;
    const httpMethod = 'GET';
    return this.publicApiCall(pathService, httpMethod, [null, null, null, null, null, ['application/json'], ['blob'], null, null, 'blob'])
      .then((response: any) => {
        if (response instanceof Blob) {
          return this.blobToBase64Edit(response);
        } else {
          return Promise.reject(new Error('Response is not of type Blob'));
        }
      });
  }

  private async blobToBase64Edit(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        resolve(base64String);
      };

      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Failed to read the blob.'));
      };

      reader.readAsDataURL(blob);
    });
  }

}

