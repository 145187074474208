import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormValidators, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ContextMenuItemModel, FilterService, GridModule, GroupService, PageService, PageSettingsModel, SortService, ToolbarItems, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, ClickEventArgs } from '@syncfusion/ej2-angular-buttons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserAlfService } from '../../services/user-alf.service';
import { CheckBoxSelectionService } from '@syncfusion/ej2-angular-dropdowns';
import { GroupAlfService } from '../../services/group-alf.service';
import { ChipListModule } from '@syncfusion/ej2-angular-buttons'

import {
  AppStore,
  SnackbarErrorAction,
  SnackbarInfoAction,
} from '@alfresco/aca-shared/store';

import { Store } from '@ngrx/store';

interface DataGroup {
  isRoot: boolean;
  displayName: string;
  id: string;
}

@Component({
  selector: 'lib-create-user',
  standalone: true,
  imports: [
    CommonModule,
    TextBoxModule,
    GridModule,
    FormsModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ChipListModule
  ],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    CheckBoxSelectionService,
    ToolbarService,
  ],
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreateUserComponent implements OnInit {

  reactForm: FormGroup;
  public pageSettings?: PageSettingsModel;
  public dataGroup?: DataGroup[];
  public toolbar?: ToolbarItems[];



  // CONTEXT MENU
  public contextMenuItems?: ContextMenuItemModel[];


  public updateUserId: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CreateUserComponent>, private userAlfService: UserAlfService, private groupAlfService: GroupAlfService, private store: Store<AppStore>,) {


    this.updateUserId = data;

    this.reactForm = new FormGroup({
      'firstName': new FormControl('', [FormValidators.required]),
      'lastName': new FormControl('', [FormValidators.required]),
      'email': new FormControl('', [FormValidators.required]),
      'userId': new FormControl('', [FormValidators.required]),
      'password': new FormControl('', this.updateUserId ? [] : [FormValidators.required]),
      'vertifyPassword': new FormControl('', this.updateUserId ? [] : [FormValidators.required])
    });


  }

  async ngOnInit(): Promise<void> {
    await this.getUser();
    await this.getGroup();
    this.toolbar = ['Search'];
    this.contextMenuItems = [
      { text: 'Edit', target: '.e-content', id: 'update', iconCss: 'e-icons e-edit' },
      { text: 'Delete', target: '.e-content', id: 'delete', iconCss: 'e-icons e-trash' }
    ];

  }

  async getGroup() {
    const resultGroups = await this.groupAlfService.getGroups().toPromise();
    const formattedEntry = resultGroups.list.entries
      .map(entry => ({
        isRoot: entry.entry.isRoot,
        displayName: entry.entry.displayName,
        id: entry.entry.id
      }));
    this.dataGroup = formattedEntry;
    this.pageSettings = { pageSize: 50 };
  }


  groupDefault: any;
  async getUser() {
    if (this.updateUserId) {
      // Get User
      const resultUser = await this.groupAlfService.getUserById(this.updateUserId).toPromise();
      const firstName = resultUser.entry.firstName;
      const lastName = resultUser.entry.lastName;
      const email = resultUser.entry.email;
      const userId = resultUser.entry.id;

      this.reactForm.controls['firstName'].setValue(firstName);
      this.reactForm.controls['lastName'].setValue(lastName);
      this.reactForm.controls['email'].setValue(email);
      this.reactForm.controls['userId'].setValue(userId);


      // Get Group
      const resultGroup = await this.groupAlfService.getGroupById(this.updateUserId).toPromise();
      console.log('resultGroup ', resultGroup);

      this.chips = resultGroup.list.entries
        .filter(item => item.entry.isRoot && item.entry.displayName)
        .map(item => item.entry.displayName);

      this.groupDefault = resultGroup.list.entries
        .filter(item => item.entry.isRoot && item.entry.id)
        .map(item => item.entry.id);

    }
  }

  async createUser() {
    console.log('this.reactForm ', this.reactForm);

    if (this.reactForm.invalid) {
      Object.keys(this.reactForm.controls).forEach(key => {
        if (this.reactForm.controls[key].value === '' || this.reactForm.controls[key].value == null) {
          console.log(`Field ${key} is not filled`);
        }
      });
      return;
    }

    if (this.password.value !== this.vertifyPassword.value) {
      this.alertError('Password and Verify Password must be the same', null);
      return;
    }


    const resultGroup = this.chips
      .filter(chipName =>
        this.dataGroup.some((group: DataGroup) => group.displayName === chipName)
      )
      .map(chipName => {
        const group = this.dataGroup.find((group: DataGroup) => group.displayName === chipName);
        return group ? group.id : null;
      })
      .filter(id => id !== null); // Remove any null values


    const body = {
      id: this.userId.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      displayName: this.firstName.value + ' ' + this.lastName.value,
      email: this.email.value,
      password: this.password.value,
    }

    console.log('this.chips ', this.chips);
    console.log('this.dataGroup ', this.dataGroup);

    await (await this.userAlfService.createUser(body)).subscribe(
      async () => {
        for (const groupId of resultGroup) {
          await this.userAlfService.addUserToGroup(body.id, groupId).subscribe(
            groupData => {
              console.log('groupData', groupData);
            },
            groupError => {
              console.error('Group Error', groupError);
            }
          );
        }
        this.alertInfo('User created successfully');
        this.dialogRef.close(true);
      },
      error => {
        console.error('Error', error);
        this.alertError('Error', error)
      }
    );
  }


  async onUpdate() {


    // Update User
    // Update Group
    // 1. ตรวจสอบว่า group เท่าของเดิมไหม
    // 2. ถ้าไม่เท่ากัน ให้ลบ group 
    // 3. ถ้าไม่มีให้ add group ใหม่

    const body: {
      // id: any;
      firstName: any;
      lastName: any;
      displayName: string;
      email: any;
      password?: any;
    } = {
      // id: this.userId.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      displayName: this.firstName.value + ' ' + this.lastName.value,
      email: this.email.value,
    };

    if (this.password.value) {
      body.password = this.password.value;
    }

    const resultGroup = this.chips
      .filter(chipName =>
        this.dataGroup.some((group: DataGroup) => group.displayName === chipName)
      )
      .map(chipName => {
        const group = this.dataGroup.find((group: DataGroup) => group.displayName === chipName);
        return group ? group.id : null;
      })
      .filter(id => id !== null); // Remove any null values

    console.log('resultGroup ', resultGroup);

    await (await this.userAlfService.updateUser(body, this.updateUserId)).subscribe(
      async () => {

        const groupNow = resultGroup;
        const groupDefault = this.groupDefault;

        console.log('groupNow ', groupNow);
        console.log('groupDefault ', groupDefault);

        // ลบ group เก่าออก
        const differenceDefaultToNow = groupDefault.filter(item => !groupNow.includes(item));
        // เพิ่ม group ใหม่  
        const differenceNowToDefault = groupNow.filter(item => !groupDefault.includes(item));

        console.log('ลบ group เก่าออก: ', differenceDefaultToNow);
        console.log('เพิ่ม group ใหม่: ', differenceNowToDefault);

        if (differenceDefaultToNow.length > 0) {
          for (const group of differenceDefaultToNow) {
            await this.groupAlfService.deleteGroupMemeber(group, this.updateUserId).subscribe(
              groupData => {
                console.log('remove ', groupData);
              },
              groupError => {
                console.error('Group Error', groupError);
              }
            );
          }
        }

        if (differenceNowToDefault.length > 0) {
          for (const group of differenceNowToDefault) {
            await this.userAlfService.addUserToGroup(this.updateUserId, group).subscribe(
              groupData => {
                console.log('add ', groupData);
              },
              groupError => {
                console.error('Group Error', groupError);
              }
            );
          }
        }

        this.alertInfo('User update successfully');
        this.dialogRef.close(true);

      },
      error => {
        console.error('Error', error);
        this.alertError('Error', error)
      }
    );


  }

  onCancel(): void {
    this.dialogRef.close();
  }

  chips: string[] = [];
  addChip(displayName: string) {
    if (!this.chips.includes(displayName)) {
      this.chips.push(displayName);
    } else {
      this.alertError(`Chip with displayName "${displayName}" already exists.`, null);
    }
  }

  removeChip(e: ClickEventArgs) {
    const index = this
      .chips.indexOf(e.text);
    if (index >= 0) {
      this.chips.splice(index, 1);
    }
  }

  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }

  get firstName() { return this.reactForm.get('firstName'); }
  get lastName() { return this.reactForm.get('lastName'); }
  get email() { return this.reactForm.get('email'); }
  get userId() { return this.reactForm.get('userId'); }
  get password() { return this.reactForm.get('password'); }
  get vertifyPassword() { return this.reactForm.get('vertifyPassword'); }

}
