/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2019 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { AppConfigService } from '@alfresco/adf-core';
import { PageComponent } from '@alfresco/aca-shared';

import { PageLayoutComponent } from '@alfresco/aca-shared';
import { MatTableModule } from '@angular/material/table';
import { UserGuideListComponent } from './user-guide-list/user-guide-list.component';
// import { ContentManagementService } from '../../services/content-management.service';
// import { AppExtensionService } from '@alfresco/adf-extensions';
// import { AppStore } from '@alfresco/aca-shared/store';
// import { Store } from '@ngrx/store';

@Component({
  standalone: true,
  imports: [PageLayoutComponent, MatTableModule, UserGuideListComponent],
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-user-guide' }
})
export class UserGuideComponent extends PageComponent implements OnInit {
  userGuideEntries: Array<{ name: string; description: string; url: string }>;

  constructor(private config: AppConfigService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const userGuide = this.config.get<Array<any>>('user-guide');
    this.userGuideEntries = userGuide.map((o) => {
      return {
        name: o.name,
        description: o.description,
        url: o.url
      };
    });
  }
}
