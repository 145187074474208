
/* eslint-disable @typescript-eslint/ban-types */
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { ContextActionsDirective, PageLayoutComponent, PaginationDirective, ToolbarComponent } from '@alfresco/aca-shared';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EditService, FilterService, GridComponent, GridModule, PageService, RowSelectEventArgs, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';


import { TextBoxComponent, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListComponent, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { AddUserComponent } from '../add-user/add-user.component';
import { ApprovalWorkflowService } from '../../../service/approval-workflow.service';

interface Group {
  group_id: string;
  group_name: string;
}

interface SubGroup {
  sub_group_id: string;
  sub_group_name: string;
  group_id: string;
  group_name: string;
}


@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ContextActionsDirective,
    PaginationDirective,
    PageLayoutComponent,
    ToolbarComponent,
    TabModule,
    MatIconModule,
    MatButtonModule,
    GridModule,
    TextBoxModule,
    DropDownListModule,
    ButtonModule,
    DialogModule,
    MatDialogModule
  ],
  providers: [PageService, SortService, FilterService, ToolbarService, EditService],
  selector: 'app-manager-workflow-dialog',
  templateUrl: './manager-workflow-dialog.component.html',
  styleUrls: ['./manager-workflow-dialog.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ManagerWorkflowDialogComponent implements OnInit {



  public pageSettings: Object;

  public data2: object[];
  public listSubGroup: string[] = ['Snooker', 'Tennis', 'Cricket', 'Football', 'Rugby'];

  // STATUS
  updateActive = false;

  defaultGroupId = ''
  defaultSubGroupId = ''
  defaultProcessName = ''
  defaultListUserApproval: any;

  dataTemps: any;

  constructor(@Inject(MAT_DIALOG_DATA) public dataTemp: any,
    public dialogRef: MatDialogRef<ManagerWorkflowDialogComponent>,
    private dialogRef2: MatDialog,
    private workflowService: ApprovalWorkflowService) {
    if (dataTemp) {
      this.dataTemps = dataTemp;
      this.updateActive = true;
    }
  }

  @ViewChild('gridUser')
  public gridUser: GridComponent;

  public data: any[] = [];



  // DROPDOWN
  public dataGroup: { [key: string]: Object }[] = [];
  public fieldsGroup: Object = { text: 'group_name', value: 'group_id' };
  public textGroup = '';

  public dataSubGroup: { [key: string]: Object }[] = [];
  public fieldsSubGroup: Object = { text: 'sub_group_name', value: 'sub_group_id' };
  public textSubGroup = '';

  @ViewChild('droupDownGroup') droupDownGroup: DropDownListComponent;
  @ViewChild('droupDownSubGroup') droupDownSubGroup: DropDownListComponent;
  @ViewChild('textProcessName') textProcessName: TextBoxComponent;

  public selectedGroup: any = null;
  public selectedSubGroup: any = null;
  public processName = '';

  isCreateDisabled(): boolean {
    if (this.loadFormSuccess) {
      return !this.droupDownGroup.value || !this.droupDownSubGroup.value || this.data.length === 0 || !this.textProcessName.value
    } else {
      return false;
    }
  }


  loadFormSuccess = false;
  async ngOnInit() {
    console.log('# ManagerWorkflowDialogComponent');
    this.pageSettings = { pageSize: 10 };

    if (this.updateActive) {
      await this.onDefault();
    } else {
      await this.getGroup();
    }

    this.loadFormSuccess = true;

  }



  async onDefault() {

    await this.getGroup();
    await this.getSubGroup(this.dataTemps.groupId);

    this.defaultGroupId = this.dataTemps.groupId
    this.defaultSubGroupId = this.dataTemps.subGroupId
    this.defaultProcessName = this.dataTemps.processName

    try {
      const response = await this.workflowService.getConfigWorkflowUser(this.dataTemps.configWorkflowId).toPromise();

      const data = response.data.map(user => ({
        userId: user.user_id,
        displayName: user.display_name,
        email: user.email,
        listNo: parseInt(user.listno, 10)
      }));
      this.data = data;
    } catch (error) {
      console.log('error ', error);
    }

  }


  async onCreate(): Promise<void> {
    console.log('# onCreate');
    try {

      const data = {
        'group_id': this.selectedGroup.group_id,
        'group_name': this.selectedGroup.group_name,
        'sub_group_id': this.selectedSubGroup.sub_group_id,
        'sub_group_name': this.selectedSubGroup.sub_group_name,
        'process_name': this.textProcessName.value,
        'config_workflow_user': this.data
      }


      if (this.updateActive) {
        // UPDATE
        // const data = {
        //   'group_id': this.selectedGroup.group_id,
        //   'group_name': this.selectedGroup.group_name,
        //   'sub_group_id': this.selectedSubGroup.sub_group_id,
        //   'sub_group_name': this.selectedSubGroup.sub_group_name,
        //   'process_name': this.textProcessName.value,
        //   'config_workflow_user': this.data
        // }
        const response = await this.workflowService.updateConfigWorkflow(data, this.dataTemps.configWorkflowId).toPromise();
        if (response) {
          this.dialogRef.close(true);
        }
      } else {
        // CREATE
        const response = await this.workflowService.createConfigWorkflow(data).toPromise();
        if (response) {
          this.dialogRef.close(true);
        }
      }
    } catch (error) {
      console.log('error', error);
      alert('Error บันทึกไม่สำเร็จ');
    }
  }

  onCancel() {
    this.dialogRef.close();
  }


  isFirstRow(currentRow: any): boolean {
    const index = this.data.findIndex(item => item.userId === currentRow.userId);
    return index === 0;
  }

  isLastRow(currentRow: any): boolean {
    const index = this.data.findIndex(item => item.userId === currentRow.userId);
    return index === this.data.length - 1;
  }

  moveUp(currentRow: any): void {
    const currentIndex = this.data.findIndex(item => item.userId === currentRow.userId);
    if (currentIndex > 0) {
      [this.data[currentIndex - 1], this.data[currentIndex]] =
        [this.data[currentIndex], this.data[currentIndex - 1]];
      this.updateListNo();
      this.gridUser.refresh();
    }
  }

  selectedRowData: any;
  onRowSelected(event: RowSelectEventArgs) {
    this.selectedRowData = event.data;
  }

  moveDown(currentRow: any): void {
    const currentIndex = this.data.findIndex(item => item.userId === currentRow.userId);
    if (currentIndex < this.data.length - 1) {
      [this.data[currentIndex], this.data[currentIndex + 1]] =
        [this.data[currentIndex + 1], this.data[currentIndex]];
      this.updateListNo();
      this.gridUser.refresh();
    }
  }


  addUser() {
    console.log('# addUser');
    const dialogRef = this.dialogRef2.open(AddUserComponent, {
      data: this.data,
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        result.forEach(row => {
          this.data.push(row);
        });
        this.updateListNo();
        this.gridUser.refresh();
        console.log('Selected Records:', this.data);
      }
    });
  }

  deleteUser() {
    const listNo = this.selectedRowData.listNo;
    this.data = this.data.filter(user => user.listNo !== listNo);
    this.updateListNo();
    setTimeout(() => {
      this.gridUser.refresh();
    }, 1000);
  }


  private updateListNo(): void {
    this.data.forEach((item, index) => {
      item.listNo = index + 1;
    });
  }

  public async getGroup() {
    const response = await this.workflowService.getGroup().toPromise();
    this.dataGroup = response.data.map((group: Group) => ({
      group_id: group.group_id,
      group_name: group.group_name
    }));
  }

  public async getSubGroup(groupId: any) {
    const response = await this.workflowService.getSubGroup(groupId).toPromise();
    this.dataSubGroup = response.data.map((group: SubGroup) => ({
      sub_group_id: group.sub_group_id,
      sub_group_name: group.sub_group_name
    }));
  }

  onDropdownChange(event: any): void {
    if (event.itemData) {
      this.selectedGroup = event.itemData;
      this.getSubGroup(event.itemData.group_id);
    }
  }

  onSubGroupChange(event: any): void {
    if (event.itemData) {
      this.selectedSubGroup = event.itemData;
    }
  }

  onProcessNameChange(event: any): void {
    this.processName = event.value;
  }

}
