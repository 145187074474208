/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


import { ContextActionsDirective, PageComponent, PageLayoutComponent, PaginationDirective, ToolbarComponent } from '@alfresco/aca-shared';
import { TabModule, ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ContextMenuService, EditService, FilterService, GridComponent, GridModule, PageService, PageSettingsModel, ResizeService, RowSelectEventArgs, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule, ChipListModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { MatDialog } from '@angular/material/dialog';
import { RequestDialogComponent } from '../request-dialog/request-dialog.component';
import { ApprovalWorkflowService } from '../../service/approval-workflow.service';
import { ReviewDialogComponent } from '../review-dialog/review-dialog.component';
import { PeopleContentService } from '@alfresco/adf-content-services';
import { AdhocRequestDialogComponent } from '../adhoc-request-dialog/adhoc-request-dialog.component';



@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule,
    ContextActionsDirective,
    PaginationDirective,
    PageLayoutComponent,
    ToolbarComponent,
    TabModule,
    MatIconModule,
    MatButtonModule,
    GridModule,
    TextBoxModule,
    DropDownListModule,
    ButtonModule,
    DialogModule,
    ToolbarModule,
    ChipListModule
  ],
  providers: [PageService, SortService, FilterService, ToolbarService, EditService, ContextMenuService, ResizeService],
  selector: 'app-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MyTasksComponent extends PageComponent implements OnInit {

  @ViewChild('grid')
  public grid: GridComponent;


  loading = false;
  public dataTemp = [];
  public pageSettings?: PageSettingsModel;


  constructor(private dialog: MatDialog, private workflowService: ApprovalWorkflowService, private peopleContentService: PeopleContentService) {
    super();
  }

  async ngOnInit() {
    console.log('# MyTasksComponent');
    await this.getTasks();
  }

  onCreate() {
    const dialogRef = this.dialog.open(RequestDialogComponent, {
      width: '800px',
      disableClose: false,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('Dialog result:', result);
        await this.getTasks();
        this.grid.refresh();
      }
    });
  }

  onCreateAdhoc() {
    const dialogRef = this.dialog.open(AdhocRequestDialogComponent, {
      width: '900px',
      disableClose: false,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('Dialog result:', result);
      }
    });
  }


  // async getTasks() {
  //   try {
  //     this.dataTemp = [];
  //     const res = await this.workflowService.getTasks().toPromise();
  //     const ids = res.list.entries.map(entry => entry.entry.processId);

  //     const reqBody = {
  //       'workflow_ids': ids
  //     }
  //     const reqProcess = await this.workflowService.getRequestProcess(reqBody).toPromise();

  //     const reqIds = reqProcess.data.map(entry => entry.request_process_id);

  //     const reqUserBody = {
  //       'request_process_ids': reqIds
  //     }
  //     const reqProcessUser = await this.workflowService.getRequestProcessUser(reqUserBody).toPromise();

  //     const promises = res.list.entries.map(async item => {

  //       const filteredData = reqProcess.data.filter(item2 => item2.workflow_id === item.entry.processId.toString());

  //       if (filteredData.length > 0) {

  //         const filteredDataUser = reqProcessUser.data.filter(item2 => item2.request_process_id === filteredData[0].request_process_id);
  //         this.dataTemp.push({
  //           reqProcessId: filteredData[0].request_process_id,
  //           workflowId: item.entry.processId,
  //           taskId: item.entry.id,
  //           subject: filteredData[0].request_subject,
  //           createDate: this.formatDate(filteredData[0].created_at),
  //           assign: item.entry.assignee, // User id ที่ถูก assign ปัจจุบัน
  //           creator: filteredData[0].workflow_start_user_id, // User id ที่ Create กระบวนการ
  //           priority: filteredData[0].request_priority_name,
  //           state: item.entry.state,
  //           stepName: item.entry.name,
  //           requestProcessData: filteredData[0],
  //           requestProcessDataUser: filteredDataUser
  //         });
  //       } else {
  //         console.warn(`No matching data found for processId: ${item.entry.processId}`);
  //       }
  //     });
  //     await Promise.all(promises);
  //     this.loading = true;
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // }

  async getTasks() {
    try {
      this.dataTemp = [];
      const userId = await this.getUserId();

      const res = await this.workflowService.getTasks(userId).toPromise();
      const ids = res.list.entries.map(entry => entry.entry.processId);

      const reqBody = { 'workflow_ids': ids };
      const reqProcess = await this.workflowService.getRequestProcess(reqBody).toPromise();

      const reqIds = reqProcess.data.map(entry => entry.request_process_id);
      const reqUserBody = { 'request_process_ids': reqIds };
      const reqProcessUser = await this.workflowService.getRequestProcessUser(reqUserBody).toPromise();
      const reqProcessFile = await this.workflowService.getRequestProcessFile(reqUserBody).toPromise();

      const promises = res.list.entries.map(async item => {
        const filteredData = reqProcess.data.filter(item2 => item2.workflow_id === item.entry.processId.toString());

        if (filteredData.length > 0) {
          const filteredDataUser = reqProcessUser.data.filter(item2 => item2.request_process_id === filteredData[0].request_process_id);
          const filteredDataFile = reqProcessFile.data.filter(item2 => item2.request_process_id === filteredData[0].request_process_id);
          this.dataTemp.push({
            reqProcessId: filteredData[0].request_process_id,
            workflowId: item.entry.processId,
            taskId: item.entry.id,
            subject: filteredData[0].request_subject,
            createDate: this.formatDate(filteredData[0].created_at),
            assign: item.entry.assignee,
            creator: filteredData[0].workflow_start_user_id,
            priority: filteredData[0].request_priority_name,
            state: item.entry.state,
            stepName: item.entry.name,
            requestProcessData: filteredData[0],
            requestProcessDataUser: filteredDataUser,
            requestProcessDataFile: filteredDataFile
          });
        } else {
          console.warn(`No matching data found for processId: ${item.entry.processId}`);
        }
      });

      await Promise.all(promises);


      // Order by Asc
      // this.dataTemp.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime());

      // Order by Desc
      this.dataTemp.sort((a, b) => {
        const dateA = new Date(a.createDate.split('/').reverse().join('-')).getTime();
        const dateB = new Date(b.createDate.split('/').reverse().join('-')).getTime();
        return dateB - dateA;
      });

      console.log('this.dataTemp ', this.dataTemp);


      this.loading = true;
    } catch (error) {
      console.error('Error:', error);
    }
  }


  formatDate(dateString: string): string {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 7); // Adjust for Thai time (UTC+7)
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }


  getPriorityClass(priority: string): string {
    switch (priority) {
      case 'Low':
        return '';
      case 'Medium':
        return 'e-warning';
      case 'High':
        return 'e-danger';
      default:
        return '';
    }
  }

  selectedRowData: any;
  onRowSelected(event: RowSelectEventArgs) {
    this.selectedRowData = event.data;
  }

  async onReview(currentRow: any) {
    console.log('# onReview');
    const currentIndex = this.dataTemp.filter(item => item.taskId === currentRow.taskId);
    const requestProcessData = currentIndex[0].requestProcessData;
    const requestProcessDataUser = currentIndex[0].requestProcessDataUser;
    const requestProcessDataFile = currentIndex[0].requestProcessDataFile;
    // const state = currentIndex[0].state;
    const state = 'Working'
    const stepName = currentIndex[0].stepName;
    requestProcessData.state = state;
    requestProcessData.stepName = stepName;
    requestProcessData.taskId = currentRow.taskId;
    requestProcessData.assign_user_id = currentIndex[0].assign;
    const dialogRef = this.dialog.open(ReviewDialogComponent, {
      data: { requestProcessData, requestProcessDataUser, requestProcessDataFile },
      width: '900px',
      disableClose: false,
      panelClass: 'custom-dialog-padding'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('Dialog result:', result);
        await this.getTasks();
        this.grid.refresh();
      }
    });
  }

  public async getUserId(): Promise<string> {
    const userInfo = await this.peopleContentService.getCurrentUserInfo().toPromise();
    const id = userInfo.id;
    return id;
  }

}
