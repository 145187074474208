import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
// import { MatTableDataSource, MatPaginator, MatSort } from

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { DocumentExpireResponseClass } from '../models/documentExpireClass.model';
import { DocumentExpireReportClass } from '../models/documentExpireClass.model';
import { NetworkService } from '../services/network.service';
import { FormControl } from '@angular/forms';

// import { PageLayoutComponent } from '@alfresco/aca-shared';

import moment from 'moment';
import { ngxCsv } from 'ngx-csv';
import { DatePipe } from '@angular/common';
import { AppConfigService } from '@alfresco/adf-core';

import { PageComponent } from '@alfresco/aca-shared';
import { FilterSettingsModel, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-document-expire-report',
  templateUrl: './document-expire-report.component.html',
  styleUrls: ['./document-expire-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class DocumentExpireReportComponent extends PageComponent implements OnInit {
  // displayedColumns: string[] = ['no', 'filename', 'status', 'expiredate', 'removefile_date', 'email', 'docowner'];
  displayedColumns: string[] = ['no', 'filename', 'status', 'expiredate', 'email', 'docowner', 'urlfile'];

  dataSource = new MatTableDataSource<DocumentExpireResponseClass>();

  filename: string;
  doctypes: string;
  startdate = new FormControl(moment().startOf('day'));
  enddate = new FormControl(moment().startOf('day'));

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userId: any;

  // private onDestroy$ = new Subject<boolean>();
  // appNavNarMode$: Observable<'collapsed' | 'expanded'>;

  public data?: object[];
  public pageSettings?: PageSettingsModel;
  public filterOptions?: FilterSettingsModel;
  public dataList: string[] = ['Expire', 'Deleted'];

  selectedStatus: string = 'Selected';

  constructor(
    private networkService: NetworkService,
    private datePipe: DatePipe,
    private config: AppConfigService,
    private translate: TranslateService
  ) {
    super();
    this.getPeopleData();
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;

    this.pageSettings = { pageSize: 50 };
  }

  openURL(url: string) {
    window.open(url, '_blank');
  }

  search(event: Event) {
    let filterValue = '';
    if (event) {
      filterValue = (event.target as HTMLInputElement).value;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAdmin = false;

  async getPeopleData() {
    const peopleData = await this.networkService.getPeople().toPromise();
    this.userId = peopleData.entry.id;

    console.log('peopleData', peopleData.entry.capabilities?.isAdmin);
    if (peopleData.entry.capabilities?.isAdmin) {
      this.isAdmin = true;
      console.log('isAdmin', this.isAdmin);
      this.displayedColumns = ['no', 'filename', 'status', 'expiredate', 'removefile_date', 'email', 'docowner'];
    }
  }

  public searchData() {
    if (this.filename == undefined) {
      this.filename = '';
    }

    // let dataContext: DocumentExpireReportClass = {
    //   filename: this.filename,
    //   status: this.selectedStatus,
    //   datestart: moment(this.startdate.value).format('YYYY-MM-DD'),
    //   dateend: moment(this.enddate.value).format('YYYY-MM-DD'),
    //   userid: this.userId
    // };

    let dataContext: DocumentExpireReportClass = {
      filename: this.filename,
      status: this.selectedStatus,
      datestart: this.startdate.value ? moment(this.startdate.value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      dateend: this.enddate.value ? moment(this.enddate.value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      userid: this.userId
    };

    this.networkService.getDocumentExpireReportData(dataContext).subscribe({
      next: (data) => {
        var tempData = [];
        var row = 0;
        for (var i = 0; i < data.length; i++) {
          row++;
          var docowner = data[i].docowner;
          var filename = data[i].filename;
          var status = data[i].status_document;
          var email = data[i].email;
          var expiredate = data[i].expiredate;
          var urlfile = data[i].urlfile;
          var removefile_date = data[i].removefile_date;

          // var monthNamesThai = [
          //   'มกราคม',
          //   'กุมภาพันธ์',
          //   'มีนาคม',
          //   'เมษายน',
          //   'พฤษภาคม',
          //   'มิถุนายน',
          //   'กรกฎาคม',
          //   'สิงหาคม',
          //   'กันยายน',
          //   'ตุลาคม',
          //   'พฤษจิกายน',
          //   'ธันวาคม'
          // ];
          // var d = new Date(expiredate);
          // var dd = d.getDate();
          // var mm = monthNamesThai[d.getMonth()];
          // var yyyy = d.getFullYear() + 543;
          // var expdate = dd + ' ' + mm + ' ' + yyyy;

          let defaultDateTimeFormat = this.config.get('dateValues.defaultDateTimeFormat') as string;
          let newFormat = defaultDateTimeFormat.replace(/, h:mm/g, '');

          const expdate = this.datePipe.transform(expiredate, newFormat);
          const remove_date = this.datePipe.transform(removefile_date, newFormat);

          console.log('expiredate ', expiredate);
          console.log('expdate ', expdate);

          tempData.push({
            no: row,
            filename: filename,
            status: status,
            expiredate: expdate,
            email: email,
            docowner: docowner,
            urlfile: urlfile,
            removefile_date: remove_date
          });
        }

        this.dataSource.data = tempData;

        if (tempData.length == 0) {
          alert('ไม่พบข้อมูล กรุณาตรวจสอบ ชื่อไฟล์,ประเภทเอกสาร,วันที่หมดอายุเอกสาร');
        }
      },
      error: (error) => {
        console.log('error getDocumentExpireReportData:', error);
        console.log(error.error.message);
      }
    });
  }

  public exportData() {
    var data = this.dataSource.data;
    const headers = ['No', 'File Name', 'Expire Date', 'Notification to email', '	Document Owner', 'URL'];
    console.log('data ', data);
    new ngxCsv(data, 'Report_Data_DocumentExpire', { headers: headers });
  }

  getStartDatePlaceholder(): string {
    if (this.selectedStatus === 'Expired') {
      return this.translate.instant('DOCUMENT-EXPIRE.REPORT.EXPIRED_DATE');
    } else if (this.selectedStatus === 'Deleted') {
      return this.translate.instant('DOCUMENT-EXPIRE.REPORT.DELETED_DATE');
    }
    return this.translate.instant('DOCUMENT-EXPIRE.REPORT.START_DATE');
  }

  clearAll() {
    this.filename = '';
    this.selectedStatus = 'Selected';
    this.startdate.reset();
    this.enddate.reset();
    this.dataSource.data = [];
  }
}
