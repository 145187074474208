<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">Groups Management</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>
  </div>

  <div class="aca-page-layout-content">
    <div class="group-main-content">
      <div class="rows-filter">
        <ejs-textbox #searchBox placeholder="Group Name" floatLabelType="Auto" suffixIcon="e-search"></ejs-textbox>
        <button ejs-button class="button-filter" (click)="filterGroup()"><span class="e-btn-icon e-icons e-search"></span> Filter</button>
        <button ejs-button class="button-filter" (click)="createGroup()"><span class="e-btn-icon e-icons e-plus"></span> Create Group</button>
      </div>

      <div class="group-grid-data">
        <ejs-grid
          [dataSource]="data"
          width="100%"
          height="100%"
          [pageSettings]="pageSettings"
          [enableHover]="false"
          [allowResizing]="true"
          [allowSorting]="true"
          allowPaging="true"
          [contextMenuItems]="contextMenuItems"
          (contextMenuClick)="contextMenuClick($event)"
        >
          <e-columns>
            <e-column field="isRoot" headerText="ISROOT" width="20">
              <ng-template #template let-data>
                <input type="checkbox" [checked]="data.isRoot" disable />
              </ng-template>
            </e-column>
            <e-column field="displayName" headerText="DISPLAYNAME" width="100"></e-column>
            <e-column field="id" headerText="ID" width="100"></e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</aca-page-layout>
