<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.TITLE' | translate }}</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>

    <div class="button-container">
      <button ejs-button class="rounded-button" cssClass="e-info" (click)="onCreateAdhoc()">
        {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.NEW_ADHOC_REQUEST' | translate }}
      </button>
      <button ejs-button class="rounded-button" cssClass="e-info" (click)="onCreate()">
        {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.NEW_REQUEST' | translate }}
      </button>

      <!-- <button ejs-button class="rounded-button" cssClass="e-info" [routerLink]="['/my-requests/create']">New Request 2</button> -->
    </div>
  </div>

  <div class="aca-page-layout-content">
    <ejs-grid
      *ngIf="loading"
      #grid
      [allowExcelExport]="true"
      width="100%"
      height="100%"
      [rowHeight]="30"
      [dataSource]="dataTemp"
      [pageSettings]="pageSettings"
      [enableHover]="false"
      [allowResizing]="true"
      [allowSorting]="true"
      allowPaging="true"
      (rowSelected)="onRowSelected($event)"
    >
      <e-columns>
        <e-column field="reqProcessDraftId" headerText="DRAFT ID" width="50" textAlign="Right" [visible]="false"></e-column>
        <e-column field="reqProcessId" headerText="REQ ID" width="50" textAlign="Right" [visible]="false"></e-column>
        <e-column
          field="workflowId"
          headerText="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GRID_PROCESS_ID' | translate }}"
          width="90"
          textAlign="Right"
        ></e-column>

        <e-column field="subject" headerText="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GRID_SUBJECT' | translate }}" width="170"></e-column>
        <e-column field="createDate" headerText="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GRID_CREATE_DATE' | translate }}" width="100"></e-column>
        <e-column field="creator" headerText="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GRID_CREATE_USER' | translate }}" width="110"></e-column>
        <e-column field="priority" headerText="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GRID_PRIORITY' | translate }}" width="90">
          <ng-template #template let-data>
            <ejs-chiplist>
              <e-chips>
                <e-chip [text]="data.priority" [cssClass]="getPriorityClass(data.priority)"></e-chip>
              </e-chips>
            </ejs-chiplist>
          </ng-template>
        </e-column>

        <e-column field="type" headerText="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GRID_TYPE' | translate }}" width="90">
          <ng-template #template let-data>
            <ejs-chiplist>
              <e-chips>
                <e-chip [text]="data.reqProcessDraftId === 0 ? 'Process' : 'Draft'" [cssClass]="getTypeClass(data.reqProcessDraftId)"> </e-chip>
              </e-chips>
            </ejs-chiplist>
          </ng-template>
        </e-column>

        <e-column field="actions" headerText="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GRID_ACTION' | translate }}" width="120">
          <ng-template #template let-data>
            <button
              *ngIf="data.workflowId === 0"
              ejs-button
              iconCss="e-icons e-medium e-edit-notes"
              class="rounded-button-item"
              (click)="onEdit(data)"
            ></button>
            <button
              *ngIf="data.workflowId !== 0"
              ejs-button
              iconCss="e-icons e-medium e-eye"
              class="rounded-button-item"
              (click)="onView(data)"
            ></button>
            <button ejs-button class="rounded-button-item" iconCss="e-icons e-medium e-circle-close-2" (click)="onCancel(data)"></button>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</aca-page-layout>
