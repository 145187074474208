<div class="adf-share-link__dialog-container">
  <div class="adf-share-link__dialog-container">
    <h1 data-automation-id="adf-share-dialog-title" class="adf-share-link__title">{{ 'SHARE.DIALOG-TITLE' | translate }}</h1>

    <mat-dialog-content>
      <p class="adf-share-link__info">{{ 'SHARE.DESCRIPTION' | translate }}</p>

      <form #sharedLinksForm="ngForm" [formGroup]="form">
        <!-- EMAIL -->
        <div class="adf-share-link--row">
          <h1 class="adf-share-link__label">Emails</h1>
          <button mat-button (click)="createToken()" *ngIf="unShareActive">ReSend Emails</button>
          <mat-form-field class="adf-full-width">
            <input
              #emailInput
              data-automation-id="adf-share-link"
              class="adf-share-link__input"
              matInput
              cdkFocusInitial
              placeholder="{{ 'SHARE.EMAIL.PLACEHOLDER' | translate }}"
              formControlName="email"
            />
            <mat-hint align="start">{{ 'SHARE.EMAIL.HINT' | translate }}</mat-hint>
          </mat-form-field>
        </div>

        <!-- TOGLE EXPIRESDATE -->
        <!-- <div class="adf-share-link--row">
          <h1 class="adf-share-link__label">{{ 'SHARE.EXPIRES' | translate }}</h1>
          <mat-slide-toggle
            [disabled]="!canUpdate"
            #slideToggleExpirationDate
            color="primary"
            data-automation-id="adf-expire-toggle"
            [checked]="form.controls['time'].value"
            (change)="onToggleExpirationDate($event)"
          >
          </mat-slide-toggle>
        </div> -->


        <div style="margin-bottom: 25px"></div>

        <!-- EXPIRESDATE -->
        <mat-form-field class="adf-full-width">
          <mat-datetimepicker-toggle #matDatetimepickerToggle="matDatetimepickerToggle" [for]="datetimePicker" matSuffix> </mat-datetimepicker-toggle>
          <mat-datetimepicker #datetimePicker (closed)="onDatetimepickerClosed()" type="date" [timeInterval]="1"> </mat-datetimepicker>
          <input
            class="adf-share-link__input"
            #dateTimePickerInput
            matInput
            [min]="minDate"
            formControlName="time"
            placeholder="Date Expiredate"
            [matDatetimepicker]="datetimePicker"
          />
        </mat-form-field>

        <!-- LINK -->
        <div class="adf-share-link--row" *ngIf="unShareActive">
          <h1 class="adf-share-link__label">{{ 'SHARE.TITLE' | translate }}</h1>
          <mat-form-field class="adf-full-width">
            <input
              #sharedLinkInput
              data-automation-id="adf-share-link"
              class="adf-share-link__input"
              matInput
              cdkFocusInitial
              placeholder="{{ 'SHARE.PUBLIC-LINK' | translate }}"
              formControlName="sharedUrl"
              readonly="readonly"
            />
            <mat-icon
              class="adf-input-action"
              matSuffix
              [clipboard-notification]="'SHARE.CLIPBOARD-MESSAGE' | translate"
              [adf-clipboard]
              [target]="sharedLinkInput"
            >
              link
            </mat-icon>
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>
  </div>

  <div mat-dialog-actions>
    <!-- NEXT -->
    <!-- <button mat-button color="primary" (click)="nextState()" *ngIf="!nextActive">Next</button> -->

    <!-- UNSHARE -->
    <form>
      <button type="submit" mat-button color="primary" (click)="toggleFormState()" *ngIf="unShareActive">UNSHARE</button>
    </form>

    <!-- SHARE -->
    <button mat-button type="submit" color="primary" (click)="toggleFormState()" *ngIf="!unShareActive">Share</button>

    <!-- CLOSE -->
    <button data-automation-id="adf-share-dialog-close" mat-button color="primary" mat-dialog-close>{{ 'SHARE.CLOSE' | translate }}</button>
  </div>
</div>
