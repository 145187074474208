import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  ContextActionsDirective,
  PageLayoutComponent,
  PaginationDirective,
  ToolbarComponent
} from '@alfresco/aca-shared';

import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts'
import { NgModule } from '@angular/core';
import { ChartAreaComponent } from './chart-area.component';

import { CategoryService, DateTimeService, ScrollBarService, ColumnSeriesService, LineSeriesService,
  ChartAnnotationService, RangeColumnSeriesService, StackingColumnSeriesService,LegendService, TooltipService
} from '@syncfusion/ej2-angular-charts'

import { BarSeriesService, StackingBarSeriesService} from '@syncfusion/ej2-angular-charts'


import { DataLabelService } from '@syncfusion/ej2-angular-charts'





@NgModule({
  declarations: [
    ChartAreaComponent,
  ],
  exports: [
    ChartAreaComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ChartModule,
    ContextActionsDirective,
    PageLayoutComponent,
    PaginationDirective,
    ToolbarComponent,
    DashboardLayoutModule,
    AccumulationChartModule
  ],
  providers: [ CategoryService, DateTimeService, ScrollBarService, LineSeriesService, ColumnSeriesService,
    ChartAnnotationService, RangeColumnSeriesService, StackingColumnSeriesService, LegendService, TooltipService, DataLabelService,
    BarSeriesService, StackingBarSeriesService],
})
export class ChartAreaModule { }
