import { CommonModule } from '@angular/common';

import {
  ContextActionsDirective,
  PageLayoutComponent,
  PaginationDirective,
  ToolbarComponent
} from '@alfresco/aca-shared';


import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { GridGroupComponent } from './grid-group.component';

import { TranslateModule } from '@ngx-translate/core';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ContextMenuService, EditService, FilterService, GridModule, PageService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@alfresco/adf-content-services';



@NgModule({
  declarations: [
    GridGroupComponent,
  ],
  exports: [
    GridGroupComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ContextActionsDirective,
    PaginationDirective,
    PageLayoutComponent,
    ToolbarComponent,
    TabModule,
    MatIconModule,
    MatButtonModule,
    GridModule,
    TextBoxModule,
    DropDownListModule,
    ButtonModule,
    DialogModule
  ],
  providers: [PageService, SortService, FilterService, ToolbarService, EditService, ContextMenuService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GridGroupModule { }
