<!-- eslint-disable @angular-eslint/template/elements-content -->
<div class="dialog-header">
  <h2 class="dialog-title">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.NEW_REQUEST' | translate }}</h2>
</div>

<form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
  <div class="dialog-content">
    <div class="input-container-main">
      <div class="e-card" style="padding: 8px; margin-top: 5px">
        <div class="input-container">
          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GROUP' | translate }}:</h4>
            <ejs-dropdownlist
              id="group"
              [dataSource]="dataGroup"
              [fields]="fieldsGroup"
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.GROUP_PLACHOLDER' | translate }}"
              formControlName="group"
              (change)="onGroupChange($event)"
              [value]="selectedGroupId"
            ></ejs-dropdownlist>
          </div>

          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.SUP_GROUP' | translate }}:</h4>
            <ejs-dropdownlist
              id="subGroup"
              [dataSource]="dataSubGroup"
              [fields]="fieldsSubGroup"
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.SUP_GROUP_PLACHOLDER' | translate }}"
              formControlName="subGroup"
              (change)="onSubGroupChange($event)"
              [value]="selectedSubGroupId"
            ></ejs-dropdownlist>
          </div>
        </div>

        <div class="input-container">
          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.PROCESS_NAME' | translate }}:</h4>
            <ejs-dropdownlist
              id="config"
              [dataSource]="dataConfig"
              [fields]="fieldsConfig"
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.PROCESS_NAME_PLACHOLDER' | translate }}"
              formControlName="config"
              (change)="onConfigChange($event)"
              [value]="selectedConfigId"
            ></ejs-dropdownlist>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="input-container">
          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.PROCESS_NAME' | translate }}:</h4>
            <div class="breadcrumb-process">
              <div class="breadcrumb-items-process">
                <ng-container *ngFor="let item of dataConfigProcess; let last = last">
                  <div class="breadcrumb-item-process">
                    <div class="card">
                      <span>{{ item.processName }}</span>
                    </div>
                    <!-- <i *ngIf="!last" class="fas fa-chevron-right" style="margin-left: 15px"></i> -->
                    <span *ngIf="!last" class="e-icons e-medium e-chevron-right-small-2" style="margin-left: 15px"></span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="input-container">
          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.APPROVAL_STEP' | translate }}:</h4>
            <div class="process-breadcrumb">
              <div class="breadcrumb-items">
                <ng-container *ngFor="let user of dataConfigUser; let last = last">
                  <div class="breadcrumb-item">
                    <span class="e-icons e-user"></span>
                    <span>{{ user.displayName }}</span>
                  </div>
                  <span *ngIf="!last" class="e-icons e-small e-chevron-right-small-2"></span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- SECTION 5 -->
        <div class="input-container">
          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.SUBJECT' | translate }}:</h4>
            <ejs-textbox
              #subject
              id="subject"
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.SUBJECT_PLACHOLDER' | translate }}"
              formControlName="subject"
              [value]="_requestProcessData?.request_subject"
            ></ejs-textbox>
          </div>

          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.PRIORITY' | translate }}:</h4>
            <ejs-dropdownlist
              #priority
              id="priority"
              [dataSource]="dataPriority"
              [fields]="fieldsPriority"
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.PRIORITY_PLACHOLDER' | translate }}"
              formControlName="priority"
              [value]="selectedPriorityId"
            ></ejs-dropdownlist>
          </div>

          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.DUE_DATE' | translate }}:</h4>
            <ejs-datepicker
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.DUE_DATE_PLACHOLDER' | translate }}"
              formControlName="dueDate"
              [value]="_requestProcessData?.request_due_date"
            ></ejs-datepicker>
          </div>
        </div>

        <!-- SECTION 6 -->
        <div class="input-container">
          <div class="input-section">
            <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.DESCRIPTION' | translate }}:</h4>
            <ejs-textarea
              id="description"
              placeholder="{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.DESCRIPTION_PLACHOLDER' | translate }}"
              resizeMode="None"
              rows="3"
              width="700px"
              formControlName="description"
              [value]="_requestProcessData?.request_description"
            ></ejs-textarea>
          </div>
        </div>

        <div class="input-section">
          <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-REQUESTS.ATTACHMENT_FILES' | translate }}:</h4>
          <button ejs-button iconCss="e-icons e-file-new" class="rounded-button" (click)="openSelectorDialog()">
            <!-- <i class="fas fa-folder-open"> </i> -->
            {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.SELETED_FILES' | translate }}
          </button>

          <!-- List File -->
          <div class="selected-files-list" *ngIf="listNodeUploadActive" style="margin-top: 10px">
            <div class="file-item" *ngFor="let node of listNodeUpload; let last = last">
              <div class="file-content">
                <div class="file-info">
                  <!-- <i class="fas fa-file mr-2"></i> -->
                  <span class="file-name">{{ node.node_name }}</span>
                  <div class="e-section-control">
                    <!-- To render ButtonGroup. -->
                    <div class="e-btn-group">
                      <button ejs-button (click)="onClick(node.node_id)">
                        <!-- <i class="fas fa-eye"> </i> -->
                        {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.VIEW' | translate }}
                      </button>
                      <button ejs-button (click)="onRemove(node.node_id)">
                        <!-- <i class="fas fa-trash"> </i> -->
                        {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.REMOVE' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </div>
          </div>
        </div>
        <br />
      </div>

      <div style="text-align: right; margin-top: 3px">
        <button ejs-button iconCss="e-icons e-circle-check" class="rounded-button" style="margin-left: 10px" cssClass="e-info" (click)="onStart()">
          {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.START' | translate }}
        </button>
        <button ejs-button iconCss="e-icons e-save-as" class="rounded-button" style="margin-left: 10px" type="button" (click)="onSaveDraft()">
          {{ 'APPROVAL-WORKFLOW.MY-REQUESTS.SAVE_DRAFT' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
