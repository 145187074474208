import { CommonModule, JsonPipe, NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';

import { FormattingService } from '../utils/formatting.service';
import { DecimalPipe } from '@angular/common';


import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import {
  EditService, FilterService, GridComponent, GridModule, PageService, RowSelectingEventArgs,
  SelectionService, SelectionSettingsModel, ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { ContractManagementService } from '../services/contract-management.service';
import { ImproveContractComponent } from '../improve/improve-contract.component';
import { ImproveContractApprovedComponent } from '../improve-approved/improve-contract-approved.component';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgIf,
    JsonPipe,
    MatListModule,
    MatTableModule,
    MatDatetimepickerModule,
    MatIconModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatGridListModule,
    MatToolbarModule,
    GridModule
  ],
  selector: 'app-improve-document',
  templateUrl: './improve-document.component.html',
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [FormattingService, DecimalPipe, EditService, ToolbarService, PageService, FilterService, SelectionService],
  styleUrls: ['./improve-document.component.scss']
})
export class ImproveDocumentComponent implements OnInit {

  public dataTempItem?: object[];
  public selectionOptions?: SelectionSettingsModel;
  public columnSelection = false;
  @ViewChild('grid') grid?: GridComponent;

  contractDocumentId: any;

  groupComplianceActive = false;

  constructor(
    // private dialogRef: MatDialogRef<ImproveDocumentComponent>,
    private formattingService: FormattingService,
    private contractManagementService: ContractManagementService,
    // private peopleContentService: PeopleContentService,
    // private uploadService: UploadService,
    // private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) {


    this.contractDocumentId = this.data.contractDocumentId;
    this.groupComplianceActive = this.data.groupComplianceActive;
    console.log('this.contractDocumentNo ', this.contractDocumentId);

    this.filterItems();

  }

  ngOnInit() {
    console.log('# ngOnInit');
    this.selectionOptions = { type: 'Single', checkboxMode: 'ResetOnRowClick' };


  }

  onComplete() {
    console.log('# onComplete');
    const selectedRecords = (this.grid as GridComponent).getSelectedRecords();
    const contractItemId = selectedRecords[0]['contractItemId'];
    const contractDocumentNo = selectedRecords[0]['contractDocumentNo'];
    const contractStatusProcess = selectedRecords[0]['contractStatusProcess'];


    if (contractStatusProcess === 'Draft' || contractStatusProcess === 'Expired') {
      this.improveData(contractDocumentNo, contractItemId);
    }

    if (contractStatusProcess === 'Approved') {
      this.improveDataApproved(contractDocumentNo, contractItemId);
    }


  }

  onClose() {
    console.log('# onClose');
  }


  // Call Service
  async filterItems() {
    const data = {
      'contract_document_id': this.contractDocumentId,
    }
    await this.contractManagementService.searchContractItem(data).subscribe(response => {
      const temp = [];
      if (response) {
        response.forEach(item => {
          const newItem = {
            contractItemId: item.contract_item_id,
            contractItemNo: item.contract_item_no,
            contractDocumentNo: item.contract_document_no,
            contractStatusProcess: item.contract_status_process,
            nodeName: item.node_name,
            contractDate: this.formattingService.convertToThaiDate(item.contract_date),

          };
          temp.push(newItem);
        });
      }
      this.dataTempItem = temp;
    }, error => {
      console.error('error filterData() :', error);
      this.dataTempItem = [];
    });
  }


  // Syncfusion Grid
  // eslint-disable-next-line @cspell/spellchecker
  rowSeleted(args: RowSelectingEventArgs): void {
    if (args.target?.classList.contains('e-icons')) { (this.grid as GridComponent).clearSelection(); }
  }


  improveData(contractDocumentNo: any, contractItemId: any) {
    const dialogRef = this.dialog.open(ImproveContractComponent, {
      width: '750px',
      autoFocus: true,
      disableClose: true,
      data: { contractDocumentNo: contractDocumentNo, contractItemId: contractItemId, groupComplianceActive: this.groupComplianceActive }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.filterItems();
      }
    });
  }


  improveDataApproved(contractDocumentNo: any, contractItemId: any) {
    const dialogRef = this.dialog.open(ImproveContractApprovedComponent, {
      width: '750px',
      autoFocus: true,
      disableClose: true,
      data: { contractDocumentNo: contractDocumentNo, contractItemId: contractItemId, groupComplianceActive: this.groupComplianceActive }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.filterItems();
      }
    });
  }


}
