import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContextActionsDirective, PageComponent, PageLayoutComponent, PaginationDirective, ToolbarComponent } from '@alfresco/aca-shared';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DocumentListModule } from '@alfresco/adf-content-services';
import { DataTableModule, PaginationModule, TemplateModule } from '@alfresco/adf-core';
import { ExtensionsModule, ProfileState } from '@alfresco/adf-extensions';
import { FormControl } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

// import { AlfrescoApiService } from '@alfresco/adf-core';
import { MatTableDataSource } from '@angular/material/table';

import { NetworkService } from '../services/network.service';
import { MatTableModule } from '@angular/material/table';

import * as moment_ from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AuditDetailDialog } from '../audit-detail/audit-detail.component';
const moment = moment_;

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { ExportToCsv } from 'export-to-csv';

import { ContentApiService } from '@alfresco/aca-shared';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DocumentListModule,
    TemplateModule,
    TemplateModule,
    DataTableModule,
    ExtensionsModule,
    ContextActionsDirective,
    PaginationDirective,
    PageLayoutComponent,
    ToolbarComponent,
    PaginationModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatButtonModule
  ],
  selector: 'aca-audit',
  templateUrl: './workspace-report.component.html',
  styleUrls: ['./workspace-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkSpaceReportComponent extends PageComponent implements OnInit {
  onDestroy$: Subject<boolean> = new Subject<boolean>();

  startdate = new FormControl(moment().startOf('day'));
  enddate = new FormControl(moment().startOf('day'));
  loadingTitle = '';
  loadingDetail = '';
  maxDate: Date;
  name = '';
  user = '';
  method = '';
  auditEntries: Array<{
    userName: string;
    nodeName: string;
    method: string;
    time: string;
    values: string[];
    entry: any;
  }>;
  pagination = {
    hasMoreItems: false,
    totalItems: 0
  };
  displayedColumns: string[] = ['userName', 'nodeName', 'method', 'type', 'time', 'entry'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  user$: Observable<ProfileState>;
  lockMenuActive = true;
  apiURL: any;
  headers: any;
  auth: any;

  constructor(
    private contentApi: ContentApiService,
    // private apiService: AlfrescoApiService,
    private networkService: NetworkService,
    public dialog: MatDialog,
    public trans: TranslateService,
  ) {
    super();
    this.enddate.disable();
    this.startdate.valueChanges.subscribe(newValue => {
      this.maxDate = moment(newValue).add(6, 'days').endOf('day').toDate();
      this.enddate.setValue(null);
      this.enddate.enable();
    });
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getGroup();

    this.trans.get('WORKSPACE-REPORT.LOADING.TITLE').subscribe((translation) => {
      this.loadingTitle = translation;
    });

    this.trans.get('WORKSPACE-REPORT.LOADING.DETAIL').subscribe((translation) => {
      this.loadingDetail = translation;
    });
  }

  async getGroup() {
    const person = await this.contentApi.getPerson('-me-').toPromise();
    const personId = person.entry.id;
    this.networkService.getGroups(personId).subscribe({
      next: (data) => {
        if (data) {
          for (const item of data.list.entries) {
            if (item.entry.id === 'GROUP_Audit_Workspace') {
              this.lockMenuActive = true;
              console.log('lockMenuActive', this.lockMenuActive);
              break;
            }
          }
        }
      },
      error: (error) => {
        console.log('error getGroup:', error);
        console.log(error.error.message);
      }
    });
  }


  public clear() {
    this.name = '';
    this.user = '';
    this.method = '';
    this.dataSource.data = [];
    this.startdate = new FormControl(moment().startOf('day'));
    this.enddate = new FormControl(moment().startOf('day'));
    this.pagination = {
      hasMoreItems: false,
      totalItems: 0
    };
  }

  optionsFilter: string[] = [
    'preview',
    'create',
    'copy',
    'move',
    'deleted',
    'download',
    'download-watermark',
    'download-digitalsignature',
    'print'
  ];

  tempData = [];
  async search() {
    Swal.fire({
      title: this.loadingTitle,
      width: 300,
      html: this.loadingDetail,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        this.tempData = [];
        if (!this.method) {
          await this.setTempsAll();
        } else {
          await this.setTemps();
        }
        await this.setDataSource();
      }
    });
  }

  async setTempsAll() {
    console.log(' Contidition select All');
    await Promise.all(this.optionsFilter.map(async (itemsMethod) => {
      const queries = [];

      queries.push(`action=${itemsMethod}`);
      if (this.user) {
        queries.push(`user=${this.user}`);
      }
      if (moment.isMoment(this.startdate.value)) {
        queries.push(`from=${this.startdate.value.startOf('day').valueOf()}`);
        queries.push(`to=${this.enddate.value.endOf('day').valueOf()}`);
      } else {
        const momentDate1 = moment(this.startdate.value);
        const formattedDate1 = momentDate1.startOf('day').valueOf();
        const momentDate2 = moment(this.enddate.value);
        const formattedDate2 = momentDate2.endOf('day').valueOf();
        queries.push(`from=${formattedDate1}`);

        if (this.enddate.value) {
          queries.push(`to=${formattedDate2}`);
        }

      }
      const queryItems = queries.length > 0 ? `?${queries.join('&')}` : '';
      // รอให้การเรียก API เสร็จสิ้นและสร้าง promises สำหรับแต่ละการเรียก API
      const dataPromises = [];
      await this.networkService.getAuditAppData(queryItems).forEach(data => {
        if (data.items.length > 0) {
          data.items.forEach(item => {
            let nodeNameStr = item.site.split(',');
            nodeNameStr = nodeNameStr[1];
            let siteStr = item.site.split(',');
            siteStr = siteStr[0];
            const jsonData = {
              'nodeRef': item.nodeRef,
              'site': siteStr,
              'nodeType': item.nodeType,
              'user': item.user,
              'action': item.action,
              'time': moment(item.time).format('DD-MM-YYYY hh:mm:ss'),
              'name': nodeNameStr
            };
            const userName = item.user;
            const nodeName = nodeNameStr;
            const method = item.action;
            const type = item.nodeType;
            const time = item.time;
            this.tempData.push({
              userName: userName,
              nodeName: nodeName,
              method: method,
              type: type,
              time: moment(item.time).format('DD-MM-YYYY hh:mm:ss'),
              timeDefault: time,
              entry: jsonData
            });
          });
        }
        // สร้าง promise สำหรับการดำเนินการนี้
        dataPromises.push(Promise.resolve());
      });

      // รอให้ทุก promise เสร็จสิ้น
      await Promise.all(dataPromises);
    }));
  }
  async setTemps() {
    const queries = [];
    queries.push(`action=${this.method}`);
    if (this.user) {
      queries.push(`user=${this.user}`);
    }
    if (moment.isMoment(this.startdate.value)) {
      queries.push(`from=${this.startdate.value.startOf('day').valueOf()}`);
      queries.push(`to=${this.enddate.value.endOf('day').valueOf()}`);
    } else {
      const momentDate1 = moment(this.startdate.value);
      const formattedDate1 = momentDate1.startOf('day').valueOf();
      const momentDate2 = moment(this.enddate.value);
      const formattedDate2 = momentDate2.endOf('day').valueOf();
      queries.push(`from=${formattedDate1}`);
      if (this.enddate.value) {
        queries.push(`to=${formattedDate2}`);
      }
    }
    const queryItems = queries.length > 0 ? `?${queries.join('&')}` : '';
    await this.networkService.getAuditAppData(queryItems).forEach(data => {
      if (data.items.length > 0) {
        data.items.forEach(item => {
          let nodeNameStr = item.site.split(',');
          nodeNameStr = nodeNameStr[1];
          let siteStr = item.site.split(',');
          siteStr = siteStr[0];
          const jsonData = {
            'nodeRef': item.nodeRef,
            'site': siteStr,
            'nodeType': item.nodeType,
            'user': item.user,
            'action': item.action,
            'time': moment(item.time).format('DD-MM-YYYY hh:mm:ss'),
            'name': nodeNameStr
          };
          const userName = item.user;
          const nodeName = nodeNameStr;
          const method = item.action;
          const type = item.nodeType;
          const time = item.time;
          this.tempData.push({
            userName: userName,
            nodeName: nodeName,
            method: method,
            type: type,
            time: moment(item.time).format('DD-MM-YYYY hh:mm:ss'),
            timeDefault: time,
            entry: jsonData
          });
        });
      }
    });
  }

  async setDataSource() {
    this.tempData.sort((a, b) => {
      const timeA = new Date(a.timeDefault);
      const timeB = new Date(b.timeDefault);
      if (timeA < timeB) {
        return 1;
      } else if (timeA > timeB) {
        return -1;
      } else {
        return 0;
      }
    });
    const filteredData = this.tempData.filter(item => {
      if (item.nodeName) {
        return item.nodeName.toLowerCase().includes(this.name.toLowerCase());
      }
    });
    this.dataSource.data = filteredData;
    this.dataSource.paginator = this.paginator;
    this.pagination = {
      hasMoreItems: false,
      totalItems: filteredData.length
    };
    Swal.close();
  }

  openDialog(entry: any) {
    this.dialog.open(AuditDetailDialog, {
      data: entry
    });
  }

  downloadCSV() {
    const d = [];
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Workspace Report Result',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };
    const csvExporter = new ExportToCsv(options);

    this.dataSource.data.forEach((entry) => {
      const o = {
        userName: entry.userName,
        nodeName: entry.nodeName,
        method: entry.method,
        time: entry.time,
        values: entry.values
      };
      d.push(o);
    });

    csvExporter.generateCsv(d);
  }
}
