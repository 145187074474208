/* eslint-disable no-prototype-builtins */

/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Category, CategoryEntry, CategoryLinkBody, CategoryPaging, Node, TagBody, TagEntry, TagPaging } from '@alfresco/js-api';
import { forkJoin, Observable, of, Subject, zip } from 'rxjs';
import {
  AppConfigService,
  CardViewBaseItemModel,
  CardViewItem,
  NotificationService,
  TranslationService,
  UpdateNotification
} from '@alfresco/adf-core';
import { ContentMetadataService } from '../../services/content-metadata.service';
import { CardViewGroup, PresetConfig, ContentMetadataCustomPanel, ContentMetadataPanel } from '../../interfaces/content-metadata.interfaces';
import { catchError, debounceTime, map, takeUntil } from 'rxjs/operators';
import { CardViewContentUpdateService } from '@alfresco/adf-content-services';
import { NodesApiService } from '@alfresco/adf-content-services';
import { TagsCreatorMode } from '@alfresco/adf-content-services';
import { TagService } from '@alfresco/adf-content-services';
import { CategoryService } from '@alfresco/adf-content-services';
import { CategoriesManagementMode } from '@alfresco/adf-content-services';
import { AllowableOperationsEnum } from '@alfresco/adf-content-services';
import { ContentService } from '@alfresco/adf-content-services';
import { DialogAlertComponent } from '../dialog-alert/dialog-alert';
import { MatDialog } from '@angular/material/dialog';

// import {
//   createSpinner,
//   showSpinner,
//   hideSpinner,
// } from '@syncfusion/ej2-popups';

const DEFAULT_SEPARATOR = ', ';

enum DefaultPanels {
  PROPERTIES = 'Properties',
  TAGS = 'Tags',
  CATEGORIES = 'Categories'
}

@Component({
  selector: 'adf-content-metadata',
  templateUrl: './content-metadata.component.html',
  styleUrls: ['./content-metadata.component.scss'],
  host: { class: 'adf-content-metadata' },
  encapsulation: ViewEncapsulation.None
})
export class ContentMetadataComponent implements OnChanges, OnInit, OnDestroy {
  protected onDestroy$ = new Subject<boolean>();

  // myArray = [
  //   { id: 1, name: 'Item 1', children: [{ id: 2, name: 'Item 1.1' }] },
  // ];

  // eslint-disable-next-line @typescript-eslint/ban-types
  // public dataTemps: { [key: string]: Object }[] = [
  //   { id: 'Hot Singles 1', name: 'Hot Singles 1' },
  //   { id: 'Hot Singles 2', name: 'Hot Singles 2' },
  // ];

  // eslint-disable-next-line @typescript-eslint/ban-types
  dataTemps: { [key: string]: Object }[] = [];
  sendData = [];

  // eslint-disable-next-line @typescript-eslint/ban-types
  defaultValue = [];

  @Input() selectedValue: any;

  /** (required) The node entity to fetch metadata about */
  @Input()
  node: Node;

  /** Toggles whether to display empty values in the card view */
  @Input()
  displayEmpty = false;

  /**
   * Toggles between expanded (ie, full information) and collapsed
   * (ie, reduced information) in the display
   */
  @Input()
  expanded = false;

  /** The multi parameter of the underlying material expansion panel, set to true to allow multi accordion to be expanded at the same time */
  @Input()
  multi = false;

  /** Name or configuration of the metadata preset, which defines aspects and their properties */
  @Input()
  preset: string | PresetConfig;

  /** Toggles whether the metadata properties should be shown */
  @Input()
  displayDefaultProperties = true;

  /** (optional) shows the given aspect in the expanded  card */
  @Input()
  displayAspect: string = null;

  /** Toggles whether or not to enable copy to clipboard action. */
  @Input()
  copyToClipboardAction = true;

  /** Toggles whether or not to enable chips for multivalued properties. */
  @Input()
  useChipsForMultiValueProperty = true;

  /** True if tags should be displayed, false otherwise */
  @Input()
  displayTags = false;

  /** True if categories should be displayed, false otherwise */
  @Input()
  displayCategories = false;

  /** List of custom metadata panels to be displayed with registered custom components */
  @Input()
  customPanels: ContentMetadataCustomPanel[] = [];

  /**
   * (optional) This flag sets the metadata in read-only mode,
   * preventing changes.
   */
  @Input()
  readOnly = false;

  private _assignedTags: string[] = [];
  private assignedTagsEntries: TagEntry[] = [];
  private _tagsCreatorMode = TagsCreatorMode.CREATE_AND_ASSIGN;
  private _tags: string[] = [];
  private targetProperty: CardViewBaseItemModel;
  private classifiableChangedSubject = new Subject<void>();
  private _saving = false;

  DefaultPanels = DefaultPanels;
  multiValueSeparator: string;
  basicProperties$: Observable<CardViewItem[]>;
  groupedProperties$: Observable<CardViewGroup[]>;

  changedProperties = {};

  hasMetadataChanged = false;
  assignedCategories: Category[] = [];
  categories: Category[] = [];
  categoriesManagementMode = CategoriesManagementMode.ASSIGN;
  classifiableChanged = this.classifiableChangedSubject.asObservable();
  editing = false;
  editedPanelTitle = '';
  currentPanel: ContentMetadataPanel = {
    expanded: false,
    panelTitle: ''
  };

  isButtonDisabled = true;

  customContentProperties: any;

  customType: any;

  constructor(
    private contentMetadataService: ContentMetadataService,
    private cardViewContentUpdateService: CardViewContentUpdateService,
    private nodesApiService: NodesApiService,
    private translationService: TranslationService,
    private appConfig: AppConfigService,
    private tagService: TagService,
    private categoryService: CategoryService,
    private contentService: ContentService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {
    this.copyToClipboardAction = this.appConfig.get<boolean>('content-metadata.copy-to-clipboard-action');
    this.multiValueSeparator = this.appConfig.get<string>('content-metadata.multi-value-pipe-separator') || DEFAULT_SEPARATOR;
    this.useChipsForMultiValueProperty = this.appConfig.get<boolean>('content-metadata.multi-value-chips');
  }

  _customContentMedataActive = false;
  _customContentMetaData: any;
  _dropdownStatus_EditProperies: any;

  // edit-properties-require
  _editPropertiesRequire: any;

  async ngOnInit() {
    this._customContentMetaData = this.appConfig.config['custom-content-metadata'] || {};
    const contentMetadata = this.appConfig.config['content-metadata'] || {};

    this._editPropertiesRequire = contentMetadata['edit-properties-require'];

    const configDisplayTags = contentMetadata.displayTags || false;

    const configDisplayCategories = contentMetadata.displayCategories || false;

    console.log('configDisplayTags ', configDisplayTags);
    console.log('configDisplayCategories ', configDisplayCategories);

    this.displayTags = configDisplayTags;
    this.displayCategories = configDisplayCategories;
    if (this._customContentMetaData) {
      if (this._customContentMetaData.isEnabled) {
        this._customContentMedataActive = true;
        if (this._customContentMetaData.dropdownStatus_EditProperies) {
          this._dropdownStatus_EditProperies = this._customContentMetaData.dropdownStatus_EditProperies;
        }
      }
    }

    await this.loadModel();

    this.cardViewContentUpdateService.itemUpdated$
      .pipe(debounceTime(500), takeUntil(this.onDestroy$))
      .subscribe((updatedNode: UpdateNotification) => {
        this.hasMetadataChanged = true;
        this.targetProperty = updatedNode.target;
        console.log('updatedNode.changed ', updatedNode.changed);
        this.updateChanges(updatedNode.changed);
      });
    this.cardViewContentUpdateService.updatedAspect$.pipe(debounceTime(500), takeUntil(this.onDestroy$)).subscribe((node) => {
      this.node.aspectNames = node?.aspectNames;
      this.loadProperties(node);
    });
    this.loadProperties(this.node);
    this.verifyAllowableOperations();
    this.currentPanel.panelTitle = this.displayAspect ?? this.DefaultPanels.PROPERTIES;
    this.currentPanel.expanded = false;
    this.readOnly = false;

    this.loginAndFetchModels();
  }

  private verifyAllowableOperations() {
    if (!this.node?.allowableOperations || !this.contentService.hasAllowableOperations(this.node, AllowableOperationsEnum.UPDATE)) {
      this.readOnly = true;
    }
  }

  get assignedTags(): string[] {
    return this._assignedTags;
  }

  get tags(): string[] {
    return this._tags;
  }

  get tagsCreatorMode(): TagsCreatorMode {
    return this._tagsCreatorMode;
  }

  get saving(): boolean {
    return this._saving;
  }

  isPanelEditing(panelTitle: string): boolean {
    // console.log('panelTitle ', panelTitle);

    return (
      this.editing && ((this.currentPanel.panelTitle === panelTitle && this.editedPanelTitle === panelTitle) || this.editedPanelTitle === panelTitle)
    );
  }

  protected handleUpdateError(error: Error) {
    let statusCode = 0;

    try {
      statusCode = JSON.parse(error.message).error.statusCode;
    } catch {}

    let message = `METADATA.ERRORS.${statusCode}`;

    if (this.translationService.instant(message) === message) {
      message = 'METADATA.ERRORS.GENERIC';
    }

    this.contentMetadataService.error.next({
      statusCode,
      message
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.node && !changes.node.firstChange) {
      this.loadProperties(changes.node.currentValue);
    }

    if (changes.readOnly?.currentValue) {
      this.resetEditing();
      this.loadProperties(this.node);
    }

    if (changes.displayAspect?.currentValue) {
      this.currentPanel.panelTitle = changes.displayAspect.currentValue;
      this.currentPanel.expanded = true;
    }
    if (changes.displayDefaultProperties?.currentValue) {
      this.expandPanel(this.DefaultPanels.PROPERTIES);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  updateChanges(updatedNodeChanges) {
    Object.keys(updatedNodeChanges).map((propertyGroup: string) => {
      if (typeof updatedNodeChanges[propertyGroup] === 'object') {
        this.changedProperties[propertyGroup] = {
          ...this.changedProperties[propertyGroup],
          ...updatedNodeChanges[propertyGroup]
        };
      } else {
        this.changedProperties[propertyGroup] = updatedNodeChanges[propertyGroup];
      }
    });
  }

  saveChanges(event?: MouseEvent) {
    console.log('# saveChanges');

    event?.stopPropagation();
    this.resetEditing();
    this._saving = true;
    if (this.hasContentTypeChanged(this.changedProperties)) {
      this.contentMetadataService.openConfirmDialog(this.changedProperties).subscribe(() => {
        this.updateNode();
      });
    } else {
      this.updateNode();
    }
  }

  selectedPropertiesValue: any;

  /**
   * Register all tags which should be assigned to node. Please note that they are just in "register" state and are not yet saved
   * until button for saving data is clicked. Calling that function causes that save button is enabled.
   *
   * @param tags array of tags to register, they are not saved yet until we click save button.
   */
  storeTagsToAssign(tags: string[]) {
    this._tags = tags;
    this.hasMetadataChanged = true;
  }

  /**
   * Store all categories that node should be assigned to. Please note that they are just in "stored" state and are not yet saved
   * until button for saving data is clicked. Calling that function causes that save button is enabled.
   *
   * @param categoriesToAssign array of categories to store.
   */
  storeCategoriesToAssign(categoriesToAssign: Category[]) {
    this.categories = categoriesToAssign;
    this.hasMetadataChanged = true;
  }

  revertChanges() {
    this.changedProperties = {};
    this.hasMetadataChanged = false;
  }

  get showEmptyTagMessage(): boolean {
    return this.tags?.length === 0 && this.currentPanel.panelTitle === 'Tags' && !this.editing;
  }

  get showEmptyCategoryMessage(): boolean {
    return this.categories?.length === 0 && this.currentPanel.panelTitle === 'Categories' && !this.editing;
  }

  toggleGroupEditing(panelTitle: string, event?: MouseEvent) {
    event?.stopPropagation();
    if (this.editing && this.hasMetadataChanged) {
      this.notificationService.showError('METADATA.BASIC.SAVE_OR_DISCARD_CHANGES');
      return;
    }
    this.editing = true;
    this.editedPanelTitle = panelTitle;

    this.expandPanel(panelTitle);
  }

  cancelGroupEditing(panelTitle: string, event?: MouseEvent) {
    event?.stopPropagation();
    this.resetEditing();
    this.revertChanges();
    const loadBasicProps = panelTitle === this.DefaultPanels.PROPERTIES;
    const loadTags = panelTitle === this.DefaultPanels.TAGS;
    const loadCategories = panelTitle === this.DefaultPanels.CATEGORIES;
    const loadGroupedProps = !loadBasicProps && !loadTags && !loadCategories;

    this.loadProperties(this.node, loadBasicProps, loadGroupedProps, loadTags, loadCategories);
  }

  expandPanel(panelTitle: string) {
    this.currentPanel.panelTitle = panelTitle;
    this.currentPanel.expanded = true;
  }

  closePanel(panelTitle: string) {
    if (this.currentPanel.panelTitle === panelTitle) {
      this.currentPanel.expanded = false;
    }
  }

  resetEditing() {
    this.editing = false;
    this.editedPanelTitle = '';
  }

  showGroup(group: CardViewGroup): boolean {
    const properties = group.properties.filter((property) => !this.isEmpty(property.displayValue));
    return properties.length > 0;
  }

  keyDown(event: KeyboardEvent) {
    if (event.keyCode === 37 || event.keyCode === 39) {
      // ArrowLeft && ArrowRight
      event.stopPropagation();
    }
  }

  // getEmptyOrNullProperties(changedProperties: any, requiredProperties: string[]): string[] {
  //   const properties = changedProperties.properties;
  //   const emptyOrNullProperties = Object.keys(properties).filter(key => properties[key] === '' || properties[key] === null);
  //   return emptyOrNullProperties.filter(property => requiredProperties.includes(property));
  // }

  getEmptyOrNullProperties(changedProperties: any, requiredProperties: string[]): string[] {
    console.log('changedProperties ', changedProperties);
    if (!changedProperties || Object.keys(changedProperties).length === 0) {
      return null;
    }

    console.log('requiredProperties ', requiredProperties);

    const properties = changedProperties.properties;
    const emptyOrNullProperties = Object.keys(properties).filter((key) => {
      const value = properties[key];
      return value === null || (typeof value === 'string' && value.trim() === '');
    });
    return emptyOrNullProperties.filter((property) => requiredProperties.includes(property));
  }

  openDialogAlert(data: any): void {
    this.dialog.open(DialogAlertComponent, {
      width: '600px',
      data: data
    });
  }

  private updateNode() {
    console.log('# updateNode()');
    console.log(' this.dropdownValues ', this.dropdownValues);

    if (this.dropdownValues) {
      const mergedProperties = this.mergeProperties(this.changedProperties, this.dropdownValues);
      this.changedProperties = mergedProperties;
    }

    console.log('this.changedProperties ', this.changedProperties);
    console.log('this._editPropertiesRequire ', this._editPropertiesRequire);

    if (this._editPropertiesRequire) {
      const getEmptyOrNullProperties = this.getEmptyOrNullProperties(this.changedProperties, this._editPropertiesRequire);
      console.log('getEmptyOrNullProperties ', getEmptyOrNullProperties);

      if (getEmptyOrNullProperties) {
        if (getEmptyOrNullProperties.length > 0) {
          this.openDialogAlert({
            editPropertiesRequire: this._editPropertiesRequire,
            emptyOrNullProperties: getEmptyOrNullProperties
          });

          event?.stopPropagation();
          this.resetEditing();
          this.revertChanges();
          this.loadProperties(this.node, true, true, true, true);

          return;
        }
      }
    }

    forkJoin({
      updatedNode: this.nodesApiService.updateNode(this.node.id, this.changedProperties),
      ...(this.displayTags ? this.saveTags() : {}),
      ...(this.displayCategories ? this.saveCategories() : {})
    })
      .pipe(
        catchError((err) => {
          this.cardViewContentUpdateService.updateElement(this.targetProperty);
          this.handleUpdateError(err);
          this._saving = false;
          this.loadProperties(this.node);
          return of(null);
        })
      )
      .subscribe((result: any) => {
        if (result) {
          this.updateUndefinedNodeProperties(result.updatedNode);
          if (this.hasContentTypeChanged(this.changedProperties)) {
            this.cardViewContentUpdateService.updateNodeAspect(this.node);
          }
          this.revertChanges();
          Object.assign(this.node, result.updatedNode);
          this.nodesApiService.nodeUpdated.next(this.node);
          if (Object.keys(result).length > 1 && this.displayTags) {
            this.loadTagsForNode(this.node.id);
          }
          if (this.displayCategories && !!result.LinkingCategories) {
            this.assignedCategories = result.LinkingCategories.list
              ? result.LinkingCategories.list.entries.map((entry: CategoryEntry) => entry.entry)
              : [result.LinkingCategories.entry];
          }
        }
        this._saving = false;
      });
  }

  private hasContentTypeChanged(changedProperties): boolean {
    return !!changedProperties?.nodeType;
  }

  private updateUndefinedNodeProperties(node: Node): void {
    if (!node.properties) {
      node.properties = {};
    }
  }

  private async loadModel() {
    if (this._customContentMedataActive) {
      await this.contentMetadataService.login().toPromise();
      const models = await this.contentMetadataService.getModels().toPromise();
      // this.modelData = models;

      this.modelData = models.map((item) => {
        return {
          ...item,
          properties:
            this._dropdownStatus_EditProperies && this._dropdownStatus_EditProperies === 'All'
              ? item.properties
              : item.properties.filter((prop) => prop.status && prop.status === this._dropdownStatus_EditProperies)
        };
      });
    }
  }

  findModelData(modelData, categoryName, itemValue) {
    if (modelData) {
      for (const category of modelData) {
        if (category.name === categoryName) {
          for (const property of category.properties) {
            if (property.name === itemValue || property.label === itemValue || property.dropdownName === itemValue) {
              return property.dropdownName;
            }
          }
        }
      }
      return itemValue;
    }
  }

  private convertToUTC7(date: Date | string): string {
    if (!date) {
      return '';
    }

    console.log('date ', date);

    const utcDate = new Date(date);
    // Add 7 hours to convert to UTC+7
    utcDate.setHours(utcDate.getHours() + 7);
    return utcDate.toISOString();
  }

  formatCardViewProperties(properties: CardViewItem[]): CardViewItem[] {
    return properties.map((prop) => {
      if (prop.type === 'date' && prop.value) {
        const convertedDate = this.convertToUTC7(prop.value as string);
        // Create a new CardViewItem while preserving the original structure
        return {
          ...prop,
          value: convertedDate,
          isEmpty: () => !convertedDate
        };
      }
      return prop;
    });
  }

  private async loadProperties(node: Node, loadBasicProps = true, loadGroupedProps = true, loadTags = true, loadCategories = true) {
    if (node) {
      if (loadBasicProps) {
        this.basicProperties$ = this.getProperties(node);
      }

      if (loadGroupedProps) {
        this.groupedProperties$ = this.contentMetadataService.getGroupedProperties(node, this.preset);

        this.groupedProperties$.subscribe((groups) => {
          console.log('groups ', groups);
        });

        // this.groupedProperties$ = this.contentMetadataService.getGroupedProperties(this.node, this.preset).pipe(
        //   map((groups) => {
        //     return groups.map((group) => ({
        //       ...group,
        //       properties: this.formatCardViewProperties(group.properties)
        //     }));
        //   })
        // );

        const customContentMetaData = this._customContentMetaData;

        if (this._customContentMedataActive) {
          if (Object.keys(customContentMetaData).length > 0) {
            const customType = customContentMetaData.customType || {};
            if (Object.keys(customType).length > 0) {
              this.customType = customType;
              this.groupedProperties$ = this.groupedProperties$.pipe(
                map((groups) =>
                  groups.map((group) => ({
                    ...group,
                    properties: group.properties.filter((item) => {
                      customType.some((property) => {
                        const itemValue = item.value;
                        const categoryName = property.categoryName;
                        const groupTitle = property.groupTitle;

                        const dropdownName = this.findModelData(this.modelData, categoryName, itemValue);

                        const match = item.key === `properties.${property.propertyName}`;

                        if (match) {
                          const value = dropdownName;
                          const existingItem = this.defaultValue.find((obj) => obj.id === property.id);
                          if (existingItem) {
                            existingItem.default = value;
                          } else {
                            this.defaultValue.push({
                              id: property.id,
                              default: value,
                              groupTitle: groupTitle
                            });
                          }
                        }
                        return match;
                      });
                      return !customType.some((type) => item.key === `properties.${type.propertyName}`);
                    })
                  }))
                )
              );
            }
          }
        }
      }

      if (this.displayTags && loadTags) {
        this.loadTagsForNode(node.id);
      }
      if (this.displayCategories && loadCategories) {
        this.loadCategoriesForNode(node.id);
        const aspectNames = node.aspectNames || [];
        if (!aspectNames.includes('generalclassifiable')) {
          this.categories = [];
          this.classifiableChangedSubject.next();
        }
      }
    }
  }

  // getFilteredData(id: string): any[] {
  //   return this.sendData.filter(item => item.id === id);
  // }

  // checkDataLoaded(_groupTitle: any, _groupTitleItem: any): boolean {
  //   if (_groupTitle === _groupTitleItem) {
  //     return true;
  //   }

  //   return false;
  // }

  checkDataLoaded(_groupTitle: any, _groupTitleItem: any, _Id: any): boolean {
    // check title ต้องตรงกัน
    if (_groupTitle === _groupTitleItem) {
      // check หากไม่มี value จะไม่แสดง
      for (const item of this.defaultValue) {
        if (item.id === _Id && item.groupTitle === _groupTitle) {
          if (!item.default) {
            return false;
          }
          return true;
        }
      }
    }
    return false;
  }

  isConfig = false;
  isDataLoaded = false;
  modelData: any;
  loginAndFetchModels() {
    if (this._customContentMedataActive) {
      try {
        const customContentMetaData = this._customContentMetaData;
        const customType = customContentMetaData.customType || {};
        const isEnabled = customContentMetaData.isEnabled || false;
        if (!isEnabled) {
          return;
        }
        if (customType) {
          this.isConfig = true;
        }
        let completedRequests = 0;
        const totalRequests = customType.length;
        for (let i = 0; i < customType.length; i++) {
          const item = customType[i];
          const categoryName = item.categoryName;
          const temps = [];

          const iDisplay = item.display;

          // this.contentMetadataService.login().subscribe(() => {
          //   this.contentMetadataService.getModels().subscribe(models => {
          //     console.log('# models 2', models);
          //     models.forEach(model => {
          //       if (model.name === categoryName) {
          //         model.properties.forEach(prop => {
          //           temps.push({
          //             'Display': prop[iDisplay],
          //             'Id': prop.dropdownName,
          //             'Category': model.label,
          //           });
          //         });
          //       }
          //     });
          //     this.sendData.push({
          //       'id': item.id,
          //       'data': temps,
          //     });
          //     completedRequests++;
          //     if (completedRequests === totalRequests) {
          //       this.isDataLoaded = true;
          //     }
          //   });
          // });

          this.modelData.forEach((model) => {
            if (model.name === categoryName) {
              model.properties.forEach((prop) => {
                temps.push({
                  Display: prop[iDisplay],
                  Id: prop.dropdownName,
                  Category: model.label
                });
                completedRequests++;
                if (completedRequests === totalRequests) {
                  this.isDataLoaded = true;
                }
              }); // LOOP ITEM
            }
          }); // LOOP CATEGORY

          this.sendData.push({
            id: item.id,
            data: temps
          });
        } // END LOOP CUSTOM TYPE
      } catch (error) {
        console.log('loginAndFetchModels error ', error);
      }
    }
  }

  transformSelectedValues(config: any, selected: any): any[] {
    const result = [];

    for (const key in selected) {
      if (selected.hasOwnProperty(key)) {
        const selectedValue = selected[key];
        const customType = config.customType.find((type: any) => type.id === key);

        if (customType) {
          result.push({
            value: selectedValue,
            propertyName: customType.propertyName
          });
        }
      }
    }

    return result;
  }

  transformToPropertiesObject(transformedValues: any[]): any {
    const properties = {};
    transformedValues.forEach((item) => {
      properties[item.propertyName] = item.value;
    });
    return { properties };
  }

  mergeProperties(changedProperties: any, propertiesObject: any): any {
    return {
      properties: {
        ...changedProperties.properties,
        ...propertiesObject.properties
      }
    };
  }

  selectedValues: { [key: string]: any } = {};
  dropdownValues: any;

  onDropdownSelectionChange(selectedValue: any, dropdownId: string) {
    const config = this._customContentMetaData;
    this.hasMetadataChanged = true;
    this.selectedValues[dropdownId] = selectedValue;
    console.log('this.selectedValues ', this.selectedValues);
    const transformedValues = this.transformSelectedValues(config, this.selectedValues);
    const propertiesObject = this.transformToPropertiesObject(transformedValues);
    this.dropdownValues = propertiesObject;
  }
  private getProperties(node: Node) {
    const properties$ = this.contentMetadataService.getBasicProperties(node);
    const contentTypeProperty$ = this.contentMetadataService.getContentTypeProperty(node);
    return zip(properties$, contentTypeProperty$).pipe(
      map(([properties, contentTypeProperty]) => {
        const filteredProperties = contentTypeProperty.filter(
          (property) => properties.findIndex((baseProperty) => baseProperty.key === property.key) === -1
        );
        return [...properties, ...filteredProperties];
      })
    );
  }

  private isEmpty(value: any): boolean {
    return value === undefined || value === null || value === '';
  }

  private loadCategoriesForNode(nodeId: string) {
    try {
      this.assignedCategories = [];
      this.categoryService.getCategoryLinksForNode(nodeId).subscribe((categoryPaging) => {
        this.categories = categoryPaging.list.entries.map((categoryEntry) => {
          const path = categoryEntry.entry.path ? categoryEntry.entry.path.split('/').splice(3).join('/') : null;
          categoryEntry.entry.name = path ? `${path}/${categoryEntry.entry.name}` : categoryEntry.entry.name;
          return categoryEntry.entry;
        });
        this.assignedCategories = [...this.categories];
      });
    } catch (error) {
      console.error('Error loading categories for node', error);
    }
  }

  private saveCategories(): { [key: string]: Observable<CategoryPaging | CategoryEntry | void> } {
    const observables: { [key: string]: Observable<CategoryPaging | CategoryEntry | void> } = {};
    if (this.categories) {
      this.assignedCategories.forEach((assignedCategory) => {
        if (this.categories.every((category) => category.name !== assignedCategory.name)) {
          observables[`Removing ${assignedCategory.id}`] = this.categoryService.unlinkNodeFromCategory(this.node.id, assignedCategory.id);
        }
      });
      const categoryLinkBodies = this.categories.map((category) => {
        const categoryLinkBody = new CategoryLinkBody();
        categoryLinkBody.categoryId = category.id;
        return categoryLinkBody;
      });
      if (categoryLinkBodies.length > 0) {
        observables['LinkingCategories'] = this.categoryService.linkNodeToCategory(this.node.id, categoryLinkBodies);
      }
    }
    return observables;
  }

  private loadTagsForNode(id: string) {
    this.tagService.getTagsByNodeId(id).subscribe((tagPaging) => {
      this.assignedTagsEntries = tagPaging.list.entries;
      this._tags = tagPaging.list.entries.map((tagEntry) => tagEntry.entry.tag);
      this._assignedTags = [...this._tags];
    });
  }

  private saveTags(): { [key: string]: Observable<TagPaging | TagEntry | void> } {
    const observables: { [key: string]: Observable<TagPaging | TagEntry | void> } = {};
    if (this.tags) {
      this.assignedTagsEntries.forEach((tagEntry) => {
        if (!this.tags.some((tag) => tagEntry.entry.tag === tag)) {
          observables[`${tagEntry.entry.id}Removing`] = this.tagService.removeTag(this.node.id, tagEntry.entry.id);
        }
      });
      if (this.tags.length) {
        observables.tagsAssigning = this.tagService.assignTagsToNode(
          this.node.id,
          this.tags.map((tag) => {
            const tagBody = new TagBody();
            tagBody.tag = tag;
            return tagBody;
          })
        );
      }
    }
    return observables;
  }
}
