<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">User Management</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>
  </div>

  <div class="aca-page-layout-content">
    <div class="group-main-content">
      <div class="rows-filter">
        <ejs-textbox #searchBox placeholder="Serach" floatLabelType="Auto" suffixIcon="e-search"></ejs-textbox>
        <button ejs-button class="button-filter" (click)="searchUser()"><span class="e-btn-icon e-icons e-search"></span> Filter</button>
        <button ejs-button class="button-filter" (click)="createUser()"><span class="e-btn-icon e-icons e-plus"></span> Create User</button>
      </div>

      <div class="group-grid-data">
        <ejs-grid
          [dataSource]="data"
          width="100%"
          height="100%"
          [pageSettings]="pageSettings"
          [enableHover]="false"
          [allowResizing]="true"
          [allowSorting]="true"
          allowPaging="true"
          [allowFiltering]="false"
          [filterSettings]="filterOptions"
          [contextMenuItems]="contextMenuItems"
          (contextMenuClick)="contextMenuClick($event)"
        >
          <e-columns>
            <e-column field="userId" headerText="User ID" width="100"></e-column>
            <e-column field="firstName" headerText="First Name" width="100"></e-column>
            <e-column field="lastName" headerText="Last Name" width="100"></e-column>
            <e-column field="displayName" headerText="Display Name" width="100"></e-column>
            <e-column field="email" headerText="Email" width="100"></e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</aca-page-layout>
