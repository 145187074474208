<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">{{ 'CONTRACT-MANAGEMENT.TITLE' | translate }}</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>
  </div>

  <div class="aca-page-layout-content">
    <div #ReportContract="ngForm" [formGroup]="myForm" class="main-content">
      <!-- ROWS-1 -->
      <div class="rows-filter-frist">
        <mat-form-field class="text-filter">
          <mat-label>ประเภทสัญญา/เอกสาร</mat-label>
          <mat-select [(ngModel)]="selectedDocType" name="docType" formControlName="docType" name="docType" id="docType">
            <mat-option *ngFor="let docType of docType" [value]="docType.value"> {{ docType.viewValue }} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="text-filter">
          <input matInput type="text" [placeholder]="'CONTRACT-MANAGEMENT.REPORT.DEPOSIT_ACCOUNT_NUMBER' | translate" formControlName="depositNo" />
        </mat-form-field>

        <mat-form-field class="text-filter">
          <input matInput type="text" [placeholder]="'CONTRACT-MANAGEMENT.REPORT.CONTRACT_NAME' | translate" formControlName="contractName" />
        </mat-form-field>

        <mat-form-field class="text-filter">
          <input matInput type="text" [placeholder]="'CONTRACT-MANAGEMENT.REPORT.CONTRACT_NUMBER' | translate" formControlName="contractNo" />
        </mat-form-field>

        <mat-form-field class="text-filter">
          <input
            matInput
            type="text"
            [placeholder]="'CONTRACT-MANAGEMENT.REPORT.CONTRACT_PAIR_NAME' | translate"
            formControlName="contractPairName"
          />
        </mat-form-field>
      </div>

      <!-- ROWS-2 -->
      <div class="rows-filter">
        <mat-form-field class="text-filter">
          <mat-label>ฝ่ายงาน/ส่วนงาน</mat-label>
          <mat-select [(ngModel)]="selectedDepartment" name="department" formControlName="department" name="department" id="department">
            <mat-option *ngFor="let department of department" [value]="department.viewValue"> {{ department.viewValue }} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="text-filter">
          <input matInput type="text" [placeholder]="'CONTRACT-MANAGEMENT.REPORT.CONTRACT_VALUE' | translate" formControlName="contractValue" />
        </mat-form-field>

        <mat-form-field class="text-filter">
          <input
            matInput
            type="text"
            [placeholder]="'CONTRACT-MANAGEMENT.REPORT.CONTRACT_COLLATERAL' | translate"
            formControlName="contractCollateral"
          />
        </mat-form-field>

        <mat-form-field class="text-filter">
          <mat-label>ประเภทการลงนาม</mat-label>
          <mat-select
            [(ngModel)]="selectedSignatureType"
            name="signatureType"
            formControlName="signatureType"
            name="signatureType"
            id="signatureType"
          >
            <mat-option *ngFor="let signatureType of signatureType" [value]="signatureType.value"> {{ signatureType.viewValue }} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="text-filter">
          <mat-label>สถานะ</mat-label>
          <mat-select [(ngModel)]="selectedStatus" name="status" formControlName="status" name="status" id="status">
            <mat-option *ngFor="let status of status" [value]="status.value"> {{ status.viewValue }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- ROWS-3 -->
      <div class="rows-filter">
        <mat-form-field class="text-filter">
          <mat-label>ผู้รับผิดชอบ</mat-label>
          <mat-select
            [(ngModel)]="selectedResponsiblePerson"
            name="responsiblePerson"
            formControlName="responsiblePerson"
            name="responsiblePerson"
            id="responsiblePerson"
          >
            <mat-option *ngFor="let responsiblePerson of responsiblePerson" [value]="responsiblePerson.value">
              {{ responsiblePerson.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field class="text-filter">
          <mat-datetimepicker-toggle
            #matDatetimepickerToggle_contractEffectiveDate="matDatetimepickerToggle"
            [for]="contractEffectiveDate"
            matSuffix
          ></mat-datetimepicker-toggle>
          <mat-datetimepicker #contractEffectiveDate type="date" [timeInterval]="1"></mat-datetimepicker>
          <input
            #contractEffectiveDate
            matInput
            formControlName="contractEffectiveDate"
            [placeholder]="'CONTRACT-MANAGEMENT.REPORT.EFFECTIVE_DATE' | translate"
            [matDatetimepicker]="contractEffectiveDate"
          />
        </mat-form-field> -->

        <mat-form-field class="text-filter">
          <mat-label>{{ 'CONTRACT-MANAGEMENT.REPORT.EFFECTIVE_DATE' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker1" placeholder="DD/MM/YYYY" formControlName="contractEffectiveDate" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

        <!-- <mat-form-field class="text-filter">
          <mat-datetimepicker-toggle
            #matDatetimepickerToggle_contractEndDate="matDatetimepickerToggle"
            [for]="contractEndDate"
            matSuffix
          ></mat-datetimepicker-toggle>
          <mat-datetimepicker #contractEndDate type="date" [timeInterval]="1"></mat-datetimepicker>
          <input
            #contractEndDate
            matInput
            [placeholder]="'CONTRACT-MANAGEMENT.REPORT.CONTRACT_ENDDATE' | translate"
            formControlName="contractEndDate"
            [matDatetimepicker]="contractEndDate"
          />
        </mat-form-field> -->

        <mat-form-field class="text-filter">
          <mat-label>{{ 'CONTRACT-MANAGEMENT.REPORT.CONTRACT_ENDDATE' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker2" placeholder="DD/MM/YYYY" formControlName="contractEndDate" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

        <button
          mat-flat-button
          color="primary"
          style="min-width: 100px; height: 40px; margin-right: 10px"
          (click)="filterData()"
          [disabled]="!enableCreate"
        >
          <mat-icon>search</mat-icon>
          {{ 'CONTRACT-MANAGEMENT.REPORT.SEARCH' | translate }}
        </button>

        <button mat-flat-button style="min-width: 110px; height: 40px; margin-right: 17px" (click)="clearData()">
          <mat-icon>clear_all</mat-icon>
          {{ 'CONTRACT-MANAGEMENT.REPORT.CLEAR' | translate }}
        </button>
      </div>

      <div style="display: flex; padding: 5px; margin-top: -22px; justify-content: space-between; align-items: center">
        <div style="display: flex">
          <!-- New -->
          <button
            mat-flat-button
            style="min-width: 110px; height: 40px; margin-right: 10px"
            color="primary"
            (click)="createData()"
            [disabled]="!enableCreate"
          >
            <mat-icon>create</mat-icon>
            {{ 'CONTRACT-MANAGEMENT.REPORT.NEW' | translate }}
          </button>

          <!-- Path -->
          <button mat-flat-button style="min-width: 110px; height: 40px; margin-right: 10px" (click)="onBackClick()" *ngIf="openItemsActive">
            <mat-icon>keyboard_arrow_left</mat-icon>
            {{ selectedPath }}
          </button>
        </div>

        <div>
          <!-- Export -->
          <button mat-flat-button style="min-width: 110px; height: 40px; margin-right: 10px" color="primary" (click)="exportData()">
            <mat-icon>save_alt</mat-icon>
            Export
          </button>

          <!--
          <button mat-flat-button style="min-width: 110px; height: 40px; margin-right: 10px" >
            <mat-icon>input</mat-icon>
            Import
          </button> -->
        </div>
      </div>

      <!-- GRID  -->
      <div class="grid-data" *ngIf="!openItemsActive">
        <ejs-grid
          #grid
          [allowExcelExport]="true"
          width="100%"
          height="100%"
          [rowHeight]="30"
          [frozenColumns]="3"
          [dataSource]="dataTemp"
          (recordClick)="onRecordClick($event)"
          [pageSettings]="pageSettings"
          [contextMenuItems]="contextMenuItems"
          (contextMenuClick)="contextMenuClick($event)"
          [enableHover]="false"
          [allowResizing]="true"
          [allowSorting]="true"
          allowPaging="true"
          (recordDoubleClick)="onRecordDoubleClick($event)"
        >
          <e-columns>
            <e-column field="listNo" headerText="No." width="80" textAlign="Right"></e-column>
            <e-column field="contractDocumentId" headerText="contractDocumentId" width="80" textAlign="Right" [visible]="false"></e-column>

            <e-column field="fileName" headerText="ชื่อไฟล์" width="290">
              <ng-template #template let-data>
                <img src="../../../../../assets/images/icons-folder.png" style="width: 21px; height: 21px; margin-bottom: -5px" alt="" />
                <span> {{ data.fileName }}</span>
              </ng-template>
            </e-column>
            <e-column field="viewContract" headerText="มุมมอง" width="100" textAlign="center">
              <ng-template #template let-data>
                <button mat-icon-button>
                  <mat-icon class="material-icons-outlined">visibility</mat-icon>
                </button>
              </ng-template>
            </e-column>
            <e-column field="depositAccNumber" headerText="เลขที่รับฝาก" width="120"></e-column>
            <e-column field="contractPairName" headerText="ชื่อคู่สัญญา" width="130"></e-column>
            <e-column field="createDate" headerText="วันที่สร้าง" width="150"></e-column>
            <e-column field="editDate" headerText="วันที่แก้ไข" width="150"></e-column>
            <e-column field="notificationEmail" headerText="แจ้งเตือนไปที่ E-mail" width="190"></e-column>
            <e-column field="docOwner" headerText="เจ้าของเอกสาร" width="150"></e-column>
            <e-column field="department" headerText="ฝ่ายงาน/ส่วนงาน" width="150"></e-column>
            <e-column field="expDate" headerText="วันหมดอายุ" width="150"></e-column>
            <e-column field="contractUpdate" headerText="การปรับปรุงสัญญา" width="150" textAlign="center">
              <ng-template #template let-data>
                <button mat-icon-button>
                  <mat-icon class="material-icons-outlined">update</mat-icon>
                </button>
              </ng-template>
            </e-column>
            <e-column field="contractStatusProcess" headerText="สถานะ" width="100" textAlign="center">
              <ng-template #template let-data>
                <span
                  [ngClass]="{
                    approved: data.contractStatusProcess === 'Approved',
                    expired: data.contractStatusProcess === 'Expired'
                  }"
                >
                  {{ data.contractStatusProcess }}
                </span>
              </ng-template>
            </e-column>

            <e-column field="downloadContract" headerText="ดาวน์โหลด" width="120" textAlign="center" *ngIf="groupComplianceActive">
              <ng-template #template let-data>
                <button mat-icon-button>
                  <mat-icon class="material-icons-outlined">downloadContract</mat-icon>
                </button>
              </ng-template>
            </e-column>

            <e-column field="deleteContract" headerText="ลบเอกสารสัญญา" width="150" textAlign="center" *ngIf="groupComplianceActive">
              <ng-template #template let-data>
                <button mat-icon-button>
                  <mat-icon class="material-icons-outlined">deleteContract</mat-icon>
                </button>
              </ng-template>
            </e-column>

            <e-column field="nodeId" headerText="NodeId" width="80" textAlign="Right" [visible]="false"></e-column>
            <e-column field="nodeIdLast" headerText="NodeId" width="80" textAlign="Right" [visible]="false"></e-column>
            <e-column field="contractDocumentNo" headerText="เลขที่เอกสาร" width="200" [visible]="false"> </e-column>
          </e-columns>

          <ng-template #emptyRecordTemplate>
            <div class="emptyRecordTemplate">
              <img
                src="../../../../../assets/images/emtry-report.svg"
                style="width: 21px; height: 21px; margin-bottom: -5px; text-align: left"
                alt="No record"
              />
              <span>There is no data available to display at the moment.</span>
            </div>
          </ng-template>
        </ejs-grid>
      </div>

      <div class="grid-data" *ngIf="openItemsActive">
        <ejs-grid
          #grid
          [allowExcelExport]="true"
          width="100%"
          height="100%"
          [rowHeight]="30"
          [frozenColumns]="4"
          [dataSource]="dataTempItem"
          (recordClick)="onRecordItemClick($event)"
          [pageSettings]="pageSettings"
          [contextMenuItems]="contextMenuItems"
          (contextMenuClick)="contextItemMenuClick($event)"
          [enableHover]="false"
          [allowResizing]="true"
          [allowSorting]="true"
          allowPaging="true"
          (recordDoubleClick)="onRecordItemsDoubleClick($event)"
        >
          <e-columns>
            <e-column field="contractItemId" headerText="contractItemId" width="150" textAlign="Right" [visible]="false"></e-column>
            <e-column field="contractDocumentId" headerText="contractDocumentId" width="80" textAlign="Right" [visible]="false"></e-column>
            <e-column field="listNo" headerText="No." width="80" textAlign="Right"></e-column>

            <e-column field="fileName" headerText="ชื่อไฟล์" width="240">
              <ng-template #template let-data>
                <span> {{ data.fileName }}</span>
              </ng-template>
            </e-column>
            <e-column field="viewContract" headerText="มุมมอง" width="100" textAlign="center">
              <ng-template #template let-data>
                <button mat-icon-button>
                  <mat-icon class="material-icons-outlined">visibility</mat-icon>
                </button>
              </ng-template>
            </e-column>
            <e-column field="depositAccNumber" headerText="เลขที่รับฝาก" width="120"></e-column>
            <e-column field="contractPairName" headerText="ชื่อคู่สัญญา" width="130"></e-column>
            <e-column field="createDate" headerText="วันที่สร้าง" width="150"></e-column>
            <e-column field="editDate" headerText="วันที่แก้ไข" width="150"></e-column>
            <e-column field="notificationEmail" headerText="แจ้งเตือนไปที่ E-mail" width="190"></e-column>
            <e-column field="docOwner" headerText="เจ้าของเอกสาร" width="150"></e-column>
            <e-column field="department" headerText="ฝ่ายงาน/ส่วนงาน" width="150"></e-column>
            <e-column field="expDate" headerText="วันหมดอายุ" width="150"></e-column>
            <e-column field="contractUpdate" headerText="การปรับปรุงสัญญา" width="150" textAlign="center">
              <ng-template #template let-data>
                <button mat-icon-button>
                  <mat-icon class="material-icons-outlined">update</mat-icon>
                </button>
              </ng-template>
            </e-column>

            <e-column field="contractStatusProcess" headerText="สถานะ" width="100" textAlign="center">
              <ng-template #template let-data>
                <span
                  [ngClass]="{
                    approved: data.contractStatusProcess === 'Approved',
                    expired: data.contractStatusProcess === 'Expired'
                  }"
                >
                  {{ data.contractStatusProcess }}
                </span>
              </ng-template>
            </e-column>

            <e-column field="downloadContract" headerText="ดาวน์โหลด" width="120" textAlign="center" *ngIf="groupComplianceActive">
              <ng-template #template let-data>
                <button mat-icon-button>
                  <mat-icon class="material-icons-outlined">download</mat-icon>
                </button>
              </ng-template>
            </e-column>

            <e-column field="deleteContract" headerText="ลบเอกสารสัญญา" width="150" textAlign="center" *ngIf="groupComplianceActive">
              <ng-template #template let-data>
                <button mat-icon-button>
                  <mat-icon class="material-icons-outlined">deleteContract</mat-icon>
                </button>
              </ng-template>
            </e-column>

            <e-column field="nodeId" headerText="NodeId" width="80" textAlign="Right" [visible]="false"></e-column>
            <e-column field="contractDocumentNo" headerText="เลขที่เอกสาร" width="200" [visible]="false"> </e-column>
          </e-columns>

          <ng-template #emptyRecordTemplate>
            <div class="emptyRecordTemplate">
              <img
                src="../../../../../assets/images/emtry-report.svg"
                style="width: 21px; height: 21px; margin-bottom: -5px; text-align: left"
                alt="No record"
              />
              <span>There is no data available to display at the moment.</span>
            </div>
          </ng-template>
        </ejs-grid>
      </div>
    </div>
  </div>
  
</aca-page-layout>
