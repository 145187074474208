<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="dialog-header">
  <h2 class="dialog-title">{{ titlePage }}</h2>
</div>
<form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
  <div class="e-card" style="padding: 5px; margin-top: 1px">
    <!-- Process Name -->
    <!-- <div class="input-container" *ngIf="dataConfigProcess?.length">
      <div class="input-section">
        <h4 class="adjusted-paragraph" id="process-name">{{ 'APPROVAL-WORKFLOW.MY-TASKS.PROCESS_NAME' | translate }}:</h4>
        <nav class="breadcrumb-process2" aria-labelledby="process-name">
          <ol class="breadcrumb-items-process2">
            <ng-container *ngFor="let item of dataConfigProcess; let last = last">
              <li class="breadcrumb-item-process2">
                <div class="process-step2">
                  <span>{{ item.processName }}</span>
                </div>
                <i *ngIf="!last" class="fas fa-chevron-right" aria-hidden="true" role="presentation"> </i>
              </li>
            </ng-container>
          </ol>
        </nav>
      </div>
    </div> -->

    <div class="spacing-container"></div>

    <div class="input-container">
      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.PROCESS_NAME' | translate }}</h4>
        <div class="process-breadcrumb">
          <div class="breadcrumb-items">
            <ng-container *ngFor="let item of dataConfigProcess; let last = last; let i = index">
              <div class="breadcrumb-item">
                <span *ngIf="i % 3 === 0" class="e-icons e-description"></span>
                <span *ngIf="i % 3 === 1" class="e-icons e-display-for-review"></span>
                <span *ngIf="i % 3 === 2" class="e-icons e-form-field"></span>
                <span>{{ item.processName }}</span>
              </div>
              <span *ngIf="!last" class="e-icons e-chevron-right-small-2" style="margin-left: 5px"></span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Approval Step -->
    <div class="input-container">
      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.APPROVAL_STEP' | translate }}</h4>
        <div class="process-breadcrumb">
          <div class="breadcrumb-items">
            <ng-container *ngFor="let user of _requestProcessDataUser; let last = last">
              <div class="breadcrumb-item">
                <span class="e-icons e-user"></span>
                <span>{{ user.user_display_name }}</span>
              </div>
              <span *ngIf="!last" class="e-icons e-small e-chevron-right-small-2"></span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Request By , Process Number , Status -->
    <div class="input-container">
      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.REQUEST_BY' | translate }}:</h4>
        <ejs-textbox [value]="_requestProcessData.workflow_start_user_display_name" [enabled]="false"></ejs-textbox>
      </div>

      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.PROCESS_NUMBER' | translate }}:</h4>
        <ejs-textbox [value]="_requestProcessData.workflow_id" [enabled]="false"></ejs-textbox>
      </div>

      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.STATUS' | translate }}:</h4>
        <ejs-textbox [value]="_requestProcessData.state" [enabled]="false"></ejs-textbox>
      </div>
    </div>

    <!-- Subject , Priority , Due Date -->
    <div class="input-container">
      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.SUBJECT' | translate }}:</h4>
        <ejs-textbox
          #subject
          id="subject"
          placeholder="Subject"
          formControlName="subject"
          [value]="_requestProcessData?.request_subject"
          [enabled]="documentRejected"
        ></ejs-textbox>
      </div>

      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.PRIORITY' | translate }}:</h4>
        <ejs-dropdownlist
          #priority
          id="priority"
          formControlName="priority"
          [dataSource]="dataPriority"
          [fields]="fieldsPriority"
          [value]="_requestProcessData?.request_priority_id"
          [enabled]="documentRejected"
        ></ejs-dropdownlist>
      </div>

      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.DUE_DATE' | translate }}:</h4>
        <ejs-datepicker
          placeholder="Due date"
          formControlName="dueDate"
          [value]="_requestProcessData.request_due_date"
          [enabled]="documentRejected"
        ></ejs-datepicker>
      </div>
    </div>

    <!-- Description -->
    <div class="input-container">
      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.DESCRIPTION' | translate }}:</h4>
        <ejs-textarea
          id="description"
          placeholder="Description"
          resizeMode="None"
          rows="3"
          width="700px"
          formControlName="description"
          [value]="_requestProcessData?.request_description"
          [enabled]="documentRejected"
        ></ejs-textarea>
      </div>
    </div>

    <!--  Attachment Files -->
    <div class="input-section">
      <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.ATTACHMENT_FILES' | translate }}:</h4>

      <div class="selected-files-list" *ngIf="_requestProcessDataFile && !documentRejected">
        <div class="file-item" *ngFor="let node of _requestProcessDataFile; let last = last">
          <div class="file-content">
            <div class="file-info">
              <!-- <i class="fas fa-file mr-2"></i> -->
              <span class="file-name" (click)="onView(node.node_id)">{{ node.node_name }}</span>
            </div>
          </div>
          <mat-divider *ngIf="!last"></mat-divider>
        </div>
      </div>

      <div *ngIf="documentRejected">
        <button ejs-button iconCss="e-icons e-file-new" class="rounded-button" (click)="openSelectorDialog()">
          {{ 'APPROVAL-WORKFLOW.MY-TASKS.SELECTED_FILES' | translate }}
        </button>
        <div class="selected-files-list2" *ngIf="listNodeUploadActive" style="margin-top: 10px">
          <div class="file-item2" *ngFor="let node of listNodeUpload; let last = last">
            <div class="file-content2">
              <div class="file-info2">
                <!-- <i class="fas fa-file mr-2"></i> -->
                <span class="file-name">{{ node.node_name }}</span>
                <div class="e-section-control">
                  <div class="e-btn-group">
                    <button ejs-button (click)="onView(node.node_id)">
                      <!-- <i class="fas fa-eye"></i>  -->
                      {{ 'APPROVAL-WORKFLOW.MY-TASKS.VIEW' | translate }}
                    </button>
                    <button ejs-button (click)="onRemove(node.node_id)">
                      <!-- <i class="fas fa-trash"></i>  -->
                      {{ 'APPROVAL-WORKFLOW.MY-TASKS.REMOVE' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <mat-divider *ngIf="!last"></mat-divider>
          </div>
        </div>
      </div>
    </div>

    <!-- History and comments -->
    <div class="input-container">
      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.HISTORY_AND_COMMENT' | translate }}:</h4>
        <mat-list class="scrollable-list">
          <mat-list-item *ngFor="let note of approvalTasks" class="compact-list-item">
            <span class="e-icons e-user"></span>
            <div matListItemTitle class="small-text">{{ note.name }}</div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Comment -->
    <div class="input-container">
      <div class="input-section">
        <h4 class="adjusted-paragraph">{{ 'APPROVAL-WORKFLOW.MY-TASKS.COMMENT' | translate }}:</h4>
        <ejs-textarea
          #remark
          id="remark"
          placeholder="{{ 'APPROVAL-WORKFLOW.MY-TASKS.COMMENT_PLACHOLDER' | translate }}"
          resizeMode="None"
          rows="3"
          width="700px"
          [disabled]="lockAgreeAction"
        ></ejs-textarea>
      </div>
    </div>
  </div>

  <div style="text-align: right; margin-top: 10px">
    <button
      ejs-button
      class="rounded-button"
      iconCss="e-icons e-circle-check"
      style="margin-left: 10px"
      cssClass="e-info"
      (click)="onReview()"
      [disabled]="lockAgreeAction"
    >
      {{ 'APPROVAL-WORKFLOW.MY-TASKS.AGREE' | translate }}
    </button>
    <button
      ejs-button
      class="rounded-button"
      iconCss="e-icons e-circle-close-2"
      style="margin-left: 10px"
      (click)="onRevise()"
      [disabled]="lockAgreeAction || documentRejected"
    >
      <!-- <i class="fa-solid fa-eject"></i>  -->
      {{ 'APPROVAL-WORKFLOW.MY-TASKS.REVISE' | translate }}
    </button>
  </div>
</form>
