<aca-page-layout>
  <aca-page-layout-header>
    <h1 class="aca-page-title">Analytic Report</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>
  </aca-page-layout-header>

  <div class="aca-page-layout-error">
    <aca-generic-error></aca-generic-error>
  </div>

  <aca-page-layout-content style="overflow: auto; padding-bottom: 10px">
    <div class="control-section">
      <ejs-dashboardlayout id="defaultLayout" #defaultLayout [columns]="6" [cellSpacing]="cellSpacing" [allowDragging]="false">
        <e-panels>
          <e-panel [sizeX]="2" [sizeY]="1" [row]="0" [col]="0">
            <ng-template #content>
              <div class="card">
                <div class="header-line">
                  <div class="card-content header" style="margin-top: 20px; margin-left: 20px">
                    <mat-icon>storage</mat-icon>
                    <span class="text">{{ totalFileSizeTitle }}</span>
                  </div>
                </div>
                <div class="card-content number">{{ sumTotal }} MB</div>
              </div>
            </ng-template>
          </e-panel>

          <e-panel [sizeX]="2" [sizeY]="1" [row]="0" [col]="2">
            <ng-template #content>
              <div class="card">
                <div class="header-line">
                  <div class="card-content header" style="margin-top: 20px; margin-left: 20px">
                    <mat-icon>file_copy</mat-icon>
                    <span class="text"> {{ totalQtyFileTitle }}</span>
                  </div>
                </div>
                <div class="card-content number">{{ countValues }}</div>
              </div>
            </ng-template>
          </e-panel>

          <!-- CHART -->

          <e-panel [sizeX]="2" [sizeY]="2" [row]="0" [col]="4">
            <ng-template #header>
              <div class="title" id="header1">{{ filesCreatedByYearTitle }}</div>
            </ng-template>
            <ng-template #content>
              <app-chart-pie class="full-width-height"></app-chart-pie>
            </ng-template>
          </e-panel>

          <e-panel [sizeX]="4" [sizeY]="2" [row]="2" [col]="0">
            <ng-template #header>
              <div class="title" id="header1">{{ filesCreatedByMonthTitle }}</div>
            </ng-template>
            <ng-template #content>
              <app-chart-dimensions class="full-width-height"></app-chart-dimensions>
            </ng-template>
          </e-panel>

          <e-panel [sizeX]="4" [sizeY]="1" [row]="4" [col]="0">
            <ng-template #header>
              <div class="title" id="header1">{{ fileAspectByPropetiesTitle }}</div>
            </ng-template>
            <ng-template #content>
              <app-chart-area class="full-width-height"></app-chart-area>
            </ng-template>
          </e-panel>

          <e-panel [sizeX]="2" [sizeY]="2" [row]="3" [col]="4">
            <ng-template #header>
              <div class="title" id="header1">{{ fileCountByTypeTitle }}</div>
            </ng-template>
            <ng-template #content>
              <app-chart-barcolums class="full-width-height"></app-chart-barcolums>
            </ng-template>
          </e-panel>

          <e-panel [sizeX]="3" [sizeY]="2" [row]="6" [col]="3">
            <ng-template #header>
              <div class="title" id="header1">{{ fileCountBySizeTitle }}</div>
            </ng-template>
            <ng-template #content>
              <app-chart-semipie class="full-width-height"></app-chart-semipie>
            </ng-template>
          </e-panel>

          <e-panel [sizeX]="3" [sizeY]="2" [row]="6" [col]="0">
            <ng-template #header>
              <div class="title" id="header1">{{ fileCountPerLibraryTitle }}</div>
            </ng-template>
            <ng-template #content>
              <app-chart-donut class="full-width-height"></app-chart-donut>
            </ng-template>
          </e-panel>
        </e-panels>
      </ejs-dashboardlayout>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
