/*!
 * @license
 * Copyright 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { CoreModule } from '@alfresco/adf-core';
import { ContentMetadataComponent } from './components/content-metadata/content-metadata.component';
import { ContentMetadataCardComponent } from './components/content-metadata-card/content-metadata-card.component';
import { TagModule } from '@alfresco/adf-content-services';
import { CategoriesModule } from '@alfresco/adf-content-services';
import { ExtensionsModule } from '@alfresco/adf-extensions';
import { ContentMetadataHeaderComponent } from './components/content-metadata/content-metadata-header.component';

import { CardViewModule2 } from '../card-view/card-view.module';
import { DropdownTreeSelectedModule } from '../dropdown-tree/dropdown-tree.module';
import { DropdownMultiSelectedModule } from '../dropdown-multi-select/dropdown-multi-select.module';
import { DropdownListModule } from '../dropdown-list/dropdown-list.module';
// import { DropdownTreeSelectedModule } from './components/dropdown-tree/dropdown-tree.module';

import { FormsModule } from '@angular/forms'

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    TagModule,
    CategoriesModule,
    ExtensionsModule,
    CardViewModule2,
    DropdownTreeSelectedModule,
    DropdownMultiSelectedModule,
    DropdownListModule,
    FormsModule,
    ContentMetadataHeaderComponent
  ],
  declarations: [
    ContentMetadataComponent,
    ContentMetadataCardComponent
  ],
  exports: [
    ContentMetadataComponent,
    ContentMetadataCardComponent,
    ContentMetadataHeaderComponent
  ]
})
export class ContentMetadataModule2 { }
