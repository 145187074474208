<h2 mat-dialog-title>Config Site Members Library</h2>

<mat-dialog-content>
  <ejs-tab id="element">
    <e-tabitems>
      <e-tabitem>
        <ng-template #headerText>
          <div>User</div>
        </ng-template>
        <ng-template #content>
          <!-- Button -->
          <button ejs-button class="rounded-button" style="margin-top: 10px" (click)="addUser()">Add User</button>
          <!-- Table -->
          <ejs-grid
            style="margin-top: 10px"
            [dataSource]="dataUser"
            width="100%"
            height="100%"
            [rowHeight]="25"
            [toolbar]="toolbar"
            [pageSettings]="pageSettings"
            [enableHover]="false"
            [allowResizing]="true"
            [allowSorting]="true"
            allowPaging="true"
            [contextMenuItems]="contextMenuItems"
            (contextMenuClick)="contextMenuUserClick($event)"
          >
            <e-columns>
              <!-- <e-column field="memberGroup" headerText="MEMBER GROUPS" type="checkbox" ></e-column> -->

              <e-column field="displayName" headerText="DISPLAYNAME"></e-column>
              <e-column field="id" headerText="USERID"></e-column>
              <e-column field="email" headerText="EMAIL"></e-column>
              <e-column field="role" headerText="ROLE"></e-column>
              <e-column field="memberGroup" headerText="MEMBER GROUPS">
                <ng-template #template let-data>
                  <input type="checkbox" [checked]="data.memberGroups" />
                </ng-template>
              </e-column>
              
            </e-columns>
          </ejs-grid>
        </ng-template>
      </e-tabitem>
      <e-tabitem>
        <ng-template #headerText>
          <div>GROUP</div>
        </ng-template>
        <ng-template #content>
          <!-- Button -->
          <button ejs-button class="rounded-button" style="margin-top: 10px" (click)="addGroups()">Add Group</button>
          <!-- Table -->
          <ejs-grid
            style="margin-top: 10px"
            [dataSource]="dataGroup"
            width="100%"
            height="100%"
            [rowHeight]="25"
            [toolbar]="toolbar"
            [pageSettings]="pageSettings"
            [enableHover]="false"
            [allowResizing]="true"
            [allowSorting]="true"
            allowPaging="true"
            [contextMenuItems]="contextMenuItems"
            (contextMenuClick)="contextMenuGroupClick($event)"
          >
            <e-columns>
              <e-column field="displayName" headerText="DISPLAYNAME"></e-column>
              <e-column field="id" headerText="GROUPID"></e-column>
              <e-column field="role" headerText="ROLE" editType="dropdownedit"></e-column>
            </e-columns>
          </ejs-grid>
        </ng-template>
      </e-tabitem>
    </e-tabitems>
  </ejs-tab>

  <div style="margin-bottom: 25px"></div>
  <div mat-dialog-actions style="text-align: right">
    <!-- <button ejs-button class="rounded-button">Create</button> -->
    <button ejs-button class="rounded-button" (click)="onCancel()">Cancel</button>
  </div>
</mat-dialog-content>
