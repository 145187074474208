import { LibraryDialogComponent, SitesService } from '@alfresco/adf-content-services';
import { AlfrescoApiService, NotificationService } from '@alfresco/adf-core';
import { QueriesApi, SiteBodyCreate, SiteEntry, SitePaging } from '@alfresco/js-api';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, finalize, mergeMap, takeUntil } from 'rxjs/operators';
import { UploadFilesDialogComponent } from '../../components/upload-files-dialog/upload-files-dialog.component';
import { MatRadioModule } from '@angular/material/radio';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSelectModule,
    MatIconModule,
    MatAutocompleteModule,
    UploadFilesDialogComponent,
    MatCardModule,
    FormsModule,
    MatRadioModule
  ],
  selector: 'adf-library-dialog',
  styleUrls: ['./library.dialog.scss'],
  templateUrl: './library.dialog.html',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'adf-library-dialog' }
})

export class LibraryDialogCustomComponent implements OnInit, OnDestroy {

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  error: EventEmitter<any> = new EventEmitter<any>();



  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  success: EventEmitter<any> = new EventEmitter<any>();

  onDestroy$: Subject<boolean> = new Subject<boolean>();


  createTitle = 'LIBRARY.DIALOG.CREATE_TITLE';
  libraryTitleExists = false;
  form: UntypedFormGroup;
  visibilityOption: any;
  visibilityOptions = [
    { value: 'PUBLIC', label: 'LIBRARY.VISIBILITY.PUBLIC', disabled: false },
    { value: 'PRIVATE', label: 'LIBRARY.VISIBILITY.PRIVATE', disabled: false }
    // {
    //   value: 'MODERATED',
    //   label: 'LIBRARY.VISIBILITY.MODERATED',
    //   disabled: false
    // }
  ];
  disableCreateButton = false;

  _queriesApi: QueriesApi;
  get queriesApi(): QueriesApi {
    this._queriesApi = this._queriesApi ?? new QueriesApi(this.alfrescoApiService.getInstance());
    return this._queriesApi;
  }

  constructor(
    private alfrescoApiService: AlfrescoApiService,
    private sitesService: SitesService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialogRef<LibraryDialogComponent>,
    private notificationService: NotificationService
  ) {
  }


  ngOnInit() {
    const validators = {
        id: [
            Validators.required,
            Validators.maxLength(72),
            this.forbidSpecialCharacters
        ],
        title: [
            Validators.required,
            this.forbidOnlySpaces,
            Validators.minLength(2),
            Validators.maxLength(256)
        ],
        description: [Validators.maxLength(512)]
    };

    this.form = this.formBuilder.group({
        title: [null, validators.title],
        id: [null, validators.id, this.createSiteIdValidator()],
        description: ['', validators.description]
    });

    this.visibilityOption = this.visibilityOptions[0].value;

    this.form.controls['title'].valueChanges
        .pipe(
            debounceTime(500),
            mergeMap(
                (title) => this.checkLibraryNameExists(title),
                (title) => title
            ),
            takeUntil(this.onDestroy$)
        )
        .subscribe((title: string) => {
            if (!this.form.controls['id'].dirty && this.canGenerateId(title)) {
                this.form.patchValue({ id: this.sanitize(title.trim()) });
                this.form.controls['id'].markAsTouched();
            }
        });
}

ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
}

get title(): string {
    const { title } = this.form.value;

    return (title || '').trim();
}

get id(): string {
    const { id } = this.form.value;

    return (id || '').trim();
}

get description(): string {
    const { description } = this.form.value;

    return (description || '').trim();
}

get visibility(): string {
    return this.visibilityOption || '';
}

submit() {
    const { form, dialog } = this;

    if (!form.valid) {
        return;
    }

    this.disableCreateButton = true;
    this.create().pipe(finalize(() => this.disableCreateButton = false)).subscribe(
        (node: SiteEntry) => {
            this.success.emit(node);
            dialog.close(node);
        },
        (error) => this.handleError(error)
    );
}

visibilityChangeHandler(event) {
    this.visibilityOption = event.value;
}

private create(): Observable<SiteEntry> {
    const { title, id, description, visibility } = this;
    const siteBody = {
        id,
        title,
        description,
        visibility
    } as SiteBodyCreate;

    return this.sitesService.createSite(siteBody);
}

private sanitize(input: string) {
    return input.replace(/[\s\s]+/g, '-').replace(/[^A-Za-z0-9-]/g, '');
}

private canGenerateId(title) {
    return Boolean(title.replace(/[^A-Za-z0-9-]/g, '').length);
}

private handleError(error: any): any {
    const errorMessage = 'CORE.MESSAGES.ERRORS.GENERIC';

    try {
        const {
            error: { statusCode }
        } = JSON.parse(error.message);

        if (statusCode === 409) {
            this.form.controls['id'].setErrors({
                message: 'LIBRARY.ERRORS.CONFLICT'
            });
        } else {
            this.notificationService.showError(errorMessage);
        }
    } catch {
        this.notificationService.showError(errorMessage);
    }

    return error;
}

private async checkLibraryNameExists(libraryTitle: string) {
    let entries = [];

    try {
        entries = (await this.findLibraryByTitle(libraryTitle)).list.entries;
    } catch {
        entries = [];
    }

    if (entries.length) {
        this.libraryTitleExists = entries[0].entry.title.toLowerCase() === libraryTitle.toLowerCase();
    } else {
        this.libraryTitleExists = false;
    }
}

private findLibraryByTitle(libraryTitle: string): Promise<SitePaging> {
    return this.queriesApi.findSites(libraryTitle, {
            maxItems: 1,
            fields: ['title']
        });
}

private forbidSpecialCharacters({ value }: UntypedFormControl) {
    if (value === null || value.length === 0) {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    const validCharacters: RegExp = /[^A-Za-z0-9-]/;
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    const isValid: boolean = !validCharacters.test(value);

    return isValid
        ? null
        : {
            message: 'LIBRARY.ERRORS.ILLEGAL_CHARACTERS'
        };
}

private forbidOnlySpaces({ value }: UntypedFormControl) {
    if (value === null || value.length === 0) {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    const isValid: boolean = !!(value || '').trim();

    return isValid
        ? null
        : {
            message: 'LIBRARY.ERRORS.ONLY_SPACES'
        };
}

private createSiteIdValidator() {
    let timer;

    return (control: AbstractControl) => {
        if (timer) {
            clearTimeout(timer);
        }

        return new Promise((resolve) => {
            timer = setTimeout(() => this.sitesService.getSite(control.value).subscribe(
                () => resolve({ message: 'LIBRARY.ERRORS.EXISTENT_SITE' }),
                () => resolve(null)
            ), 300);
        });
    };
}
}
