<aca-page-layout>
  <div class="aca-page-layout-header">
    <button mat-icon-button [routerLink]="landingPage">
      <mat-icon class="app-profile-icon">arrow_back</mat-icon>
    </button>
    <h1>{{ 'APP.BROWSE.ABOUT.TITLE' | translate }}</h1>
  </div>

  <div class="aca-page-layout-content scrollable">
    <adf-about>
      <adf-about-panel [label]="'License'" *ngIf="repository">
        <ng-template>
          <input hidden type="file" accept=".lic" #fileInput (change)="onUploadLicenseFile($event)" />
          <button mat-raised-button (click)="fileInput.click()"><mat-icon>info_outline</mat-icon>Upload License (bcircle.lic)</button>
          <div *ngIf="licenseValueEntries">
            <mat-table [dataSource]="licenseValueEntries">
              <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                <mat-header-cell *matHeaderCellDef>
                  {{ column.header | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row">{{ column.cell(row) }}</mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
          </div>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel *ngIf="dev" [label]="'ABOUT.SERVER_SETTINGS.TITLE' | translate">
        <ng-template>
          <adf-about-server-settings></adf-about-server-settings>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel [label]="'ABOUT.REPOSITORY' | translate" *ngIf="repository">
        <ng-template>
          <adf-about-repository-info [data]="repository"></adf-about-repository-info>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel *ngIf="dev" [label]="'ABOUT.PACKAGES.TITLE' | translate">
        <ng-template>
          <adf-about-package-list [dependencies]="packageJson?.dependencies"></adf-about-package-list>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel *ngIf="extensions$ | async as extensions" [label]="'ABOUT.PLUGINS.TITLE' | translate">
        <ng-template>
          <adf-about-extension-list [data]="extensions"></adf-about-extension-list>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel [label]="'Version'">
        <ng-template>
          <div>
            <p>Beflex Workspace Application: {{ releaseVersion }}</p>
          </div>
        </ng-template>
      </adf-about-panel>
    </adf-about>
  </div>
</aca-page-layout>
