
import { Component, Input, OnInit } from '@angular/core';
import { AnalyticService } from '../../services/analytic-service.service';


interface ChartData {
  month: string;
  year: number;
  monthYear: string;
  qty: number;
  difference?: number;  // เพิ่มคุณสมบัติ difference
}

@Component({
  selector: 'app-chart-dimensions',
  templateUrl: './chart-dimensions.component.html',
  styleUrls: ['./chart-dimensions.component.scss']
})
export class ChartDimensionsComponent implements OnInit {
  public groupingSeparator = true;

  public primaryXAxis = {
    valueType: 'Category',
    labelIntersectAction: 'Wrap'
  };

  public chartData?: ChartData[];

  public marker = {
    visible: true, height: 10, width: 10, dataLabel: {
      visible: true,
      position: 'Outer',
      margin: { top: 70 },
    }
  }

  public primaryYAxis = {
    labelFormat: 'n0', // Compact number format
    decimalPlaces: 2 // Optional: specify the number of decimal places
  };

  public tooltip: object = {
    enable: true
  };

  @Input() data: any;

  constructor(private analyticService: AnalyticService) { }

  thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ];
  // async ngOnInit() {
  //   const thaiMonths = [
  //     'มกราคม',
  //     'กุมภาพันธ์',
  //     'มีนาคม',
  //     'เมษายน',
  //     'พฤษภาคม',
  //     'มิถุนายน',
  //     'กรกฎาคม',
  //     'สิงหาคม',
  //     'กันยายน',
  //     'ตุลาคม',
  //     'พฤศจิกายน',
  //     'ธันวาคม'
  //   ];

  //   await (await this.analyticService.filesCreatedByMonth()).subscribe(
  //     data => {
  //       this.chartData = data.list.context.facetQueries.map(item => {
  //         console.log('item ', item);
  //         const [month, year] = item.label.split(' ');
  //         const thaiMonth = thaiMonths[new Date(Date.parse(`1 ${month} 2000`)).getMonth()];
  //         return {
  //           month: `${thaiMonth} ${year}`,
  //           year: parseInt(year, 10),
  //           monthYear: `${thaiMonth} ${year}`,
  //           qty: parseInt(item.count, 10),
  //         } as ChartData;  // ใช้อินเตอร์เฟซ ChartData
  //       })
  //         .sort((a, b) => {
  //           const months = [
  //             'มกราคม',
  //             'กุมภาพันธ์',
  //             'มีนาคม',
  //             'เมษายน',
  //             'พฤษภาคม',
  //             'มิถุนายน',
  //             'กรกฎาคม',
  //             'สิงหาคม',
  //             'กันยายน',
  //             'ตุลาคม',
  //             'พฤศจิกายน',
  //             'ธันวาคม'
  //           ];
  //           if (a.year === b.year) {
  //             return months.indexOf(a.month) - months.indexOf(b.month);
  //           }
  //           return a.year - b.year;
  //         });

  //       // คำนวณความแตกต่างระหว่างเดือน
  //       for (let i = 1; i < this.chartData.length; i++) {
  //         this.chartData[i].difference = this.chartData[i].qty - this.chartData[i - 1].qty;
  //       }

  //       console.log('this.chartData ', this.chartData);


  //     },
  //     error => {
  //       console.error('Error fetching data', error);
  //     }
  //   );
  // }

  async ngOnInit() {
    await (await this.analyticService.filesCreatedByMonth()).subscribe(
      data => {
        this.chartData = data.list.context.facetQueries.map(item => {
          const [month, year] = item.label.split(' ');
          const thaiMonth = this.thaiMonths[new Date(Date.parse(`1 ${month} 2000`)).getMonth()];
          return {
            month: `${thaiMonth} ${year}`,
            year: parseInt(year, 10),
            monthYear: `${thaiMonth} ${year}`,
            qty: parseInt(item.count, 10),
          } as ChartData;
        })
          .sort((a, b) => {
            if (a.year === b.year) {
              return this.thaiMonths.indexOf(a.month.split(' ')[0]) - this.thaiMonths.indexOf(b.month.split(' ')[0]);
            }
            return a.year - b.year;
          });

        // คำนวณความแตกต่างระหว่างเดือน
        for (let i = 1; i < this.chartData.length; i++) {
          this.chartData[i].difference = this.chartData[i].qty - this.chartData[i - 1].qty;
        }

        console.log('this.chartData ', this.chartData);
      },
      error => {
        console.error('Error fetching data', error);
      }
    );
  }


  onAxisLabelRender(args: any): void {
    if (args.axis.name === 'primaryYAxis') {
      args.text = Number(args.value).toLocaleString('en-US');  // เพิ่ม comma ในตัวเลข
    }
  }

  onTooltipRender(args: any): void {
    if (args.series.type === 'Spline' && args.point.y < 0) {
      args.text = `ลดลง: ${args.point.y} ไฟล์`;
    } else if (args.series.type === 'Spline') {
      args.text = `เพิ่มขึ้น: ${args.point.y} ไฟล์`;
    }
  }
}
