/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/*!
 * Copyright 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { AppConfigService, DataTableModule, FormBaseModule, PaginationModule, ShowHeaderMode, UserPreferencesService } from '@alfresco/adf-core';
import { AfterContentInit, AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NodeEntry, Node, PathElement, NodePaging, ResultSetPaging, SearchRequest, Pagination } from '@alfresco/js-api';
import { NodeActionsService } from '../../services/node-actions.service';
import {
  ContentApiService,
  ContextActionsDirective,
  GenericErrorComponent,
  InfoDrawerComponent,
  PageComponent,
  PageLayoutComponent,
  PaginationDirective,
  ToolbarComponent
} from '@alfresco/aca-shared';
import { SetCurrentFolderAction, isAdmin, UploadFileVersionAction, showLoaderSelector, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  FilterSearch,
  ShareDataRow,
  FileUploadEvent,
  UploadModule,
  UploadFilesEvent,
  RowFilter,
  TreeViewModule,
  DocumentListModule,
  SearchModule,
  SearchService
} from '@alfresco/adf-content-services';
import { DocumentListPresetRef, ExtensionsModule } from '@alfresco/adf-extensions';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentListDirective } from '../../directives/document-list.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AcaViewerDrawerModule } from '@alfresco/aca-content/viewer-drawer';
import { MatCardModule } from '@angular/material/card';
import { PageLayoutModule } from '@alfresco/aca-shared';
import { Observable } from 'rxjs';
import { TagsColumnComponent } from '../dl-custom-components/tags-column/tags-column.component';
import { SearchResultsRowComponent } from '../search/search-results-row/search-results-row.component';
import { ThumbnailColumnComponent } from '../dl-custom-components/thumbnail-column/thumbnail-column.component';
import { NodeViewerService } from '../../services/node-viewer.service';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TextBoxComponent, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { ContentManagementService } from '../../services/content-management.service';
import { BreadcrumbModule } from '../breadcrumb';
// import { ConfigExtensionService } from '../service/config-extension.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    GenericErrorComponent,
    UploadModule,
    DocumentListModule,
    DocumentListDirective,
    ContextActionsDirective,
    DataTableModule,
    ExtensionsModule,
    PaginationModule,
    MatProgressSpinnerModule,
    InfoDrawerComponent,
    PaginationDirective,
    PageLayoutComponent,
    ToolbarComponent,
    AcaViewerDrawerModule,
    MatCardModule,
    PageLayoutModule,
    TagsColumnComponent,
    SearchResultsRowComponent,
    ThumbnailColumnComponent,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TextBoxModule,
    ButtonModule,
    TreeViewModule,
    SearchModule,
    MatProgressBarModule,
    FormBaseModule,
    ProgressButtonModule,
    BreadcrumbModule
  ],
  templateUrl: './files.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FilesComponent extends PageComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
  isValidPath = true;
  isAdmin = false;
  selectedNode: NodeEntry;
  queryParams = null;

  showLoader$ = this.store.select(showLoaderSelector);
  private nodePath: PathElement[];

  columns: DocumentListPresetRef[] = [];
  isFilterHeaderActive = false;

  rowsFilter: RowFilter;

  isContentAction$: Observable<boolean>;

  maxVisibleFolder = 10;

  @Input()
  sortingMode: 'server' | 'client' = 'server';

  @ViewChild('searchBox') searchBox: TextBoxComponent;

  // @Input()
  // where: string;

  searchTerm: string = '';
  results: NodePaging;
  data: ResultSetPaging;
  currentPage = 0;

  // ไม่ได้ใช้งาน ใช้ this.preferences.paginationSize แทน
  pageSize = 25;

  skipCount = 0;
  maxItems = 0;

  // nodeResult: NodePaging;

  currentSorting: any[] = ['name', 'ASC'];
  ascending: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private contentApi: ContentApiService,
    private nodeActionsService: NodeActionsService,
    private config: AppConfigService,
    private nodeViewerService: NodeViewerService,
    private searchService: SearchService,
    private contentService: ContentManagementService,
    private preferences: UserPreferencesService // private configExtService: ConfigExtensionService
  ) {
    super();
    document.addEventListener('keydown', (_event) => {
      this.isCommandCtrl = true;
      setTimeout(() => {
        this.isCommandCtrl = false;
      }, 1000);
    });

    // Get watermark data
    // this.configExtService.getWatermark().subscribe(watermarkData => {
    //   console.log('watermark data:', watermarkData);
    // });
  }

  ngOnChanges() {}

  overwriteUploadActive = false;
  majorVersionActive = true;

  async ngOnInit() {
    super.ngOnInit();

    // const watermarkData = await this.configExtService.getWatermark();
    // console.log('watermark data:', watermarkData);

    const { data } = this.route.snapshot;
    this.title = data.title;
    this.route.queryParamMap.subscribe((queryMap: Params) => {
      this.queryParams = queryMap.params;
    });
    this.route.params.subscribe(({ folderId }: Params) => {
      const nodeId = folderId || data.defaultNodeId;
      this.contentApi.getNode(nodeId).subscribe(
        (node) => {
          this.isValidPath = true;
          if (node.entry && node.entry.isFolder) {
            this.updateCurrentNode(node.entry);
          } else {
            this.router.navigate(['/personal-files', node.entry.parentId], {
              replaceUrl: true
            });
          }
        },
        () => (this.isValidPath = false)
      );
    });

    this.subscriptions = this.subscriptions.concat([
      this.nodeActionsService.contentCopied.subscribe((nodes) => this.onContentCopied(nodes)),
      this.uploadService.fileUploadComplete.pipe(debounceTime(300)).subscribe((file) => this.onFileUploadedEvent(file)),
      this.uploadService.fileUploadDeleted.pipe(debounceTime(300)).subscribe((file) => this.onFileUploadedEvent(file))
    ]);

    this.store
      .select(isAdmin)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.isAdmin = value;
      });

    this.extensions.filesDocumentListPreset$.pipe(takeUntil(this.onDestroy$)).subscribe((preset) => {
      this.columns = preset;
    });

    if (this.queryParams && Object.keys(this.queryParams).length > 0) {
      this.isFilterHeaderActive = true;
    }

    const overwriteUpload = this.config.get('uploadNewDocumentVersion.enabled');
    if (overwriteUpload) {
      this.overwriteUploadActive = true;
    }

    if (this.config.get('maxVisibleFolder.value')) {
      const maxVisible = this.config.get('maxVisibleFolder.value') as number;
      if (maxVisible) {
        this.maxVisibleFolder = maxVisible;
      }
    }

    console.log('# this.preferences.paginationSize ', this.preferences.paginationSize);

    // Subscribe to node deletion events
    this.subscriptions.push(
      this.contentService.deleteNodesSubject.subscribe(() => {
        if (this.nodeResult) {
          console.log('# deleteNodesSubject');
          this.onAllFilterCleared();
          this.clearTextFilter();
        }
      })
    );
  }

  ngAfterContentInit() {}

  ngAfterViewInit() {}

  isMajorVersion(): boolean {
    return this.majorVersionActive;
  }

  ngOnDestroy() {
    this.store.dispatch(new SetCurrentFolderAction(null));
    super.ngOnDestroy();
  }

  navigate(nodeId: string = null) {
    const currentNodeId = this.route.snapshot.paramMap.get('folderId');
    const urlWithoutParams = decodeURIComponent(this.router.url).split('?')[0];
    const urlToNavigate: string[] = this.getUrlToNavigate(urlWithoutParams, currentNodeId, nodeId);
    this.router.navigate(urlToNavigate);
    this.clearTextFilter();
  }

  private getUrlToNavigate(currentURL: string, currentNodeId: string, nextNodeId: string): string[] {
    return currentNodeId ? this.getNextNodeUrlToNavigate(currentURL, currentNodeId, nextNodeId) : this.appendNextNodeIdToUrl(currentURL, nextNodeId);
  }

  private getNextNodeUrlToNavigate(currentURL: string, currentNodeId: string, nextNodeId: string): string[] {
    const urlToNavigate: string[] =
      nextNodeId && !this.isRootNode(nextNodeId)
        ? this.replaceCurrentNodeIdWithNextNodeId(currentURL, currentNodeId, nextNodeId)
        : this.removeNodeIdFromUrl(currentURL, currentNodeId);
    urlToNavigate.shift();
    return urlToNavigate;
  }

  private replaceCurrentNodeIdWithNextNodeId(currentURL: string, currentNodeId: string, nextNodeId: string): string[] {
    const nextNodeUrlToNavigate = currentURL.split('/');
    const index = nextNodeUrlToNavigate.indexOf(currentNodeId);
    if (index > 0) {
      nextNodeUrlToNavigate[index] = nextNodeId;
    }
    return nextNodeUrlToNavigate;
  }

  private removeNodeIdFromUrl(currentURL: string, currentNodeId: string): string[] {
    const rootUrl: string[] = currentURL.replace(currentNodeId, '').split('/');
    rootUrl.pop();
    return rootUrl;
  }

  formatDate(formatStr: any) {
    return formatStr;
  }

  private appendNextNodeIdToUrl(currentURL: string, nodeId: string): string[] {
    const navigateToNodeUrl = currentURL.split('/');
    if (nodeId && !this.isRootNode(nodeId)) {
      navigateToNodeUrl.push(nodeId);
    }
    navigateToNodeUrl.shift();
    return navigateToNodeUrl;
  }

  onUploadNewVersion(ev: CustomEvent) {
    this.store.dispatch(new UploadFileVersionAction(ev));
  }

  onBeginUpload(event: UploadFilesEvent) {
    const files = event.files || [];
    if (files.length >= 1) {
      event.pauseUpload();
      const maxFileSizeUpload = this.config.get('maxFileSizeUpload.value');
      if (maxFileSizeUpload) {
        const maxFileSizeUploadByte = Number(maxFileSizeUpload) * 1024 * 1024;
        const validFiles = files.filter((file) => file.size <= maxFileSizeUploadByte);
        if (validFiles.length === 0) {
          this.alertError(`ไฟล์มีขนาดเกินสูงสุดที่อนุญาตคือ ${maxFileSizeUpload}MB.`, null);
          return;
        }
      }
      const fileExcluded: string[] = this.config.get('files.excluded') as string[];
      const excludedPatterns = fileExcluded.map((pattern) => {
        if (pattern.includes('*')) {
          return new RegExp(pattern.replace('*', '.*').replace('.', '\\.') + '$');
        } else {
          return new RegExp(pattern.replace('.', '\\.') + '$');
        }
      });
      let resumeActive = false;
      files.filter((file) => {
        const noUpload = '.DS_Store';
        if (file.name.trim().toUpperCase() !== noUpload.toUpperCase()) {
          const isExcluded = excludedPatterns.some((pattern) => pattern.test(file.name));
          if (isExcluded) {
            this.alertError(`ไม่สามารถอัปโหลดประเภทไฟล์:  ${file.extension} ได้`, null);
          } else {
            resumeActive = true;
          }
        }
      });
      if (resumeActive) {
        event.resumeUpload();
      }
    }
  }

  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 2000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 2000 }));
    }
  }

  navigateTo(node: NodeEntry) {
    if (node && node.entry) {
      this.selectedNode = node;
      const { isFolder } = node.entry;
      if (isFolder) {
        let id: string;
        if (node.entry.nodeType === 'app:folderlink') {
          id = node.entry.properties['cm:destination'];
        } else {
          id = node.entry.id;
        }
        this.documentList.resetNewFolderPagination();
        this.navigate(id);
        return;
      }

      this.nodeViewerService.setNode(node);
      this.showPreview(node, { location: this.router.url });
    }
  }

  isRightClick = false;
  isCommandCtrl = false;
  isRightNotClickActive = false;

  onNodeClicked(_event: Event) {
    this.isRightNotClickActive = false;
    setTimeout(() => {
      if (this.isRightClick || this.isCommandCtrl) {
        this.isRightNotClickActive = false;
      } else {
        this.isRightNotClickActive = true;
      }
    }, 100);
  }

  onRightClick() {
    this.isRightClick = true;
    this.isRightNotClickActive = false;
    setTimeout(() => {
      this.isRightClick = false;
    }, 500);
  }

  isSuccess = true;

  onNameClicked(event: Event) {
    this.navigateTo((event as CustomEvent).detail?.node);
  }

  onNodeUnselect(_event: Event) {}

  handleNodeClick(event: Event) {
    this.navigateTo((event as CustomEvent).detail?.node);
  }

  handleNameClick(event: Event) {
    this.navigateTo((event as CustomEvent).detail?.node);
  }

  onBreadcrumbNavigate(route: PathElement) {
    this.documentList.resetNewFolderPagination();
    // todo: review this approach once 5.2.3 is out
    if (this.nodePath && this.nodePath.length > 2) {
      if (this.nodePath[1].name === 'Sites' && this.nodePath[2].id === route.id) {
        return this.navigate(this.nodePath[3].id);
      }
    }
    this.navigate(route.id);
  }

  onFileUploadedEvent(event: FileUploadEvent) {
    const node: NodeEntry = event.file.data;
    if (this.nodeResult) {
      this.onAllFilterCleared();
      this.clearTextFilter();
    }

    // check root and child nodes
    if (node && node.entry && node.entry.parentId === this.getParentNodeId()) {
      this.reload(this.selectedNode);
      return;
    }

    // check the child nodes to show dropped folder
    if (event && event.file.options.parentId === this.getParentNodeId()) {
      this.displayFolderParent(event.file.options.path, 0);
      return;
    }

    if (event && event.file.options.parentId) {
      if (this.nodePath) {
        const correspondingNodePath = this.nodePath.find((pathItem) => pathItem.id === event.file.options.parentId);
        // check if the current folder has the 'trigger-upload-folder' as one of its parents
        if (correspondingNodePath) {
          const correspondingIndex = this.nodePath.length - this.nodePath.indexOf(correspondingNodePath);
          this.displayFolderParent(event.file.options.path, correspondingIndex);
        }
      }
    }
  }

  displayFolderParent(filePath = '', index: number) {
    const parentName = filePath.split('/')[index];
    const currentFoldersDisplayed = (this.documentList.data.getRows() as ShareDataRow[]) || [];
    const alreadyDisplayedParentFolder = currentFoldersDisplayed.find((row) => row.node.entry.isFolder && row.node.entry.name === parentName);
    if (alreadyDisplayedParentFolder) {
      return;
    }
    this.reload(this.selectedNode);
  }

  onContentCopied(nodes: NodeEntry[]) {
    const newNode = nodes.find((node) => node && node.entry && node.entry.parentId === this.getParentNodeId());
    if (newNode) {
      this.reload(this.selectedNode);
    }
  }

  // todo: review this approach once 5.2.3 is out
  private async updateCurrentNode(node: Node) {
    this.nodePath = null;
    if (node && node.path && node.path.elements) {
      const elements = node.path.elements;

      this.nodePath = elements.map((pathElement) => Object.assign({}, pathElement));

      if (elements.length > 1) {
        if (elements[1].name === 'User Homes') {
          if (!this.isAdmin) {
            elements.splice(0, 2);
          }
        } else if (elements[1].name === 'Sites') {
          await this.normalizeSitePath(node);
        }
      }
    }

    this.node = node;
    this.store.dispatch(new SetCurrentFolderAction(node));
  }

  // todo: review this approach once 5.2.3 is out
  private async normalizeSitePath(node: Node) {
    const elements = node.path.elements;
    elements.splice(1, 1);

    if (this.isSiteContainer(node)) {
      const parentNode = await this.contentApi.getNodeInfo(node.parentId).toPromise();
      node.name = parentNode.properties['cm:title'] || parentNode.name;
      elements.splice(1, 1);
    } else {
      const docLib = elements.findIndex((el) => el.name === 'documentLibrary');
      if (docLib > -1) {
        const siteFragment = elements[docLib - 1];
        const siteNode = await this.contentApi.getNodeInfo(siteFragment.id).toPromise();
        siteFragment.name = siteNode.properties['cm:title'] || siteNode.name;
        elements.splice(docLib, 1);
      }
    }
  }

  isSiteContainer(node: Node): boolean {
    if (node && node.aspectNames && node.aspectNames.length > 0) {
      return node.aspectNames.indexOf('st:siteContainer') >= 0;
    }
    return false;
  }

  isRootNode(nodeId: string): boolean {
    if (this.node && this.node.path && this.node.path.elements && this.node.path.elements.length > 0) {
      return this.node.path.elements[0].id === nodeId;
    }
    return false;
  }

  onFilterSelected(activeFilters: FilterSearch[]) {
    if (activeFilters.length) {
      this.showHeader = ShowHeaderMode.Always;
      this.isFilterHeaderActive = true;
      this.navigateToFilter(activeFilters);
      const nameValue = activeFilters.find((item) => item.key === 'name').value;
      this.rowFilters(nameValue);
    } else {
      this.router.navigate(['.'], { relativeTo: this.route });
      this.isFilterHeaderActive = false;
      this.showHeader = ShowHeaderMode.Data;
      this.onAllFilterCleared();
      this.rowFilters(null);
    }
  }

  navigateToFilter(activeFilters: FilterSearch[]) {
    const objectFromMap = {};
    activeFilters.forEach((filter: FilterSearch) => {
      let paramValue;
      if (filter.value && filter.value.from && filter.value.to) {
        paramValue = `${filter.value.from}||${filter.value.to}`;
      } else {
        paramValue = filter.value;
      }
      objectFromMap[filter.key] = paramValue;
    });

    this.router.navigate([], { relativeTo: this.route, queryParams: objectFromMap });
  }

  onError() {
    this.isValidPath = false;
  }

  onSortingChangeds(event: any): void {
    this.onSortingChanged(event);
    if (this.nodeResult) {
      this.currentSorting = [event.detail.key, event.detail.direction];
      this.ascending = event.detail.direction === 'asc';
      this.onSort();
    }
  }

  private generateSearchQuery(searchTerm: string): string {
    console.log('# generateSearchQuery');
    let query = `(cm:name:'*${this.searchTerm}*')`;
    const filterInFolder = this.config.get('search')[0]?.filterInFolder;
    if (filterInFolder) {
      const enabled = filterInFolder.enabled;
      if (enabled) {
        const queryTemplate = filterInFolder.query as string;
        query = queryTemplate.replace('${searchTerm}', searchTerm);
      }
    }
    return query;
  }

  private generateInclude(): string[] {
    let include: string[] = ['path', 'allowableOperations', 'properties'];
    const filterInFolder = this.config.get('search')[0]?.filterInFolder;
    if (filterInFolder) {
      const enabled = filterInFolder.enabled;
      if (enabled) {
        const includeTemplate = filterInFolder.include as string[];
        if (Array.isArray(includeTemplate)) {
          include = includeTemplate;
        }
      }
    }
    return include;
  }

  async onSearch(): Promise<void> {
    console.log('onSearch');
    const inputElement = document.querySelector('ejs-textbox input') as HTMLInputElement;
    this.searchTerm = inputElement?.value;

    if (!this.searchTerm) {
      this.nodeResult = null;
      this.onAllFilterCleared();
      return;
    }

    const folderNodeId = this.node?.id;

    if (this.maxItems === 0) {
      this.maxItems = this.preferences.paginationSize;
    }

    const query = this.generateSearchQuery(this.searchTerm);

    const queryBody: SearchRequest = {
      query: {
        query: query,
        language: 'afts'
      },
      include: this.generateInclude(),
      includeRequest: true,
      paging: {
        maxItems: this.maxItems,
        skipCount: this.skipCount
      },
      filterQueries: [
        {
          query: `PARENT:"workspace://SpacesStore/${folderNodeId}"`
        }
      ],
      limits: {
        permissionEvaluationTime: 20000,
        permissionEvaluationCount: 2000
      },
      sort: [
        {
          type: 'FIELD',
          field: `cm:name`,
          ascending: this.ascending
        }
      ]
    };

    await this.searchService.searchByQueryBody(queryBody).subscribe({
      next: (searchResults: NodePaging) => {
        const folders = searchResults.list.entries.filter((entry) => entry.entry.isFile === false);
        const files = searchResults.list.entries.filter((entry) => entry.entry.isFile === true);
        let resultFiles = [];
        let resultFolder = [];
        resultFiles = files;
        resultFolder = folders;

        const sortedEntries = [...resultFolder, ...resultFiles];
        this.nodeResult = {
          list: {
            entries: sortedEntries,
            pagination: {
              count: searchResults.list.pagination.count,
              hasMoreItems: searchResults.list.pagination.hasMoreItems,
              totalItems: searchResults.list.pagination.totalItems,
              skipCount: searchResults.list.pagination.skipCount,
              maxItems: searchResults.list.pagination.maxItems
            }
          }
        };
      },
      error: (error) => {
        console.error('Search error:', error);
        if (error.status === 404) {
          console.error('Folder not found:', folderNodeId);
        }
      }
    });
  }

  sortField() {
    const column = this.currentSorting[0];
    const sortFieldMapping: { [key: string]: string } = {
      name: 'cm:name',
      'content.sizeInBytes': 'content.size',
      modifiedAt: 'cm:modifier',
      'modifiedByUser.displayName': 'cm:modified',
      'createdByUser.displayName': 'cm:creator'
    };
    return sortFieldMapping[column] || 'cm:name';
  }

  onSort(): void {
    const inputElement = document.querySelector('ejs-textbox input') as HTMLInputElement;
    this.searchTerm = inputElement?.value;
    const folderNodeId = this.node?.id;

    if (this.maxItems === 0) {
      this.maxItems = this.preferences.paginationSize;
    }

    const query = this.generateSearchQuery(this.searchTerm);

    const queryBody: SearchRequest = {
      query: {
        query: query,
        language: 'afts'
      },
      include: this.generateInclude(),
      paging: {
        maxItems: this.maxItems,
        skipCount: this.skipCount
      },
      filterQueries: [
        {
          query: `PARENT:"workspace://SpacesStore/${folderNodeId}"`
        }
      ],
      limits: {
        permissionEvaluationTime: 20000,
        permissionEvaluationCount: 2000
      },
      sort: [
        {
          type: 'FIELD',
          field: this.sortField(),
          ascending: this.ascending
        }
      ]
    };

    this.searchService.searchByQueryBody(queryBody).subscribe({
      next: (searchResults: NodePaging) => {
        const folders = searchResults.list.entries.filter((entry) => entry.entry.isFile === false);
        const files = searchResults.list.entries.filter((entry) => entry.entry.isFile === true);

        let resultFiles = [];
        let resultFolder = [];

        resultFiles = files;
        resultFolder = folders;

        const sortedEntries = [...resultFolder, ...resultFiles];
        this.nodeResult = {
          list: {
            entries: sortedEntries,
            pagination: {
              count: searchResults.list.pagination.count,
              hasMoreItems: searchResults.list.pagination.hasMoreItems,
              totalItems: searchResults.list.pagination.totalItems,
              skipCount: searchResults.list.pagination.skipCount,
              maxItems: searchResults.list.pagination.maxItems
            }
          }
        };
      },
      error: (error) => {
        console.error('Search error:', error);
        if (error.status === 404) {
          console.error('Folder not found:', folderNodeId);
        }
      }
    });
  }

  onPaginationChanged(pagination: Pagination) {
    this.skipCount = pagination.skipCount;
    this.maxItems = pagination.maxItems;
    console.log('pagination ', pagination);
    if (this.nodeResult) {
      this.onSort();
    }
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSearch();
    }
  }

  rowFilters(filterValue: any) {
    if (filterValue) {
      this.rowsFilter = (row: ShareDataRow) => {
        const nodeName = row.node.entry.name;
        if (nodeName.toUpperCase().includes(filterValue.toUpperCase())) {
          return true;
        } else {
          return false;
        }
      };
    } else {
      this.rowsFilter = (_row: ShareDataRow) => {
        return true;
      };
    }
  }

  clearTextFilter() {
    const inputElement = document.querySelector('ejs-textbox input') as HTMLInputElement;
    this.nodeResult = null;
    inputElement.value = '';
    this.searchTerm = '';
  }
}
