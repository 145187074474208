/* eslint-disable max-len */
import { Component, Input, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-watermark',
  template: `
    <div class="watermark-container" *ngIf="displayLayout !== 'center' && !reviewOnly" [ngStyle]="{'width': containerWidth}">
      <div class="watermark-row" *ngFor="let row of rows">
        <div class="watermark-cell" *ngFor="let col of displayColumns">
          <div class="watermark-content">
            <div class="watermark-line-one"
                 [style.fontSize.px]="fontSizeLineOne"
                 [style.color]="colorLineOne"
                 [style.opacity]="opacityLineOne">{{ lineOne }}</div>
            <div class="watermark-line-two"
                 [style.fontSize.px]="fontSizeLineTwo"
                 [style.color]="colorLineTwo"
                 [style.opacity]="opacityLineTwo">{{ lineTwo }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="watermark-container-center" *ngIf="displayLayout === 'center'">
      <div class="watermark-content">
        <div class="watermark-line-one"
             [style.fontSize.px]="fontSizeLineOne"
             [style.color]="colorLineOne"
             [style.opacity]="opacityLineOne">{{ lineOne }}</div>
        <div class="watermark-line-two"
             [style.fontSize.px]="fontSizeLineTwo"
             [style.color]="colorLineTwo"
             [style.opacity]="opacityLineTwo">{{ lineTwo }}</div>
      </div>
    </div>
    <div class="watermark-container2" *ngIf="reviewOnly">
      <div class="watermark-row" *ngFor="let row of rows">
        <div class="watermark-cell">
          <div class="watermark-content">
            <div class="watermark-line-one"
                [style.fontSize.px]="fontSizeLineOne"
                [style.color]="colorLineOne"
                [style.opacity]="opacityLineOne">{{ lineOne }}</div>
              <div class="watermark-line-two"
                  [style.fontSize.px]="fontSizeLineTwo"
                  [style.color]="colorLineTwo"
                  [style.opacity]="opacityLineTwo">{{ lineTwo }}</div>
            </div>
          </div>
        </div>
    </div>
    `,
  styles: [`
    .watermark-container {
      position: fixed;
      top: 20%;
      left: 0;
      height: 100%;
      pointer-events: none;
      z-index: 2147483647;
    }

    .watermark-container2 {
      position: fixed;
      top: 20%;
      right: 15px;
      left: auto;
      height: 100%;
      pointer-events: none;
      z-index: 2147483647;
    }

    .watermark-container-center {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      pointer-events: none;
      z-index: 2147483647;
    }

    .watermark-row {
      display: flex;
      justify-content: space-between;
      height: 15%;
    }

    .watermark-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 0.2);
      transform: rotate(-45deg);
      margin: 0 1px;
    }

    .watermark-content {
      text-align: center;
    }

    .watermark-line-one,
    .watermark-line-two {
      text-align: center;
      padding-left: 55px;
      width: 100%;
    }
  `]
})
export class WatermarkComponent implements OnInit {
  rows: any[] = [];
  fontSizeLineOne = 0;
  fontSizeLineTwo = 0;
  displayColumns: any[] = [];
  containerWidth = '100%';

  @Input() columns: any[] = new Array(5);
  @Input() lineOne = 'DO NOT COPY';
  @Input() lineTwo = 'beflex-workspace';
  @Input() minFontSizeLineOne = 1;
  @Input() maxFontSizeLineOne = 25;
  @Input() minFontSizeLineTwo = 1;
  @Input() maxFontSizeLineTwo = 20;
  @Input() colorLineOne = '#818589';
  @Input() colorLineTwo = '#A9A9A9';
  @Input() opacityLineOne = 0.4;
  @Input() opacityLineTwo = 0.4;
  @Input() displayLayout = '';
  @Input() maxColumns = 0;
  @Input() rightTabOpen = false;
  @Input() reviewOnly = false;

  ngOnInit() {
    if (this.displayLayout !== 'center' && !this.reviewOnly) {
      this.rows = new Array(5);
      this.columns = new Array(this.maxColumns);
      this.displayColumns = this.rightTabOpen ? new Array(3) : this.columns;
      this.containerWidth = this.rightTabOpen ? '50%' : '100%';
    }

    if (this.reviewOnly) {
      this.rows = new Array(5);
      this.columns = new Array(1);
      this.displayColumns = this.columns;
      this.containerWidth = '100%';
    }



    console.log('displayLayout ', this.displayLayout);



    this.calculateFontSize();
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateFontSize();
  }

  private calculateFontSize() {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const size = Math.min(viewportWidth, viewportHeight) * 0.02;

    this.fontSizeLineOne = Math.min(
      Math.max(size, this.minFontSizeLineOne),
      this.maxFontSizeLineOne
    );

    this.fontSizeLineTwo = Math.min(
      Math.max(size, this.minFontSizeLineTwo),
      this.maxFontSizeLineTwo
    );
  }
}
