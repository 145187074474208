/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


import { ContextActionsDirective, PageComponent, PageLayoutComponent, PaginationDirective, ToolbarComponent } from '@alfresco/aca-shared';
import { TabModule, ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ContextMenuService, EditService, FilterService, GridComponent, GridModule, PageService, PageSettingsModel, ResizeService, RowSelectEventArgs, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule, ChipListModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { MatDialog } from '@angular/material/dialog';
import { RequestDialogComponent } from '../request-dialog/request-dialog.component';
import { ApprovalWorkflowService } from '../../service/approval-workflow.service';
import { ReviewDialogComponent } from '../review-dialog/review-dialog.component';
import { ConfirmDialogComponent, PeopleContentService } from '@alfresco/adf-content-services';
import { AdhocRequestDialogComponent } from '../adhoc-request-dialog/adhoc-request-dialog.component';
import { RouterModule } from '@angular/router';
import { BeflexWorkflowService } from '../../service/beflex-workflow.service';

import {
  SnackbarErrorAction,
  SnackbarInfoAction,
} from '@alfresco/aca-shared/store';
import { map } from 'rxjs/operators';


@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule,
    ContextActionsDirective,
    PaginationDirective,
    PageLayoutComponent,
    ToolbarComponent,
    TabModule,
    MatIconModule,
    MatButtonModule,
    GridModule,
    TextBoxModule,
    DropDownListModule,
    ButtonModule,
    DialogModule,
    ToolbarModule,
    ChipListModule,
    RouterModule
  ],
  providers: [PageService, SortService, FilterService, ToolbarService, EditService, ContextMenuService, ResizeService],
  selector: 'app-my-request',
  templateUrl: './my-request.component.html',
  styleUrls: ['./my-request.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MyRequestComponent extends PageComponent implements OnInit {

  @ViewChild('grid')
  public grid: GridComponent;


  loading = false;
  public dataTemp = [];
  public pageSettings?: PageSettingsModel;



  constructor(private dialog: MatDialog,
    private beflexWorkflowService: BeflexWorkflowService,
    private workflowService: ApprovalWorkflowService,
    private peopleContentService: PeopleContentService) {
    super();
  }

  async ngOnInit() {
    console.log('# MyTasksComponent');
    await this.getProcess();
  }


  public async getUserId(): Promise<string> {
    const userInfo = await this.peopleContentService.getCurrentUserInfo().toPromise();
    const id = userInfo.id;
    return id;
  }

  async getProcess() {
    try {
      this.dataTemp = [];
      const userId = await this.getUserId();
      // Get Process
      const res = await this.workflowService.getProcess(userId).toPromise();
      console.log('res ', res);
      const ids = res.list.entries.map(entry => entry.entry.id);
      const reqBody = { 'workflow_ids': ids }
      const reqProcess = await this.workflowService.getRequestProcess(reqBody).toPromise();
      const reqIds = reqProcess.data.map(entry => entry.request_process_id);
      const reqUserBody = { 'request_process_ids': reqIds }
      const reqProcessUser = await this.workflowService.getRequestProcessUser(reqUserBody).toPromise();
      const reqProcessFile = await this.workflowService.getRequestProcessFile(reqUserBody).toPromise();
      let promises = res.list.entries.map(async item => {
        const filteredData = reqProcess.data.filter(item2 => item2.workflow_id === item.entry.id.toString());
        if (filteredData.length > 0) {
          const filteredDataUser = reqProcessUser.data.filter(item2 => item2.request_process_id === filteredData[0].request_process_id);
          const filteredDataFile = reqProcessFile.data.filter(item2 => item2.request_process_id === filteredData[0].request_process_id);
          this.dataTemp.push({
            reqProcessDraftId: 0,
            reqProcessId: filteredData[0].request_process_id,
            workflowId: item.entry.id,
            taskId: 0,
            subject: filteredData[0].request_subject,
            createDate: this.formatDate(filteredData[0].created_at),
            updateDate: filteredData[0].updated_at,
            assign: item.entry.assignee, // User id ที่ถูก assign ปัจจุบัน
            creator: filteredData[0].workflow_start_user_id, // User id ที่ Create กระบวนการ
            priority: filteredData[0].request_priority_name,
            state: item.entry.state,
            requestProcessData: filteredData[0],
            requestProcessDataUser: filteredDataUser,
            requestProcessDataFile: filteredDataFile,
            remark: filteredData[0].remark,
          });
        } else {
          console.warn(`No matching data found for processId: ${item.entry.processId}`);
        }
      });


      // Get Draft Process
      const reqDraft = await this.workflowService.getRequestProcessDraft(userId).toPromise();
      const reqIdsDraft = reqDraft.data.map(entry => entry.request_process_draft_id);
      const reqUserBodyDraft = { 'request_process_draft_ids': reqIdsDraft }
      const reqProcessUserDraft = await this.workflowService.getRequestProcessUserDraft(reqUserBodyDraft).toPromise();
      const reqProcessFileDraft = await this.workflowService.getRequestProcessFileDraft(reqUserBodyDraft).toPromise();
      promises = reqDraft.data.map(async item => {
        const filteredDataUser = reqProcessUserDraft.data.filter(item2 => item2.request_process_draft_id === item.request_process_draft_id);
        const filteredDataFile = reqProcessFileDraft.data.filter(item2 => item2.request_process_draft_id === item.request_process_draft_id);
        this.dataTemp.push({
          reqProcessDraftId: item.request_process_draft_id,
          reqProcessId: 0,
          workflowId: 0,
          taskId: 0,
          subject: item.request_subject,
          createDate: this.formatDate(item.created_at),
          updateDate: item.updated_at,
          assign: '', // User id ที่ถูก assign ปัจจุบัน
          creator: item.workflow_start_user_id, // User id ที่ Create กระบวนการ
          priority: item.request_priority_name,
          state: '',
          requestProcessData: item,
          requestProcessDataUser: filteredDataUser,
          requestProcessDataFile: filteredDataFile,
          remark: item.remark
        });
      });

      await Promise.all(promises);

      this.dataTemp.sort((a, b) => {
        const dateA = new Date(a.updateDate);
        const dateB = new Date(b.updateDate);
        return dateB.getTime() - dateA.getTime();
      });




      this.loading = true;
    } catch (error) {
      console.error('Error:', error);
    }
  }




  async onEdit(currentRow: any) {
    console.log('# onEdit ', currentRow);
    const currentIndex = this.dataTemp.filter(item => item.reqProcessDraftId === currentRow.reqProcessDraftId);
    console.log('remark ', currentIndex[0].remark);


    const requestProcessData = currentIndex[0].requestProcessData;
    const requestProcessDataUser = currentIndex[0].requestProcessDataUser;
    const requestProcessDataFile = currentIndex[0].requestProcessDataFile;

    if (currentIndex[0].remark === 'adhoc') {
      const dialogRef = this.dialog.open(AdhocRequestDialogComponent, {
        data: { requestProcessData, requestProcessDataUser, requestProcessDataFile },
        width: '900px',
        disableClose: false
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          console.log('Dialog result:', result);
          this.getProcessAndRefresh();
        }
      });
    } else {
      const dialogRef = this.dialog.open(RequestDialogComponent, {
        data: { requestProcessData, requestProcessDataUser, requestProcessDataFile },
        width: '900px',
        disableClose: false
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          console.log('Dialog result:', result);
          this.getProcessAndRefresh();
        }
      });
    }
  }

  async onView(currentRow: any) {
    console.log('# onView');
    const currentIndex = this.dataTemp.filter(item => item.workflowId === currentRow.workflowId);
    const requestProcessData = currentIndex[0].requestProcessData;
    const requestProcessDataUser = currentIndex[0].requestProcessDataUser;
    const requestProcessDataFile = currentIndex[0].requestProcessDataFile;
    const state = 'Working'
    requestProcessData.state = state;
    requestProcessData.taskId = currentRow.taskId;
    requestProcessData.assign_user_id = currentIndex[0].assign;
    const dialogRef = this.dialog.open(ReviewDialogComponent, {
      data: { requestProcessData, requestProcessDataUser, requestProcessDataFile },
      width: '900px',
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('Dialog result:', result);
        this.getProcessAndRefresh();
      }
    });
  }

  async onCancel(currentRow: any) {
    console.log('# onCancel ', currentRow);

    const processId = currentRow.workflowId;
    const reqProcessDraftId = currentRow.reqProcessDraftId;


    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmation',
        message: processId === 0
          ? 'Are you sure you want to cancel this draft?'
          : 'Are you sure you want to cancel this process?'
      },
      minWidth: '250px'
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {

        if (processId === 0) {
          try {
            this.workflowService.deleteDraftProcess(reqProcessDraftId)
              .pipe(
                map(response => {
                  console.log('Status code:', response.status);
                  return response.body;
                })
              )
              .subscribe({
                next: async (response) => {
                  console.log('Success:', response);
                  this.alertInfo('Cancel draft success.');
                  this.getProcessAndRefresh();
                },
                error: (error) => {
                  console.log('Error status:', error.status);
                  this.alertError(error, null);
                }
              });
          } catch (error) {
            this.alertError(error.message, null);
          }
        } else {
          try {
            // 1. Call Check api alfresco check status Cancel ? [Alfresco]
            const resStatus = await this.beflexWorkflowService.getInfoProcess(processId).toPromise();
            console.log('resStatus ', resStatus);
            const completed = resStatus.completed;
            if (completed) {
              const errorTxt = 'This process is already completed.';
              this.alertError(errorTxt, null)
              return;
            }
            // 2. Call api update status Cancel [Alfresco]
            const resCancelProcess = await this.beflexWorkflowService.cancelProcess(processId).toPromise();
            console.log('resCancelProcess ', resCancelProcess);


            // 3. Call Api update status Cancel [Workflow]
            const body = {
              'workflow_id': processId,
              'complete': true,
              'status': 'CANCEL'
            }
            this.workflowService.updateCompleteRequestProcess(body)
              .pipe(
                map(response => {
                  console.log('Status code:', response.status);
                  return response.body;
                })
              )
              .subscribe({
                next: async (response) => {
                  console.log('Success:', response);
                  this.alertInfo('Cancel process success.');
                  this.getProcessAndRefresh();
                },
                error: (error) => {
                  console.log('Error status:', error.status);
                  this.alertError(error, null);
                }
              });
          } catch (error) {
            this.alertError(error.message, null);
          }
        }



      }
    });





  }


  async getProcessAndRefresh() {
    await this.getProcess();
    this.grid.refresh();
  }

  onCreate() {
    const dialogRef = this.dialog.open(RequestDialogComponent, {
      width: '900px',
      disableClose: false,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('Dialog result:', result);
        this.getProcessAndRefresh();
      }
    });
  }

  onCreateAdhoc() {
    const dialogRef = this.dialog.open(AdhocRequestDialogComponent, {
      width: '900px',
      disableClose: false,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('Dialog result:', result);
        this.getProcessAndRefresh();
      }
    });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 7); // Adjust for Thai time (UTC+7)
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }


  getPriorityClass(priority: string): string {
    switch (priority) {
      case 'Low':
        return '';
      case 'Medium':
        return 'e-warning';
      case 'High':
        return 'e-danger';
      default:
        return '';
    }
  }

  getTypeClass(reqProcessDraftId: any): any {

    if (reqProcessDraftId === 0 || reqProcessDraftId === '0') {
      return 'e-info';
    } else {
      return '';
    }

  }

  selectedRowData: any;
  onRowSelected(event: RowSelectEventArgs) {
    this.selectedRowData = event.data;
  }

  // ===================  Alert  ===================
  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }





}
