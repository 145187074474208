import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


import { ContextActionsDirective, PageComponent, PageLayoutComponent, PaginationDirective, ToolbarComponent } from '@alfresco/aca-shared';
import { TabModule, ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ContextMenuService, EditService, FilterService, GridModule, PageService, ResizeService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule, ChipListModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    ContextActionsDirective,
    PaginationDirective,
    PageLayoutComponent,
    ToolbarComponent,
    TabModule,
    MatIconModule,
    MatButtonModule,
    GridModule,
    TextBoxModule,
    DropDownListModule,
    ButtonModule,
    DialogModule,
    ToolbarModule,
    ChipListModule,
  ],
  providers: [PageService, SortService, FilterService, ToolbarService, EditService, ContextMenuService, ResizeService],
  selector: 'aca-create-request',
  templateUrl: './create-request.component.html',
  styleUrls: ['./create-request.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreateRequestComponent extends PageComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
