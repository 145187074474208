import { Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DEV_MODE_TOKEN } from './dev-mode.tokens';
import { AboutModule, AppConfigService, AuthenticationService, RepositoryInfo } from '@alfresco/adf-core';
import { DiscoveryApiService } from '@alfresco/adf-content-services';
import { PACKAGE_JSON } from './package-json.token';
import { TranslateModule } from '@ngx-translate/core';
import { AppExtensionService, PageLayoutComponent } from '@alfresco/aca-shared';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { from, Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { ProfileState } from '@alfresco/adf-extensions';
// import { version } from '../../package.json';

export interface LicenseData {
  property: string;
  value: string;
}

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, AboutModule, RouterModule, MatIconModule, MatButtonModule, PageLayoutComponent, MatTableModule],
  selector: 'app-about-page',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnInit {
  private authService = inject(AuthenticationService);
  private appExtensions = inject(AppExtensionService);
  private discovery = inject(DiscoveryApiService);
  private appConfigService = inject(AppConfigService);
  public packageJson? = inject(PACKAGE_JSON, { optional: true });
  public dev = inject(DEV_MODE_TOKEN);

  extensions$ = this.appExtensions.references$;
  repository: RepositoryInfo = null;

  landingPage = this.appConfigService.get<string>('application.LandingPage');

  licenseValueEntries: Array<{ property: string; value: string }>;
  user$: Observable<ProfileState>;

  apiURL: any;
  headers: any;


  dependencyEntries: Array<{ name: string; version: string }>;
  releaseVersion = '';


  columns = [
    {
      columnDef: 'property',
      header: 'APP.ABOUT.LICENSE.PROPERTY',
      cell: (row: LicenseData) => `${row.property}`
    },
    {
      columnDef: 'value',
      header: 'APP.ABOUT.LICENSE.VALUE',
      cell: (row: LicenseData) => `${row.value}`
    }
  ];

  displayedColumns = this.columns.map((x) => x.columnDef);

  @Input()
  data: Array<LicenseData> = [];

  constructor(private apiService: AlfrescoApiService) {
    this.apiURL = '';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
  }

  ngOnInit(): void {
    // this.dependencyEntries = Object.keys(dependencies).map(key => {
    //   return {
    //     name: key,
    //     version: dependencies[key]
    //   };
    // });
    if (this.authService.isEcmLoggedIn()) {
      this.setECMInfo();
    }
    this.getLicense();

    this.releaseVersion = this.packageJson.version;
    console.log(' packageJson ', this.packageJson.version);
  }

  setECMInfo() {
    this.discovery.getEcmProductInfo().subscribe((repository) => {
      this.repository = repository as RepositoryInfo;
    });
  }

  async getLicense() {
    console.log('getLicense()');
    this.getDataLicense().subscribe({
      next: (data) => {
        if (data.licenseValue) {
          this.licenseValueEntries = Object.keys(data.licenseValue)
            .filter((o) => o !== 'productName' && o !== 'hash')
            .map((key) => {
              return {
                property: key,
                value: data.licenseValue[key]
              };
            });
        } else {
          this.licenseValueEntries = [
            {
              property: 'Machine Key',
              value: data.licenseKey
            },
            {
              property: 'Status',
              value: 'Trial Version'
            },
            {
              property: 'Max Users',
              value: '3'
            }
          ];
        }
      },
      error: (error) => {
        console.log('error getDataLicense:', error);
        console.log(error.error.message);
      }
    });
  }

  private privateApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callCustomApi(path, httpMethod, ...params);
  }


  getDataLicense(): Observable<any> {
    // return this.httpClient.get<any>(`${this.apiURL}/alfresco/service/license?alf_ticket=${this.apiService.getInstance().contentAuth.ticket}`, {
    //   headers: this.headers
    // });
    const pathService = `/alfresco/service/license`
    const httpMethod = 'GET';
    return from(this.privateApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  onUploadLicenseFile(event: any) {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileContent = reader.result as string;

        try {
          const params = [{}, {}, {}, {}, fileContent, ['application/json'], ['application/json']];
          this.apiService.getInstance().contentClient.callCustomApi(`/alfresco/s/license`, 'POST', ...params);

          alert('Upload License Success.');
          this.getLicense();
        } catch (error) {
          console.error('license format is invalid', error);
        }
      };
      reader.readAsText(file);
    }
  }
}
