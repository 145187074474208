<!-- <form [formGroup]="myForm" class="example-form"> -->
<form #documentExpireForm="ngForm" (submit)="onSubmit(documentExpireForm)" [formGroup]="myForm" class="example-form">
  <div class="docexp-dialog-title">{{ 'DOCUMENT-EXPIRE.FORM.TITLE' | translate }}</div>

  <!-- docname -->
  <mat-form-field class="example-full-width">
    <input
      matInput
      type="text"
      placeholder="{{ 'DOCUMENT-EXPIRE.FORM.DOCUMENT_NAME' | translate }}"
      formControlName="docname"
      name="docname"
      id="docname"
    />
  </mat-form-field>

  <!-- expiredate -->
  <mat-form-field class="example-full-width">
    <span class="adf-fill-remaining-space"></span>
    <input
      matInput
      [matDatepicker]="picker"
      placeholder="{{ 'DOCUMENT-EXPIRE.FORM.EXPIRE_DATE' | translate }}"
      formControlName="expiredate"
      name="expiredate"
      id="expiredate"
      [disabled]="isDatepickerDisabled"
      isDisabled
    />
    <mat-hint>{{ 'DOCUMENT-EXPIRE.FORM.EXPIRE_DATE_HINT' | translate }} </mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <!-- numberofdays test -->
  <mat-form-field class="example-full-width">
    <input
      matInput
      type="text"
      placeholder="{{ 'DOCUMENT-EXPIRE.FORM.NUMBER_OF_DAY' | translate }}"
      formControlName="numberofdays"
      name="numberofdays"
      id="numberofdays"
    />
    <mat-hint>{{ 'DOCUMENT-EXPIRE.FORM.NUMBER_OF_DAY_HINT' | translate }}</mat-hint>
  </mat-form-field>

  <!-- email -->
  <mat-form-field class="example-full-width">
    <input
      matInput
      type="email"
      placeholder="{{ 'DOCUMENT-EXPIRE.FORM.EMAIL' | translate }}"
      formControlName="email"
      name="email"
      id="email"
      [disabled]="isDatepickerDisabled"
      isDisabled
    />
    <mat-hint>{{ 'DOCUMENT-EXPIRE.FORM.EMAIL_HINT' | translate }}</mat-hint>
    <!-- <mat-error *ngIf="myError('email', 'required')"></mat-error> -->
  </mat-form-field>

  <!-- ########################################### DEV ################################################################################## -->

  <!-- checkbox -->
  <mat-checkbox
    class="example-full-width2"
    formControlName="removefile_active"
    name="removefile_active"
    id="removefile_active"
    (change)="handleChange($event)"
  >
    Auto delete after expire Kept in the system (month) after the date expires.</mat-checkbox
  >

  <!-- input days -->
  <mat-form-field class="example-full-width2" *ngIf="isChecked">
    <input
      matInput
      type="number"
      name="removefile_days"
      id="removefile_days"
      formControlName="removefile_days"
      placeholder="Enter delete after month"
    />
  </mat-form-field>

  <br />

  <!-- ######################## CONTENT BUTTON (Insert) ###############################-->
  <mat-dialog-actions class="adf-dialog-buttons" *ngIf="insertActive">
    <span class="adf-fill-remaining-space"></span>

    <!-- SAVE -->
    <button mat-button type="submit" class="adf-dialog-action-button" id="adf-folder-create-button">
      {{ 'DOCUMENT-EXPIRE.FORM.SAVE' | translate }}
    </button>
  </mat-dialog-actions>

  <!-- ######################## CONTENT BUTTON (Update) ###############################-->
  <mat-dialog-actions class="adf-dialog-buttons" *ngIf="!insertActive">
    <span class="adf-fill-remaining-space"></span>

    <!-- EDIT -->
    <form>
      <button mat-button class="adf-dialog-action-button" (click)="toggleFormState()" *ngIf="!formDisabled">
        {{ 'DOCUMENT-EXPIRE.FORM.EDIT' | translate }}
      </button>
    </form>

    <!-- SAVE -->
    <button mat-button type="submit" class="adf-dialog-action-button" id="adf-folder-create-button" *ngIf="formDisabled">
      {{ 'DOCUMENT-EXPIRE.FORM.SAVE' | translate }}
    </button>

    <!-- CANCEL  -->
    <button mat-button type="submit" (click)="toggleCancel()">{{ 'DOCUMENT-EXPIRE.FORM.DELETE' | translate }}</button>
  </mat-dialog-actions>

  
</form>
