<div class="control-styles">
  <label class="light-label">{{ label }}</label>
  <div></div>
  <ejs-dropdownlist
    [id]="dropdownId"
    #samples
    [dataSource]="data"
    [value]="dataValue"
    [enabled]="!enabled"
    [fields]="fields"
    [placeholder]="placeholder"
    [allowFiltering]="true"
    (filtering)="onFiltering($event)"
    (change)="onSelectionChange($event)"
  ></ejs-dropdownlist>
</div>
