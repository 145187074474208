/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { ContentPipeModule } from '@alfresco/adf-content-services';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { ContentApiService, LockedByComponent } from '@alfresco/aca-shared';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NodeEntry } from '@alfresco/js-api';
import { Observable } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, LockedByComponent, ContentPipeModule, MatProgressBarModule],
  selector: 'aca-custom-name-column',
  templateUrl: './site-quota-column.component.html',
  styleUrls: ['./site-quota-column.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'adf-datatable-content-cell adf-datatable-link adf-name-column aca-custom-name-column'
  }
})
export class SiteQuotaColumnComponent implements OnInit {

  @Input()
  context: any;

  node: NodeEntry;

  size: Observable<any>;

  constructor(private contentApi: ContentApiService) { }

  ngOnInit() {
    this.node = this.context.row.node;
    this.size = this.getDisplayText(this.context.row.node.entry.guid);
  }

  private getDisplayText(nodeId: string): Observable<any> {
    return new Observable<any>(observer => {
      this.contentApi.getNodeInfo(nodeId).subscribe(
        node => {




          if (node.properties?.['fq:sizeCurrent'] && node.properties['fq:sizeQuota']) {

            observer.next(
              {
                // eslint-disable-next-line radix
                size: `${this.bytesToSize(parseInt(node.properties['fq:sizeCurrent']))} / ${this.bytesToSize(parseInt(node.properties['fq:sizeQuota']))}`,
                // eslint-disable-next-line radix
                percent: this.calculate(parseInt(node.properties['fq:sizeCurrent']), parseInt(node.properties['fq:sizeQuota']))
              }
            );
          } else {
            observer.next(
              {
                size: '',
                percent: 0
              }
            );
          }

          observer.complete();
        },
        () => {
          observer.next({
            size: '',
            percent: 0
          });
          observer.complete();
        }
      );
    });
  }

  bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    // eslint-disable-next-line curly
    if (bytes === 0) return 'n/a'
    const i = parseInt((Math.floor(Math.log(bytes) / Math.log(1024))).toString(), 10)
    // eslint-disable-next-line curly
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
  }

  calculate(sizeCurrent: number, sizeQuota: number) {
    // var decreaseValue = sizeQuota - sizeCurrent;
    return (sizeCurrent / sizeQuota) * 100;
  }
}
