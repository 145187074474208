import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgIf, JsonPipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatIconModule } from '@angular/material/icon';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { from, Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgIf,
    JsonPipe,
    MatTableModule,
    MatDatetimepickerModule,
    MatIconModule
  ],
  templateUrl: './dialog-alert.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dialog-alert.scss'],
  host: { class: 'aca-dialog-alert' }
})
export class DialogAlertComponent implements OnInit {

  displayedColumns: string[] = ['Propeties', 'Value'];
  dataSource = [];



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogAlertComponent>,
    private apiService: AlfrescoApiService
  ) {
    console.log('dialogRef ', dialogRef);
  }

  ngOnInit(): void {
    console.log('# DialogAlertComponent');
    console.log('this.data ', this.data);

    // const emptyOrNullProperties = this.data.emptyOrNullProperties;
    // console.log('emptyOrNullProperties ', emptyOrNullProperties);

    // emptyOrNullProperties.forEach(async propertyId => {
    //   try {
    //     const property = await this.getAspect(propertyId).toPromise();
    //     const propertyName = property.entry.title;
    //     this.dataSource.push({ Properties: propertyId, Value: propertyName });
    //   } catch (error) {
    //     console.error(`Failed to get aspect for propertyId ${propertyId}:`, error);
    //   }
    // });

    this.fetchProperties();

  }


  loadSuccess = false;
  fetchProperties = async () => {
    const emptyOrNullProperties = this.data.emptyOrNullProperties;
    for (const propertyId of emptyOrNullProperties) {
      try {
        const property = await this.getAspect(propertyId).toPromise();
        const propertyName = property.entry.title;
        this.dataSource.push({ Propeties: propertyId, Value: propertyName });
      } catch (error) {

        let propertyId2 = propertyId
        if (propertyId.includes(':')) {
          propertyId2 = propertyId.split(':')[1];
        }
        this.dataSource.push({ Propeties: propertyId, Value: propertyId2 });
      }
    }
    this.loadSuccess = true;
  };


  getAspect(aspectId: string): Observable<any> {
    const pathService = `/aspects/${aspectId}`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }



  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }


}

