/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, AbstractControl, Validators } from '@angular/forms';
import { AlfrescoApiService, AppConfigService } from '@alfresco/adf-core';

import { NodeBodyUpdate, NodesApi, Person } from '@alfresco/js-api';
import { debounceTime, map, startWith } from 'rxjs/operators';
import * as moment from 'moment';

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
// import { Node } from '@alfresco/js-api';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FileModel, FileUploadCompleteEvent, UploadService } from '@alfresco/adf-content-services';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { UploadFilesDialogComponent } from '../../components/upload-files-dialog/upload-files-dialog.component';
import { Observable, Subject, from } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';

import { MatDialogRef } from '@angular/material/dialog';


import { ContentMetadataService } from '../../components/content-metadata';
// import { MAT_DATE_FORMATS } from '@angular/material/core';

// import { BeflexWorkspaceService } from '../../components/service/beflex-workspace.service';




// export const CUSTOM_DATE_FORMATS = {
//   parse: {
//     dateInput: 'DD/MM/YYYY', // รูปแบบวันที่ที่ใช้รับค่า
//   },
//   display: {
//     dateInput: 'DD/MM/YYYY',  // รูปแบบวันที่ที่แสดง
//     monthYearLabel: 'MMMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };



export interface SmartItem {
  id: string;
  title: string;
  placeholder: string;
  field: string;
  required: boolean;
  type: string;
  options: any[];
  defaultValue: string;
}

export interface Type {
  id: string;
  name: string;
  // path: string;
  fileName: string;
  items: SmartItem[];
  pathDefault: string;
  pathCondition: string;
  overwrite: boolean;
}

export interface SmartObject {
  types: Type[];
}

export interface Option {
  id: string;
  name: string;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSelectModule,
    MatIconModule,
    MatAutocompleteModule,
    UploadFilesDialogComponent,
    MatCardModule
  ],
  templateUrl: './file.dialog.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./file.dialog.scss'],
  // providers: [
  //   { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  // ],
  host: { class: 'aca-file-dialog' }
})
export class FileDialogComponent implements OnInit {
  form: FormGroup;

  smartObject: SmartObject;
  currentType: Type;
  currentConfig: any;
  currentFile: FileModel[];
  values: any;
  currentPath = '';
  currentFileName = '';

  extensionFile = '';
  renameFileActive = false;
  overwriteFileActive = false;
  fileTemps: any;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  success: EventEmitter<any> = new EventEmitter<Node>();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;


  private _nodesApi: NodesApi;
  get nodesApi(): NodesApi {
    this._nodesApi = this._nodesApi ?? new NodesApi(this.apiService.getInstance());
    return this._nodesApi;
  }

  apiURLAlfresco: any;
  headers: any;

  _isCustomActive = false;

  private selectorChangeSubject = new Subject<any>();


  constructor(
    private http: HttpClient,
    private dialog: MatDialogRef<FileDialogComponent>,
    // private httpClient: HttpClient,
    private apiService: AlfrescoApiService,
    private fb: FormBuilder,
    private uploadService: UploadService,
    private contentMetadataService: ContentMetadataService,
    private appConfig: AppConfigService,
  ) {
    this.apiURLAlfresco = '';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });


    this.selectorChangeSubject.pipe(
      debounceTime(300) // Adjust the debounce time as needed
    ).subscribe(event => this.onSelectorChange(event));
  }


  @ViewChild('formField') formField: ElementRef;


  openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }


  openAutocompletePanel() {
    this.autocompleteTrigger.openPanel();
  }

  myControl = new FormControl<string | Option>('');
  options: Option[] = [];
  filteredOptions: Observable<Option[]>;

  displayFn(user: Option): string {
    return user?.name ? user.name : '';
  }

  private _filter(name: string): Option[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  async getConfig(): Promise<any> {
    try {
      const data = await this.http.get('./smart-upload.config.json').toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching configuration:', error);
      throw error;
    }
  }

  isConfig = false;
  async loadConfig(): Promise<void> {
    try {
      const data = await this.getConfig();
      this.smartObject = data['smart-upload'];
      this.currentType = this.smartObject.types[0];
      this.currentConfig = this.smartObject.types[0];
      this.isConfig = true;
    } catch (error) {
    }
  }

  async ngOnInit(): Promise<void> {
    await this.loadConfig();
    this.options = this.smartObject.types;

    const isCustom = this.appConfig.config['custom-content-metadata'] || null;
    if (isCustom) {
      const isEnabled = isCustom.isEnabled;
      this._isCustomActive = isEnabled
    } else {
      this._isCustomActive = false;
    }

    const isCustomDropdown = this.currentType['dropdown-custom-setting'];
    if (isCustomDropdown) {
      const isEnabled = isCustomDropdown?.isEnabled;
      if (isEnabled && this._isCustomActive) {
        this._isCustomActive = true;
      }
    }

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );

    // this.form = this.fb.group({
    //   name: [Validators.required]
    // });

    this.form = this.fb.group({
      name: [Validators.required]
    }, { validators: this.noEmptyValuesValidator });

    this.currentType.items.forEach(e => {
      this.form.addControl(
        e.field,
        new FormControl('', e.required ? Validators.required : undefined)
      );
    });

    this.reset();

    this.uploadService.fileUploadComplete
      .pipe(debounceTime(300))
      .subscribe(file => this.onFileUploadedEvent(file));
  }


  // noEmptyValuesValidator(control: AbstractControl): { [key: string]: boolean } | null {
  //   const controls = control instanceof FormGroup ? control.controls : {};
  //   for (const key in controls) {
  //     const value = controls[key].value;
  //     if (typeof value === 'string' && value.trim().length === 0) {
  //       return { 'emptyValue': true };
  //     }
  //   }
  //   return null;
  // }

  noEmptyValuesValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const controls = control instanceof FormGroup ? control.controls : {};
    for (const key in controls) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const control = controls[key];
      const value = control.value;
      if (control.hasValidator(Validators.required) && typeof value === 'string' && value.trim().length === 0) {
        return { 'emptyValue': true };
      }
    }
    return null;
  }

  transform(value: string): string {
    const regex = /[^\u0E00-\u0E7Fa-zA-Z0-9\s.-]+/g;
    return value.replace(regex, '_');
  }

  apply(model: any, _isValid: boolean) {
    if (this.renameFileActive) {
      this.currentFileName = this.transform(this.currentFileName);
      model.name = this.currentFileName;
      const file = this.renameFile(this.fileTemps, this.currentFileName);
      const reader = new FileReader();
      this.currentFile = [
        new FileModel(file, {
          parentId: '-root-',
          path: this.currentPath,
          nodeType: 'cm:content',
          newVersion: this.overwriteFileActive,
        })
      ];
      reader.readAsText(file);
    }
    this.values = model;
    if (this.currentFile && this.currentFile.length > 0) {
      this.currentFile.forEach(e => {
        e.options = { ...e.options, ...model };
        e.options.path = this.currentPath
      });
      this.uploadQueue(this.currentFile);
    }
  }

  async reset() {
    const peopleData = await this.getPeople().toPromise();
    await this.form.reset(<Person>peopleData.entry);
  }

  private uploadQueue(files: FileModel[]) {
    if (files.length > 0) {
      this.uploadService.addToQueue(...files);
      this.uploadService.uploadFilesInTheQueue();
    }
  }


  fileUploaded = false;

  onUploadFile(e: any) {
    e.stopPropagation();
    e.preventDefault();
    const fileName = e.currentTarget.files[0].name;
    const file = e.currentTarget.files[0];

    if (this.renameFileActive) {
      this.fileTemps = file;
      const dotIndex = fileName.lastIndexOf('.');
      const extension = fileName.substring(dotIndex + 1);
      this.extensionFile = extension;
      this.onChanged();
    }
    (<AbstractControl>this.form.controls['name']).setValue(fileName);
    const reader = new FileReader();
    this.currentFile = [
      new FileModel(file, {
        parentId: '-root-',
        path: this.currentPath,
        nodeType: 'cm:content',
        newVersion: this.overwriteFileActive,
      })
    ];
    reader.readAsText(file);
    this.fileUploaded = true;
  }


  renameFile(file: File, newFilename: string): File {
    return new File([file], newFilename, { type: file.type });
  }



  isSelector = false;

  // onSelectorChange(event) {
  //   if (event.isUserInput) {

  //     this.isSelector = true;
  //     this.currentType = event.source.value;
  //     this.currentConfig = event.source.value;
  //     if (this.currentType?.overwrite) {
  //       this.overwriteFileActive = true;
  //     }
  //     if ('fileName' in this.currentType) {
  //       this.renameFileActive = true;
  //     } else {
  //       this.renameFileActive = false;
  //     }
  //     this.loadData();
  //     this.form.reset();
  //     Object.keys(this.form.controls).forEach(c => {
  //       if (c !== 'name') {
  //         this.form.controls[c].setErrors(null);
  //         this.form.controls[c].updateValueAndValidity();
  //       }
  //     });
  //     Object.keys(this.form.controls).forEach(c => {
  //       if (c !== 'name') {
  //         this.form.removeControl(c);
  //       }
  //     });

  //     this.currentType.items.forEach(e => {
  //       let defaultValue = '';
  //       if (e.type === 'text') {
  //         defaultValue = e.defaultValue || '';
  //       } else if (e.type === 'date') {
  //         if (e.defaultValue) {
  //           const [month, day, year] = e.defaultValue.split('-');
  //           const dateObj = new Date(`${year}-${month}-${day}`);
  //           defaultValue = dateObj.toISOString().split('T')[0];
  //         }
  //       } else if (e.type === 'dropdown' || e.type === 'dropdown-custom') {
  //         defaultValue = e.defaultValue || '';
  //       } else {
  //         defaultValue = e.defaultValue || '';
  //       }
  //       e.required
  //         ? this.form.addControl(
  //           e.field,
  //           new FormControl(defaultValue, Validators.required)
  //         )
  //         : this.form.addControl(e.field, new FormControl(defaultValue));
  //     });

  //   }
  // }

  onSelectorChange(event: any) {
    if (event.isUserInput) {
      const selectedOption = event.source.value;
      this.isSelector = true;
      this.currentType = selectedOption;
      this.currentConfig = selectedOption;

      if (this.currentType?.overwrite) {
        this.overwriteFileActive = true;
      }

      if ('fileName' in this.currentType) {
        this.renameFileActive = true;
      } else {
        this.renameFileActive = false;
      }

      this.loadData();
      this.form.reset();
      Object.keys(this.form.controls).forEach(c => {
        if (c !== 'name') {
          this.form.controls[c].setErrors(null);
          this.form.controls[c].updateValueAndValidity();
        }
      });
      Object.keys(this.form.controls).forEach(c => {
        if (c !== 'name') {
          this.form.removeControl(c);
        }
      });

      this.currentType.items.forEach(e => {
        let defaultValue = '';
        if (e.type === 'text') {
          defaultValue = e.defaultValue || '';
        } else if (e.type === 'date') {
          if (e.defaultValue) {
            const [month, day, year] = e.defaultValue.split('-');
            const dateObj = new Date(`${year}-${month}-${day}`);
            defaultValue = dateObj.toISOString().split('T')[0];
          }
        } else if (e.type === 'dropdown' || e.type === 'dropdown-custom') {
          defaultValue = e.defaultValue || '';
        } else {
          defaultValue = e.defaultValue || '';
        }
        e.required
          ? this.form.addControl(
            e.field,
            new FormControl(defaultValue, Validators.required)
          )
          : this.form.addControl(e.field, new FormControl(defaultValue));
      });

      // Display all matching options
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filter(name as string) : this.options.slice();
        }),
      );
    }
  }


  handleSelectorChange(event: any) {
    this.selectorChangeSubject.next(event);
  }




  updateSuccess = false;
  private onFileUploadedEvent(event: FileUploadCompleteEvent) {

    if (this.updateSuccess) {
      return;
    }

    const body = new NodeBodyUpdate();


    if (this.updatedMatchedType && this._isCustomActive) {
      const updatedValue = Object.keys(this.values).reduce((acc, key) => {
        const matchedType = this.updatedMatchedType.find(type => type.field === key);
        if (matchedType) {
          const matchedProperty = matchedType.properties.find(prop => prop.name === this.values[key]);
          acc[key] = matchedProperty ? matchedProperty.dropdownName : this.values[key];
        } else {
          acc[key] = this.values[key];
        }
        return acc;
      }, {});
      body.properties = updatedValue
      delete body.properties.name;
    } else {
      body.properties = this.values;
      delete body.properties.name;
    }

    this.nodesApi.updateNode(event.data.entry.id, body).then((r: any) => {
      this.updateSuccess = true;
      this.success.emit(r);
      this.dialog.close(r);
    }).catch((error: any) => {
      console.log('onFileUploadedEvent error: ', error);
      this.success.emit();
      this.dialog.close();
    });

  }



  // getPathTemplate(selectedPath: any, args: any) {

  //   console.log('selectedPath ', selectedPath);


  //   console.log('args ', args);


  //   const listItem = this.currentConfig.items.filter(item => item.digitCheckerField);
  //   listItem.forEach(fieldConfig => {
  //     const digitCheckerField = fieldConfig.digitCheckerField;
  //     const fieldToSet = fieldConfig.field;
  //     const valueToCheck = args[digitCheckerField] || '';
  //     const valueConfig = fieldConfig.setValue.find(v => v.digit === valueToCheck.length) || fieldConfig.setValue.find(v => v.digit === 0);
  //     const newValue = valueConfig ? valueConfig.value : '';
  //     if (args[fieldToSet] !== newValue) {
  //       args[fieldToSet] = newValue;
  //       this.form.patchValue({
  //         [fieldToSet]: newValue
  //       });
  //     }
  //   });

  //   return selectedPath.replace(/\${([A-Za-z0-9.,:-|]*)}/g, (_, v) => {
  //     const d = v.split('|');
  //     if (d.length > 1) {
  //       const [key, format] = d;
  //       if (args[key] instanceof moment) {
  //         return args[key] ? args[key].format(format) : v;
  //       } else if (format.includes(',')) {
  //         const [start, end] = format.split(',').map(Number);
  //         return args[key] ? args[key].substring(start - 1, end) : v;
  //       } else {
  //         return args[key] ? args[key] : v;
  //       }
  //     } else {
  //       return args[v] ? args[v] : v;
  //     }
  //   });
  // }


  // DEV 1
  // getPathTemplate(selectedPath: any, args: any) {
  //   console.log('selectedPath ', selectedPath);
  //   console.log('args ', args);

  //   const labelMapping = {};

  //   if (this.updatedMatchedType) {
  //     this.updatedMatchedType.forEach(conf => {
  //       conf.properties.forEach(prop => {
  //         const label = prop.label.replace(/\.$/, '');
  //         labelMapping[`${conf.field}|${prop.name}`] = label;
  //       });
  //     });

  //     console.log('labelMapping ',labelMapping);
  //   }

  //   const listItem = this.currentConfig.items.filter(item => item.digitCheckerField);
  //   listItem.forEach(fieldConfig => {
  //     const digitCheckerField = fieldConfig.digitCheckerField;
  //     const fieldToSet = fieldConfig.field;
  //     const valueToCheck = args[digitCheckerField] || '';
  //     const valueConfig = fieldConfig.setValue.find(v => v.digit === valueToCheck.length) || fieldConfig.setValue.find(v => v.digit === 0);
  //     const newValue = valueConfig ? valueConfig.value : '';
  //     if (args[fieldToSet] !== newValue) {
  //       args[fieldToSet] = newValue;
  //       this.form.patchValue({
  //         [fieldToSet]: newValue
  //       });
  //     }
  //   });

  //   return selectedPath.replace(/\${([A-Za-z0-9.,:-|]*)}/g, (_, v) => {
  //     const d = v.split('|');
  //     if (d.length > 1) {
  //       const [key, format, range] = d;
  //       if (format === 'label' && labelMapping[`${key}|${args[key]}`]) {
  //         let label = labelMapping[`${key}|${args[key]}`];
  //         if (range) {
  //           const [start, end] = range.split(',').map(Number);
  //           label = label.substring(start - 1, end);
  //         }
  //         return label;
  //       } else if (format === 'name' && labelMapping[`${key}|${args[key]}`]) {
  //         let name = labelMapping[`${key}|${args[key]}`];
  //         if (range) {
  //           const [start, end] = range.split(',').map(Number);
  //           name = name.substring(start - 1, end);
  //         }
  //         return name;
  //       } else if (args[key] instanceof moment) {
  //         return args[key] ? args[key].format(format) : v;
  //       } else if (format.includes(',')) {
  //         const [start, end] = format.split(',').map(Number);
  //         return args[key] ? args[key].substring(start - 1, end) : v;
  //       } else {
  //         return args[key] ? args[key] : v;
  //       }
  //     } else {
  //       return args[v] ? args[v] : v;
  //     }
  //   });


  // }


  // DEV 2

  getMatchedProperty(args: any, key: string, updatedMatchedType: any[]): any {


    if (!updatedMatchedType) {
      return null;
    }

    const matchedType = updatedMatchedType.find(type =>
      type.properties.some(prop =>
        prop.name === args[key] || prop.label === args[key] || prop.dropdownName === args[key]
      )
    );
    if (matchedType) {
      return matchedType.properties.find(prop =>
        prop.name === args[key] || prop.label === args[key] || prop.dropdownName === args[key]
      );
    }
    return null;
  }

  getPathTemplate(selectedPath: any, args: any) {

    console.log('# getPathTemplate ',);

    if (this._isCustomActive) {
      const listItem = this.currentConfig.items.filter(item => item.digitCheckerField);
      listItem.forEach(fieldConfig => {
        const digitCheckerField = fieldConfig.digitCheckerField;
        const fieldToSet = fieldConfig.field;
        const valueToCheck = args[digitCheckerField] || '';
        const valueConfig = fieldConfig.setValue.find(v => v.digit === valueToCheck.length) || fieldConfig.setValue.find(v => v.digit === 0);
        const newValue = valueConfig ? valueConfig.value : '';
        if (args[fieldToSet] !== newValue) {
          args[fieldToSet] = newValue;
          this.form.patchValue({
            [fieldToSet]: newValue
          });
        }
      });



      // const dateTest = selectedPath.replace(/\${([A-Za-z0-9.,:-|]*)}/g, (_, v) => {
      //   const d = v.split('|');
      //   if (d.length > 1) {
      //     const [key, format] = d;
      //     const date = moment(args[key]); // แปลง args[key] เป็น moment object
      //     if (date.isValid()) { // ตรวจสอบว่าเป็นวันที่ถูกต้อง
      //       return date.format(format);
      //     }
      //   }
      //   return v; // คืนค่าเดิมถ้าไม่เข้าเงื่อนไข
      // });

      // console.log('dateTest ', dateTest);



      return selectedPath.replace(/\${([A-Za-z0-9.,:-|]*)}/g, (_, v) => {
        const d = v.split('|');
        if (d.length > 1) {
          const [key, format, range] = d;
          const matchedProperty = this.getMatchedProperty(args, key, this.updatedMatchedType);

          const date = moment(args[key]);

          if (matchedProperty) {
            let value = format === 'label' ? matchedProperty.label : matchedProperty.name;
            if (format === 'label') {
              value = value.replace(/\.$/, ''); // Remove trailing period if it exists
            }
            if (range) {
              const [start, end] = range.split(',').map(Number);
              return value.substring(start - 1, end);
            }
            return value;
          } else if (date.isValid()) {  // } else if (args[key] instanceof moment) {
            return date.format(format); //  return args[key] ? args[key].format(format) : v;
          } else if (format.includes(',')) {
            const [start, end] = format.split(',').map(Number);
            return args[key] ? args[key].substring(start - 1, end) : v;
          } else {
            return args[key] ? args[key] : v;
          }
        } else {
          return args[v] ? args[v] : v;
        }
      });
    } else {
      return selectedPath.replace(/\${([A-Za-z0-9.,:-|]*)}/g, (_, v) => {
        const d = v.split('|');
        if (d.length > 1) {
          const [key, format, range] = d;
          const date = moment(args[key]);
          if (range) {
            const [start, end] = range.split(',').map(Number);
            return key.substring(start - 1, end);
          }
          if (date.isValid()) { //  if (args[key] instanceof moment) {
            return date.format(format);  // return args[key] ? args[key].format(format) : v;
          } else if (format.includes(',')) {
            const [start, end] = format.split(',').map(Number);
            return args[key] ? args[key].substring(start - 1, end) : v;
          } else {
            return args[key] ? args[key] : v;
          }
        } else {
          return args[v] ? args[v] : v;
        }
      });
    }
  }

  getLabelFromUpdatedMatchedType(key, args: any) {
    for (const type of this.updatedMatchedType) {
      for (const property of type.properties) {
        if (property.name === args[key] || property.label === args[key] || property.dropdownName === args[key]) {
          return property.label;
        }
      }
    }
    return args[key] || key;
  }


  getPath(args) {

    if (this.currentConfig?.path) {
      return this.currentConfig.path;
    }

    if (!this.currentConfig?.pathCondition) {
      return this.currentConfig ? this.currentConfig.pathDefault : null;
    }

    const { pathDefault, pathCondition } = this.currentConfig;

    // eslint-disable-next-line no-prototype-builtins
    if (!pathCondition.field || !args.hasOwnProperty(pathCondition.field)) {
      return pathDefault;
    }

    const idCardNo = args[pathCondition.field];

    if (idCardNo && idCardNo.length === pathCondition.digit) {
      return pathCondition.path;
    } else {
      return pathDefault;
    }
  }


  template(tpl: any, args: any) {
    return tpl.replace(/\${([A-Za-z0-9.,:-|]*)}/g, (_: any, v: any) => {
      const d = v.split('|');
      if (d.length > 1) {
        return args[d[0]] instanceof moment
          ? args[d[0]]
            ? args[d[0]].format(d[1])
            : v
          : v;
      } else {
        return args[v] ? args[v] : v;
      }
    });
  }


  updatePathAndFileName() {

    console.log('# updatePathAndFileName');

    const formValues = this.form.getRawValue();

    const selectedPath = this.getPath({ ...formValues });

    this.currentPath = this.getPathTemplate(selectedPath, {
      ...formValues,
      created: moment()
    });

    if (this.renameFileActive) {
      const date_yyyy = moment().format('YYYY');
      const date_mm = moment().format('MM');
      const date_dd = moment().format('DD');
      const extension = this.extensionFile;

      this.currentFileName = this.getPathTemplate(this.currentType.fileName, {
        ...formValues,
        date_yyyy,
        date_mm,
        date_dd,
        extension
      });


    }


    console.log('currentFileName ', this.currentFileName);
  }

  onChanged() {
    this.updatePathAndFileName();
  }

  selectedValue: any;
  onChangedAutoCompleteDropdown() {
    this.updatePathAndFileName();
  }


  customOptions = [
    {
      name: 'Contract Document #1 #custom',
      value: 'Contract Document #1 #custom'
    },
    {
      name: 'Contract Document #2 #custom',
      value: 'Contract Document #2 #custom'
    },
  ]
  modelTypeData: any;
  updatedMatchedType: any;
  async loadData() {
    if (this._isCustomActive) {
      const customDropdown = this.currentType['dropdown-custom-setting'];
      if (customDropdown) {
        if (customDropdown.isEnabled) {
          await this.contentMetadataService.login().subscribe(async () => {
            await this.contentMetadataService.getModels().subscribe(models => {
              const _dropdownStatus_SmartUpload = customDropdown?.dropdownStatus_SmartUpload;
              if (_dropdownStatus_SmartUpload) {
                this.modelTypeData = models.map(item => {
                  return {
                    ...item,
                    properties: item.properties.filter(prop => _dropdownStatus_SmartUpload === 'All' || (prop.status && prop.status === _dropdownStatus_SmartUpload))
                  };
                });
              }
              const config = this.currentConfig.items;
              if (this.modelTypeData) {
                this.updatedMatchedType = this.modelTypeData.map(item => {
                  const configItem = config.find(c => c.id === item.name && c.type === 'dropdown-custom');
                  const displayField = configItem ? configItem.display : 'name';
                  return {
                    ...item,
                    field: configItem ? configItem.field : null,
                    properties: item.properties.map(prop => ({
                      ...prop,
                      display: prop[displayField]
                    }))
                  };
                });
              }
            });
          });

        }
      }
    }
  }


  customFilterOptions(item) {

    if (!this.updatedMatchedType) {
      return [];
    }

    const matchedType = this.updatedMatchedType
      .filter(data => data.name === item.id)
      .flatMap(data => data.properties);

    const fieldValue = this.form.getRawValue()[item.field];
    if (!fieldValue) {
      return matchedType;
    }

    const upperFieldValue = fieldValue.toUpperCase();

    return matchedType.filter(option =>
      option.dropdownName.toUpperCase().includes(upperFieldValue)
    );

  }


  selectedItem: string;
  filterOptions(item) {
    const v = this.form.getRawValue();
    return v[item.field]
      ? item.options.filter(o =>
        o.value.toUpperCase().includes(v[item.field].toUpperCase())
      )
      : item.options;
  }


  onBlur(item) {
    const fieldID = item.field;
    const filterValue = this.form.get(fieldID).value.toLowerCase();
    const temp = item.options.filter(o => o.value.toLowerCase() === filterValue);
    if (temp.length === 0) {
      this.form.get(item.field).setValue('');
    }
    return temp;
  }

  onBlurCustom(item) {
    const fieldID = item.field;
    const filterValue = this.form.get(fieldID).value.toLowerCase();

    if (!item.options) {
      this.form.get(item.field).setValue('');
      return [];
    }

    const temp = item.options.filter(o => o.value.toLowerCase() === filterValue);
    if (temp.length === 0) {
      this.form.get(item.field).setValue('');
    }
    return temp;
  }


  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  getPeople(): Observable<any> {
    const pathService = `/people/-me-`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }


}
