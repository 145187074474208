import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  ContextActionsDirective,
  PageLayoutComponent,
  PaginationDirective,
  ToolbarComponent
} from '@alfresco/aca-shared';

import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts'
import { NgModule } from '@angular/core';
import { ChartDimensionsComponent } from './chart-dimensions.component';


@NgModule({
  declarations: [
    ChartDimensionsComponent,
  ],
  exports: [
    ChartDimensionsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ChartModule,
    ContextActionsDirective,
    PageLayoutComponent,
    PaginationDirective,
    ToolbarComponent,
    DashboardLayoutModule,
    AccumulationChartModule
  ]
})
export class ChartDimensionsModule { }
