import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AlfrescoApiService } from '@alfresco/adf-core';

@Injectable({
  providedIn: 'root'
})
export class UserLibService {

  constructor(private apiService: AlfrescoApiService) {
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }


  private privateApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callCustomApi(path, httpMethod, ...params);
  }

  getUser(): Observable<any> {
    const pathService = `people?skipCount=0&maxItems=10000`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  getPeople(): Observable<any> {
    const pathService = `/people?skipCount=0&maxItems=10000`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  getGroups(): Observable<any> {
    const pathService = `groups?skipCount=0&maxItems=1000`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }



  createUser(body: any): Observable<any> {
    const pathService = `people`
    return from(this.publicApiCall(
      pathService,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  updateUser(body: any, userId: any): Observable<any> {
    const pathService = `people/${userId}`
    return from(this.publicApiCall(
      pathService,
      'PUT',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  addUserToGroup(userId: string, groupId: string): Observable<any> {
    const pathService = `groups/${groupId}/members`;
    const body = { id: userId, memberType: 'PERSON' }; // Add memberType
    return from(this.publicApiCall(
      pathService,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }



  getListSiteMember(siteId: any): Observable<any> {
    const pathService = `sites/${siteId}/members?skipCount=0&maxItems=10000`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  getListSiteGroupMember(siteId: string): Observable<any> {
    const pathService = `alfresco/s/api/sites/${siteId}/memberships?size=250&nf=&authorityType=GROUP`
    const httpMethod = 'GET';
    return from(this.privateApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  createSiteMember(siteId: any, userId: any, role: any): Observable<any> {
    const pathService = `sites/${siteId}/members`;
    const body = { role: role, id: userId };
    return from(this.publicApiCall(
      pathService,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  createSiteGroupMember(siteId: any, groupId: any, role: any): Observable<any> {
    const pathService = `sites/${siteId}/group-members`;
    const body = { role: role, id: groupId };
    return from(this.publicApiCall(
      pathService,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  deleteSiteMember(siteId: any, userId: any): Observable<any> {
    const pathService = `sites/${siteId}/members/${userId}`
    const httpMethod = 'DELETE';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }

  deleteSiteGroupMember(siteId: any, groupId: any): Observable<any> {
    const pathService = `sites/${siteId}/group-members/${groupId}`
    const httpMethod = 'DELETE';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }


  updateSiteMember(siteId: any, userId: any, role: any): Observable<any> {
    const pathService = `sites/${siteId}/members/${userId}`
    const body = { role: role };
    return from(this.publicApiCall(
      pathService,
      'PUT',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  updateSiteGroupMember(siteId: any, groupId: any, role: any): Observable<any> {
    const pathService = `sites/${siteId}/group-members/${groupId}`
    const body = { role: role };
    return from(this.publicApiCall(
      pathService,
      'PUT',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

}
