<div class="dialog-header">
  <h2 class="dialog-title">Set Password</h2>
</div>

<div class="dialog-content">
  <div>
    <ejs-textbox
      placeholder="*Enter Filename:"
      [(value)]="zipFileName"
      floatLabelType="Always"
      required="true"
      [showClearButton]="true">
    </ejs-textbox>
    <div *ngIf="submitted && !zipFileName" class="error-message">Filename is required</div>

    <ejs-textbox
      placeholder="*Enter Password:"
      [(value)]="zipPassword"
      floatLabelType="Always"
      required="true"
      [showClearButton]="true">
    </ejs-textbox>
    <div *ngIf="submitted && !zipPassword" class="error-message">Password is required</div>
  </div>

  <div style="text-align: right; margin-bottom: 2px">
    <button ejs-button class="rounded-button" style="margin-left: 5px" (click)="isOk()">Confirm</button>
    <button ejs-button class="rounded-button" style="margin-left: 5px" (click)="isCancel()">Cancel</button>
  </div>
</div>
