import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { DataConfigStructure } from '../data/data-structures';


@Injectable({
  providedIn: 'root'
})
export class AnalyticService {
  environment: any;
  apiURL: any;
  apiURL_internalShare: any;
  headers: any;
  userName: any;

  configData: any;
  config: any;

  urlAPI: any;
  apiPath: any;

  constructor(
    private apiService: AlfrescoApiService,
    private httpClient: HttpClient,
  ) {
    this.apiPath = `/alfresco/api/-default-/public/search/versions/1/search`;
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callCustomApi(path, httpMethod, ...params);
  }



  // ==============================================================================================================================

  async loadConfig(): Promise<any> {
    try {
      const data = await this.getConfig();
      this.configData = data['analytic-report'];
      this.config = this.configData;
      return this.config;
    } catch (error) {
      console.log('error loadConfig() :', error);
    }
  }

  async getConfig(): Promise<any> {
    try {
      const data = await this.httpClient.get('./analytic-report.config.json').toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching configuration:', error);
      throw error;
    }
  }


  // ==============================================================================================================================

  async getConfigReport(): Promise<Observable<DataConfigStructure>> {
    await this.loadConfig();
    const temp = this.config?.options?.[0] || [];
    return of(temp);
  }


  async filesCreatedByYearTitle(): Promise<Observable<any>> {
    await this.loadConfig();
    const temp = this.config?.options?.[0]?.filesCreatedByYear[0] || [];
    const viewValue = temp.viewValue;
    return viewValue;
  }

  async viewValue(filter: any): Promise<Observable<any>> {
    console.log('filter ', filter);
    await this.loadConfig();
    const option = this.config?.options?.[0];
    console.log('option ', option);

    return null;
  }


  // 1. แสดงจำนวนไฟล์ที่สร้างแต่ละปีย้อนหลัง
  async filesCreatedByYear(): Promise<Observable<any>> {
    await this.loadConfig();
    const temp = this.config?.options?.[0]?.filesCreatedByYear[1] || [];
    const temps = JSON.stringify(temp);
    const body = JSON.parse(temps)
    return from(this.publicApiCall(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  // 2. แสดงจำนวนไฟล์ที่าร้างตามเดือนย้อนหลัง
  // async filesCreatedByMonth(): Promise<Observable<any>> {
  //   await this.loadConfig();
  //   const temp = this.config?.options?.[0]?.filesCreatedByMonth[1] || [];
  //   const currentYear = temp.year;
  //   const months = ['January',
  //     'February',
  //     'March',
  //     'April',
  //     'May',
  //     'June',
  //     'July',
  //     'August',
  //     'September',
  //     'October',
  //     'November',
  //     'December'];
  //   console.log('months ', months);
  //   const facetQueries = months.map((month, index) => {
  //     const startDate = `${currentYear}-${String(index + 1).padStart(2, '0')}-01`;
  //     let endDate = `${currentYear}-${String(index + 2).padStart(2, '0')}-01`;
  //     if (index === 11) {
  //       endDate = `${currentYear + 1}-01-01`;
  //     }
  //     return {
  //       query: `created:[${startDate} TO ${endDate}]`,
  //       label: `${month} ${currentYear}`
  //     };
  //   });
  //   const body = {
  //     query: {
  //       query: 'cm:name:* AND TYPE:\"cm:content\"'
  //     },
  //     facetQueries: facetQueries,
  //     paging: {
  //       maxItems: 1
  //     }
  //   };
  //   return from(this.publicApiCall(
  //     this.apiPath,
  //     'POST',
  //     [null, null, null, null, body, ['application/json'], ['application/json']]
  //   ));
  // }

  async filesCreatedByMonth(): Promise<Observable<any>> {
    await this.loadConfig();
    const currentDate = new Date();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const facetQueries = [];

    for (let i = 0; i < 12; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      const year = date.getFullYear();
      const month = date.getMonth();
      const startDate = `${year}-${String(month + 1).padStart(2, '0')}-01`;
      const endDate = new Date(year, month + 1, 1);
      const endDateString = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-01`;

      facetQueries.push({
        query: `created:[${startDate} TO ${endDateString}]`,
        label: `${months[month]} ${year}`
      });
    }

    const body = {
      query: {
        query: 'cm:name:* AND TYPE:"cm:content"'
      },
      facetQueries: facetQueries,
      paging: {
        maxItems: 1
      }
    };

    return from(this.publicApiCall(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  // 3. แสดงจำนวนไฟล์ตามประเภทเอกสาร
  async fileCountByType(): Promise<Observable<any>> {
    await this.loadConfig();
    const temp = this.config?.options?.[0]?.fileCountByType[1] || [];
    const temps = JSON.stringify(temp);
    const body = JSON.parse(temps)
    return from(this.publicApiCall(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  // 4. แสดงจำนวนไฟล์ตามขนาดไฟล์
  async fileCountBySize(): Promise<Observable<any>> {
    await this.loadConfig();
    const temp = this.config?.options?.[0]?.fileCountBySize[1] || [];
    const temps = JSON.stringify(temp);
    const body = JSON.parse(temps)
    return from(this.publicApiCall(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }


  // 5. แสดงจำนวนไฟล์ในแต่ละ library (SITE)
  async fileCountPerLibrary(): Promise<Observable<any>> {
    await this.loadConfig();
    const temp = this.config?.options?.[0]?.fileCountPerLibrary[1] || [];
    const temps = JSON.stringify(temp);
    const body = JSON.parse(temps)
    return from(this.publicApiCall(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  // 6. แสดงจำนวนไฟล์ในแต่ละ library (SITE)
  async displayTotalStorage(): Promise<Observable<any>> {
    await this.loadConfig();
    const temp = this.config?.options?.[0]?.displayTotalStorage[1] || [];
    const temps = JSON.stringify(temp);
    const body = JSON.parse(temps)
    return from(this.publicApiCall(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }


  // 7. แสดงจำนวนไฟล์ของแต่ละ Properties propetyName, propetyValue
  async fileAspectByPropeties(propetyName: any, propetyValue: any): Promise<Observable<any>> {
    const body = {
      'query': {
        'query': `(TYPE:'cm:content') AND (${propetyName}: ${propetyValue})`,
        'language': 'afts'
      },
      'include': [
        'properties'
      ]
    };
    return from(this.publicApiCall(
      this.apiPath,
      'POST',
      [null, null, null, null, body, ['application/json'], ['application/json']]
    ));
  }

  async fileAspectByPropetiesData(): Promise<any> {
    await this.loadConfig();
    const temp = this.config?.options?.[0]?.fileAspectByPropeties[1] || [];
    const promises = temp.propeties.map(async (aa) => {
      const propetyName = aa.propety;
      const propetyValue = aa.value;
      const propetyTitle = aa.title;
      const response = await (await this.fileAspectByPropeties(propetyName, propetyValue)).toPromise();
      const countValue = response.list.pagination.count;
      return { label: propetyTitle, count: countValue };
    });
    const chartData = await Promise.all(promises);
    return chartData;
  }

}
