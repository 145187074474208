/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TextAreaModule, TextBoxModule, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ButtonModule, ChipListModule } from '@syncfusion/ej2-angular-buttons';
import { BreadcrumbBeforeItemRenderEventArgs, BreadcrumbModule } from '@syncfusion/ej2-angular-navigations';
import { ApprovalWorkflowService } from '../../service/approval-workflow.service';
import { CommonModule } from '@angular/common';
import {
  PeopleContentService,
  ContentNodeSelectorComponentData,
  ContentNodeSelectorComponent,
  // ContentNodeDialogService,
  // ContentNodeSelectorComponent,
} from '@alfresco/adf-content-services';
import { AppConfigService, TranslationService } from '@alfresco/adf-core';
import { Store } from '@ngrx/store';

import {
  AppStore,
  SnackbarErrorAction,
  SnackbarInfoAction,
  // ViewFileAction,
  // ViewNodeAction,
  // SnackbarInfoAction,
  // SnackbarWarningAction
} from '@alfresco/aca-shared/store';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { Subject } from 'rxjs';
import { Node, Site, SitePaging, SitePagingList } from '@alfresco/js-api'; // Ensure you import the correct Node type
import { DropDownButtonModule, ItemModel } from '@syncfusion/ej2-angular-splitbuttons'
import { TranslateModule } from '@ngx-translate/core';
// import { take } from 'rxjs/operators';
// import {
//   ActivatedRoute,
//   // Router
// } from '@angular/router';


interface Group {
  group_id: string;
  group_name: string;
}


interface SubGroup {
  sub_group_id: string;
  sub_group_name: string;
  group_id: string;
  group_name: string;
}


@Component({
  standalone: true,
  imports: [
    MatDialogModule,
    DropDownListModule,
    TranslateModule,
    DatePickerModule,
    UploaderModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TextBoxModule,
    ButtonModule,
    BreadcrumbModule,
    ChipListModule,
    TextAreaModule,
    CommonModule,
    MatDividerModule,
    MatGridListModule,
    DropDownButtonModule,
    ButtonModule
    // ... other imports
  ],
  selector: 'app-request-dialog',
  templateUrl: './request-dialog.component.html',
  styleUrls: ['./request-dialog.component.scss'],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Add this if mat-form-field is a Web Component
  ],
  encapsulation: ViewEncapsulation.None
})
export class RequestDialogComponent implements OnInit {
  requestForm: FormGroup;
  groups = [{ id: 'accounting', name: 'แผนกบัญชี' }];
  subGroups = [{ id: 'purchase', name: 'ขอซื้อ' }];
  processes = [{ id: 'process1', name: 'คอมพิวเตอร์และอุปกรณ์มากกว่า 10,001 บาท' }];
  priorities = ['Low', 'Medium', 'High'];
  approvers = ['Peter Parker', 'Tony Stark', 'Chris Evens', 'Mark Raffalo', 'Chris Pratt'];
  attachedFiles = [];

  uploadSettings = {
    saveUrl: 'api/upload/save',
    removeUrl: 'api/upload/remove'
  };

  public dataGroup: { [key: string]: Object }[] = [];
  public fieldsGroup: Object = { text: 'group_name', value: 'group_id' };
  public textGroup = '';
  public selectedGroupId: any;
  public selectedGroupName: any;

  public dataSubGroup: { [key: string]: Object }[] = [];
  public fieldsSubGroup: Object = { text: 'sub_group_name', value: 'sub_group_id' };
  public textSubGroup = '';
  public selectedSubGroupId: any;
  public selectedSubGroupName: any;

  public dataConfig: { [key: string]: Object }[] = [];
  public fieldsConfig: Object = { text: 'process_name', value: 'config_manager_workflow_id' };
  public textConfig = '';
  public selectedConfigId: any;
  public selectedConfigName: any;


  public dataPriority: { [key: string]: Object }[] = [];
  public fieldsPriority: Object = { text: 'priority_name', value: 'priority_id' };
  public textPriority = '';
  public selectedPriorityId: any;
  public selectedPriorityName: any;



  public dateValue: Date = new Date();

  processDefinitionKey: any;

  public items: ItemModel[] = [
    {
      text: 'View'
    },
    {
      text: 'Remove'
    }
  ];

  public _requestProcessData: any;
  public _requestProcessDataUser: any;
  public _requestProcessDataFile: any;
  public _dataTemp: any;

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<RequestDialogComponent>,
    private fb: FormBuilder,
    private workflowService: ApprovalWorkflowService,
    private peopleContentService: PeopleContentService,
    private appConfigService: AppConfigService,
    private store: Store<AppStore>,
    private translation: TranslationService,
    @Inject(MAT_DIALOG_DATA) public _data: any
    // private activatedRoute: ActivatedRoute,
    // private router: Router,
  ) {
    this.requestForm = this.fb.group({
      group: ['', Validators.required],
      subGroup: ['', Validators.required],
      config: ['', Validators.required],
      subject: ['', Validators.required],
      priority: ['', Validators.required],
      dueDate: ['', Validators.required],
      description: ['', Validators.required]
    });

    this._dataTemp = _data;


  }

  async ngOnInit() {
    // Initialize any necessary data

    this.dataPriority = [
      { priority_id: '1', priority_name: 'Low' },
      { priority_id: '2', priority_name: 'Medium' },
      { priority_id: '3', priority_name: 'High' },
    ];

    this.processDefinitionKey = this.appConfigService.get('appoveWorkflow.processDefinitionKey');


    await this.getGroup();
    await this.loadEcmUserInfo();

    if (this._dataTemp) {
      this.onDefaultDraft();
    }


  }


  async onDefaultDraft() {
    this._requestProcessData = this._dataTemp.requestProcessData;
    this._requestProcessDataUser = this._dataTemp.requestProcessDataUser;
    this._requestProcessDataFile = this._dataTemp.requestProcessDataFile;
    await this.getSubGroup(this._requestProcessData.group_id);
    await this.getProcessConfig(this._requestProcessData.sub_group_id);
    // Dropdown
    this.selectedGroupId = this._requestProcessData.group_id;
    this.selectedSubGroupId = this._requestProcessData.sub_group_id;
    this.selectedConfigId = this._requestProcessData.config_manager_workflow_id;
    this.selectedPriorityId = this._requestProcessData.request_priority_id;
    // File
    if (this._requestProcessDataFile) {
      if (this._requestProcessDataFile.length > 0) {
        this.listNodeUploadActive = true;
        this.listNodeUpload = this._requestProcessDataFile;
      }
    }
  }


  closeDialog() {
    this.dialogRef.close();
  }

  saveDraft() {
    // Implement save draft logic
    if (this.requestForm.valid) {
      const formData = this.requestForm.value;
      console.log('formData:', formData);
      // Handle save draft
    }
  }

  submit() {
    if (this.requestForm.valid) {
      const formData = this.requestForm.value;
      this.dialogRef.close(formData);
    }
  }

  onSubmit(): void {
    if (this.requestForm.valid) {
      // Handle form submission
    }
  }


  public beforeItemRenderHandler(args: BreadcrumbBeforeItemRenderEventArgs): void {
    if (args.item.text !== '') {
      args.element.classList.add('e-icon-right');
    }
  }

  // Group & Sub-Group

  public async getGroup() {
    const response = await this.workflowService.getGroup().toPromise();
    this.dataGroup = response.data.map((group: Group) => ({
      group_id: group.group_id,
      group_name: group.group_name
    }));
  }

  onGroupChange(event: any): void {
    if (event.itemData) {
      this.getSubGroup(event.itemData.group_id);
    }
  }

  onSubGroupChange(event: any): void {
    if (event.itemData) {
      this.getProcessConfig(event.itemData.sub_group_id);
    }
  }


  userId: any;
  public async loadEcmUserInfo() {
    await this.peopleContentService.getCurrentUserInfo().subscribe(userInfo => {
      this.userId = userInfo.id;
      // this.userFullName = userInfo.firstName + ' ' + (userInfo.lastName ? userInfo.lastName : '');
      // this.userEmail = userInfo.email;
    });
  }



  public async getSubGroup(groupId: any) {
    const response = await this.workflowService.getSubGroup(groupId).toPromise();
    this.dataSubGroup = response.data.map((group: SubGroup) => ({
      sub_group_id: group.sub_group_id,
      sub_group_name: group.sub_group_name
    }));
  }

  // Process Config
  public async getProcessConfig(subGroupId: any) {
    try {
      console.log('subGroupId ', subGroupId);
      const response = await this.workflowService.getConfigWorkflowBySupGroupId(subGroupId).toPromise();
      console.log('response ', response.data);
      this.dataConfig = [];
      if (response) {
        response.data.forEach(item => {
          this.dataConfig.push({
            config_manager_workflow_id: item.config_manager_workflow_id,
            process_name: item.process_name,
            group_id: item.group_id,
            group_name: item.group_name,
            sub_group_id: item.sub_group_id,
            sub_group_name: item.sub_group_name
          });
        });
      }
    } catch (error) {
      console.log('error ', error);
    }
  }

  dataConfigUser: any;
  dataConfigProcess: any;
  async onConfigChange(event: any): Promise<void> {
    console.log('event.itemData ', event.itemData);
    if (event.itemData) {
      await this.getSubGroup(event.itemData.group_id);
      this.selectedGroupId = event.itemData.group_id;
      this.selectedSubGroupId = event.itemData.sub_group_id
      this.selectedGroupName = event.itemData.group_name;
      this.selectedSubGroupName = event.itemData.sub_group_name;
      this.selectedConfigName = event.itemData.process_name;

      this.dataConfigProcess = [
        { 'processName': this.selectedGroupName },
        { 'processName': this.selectedSubGroupName },
        { 'processName': this.selectedConfigName }
      ];

      const response = await this.workflowService.getConfigWorkflowUser(event.itemData.config_manager_workflow_id).toPromise();
      const data = response.data.map(user => ({
        userId: user.user_id,
        displayName: user.display_name,
        email: user.email,
        listNo: parseInt(user.listno, 10)
      }));
      this.dataConfigUser = data;
      console.log('this.dataConfigUser ', this.dataConfigUser);
    }
  }


  listNodeUpload: any;
  listNodeUploadActive = false;
  openSelectorDialog() {
    const select = new Subject<Node[]>(); // Use the correct Node type here
    select.subscribe({
      complete: this.close.bind(this)
    });
    const customDropdown = new SitePaging({
      list: {
        entries: [
          {
            entry: {
              guid: '-my-',
              title: this.translation.instant('APP.BROWSE.PERSONAL.SIDENAV_LINK.LABEL')
            } as Site
          },
          {
            entry: {
              guid: '-mysites-',
              title: this.translation.instant('APP.BROWSE.LIBRARIES.MENU.MY_LIBRARIES.SIDENAV_LINK.LABEL')
            } as Site
          }
        ]
      } as SitePagingList
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data: ContentNodeSelectorComponentData = {
      selectionMode: 'multiple',
      title: 'Select file',
      dropdownHideMyFiles: true,
      currentFolderId: '-my-',
      dropdownSiteList: customDropdown,
      showSearch: true,
      showDropdownSiteList: true,
      select: select // Use the correct Node type here
    };

    this.dialog.open(
      ContentNodeSelectorComponent,
      {
        data, panelClass: 'adf-content-node-selector-dialog',
        width: '630px'
      }
    );

    data.select.subscribe((selections: Node[]) => {
      // Use or store selection...
      console.log('selections ', selections);

      const sectionData = selections.map((item, index) => ({
        node_id: item.id,
        node_name: item.name,
        node_path: '',
        mode_remark: '',
        listno: index + 1
      }));
      this.listNodeUpload = sectionData;
      this.listNodeUploadActive = true;

      console.log('listNodeUpload ', this.listNodeUpload);
    },
      (error) => {
        console.log('error ', error);
      },
      () => {
        this.dialog.closeAll();
      });
  }

  close() {
    this.dialog.closeAll();
  }

  removeNode(nodeId: string) {
    this.listNodeUpload = this.listNodeUpload.filter(node => node.id !== nodeId);
  }

  onClick(nodeId: any) {
    window.open(`#/Appoval-Workflow/Preview?param=${nodeId}`, '_blank');
  }

  onRemove(nodeId: string) {
    try {
      this.listNodeUpload = this.listNodeUpload.filter(
        node => node.node_id !== nodeId
      );
    } catch (error) {
      console.error('Error removing node:', error);
    }
  }


  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }

  public async onSaveDraft() {
    try {

      const groupId = this.requestForm.get('group').value;
      const groupName = this.dataGroup.find(item => item.group_id === groupId)?.group_name;
      const subGroupId = this.requestForm.get('subGroup').value;
      const subGroupName = this.dataSubGroup.find(item => item.sub_group_id === subGroupId)?.sub_group_name;
      const configValueId = this.requestForm.get('config').value;
      const configValueName = this.dataConfig.find(item => item.config_manager_workflow_id === configValueId)?.process_name;
      const priorityValueId = this.requestForm.get('priority').value;
      const priorityValueName = this.dataPriority.find(item => item.priority_id === priorityValueId)?.priority_name;
      const subjectValue = this.requestForm.get('subject').value;
      const dueDateValue = this.requestForm.get('dueDate').value;
      const descriptionValue = this.requestForm.get('description').value;

      if (!groupName) {
        this.alertError('Please select Group', null);
        return;
      }
      if (!subGroupName) {
        this.alertError('Please select Sub Group', null);
        return;
      }
      if (!configValueName) {
        this.alertError('Please select Process', null);
        return;
      }
      if (!priorityValueName) {
        this.alertError('Please select Priority', null);
        return;
      }
      if (!subjectValue) {
        this.alertError('Please input Subject', null);
        return;
      }
      if (!dueDateValue) {
        this.alertError('Please input Due Date', null);
        return;
      }
      if (!descriptionValue) {
        this.alertError('Please input Description', null);
        return;
      }


      if (!this._dataTemp) {
        // 1. Insert Draft
        const dataRequestProcess = {
          'workflow_id': 0,
          'workflow_def_key': this.processDefinitionKey,
          'workflow_start_user_id': this.userId,
          'group_id': groupId,
          'group_name': groupName,
          'sub_group_id': subGroupId,
          'sub_group_name': subGroupName,
          'config_manager_workflow_id': configValueId,
          'process_name': configValueName,
          'request_subject': subjectValue,
          'request_priority_id': priorityValueId,
          'request_priority_name': priorityValueName,
          'request_due_date': dueDateValue,
          'request_description': descriptionValue,
          'request_process_user_draft': this.dataConfigUser,
          'request_process_file_draft': this.listNodeUpload,
          'request_process_status': false
        }
        const resCreateRequestProcess = await this.workflowService.createRequestProcessDraft(dataRequestProcess).toPromise();
        if (resCreateRequestProcess) {
          this.alertInfo('Save Draft Request Process Success ');
          console.log('resCreateRequestProcess ', resCreateRequestProcess);
          this.dialogRef.close(true);
        }
      } else {
        // update request_process_draft
        // delete & insert request_process_user_draft
        // delete & insert request_process_file_draft
        const dataRequestProcess = {
          'request_process_draft_id': this._dataTemp.requestProcessData.request_process_draft_id,
          'workflow_id': 0,
          'workflow_def_key': this.processDefinitionKey,
          'workflow_start_user_id': this.userId,
          'group_id': groupId,
          'group_name': groupName,
          'sub_group_id': subGroupId,
          'sub_group_name': subGroupName,
          'config_manager_workflow_id': configValueId,
          'process_name': configValueName,
          'request_subject': subjectValue,
          'request_priority_id': priorityValueId,
          'request_priority_name': priorityValueName,
          'request_due_date': dueDateValue,
          'request_description': descriptionValue,
          'request_process_user_draft': this.dataConfigUser,
          'request_process_file_draft': this.listNodeUpload,
          'request_process_status': false
        }
        const resUpdateRequestProcess = await this.workflowService.updateRequestProcessDraft(dataRequestProcess).toPromise();
        if (resUpdateRequestProcess) {
          this.alertInfo('Update Draft Request Process Success ');
          console.log('resCreateRequestProcess ', resUpdateRequestProcess);
          this.dialogRef.close(true);
        }

      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
      this.alertError('Save Draft Request Process Error', errorMessage);
    }
  }


  public async onStart() {
    try {

      const groupId = this.requestForm.get('group').value;
      const groupName = this.dataGroup.find(item => item.group_id === groupId)?.group_name;


      const subGroupId = this.requestForm.get('subGroup').value;
      const subGroupName = this.dataSubGroup.find(item => item.sub_group_id === subGroupId)?.sub_group_name;


      const configValueId = this.requestForm.get('config').value;
      const configValueName = this.dataConfig.find(item => item.config_manager_workflow_id === configValueId)?.process_name;

      const priorityValueId = this.requestForm.get('priority').value;
      const priorityValueName = this.dataPriority.find(item => item.priority_id === priorityValueId)?.priority_name;

      const subjectValue = this.requestForm.get('subject').value;
      const dueDateValue = this.requestForm.get('dueDate').value;
      const descriptionValue = this.requestForm.get('description').value;

      const fields = [
        { value: groupName, message: 'Group' },
        { value: subGroupName, message: 'Sub Group' },
        { value: configValueName, message: 'Process' },
        { value: subjectValue, message: 'Subject' },
        { value: priorityValueName, message: 'Priority' },
        { value: dueDateValue, message: 'Due Date' },
        { value: descriptionValue, message: 'Description' }
      ];

      // for (const field of fields) {
      //   if (!field.value) {
      //     this.alertError(field.message, null);
      //     return;
      //   }
      // }

      const errorMessages = fields
        .filter(field => !field.value)
        .map(field => field.message);

      if (errorMessages.length > 0) {
        this.alertError(`Please input: ${errorMessages.join(', ')}`, null);
        return;
      }



      // 1. Create Process

      const transformedData = this.dataConfigUser.map(item => ({
        no: parseInt(item.listNo, 10),
        assignee: item.userId
      }));

      const data = {
        'processDefinitionKey': `${this.processDefinitionKey}`,
        'variables': {
          'bpm_assignee': `${this.userId}`,
          'bpm_sendEMailNotifications': false,
          'bpm_workflowPriority': 0,
          'bpm_package': '',
          'bc_steps': JSON.stringify(transformedData)
        }
      }
      const response = await this.workflowService.createProcess(data).toPromise();

      // 2. Insert Database to Backend
      let requestProcessDraftId = this._dataTemp?.requestProcessData?.request_process_draft_id
      if (!requestProcessDraftId) {
        requestProcessDraftId = null
      }
      const dataRequestProcess = {
        'request_process_draft_id': requestProcessDraftId,
        'workflow_id': response.entry.id,
        'workflow_def_key': this.processDefinitionKey,
        'workflow_start_user_id': this.userId,
        'group_id': groupId,
        'group_name': groupName,
        'sub_group_id': subGroupId,
        'sub_group_name': subGroupName,
        'config_manager_workflow_id': configValueId,
        'process_name': configValueName,
        'request_subject': subjectValue,
        'request_priority_id': priorityValueId,
        'request_priority_name': priorityValueName,
        'request_due_date': dueDateValue,
        'request_description': descriptionValue,
        'request_process_user': this.dataConfigUser,
        'request_process_file': this.listNodeUpload
      }

      const resCreateRequestProcess = await this.workflowService.createRequestProcess(dataRequestProcess).toPromise();
      if (resCreateRequestProcess) {
        this.alertInfo('Create Request Process Success ');
        console.log('resCreateRequestProcess ', resCreateRequestProcess);
        this.dialogRef.close(true);
      }

    } catch (error) {
      console.log('error ', error);
      this.alertError('Create Request Process Error', error);
    }

  }


}
