import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContextActionsDirective, PageComponent, PageLayoutComponent, PaginationDirective, ToolbarComponent } from '@alfresco/aca-shared';

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentListModule } from '@alfresco/adf-content-services';
import { DataTableModule, PaginationModule, TemplateModule } from '@alfresco/adf-core';
import { ExtensionsModule, ProfileState } from '@alfresco/adf-extensions';
import { FormControl } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';


import { MatTableDataSource } from '@angular/material/table';

import { NetworkService } from '../services/network.service';
import { MatTableModule } from '@angular/material/table';

import * as moment_ from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AuditDetailDialog } from '../audit-detail/audit-detail.component';
const moment = moment_;

import { ExportToCsv } from 'export-to-csv';

import { ContentApiService } from '@alfresco/aca-shared';

import { MatProgressBarModule } from '@angular/material/progress-bar';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PageLayoutModule } from '@alfresco/aca-shared';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { TranslateService } from '@ngx-translate/core';
// import { addDays, differenceInDays } from 'date-fns';

import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatButtonModule } from '@angular/material/button';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DocumentListModule,
    TemplateModule,
    TemplateModule,
    DataTableModule,
    ExtensionsModule,
    ContextActionsDirective,
    PaginationDirective,
    PageLayoutComponent,
    ToolbarComponent,
    PaginationModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    PageLayoutModule,
    MatDatetimepickerModule,
    MatButtonModule
  ],
  selector: 'aca-audit',
  templateUrl: './system-report.component.html',
  styleUrls: ['./system-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SystemReportComponent extends PageComponent implements OnInit {
  onDestroy$: Subject<boolean> = new Subject<boolean>();

  startdate = new FormControl(moment().startOf('day'));
  enddate = new FormControl(moment().startOf('day'));
  maxDate: Date;
  user = '';
  method = '';
  auditEntries: Array<{
    userName: string;
    nodeName: string;
    method: string;
    time: string;
    values: string[];
    entry: any;
  }>;
  pagination = {
    hasMoreItems: false,
    totalItems: 0
  };
  displayedColumns: string[] = ['userName', 'nodeName', 'method', 'time', 'values', 'entry'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  user$: Observable<ProfileState>;
  lockMenuActive = true;
  apiURL: any;
  headers: any;
  auth: any;

  loadingTitle = '';
  loadingDetail = '';

  constructor(
    private contentApi: ContentApiService,
    private networkService: NetworkService,
    public dialog: MatDialog,
    public trans: TranslateService,
  ) {
    super();
    this.enddate.disable();
    this.startdate.valueChanges.subscribe(newValue => {
      this.maxDate = moment(newValue).add(6, 'days').endOf('day').toDate();
      this.enddate.setValue(null);
      this.enddate.enable();
    });
  }


  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getGroup();

    this.trans.get('WORKSPACE-REPORT.LOADING.TITLE').subscribe((translation) => {
      this.loadingTitle = translation;
    });

    this.trans.get('WORKSPACE-REPORT.LOADING.DETAIL').subscribe((translation) => {
      this.loadingDetail = translation;
    });


  }


  async getGroup() {
    const person = await this.contentApi.getPerson('-me-').toPromise();
    const personId = person.entry.id;
    console.log('personId', personId);

    this.networkService.getGroups(personId).subscribe({
      next: (data) => {
        if (data) {
          for (const item of data.list.entries) {
            if (item.entry.id === 'GROUP_Audit_System') {
              this.lockMenuActive = true;
              console.log('lockMenuActive', this.lockMenuActive);
              break;
            }
          }
        }
      },
      error: (error) => {
        console.log('error getGroup:', error);
        console.log(error.error.message);
      }
    });
  }


  public clear() {
    console.log('this.user', this.user);
    console.log('this.method', this.method);
    this.user = '';
    this.method = '';
    this.dataSource.data = [];
    this.startdate = new FormControl(moment().startOf('day'));
    this.enddate = new FormControl(moment().startOf('day'));
    this.pagination = {
      hasMoreItems: false,
      totalItems: 0
    };
  }

  public search() {
    Swal.fire({
      title: this.loadingTitle,
      width: 300,
      html: this.loadingDetail,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        this.networkService.getAuditData(this.buildQuery()).subscribe({
          next: (data) => {
            const tempData = [];
            console.log(' data ', data);
            for (let i = 0; i < data.list.entries.length; i++) {
              const userName = data.list.entries[i].entry.createdByUser ? data.list.entries[i].entry.createdByUser.id : '';
              const nodeName = this.getNodeName(data.list.entries[i].entry);
              const method = this.getMethod(data.list.entries[i].entry);
              const time = data.list.entries[i].entry.createdAt ? moment(data.list.entries[i].entry.createdAt).format('DD-MM-YYYY hh:mm:ss') : '';
              const values = this.getValues(data.list.entries[i].entry);
              const entry = data.list.entries[i].entry;
              tempData.push({
                userName: userName,
                nodeName: nodeName,
                method: method,
                time: time,
                values: values,
                entry: entry
              });
            } // END LOOP

            this.dataSource.data = tempData;
            this.dataSource.paginator = this.paginator;
            this.pagination = data.list.pagination;
            Swal.close();
          },
          error: (error) => {
            console.log('error getAuditData:', error);
            console.log(error.error.message);
            Swal.close();
          }
        });
      }
    });
  }

  openDialog(entry: any) {
    this.dialog.open(AuditDetailDialog, {
      data: entry
    });
  }

  downloadCSV() {
    const d = [];
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'System Report',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };
    const csvExporter = new ExportToCsv(options);

    this.dataSource.data.forEach((entry) => {
      const o = {
        userName: entry.userName,
        nodeName: entry.nodeName,
        method: entry.method,
        time: entry.time,
        values: entry.values
      };
      d.push(o);
    });

    csvExporter.generateCsv(d);
  }

  private buildQuery(): string {
    const queries = [];
    if (this.user) {
      queries.push(`createdByUser='${this.user}'`);
    }
    if (this.method === 'LOGIN') {
      queries.push(`valuesKey='/share-site-access/login/no-error/action' and valuesValue='LOGIN'`);
    } else if (this.method === 'LOGOUT') {
      queries.push(`valuesKey='/share-site-access/logout/action' and valuesValue='LOGOUT'`);
    } else if (this.method === 'LOGINFAILED') {
      queries.push(`valuesKey='/share-site-access/login/error/action' and valuesValue='LOGIN FAILURE'`);
    } else if (this.method === 'SET PROPERTIES') {
      queries.push(`valuesKey='/share-site-access/nodeManage/setProperties/no-error/action' and valuesValue='SET PROPERTIES'`);
    } else if (this.method) {
      queries.push(`valuesKey='/share-site-access/transaction/action' and valuesValue='${this.method}'`);
    }

    if (moment.isMoment(this.startdate.value)) {
      queries.push(`createdAt BETWEEN ('${this.startdate.value.startOf('day').format()}' , '${this.enddate.value.endOf('day').format()}')`);
    } else {
      const momentDate1 = moment(this.startdate.value);
      const formattedDate1 = momentDate1.startOf('day').format();
      const momentDate2 = moment(this.enddate.value);
      const formattedDate2 = momentDate2.endOf('day').format();
      queries.push(`createdAt BETWEEN ('${formattedDate1}' , '${formattedDate2}')`);
    }
    return queries.length > 0 ? `&where=(${queries.join(' and ')})` : '';
  }

  private getMethod(entry: any): string {
    let method = '';
    method = entry.values
      ? entry.values['/share-site-access/transaction/action']
        ? entry.values['/share-site-access/transaction/action']
        : entry.values['/share-site-access/login/no-error/action']
          ? 'LOGIN'
          : entry.values['/share-site-access/logout/action']
            ? 'LOGOUT'
            : entry.values['/share-site-access/login/error/action']
              ? 'LOGIN FAILED'
              : entry.values['/share-site-access/nodeManage/setProperties/no-error/action']
                ? entry.values['/share-site-access/nodeManage/setProperties/no-error/action']
                : ''
      : '';

    return method;
  }

  private getNodeName(entry: any): string {
    let name = '';
    for (const key of Object.keys(entry.values)) {
      if (key.startsWith('/share-site-access/') && key.endsWith('/nodename')) {
        name = entry.values[key];
      }
    }
    return name;
  }

  private getValues(entry: any): string[] {
    const values = [];

    if (entry.values) {
      if (entry.values['/share-site-access/login/no-error/user']) {
        values.push(entry.values['/share-site-access/login/no-error/user']);
      } else if (entry.values['/share-site-access/logout/user']) {
        values.push(entry.values['/share-site-access/logout/user']);
      } else if (entry.values['/share-site-access/login/error/user']) {
        values.push(entry.values['/share-site-access/login/error/user']);
      } else if (entry.values['/share-site-access/transaction/action']) {
        // values.push(entry.values['/alfresco-access/transaction/action'])
        values.push(`NAME : ${entry.values['/share-site-access/transaction/nodename']}`);
        values.push(`PATH : ${entry.values['/share-site-access/transaction/nodepath']}`);
        values.push(`SITE : ${entry.values['/share-site-access/transaction/site']}`);
      } else if (entry.values['/share-site-access/nodeManage/setProperties/no-error/action']) {
        values.push(`SET PROPERTIES`);
      }
    }

    return values;
  }
}
