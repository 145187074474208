<ng-container>
  <ejs-toolbar cssClass="custom-toolbar"> </ejs-toolbar>
  <ejs-pdfviewer
    id="pdfViewer"
    [documentPath]="document"
    (documentLoad)="documentLoaded($event)"
    [toolbarSettings]="toolbarSettings"
    style="height: 97vh; display: block"
  ></ejs-pdfviewer>
</ng-container>
