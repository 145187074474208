/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActionEventArgs, EditSettingsModel, GridComponent, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { ApprovalWorkflowService } from '../../../service/approval-workflow.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@alfresco/adf-content-services';

@Component({
  selector: 'app-grid-group',
  templateUrl: './grid-group.component.html',
  styleUrls: ['./grid-group.component.scss']
})
export class GridGroupComponent implements OnInit {

  constructor(private workflowService: ApprovalWorkflowService, private dialogRef: MatDialog) { }

  async ngOnInit() {
    console.log('# GridGroupComponent');

    this.editSettingsGroup = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
    this.editSettingsSubGroup = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
    this.toolbarGroup = ['Add', 'Edit', 'Delete'];
    this.toolbarSubGroup = ['Edit', 'Delete'];

    await this.getGroup();
  }

  // Group & Sub-Group
  public dataGroup;
  public dataSubGroup;

  // Component Grid
  public editSettingsGroup?: EditSettingsModel;
  public toolbarGroup?: ToolbarItems[];

  public toolbarSubGroup?: ToolbarItems[];
  public editSettingsSubGroup?: EditSettingsModel;


  public selectedRecords: any[] = [];

  public selectedSubGroupRecords: any[] = [];

  @ViewChild('gridGroup')
  public gridGroup: GridComponent;

  @ViewChild('gridSubGroup')
  public gridSubGroup: GridComponent;


  public requiredRules = { required: true };

  selectGroupId = 0;
  selectGroupName = '';
  groupNameHeaderText = 'SUB-GROUP';


  public pageSettings = { pageSize: 10 };

  // ======================== Sub-Group ==========================
  public async getGroup() {
    const response = await this.workflowService.getGroup().toPromise();
    this.dataGroup = response.data;

    this.gridGroup.clearSelection();
    this.gridGroup.refresh();
  }

  public async actionCompleteGroup(args: any) {

    // this.gridGroup.clearSelection();

    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog = (args as any).dialog;
      dialog.showCloseIcon = false;
      dialog.height = 250;
      dialog.width = 400;
      dialog.header = 'Edit Form';
    }


    if (args.requestType === 'save' && args.action === 'add') {
      const param = {
        'group_name': args.data.group_name,
      }
      await this.createGroup(param);
    }

    if (args.requestType === 'save' && args.action === 'edit') {
      const param = {
        'group_id': args.data.group_id,
        'group_name': args.data.group_name,
      }
      await this.updateGroup(param);
    }


  }

  public actionBeginGroup(args: ActionEventArgs): void {
    if (args.requestType === 'delete') {
      args.cancel = true;
      this.selectedRecords = this.gridGroup.getSelectedRecords();
      const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirmation',
          message: 'Are you sure you want to proceed?'
        },
        minWidth: '250px'
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.deleteRecordGroup();
        }
      });
    }
  }

  private async deleteRecordGroup(): Promise<void> {
    console.log('# deleteRecords', this.selectedRecords[0].group_id);
    this.selectedRecords.forEach((record: any) => {
      const index = (this.dataGroup as any[]).findIndex(
        item => item.group_id === record.group_id
      );
      if (index > -1) {
        (this.dataGroup as any[]).splice(index, 1);
      }
    });

    await this.deleteGroup(this.selectedRecords[0].group_id);
    this.gridGroup.clearSelection();
    this.gridGroup.refresh();
    this.selectedRecords = [];
  }


  public async rowSelectedGroup(args: any): Promise<void> {
    if (!args.data.group_id) {
      return
    }
    console.log('# rowSelectedGroup ', args.data);
    const groupName = args.data.group_name;
    this.groupNameHeaderText = 'SUB-GROUP : ' + groupName;
    this.selectGroupId = args.data.group_id;
    this.selectGroupName = args.data.group_name;
    this.toolbarSubGroup = ['Add', 'Edit', 'Delete'];
    await this.getSubGroup();
  }


  //  Create & Delete & Update

  async createGroup(param: any) {
    await this.workflowService.createGroup(param).subscribe(async () => {
      console.log('Create Group Success');
      await this.getGroup();
    }, error => {
      alert('บันทึกข้อมูลไม่สำเร็จ');
      console.log('error ', error)
    });

  }

  async deleteGroup(groupId: any) {
    try {
      await this.workflowService.deleteGroup(groupId).toPromise();
      console.log('Delete Group Success');
      await this.getGroup();
    } catch (error) {
      alert('ลบข้อมูลไม่สำเร็จ');
      console.log('error ', error);
    }

  }


  async updateGroup(param: any) {
    try {
      await this.workflowService.updateGroup(param).toPromise();
      console.log('Update Group Success');
      await this.getGroup();
    } catch (error) {
      alert('แก้ไขข้อมูลไม่สำเร็จ');
      console.log('error ', error);
    }

  }




  // ======================== Sub-Group ==========================

  async getSubGroup() {
    const response = await this.workflowService.getSubGroup(this.selectGroupId).toPromise();
    this.dataSubGroup = response.data;
  }

  async actionCompleteSubGroup(args: any) {
    const gridSubGroup = (this as any).gridSubGroup;
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog = (args as any).dialog;
      dialog.showCloseIcon = true;
      dialog.height = 210;
      dialog.width = 400;
      dialog.header = 'Edit Form';
    }

    if (args.requestType === 'save' && args.action === 'add') {
      const param = {
        'sub_group_name': args.data.sub_group_name,
        'group_id': this.selectGroupId,
        'group_name': this.selectGroupName
      };
      await this.createSubGroup(param, gridSubGroup, args);
    }


    if (args.requestType === 'save' && args.action === 'edit') {
      const param = {
        'sub_group_id': args.data.sub_group_id,
        'sub_group_name': args.data.sub_group_name,
      }
      await this.updateSubGroup(param);
    }

  }

  removeRow(grid: any, args: any) {
    if (grid) {
      grid.closeEdit();
      const dataSource = [...this.dataSubGroup];
      const index = dataSource.findIndex(item =>
        item.sub_group_name === args.data.sub_group_name
      );
      if (index > -1) {
        dataSource.splice(index, 1);
        this.dataSubGroup = dataSource;
      }
    }
  }

  public actionBeginSubGroup(args: ActionEventArgs): void {
    if (args.requestType === 'delete') {
      args.cancel = true;
      this.selectedSubGroupRecords = this.gridSubGroup.getSelectedRecords();
      const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirmation',
          message: 'Are you sure you want to proceed?'
        },
        minWidth: '250px'
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.deleteRecordSubGroup();
        }
      });
    }
  }

  private async deleteRecordSubGroup(): Promise<void> {
    console.log('# deleteRecordSubGroup', this.selectedSubGroupRecords[0].sub_group_id);
    this.selectedSubGroupRecords.forEach((record: any) => {
      const index = (this.dataSubGroup as any[]).findIndex(
        item => item.sub_group_id === record.sub_group_id
      );
      if (index > -1) {
        (this.dataSubGroup as any[]).splice(index, 1);
      }
    });

    await this.deleteSubGroup(this.selectedSubGroupRecords[0].sub_group_id);

    this.gridSubGroup.clearSelection();
    this.gridSubGroup.refresh();
    this.selectedSubGroupRecords = [];
  }



  //  Create & Delete & Update
  async createSubGroup(param: any, gridSubGroup: any, args: any) {
    try {
      await this.workflowService.createSubGroup(param).toPromise();
      console.log('Create Sub-Group Success');
      await this.getSubGroup();
    } catch (error) {
      this.removeRow(gridSubGroup, args)
      alert('บันทึกข้อมูลไม่สำเร็จ');
      console.log('error ', error);
    }

  }

  async updateSubGroup(param: any) {
    try {
      await this.workflowService.updateSubGroup(param).toPromise();
      console.log('Update Sub-Group Success');
      await this.getSubGroup();
    } catch (error) {
      alert('แก้ไขข้อมูลไม่สำเร็จ');
      console.log('error ', error);
    }

  }

  async deleteSubGroup(subGroupId: any) {
    try {
      await this.workflowService.deleteSubGroup(subGroupId).toPromise();
      console.log('Delete Sub-Group Success');
      await this.getGroup();
    } catch (error) {
      alert('ลบข้อมูลไม่สำเร็จ');
      console.log('error ', error);
    }

  }


}
