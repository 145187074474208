/* eslint-disable curly */
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { ApprovalWorkflowService } from '../../../service/approval-workflow.service';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import {
  EditService, FilterService, GridComponent, GridModule, PageService,
  // RowSelectEventArgs,
  RowSelectingEventArgs, SelectionSettingsModel, ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@alfresco/adf-content-services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';


import {
  AppStore,
  SnackbarErrorAction,
  SnackbarInfoAction,
} from '@alfresco/aca-shared/store';
import { UserLibService } from '../user-library.service';


// interface User {
//   userId: string;
// }


@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    GridModule,
    TextBoxModule,
    DropDownListModule,
    ButtonModule,
    DialogModule,
  ],
  templateUrl: './add-user.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./add-user.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [EditService, ToolbarService, PageService, FilterService],
  host: { class: 'aca-adduser' }
})
export class AddUserComponent implements OnInit {

  private inputSubject: Subject<any> = new Subject();

  public data: any[] = [];

  public selectionOptions?: SelectionSettingsModel;

  @ViewChild('grid') grid?: GridComponent;


  dataUserSelected: any;

  public dataRole: string[] = ['SitePreview', 'SiteCanDownloadWatermark','SiteConsumer', 'SiteContributor', 'SiteCollaborator', 'SiteManager'];

  constructor(
    private userLibService: UserLibService,
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public dataSelected: any,
    private store: Store<AppStore>
  ) {
    this.inputSubject.pipe(
      debounceTime(300)
    ).subscribe(event => this.handleInputChange(event));


    this.dataUserSelected = dataSelected;



  }

  async ngOnInit(): Promise<void> {
    console.log('# AddUserComponent ');
    // this.selectionOptions = { type: 'Single', checkboxMode: 'ResetOnRowClick' };
    this.selectionOptions = { type: 'Single', checkboxMode: 'ResetOnRowClick' };


    await this.getPeople();
  }

  searchPeople(searchTerm: string, data: any) {
    return data.filter(person =>
      person.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      person.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  dataTemp = [];
  async getPeople() {
    console.log('getUser');
    const response = await this.userLibService.getPeople().toPromise();
    const dataEntry = response.list.entries.map(entry => entry.entry);


    const result = dataEntry
      .map((user) => ({
        userId: user.id,
        displayName: user.displayName,
        email: user.email
      }));


    this.data = result;
    this.dataTemp = result;

  }

  rowSelecting(args: RowSelectingEventArgs): void {

    console.log('this.dataUserSelected', this.dataUserSelected);

    // if (args.target?.classList.contains('e-icons'))
    //   (this.grid as GridComponent).clearSelection();

    // const selectedRowData = args.data as User;


    if (args.target?.classList.contains('e-icons')) {
      (this.grid as GridComponent).clearSelection();
    }

    // if (this.dataUserSelected.some(user => user.userId === selectedRowData.userId)) {
    //   alert('This user is already selected.');
    //   args.cancel = true;
    // } else {
    //   if (args.target?.classList.contains('e-icons')) {
    //     (this.grid as GridComponent).clearSelection();
    //   }
    // }
  }



  onInputChange(event: any): void {
    this.inputSubject.next(event);
  }

  onEnterPress(event: any): void {
    const value = event.value;
    console.log('Enter pressed with value:', value);
  }

  handleInputChange(event: any): void {
    console.log('Input change:', event);
    const value = event.value;
    if (value.length >= 3) {
      console.log('Searching for:', value);
      const results = this.searchPeople(value, this.dataTemp);
      this.data = results;
    } else {
      this.data = this.dataTemp;
    }
  }

  isCancel(): void {
    this.dialogRef.close();
  }


  public selectRole: string = '';
  isOk() {
    if (!this.selectRole) {
      this.alertError('Please select role', null)
      return;
    }
    if (this.grid) {
      const selectedRecords = this.grid.getSelectedRecords();
      const value = {
        selectedRecords,
        role: this.selectRole
      }
      this.dialogRef.close(value);
    } else {
      this.dialogRef.close(null);
    }
  }

  alertError(errorTxt: string, error: any) {
    if (error) {
      this.store.dispatch(new SnackbarErrorAction(errorTxt + ':' + error, { duration: 1000 }));
    } else {
      this.store.dispatch(new SnackbarErrorAction(errorTxt, { duration: 1000 }));
    }
  }

  alertInfo(infoTxt: string) {
    this.store.dispatch(new SnackbarInfoAction(infoTxt, { duration: 1000 }));
  }

}
