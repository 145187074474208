/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';

import { NodeEntry, Node, PathElement, PathInfo, ResultSetPaging } from '@alfresco/js-api';

import {
  AppHookService,
  ContextActionsDirective,
  InfoDrawerComponent,
  PageComponent,
  PageLayoutComponent,
  PaginationDirective,
  ToolbarComponent
} from '@alfresco/aca-shared';
import { DocumentListPresetRef, ExtensionsModule } from '@alfresco/adf-extensions';
import { CommonModule } from '@angular/common';
import { DocumentListModule } from '@alfresco/adf-content-services';
import { DataTableModule, PaginationModule, TemplateModule } from '@alfresco/adf-core';
import { DocumentListDirective } from '../../directives/document-list.directive';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { HttpHeaders } from '@angular/common/http';
// import { NodePaging } from '@alfresco/js-api';

import { ContentApiService } from '@alfresco/aca-shared';
// import { BeflexWorkspaceService } from '../service/beflex-workspace.service';
import { from } from 'rxjs';
// import { NavigateLibraryAction } from '@alfresco/aca-shared/store';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DocumentListModule,
    DocumentListDirective,
    ContextActionsDirective,
    TemplateModule,
    DataTableModule,
    ExtensionsModule,
    PaginationModule,
    InfoDrawerComponent,
    PaginationDirective,
    PageLayoutComponent,
    TranslateModule,
    ToolbarComponent
  ],
  templateUrl: './shared-files.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SharedFilesComponent extends PageComponent implements OnInit {
  columns: DocumentListPresetRef[] = [];

  headers: any;
  apiURL: any;

  // data: NodePaging;
  totalResults = 0;
  isLoading = false;
  currentFolderId = '';

  data: ResultSetPaging;

  constructor(
    private contentApi: ContentApiService,
    private appHookService: AppHookService,
    private apiService: AlfrescoApiService,
    // private httpClient: HttpClient,
    // private beflexWorkspaceService: BeflexWorkspaceService
  ) {
    super();

    this.apiURL = '';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.columns = this.extensions.documentListPresets.shared || [];
    // this.extensions.documentListPresets.shared || [];
    this.loadSharedLink2();
    this.subscriptions = this.subscriptions.concat([
      this.appHookService.linksUnshared.pipe(debounceTime(300)).subscribe(() => this.reload()),
      this.uploadService.fileUploadComplete.pipe(debounceTime(300)).subscribe(() => this.reload()),
      this.uploadService.fileUploadDeleted.pipe(debounceTime(300)).subscribe(() => this.reload())
    ]);
  }

  loadSharedLink2() {
    this.isLoading = true;
    try {
      this.getSharedLink2().subscribe(
        (response) => {
          if (response) {

            const resResult = response;

            if (resResult.list?.entries) {
              resResult.list.entries.forEach((entry: any) => {
                if (!entry.entry.content) {
                  entry.entry.isFolder = true;
                }
              });
            }

            this.onSearchResultLoaded(resResult);
            this.isLoading = false;
          }
        },
        (error) => {
          console.log('Error getSharedLink2():', error);
          this.isLoading = false;
        }
      );
    } catch (error) {
      console.log('Error ngOnInit():', error);
      this.isLoading = false;
    }
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.apiService.getInstance().contentClient.callApi(path, httpMethod, ...params);
  }

  getSharedLink2(): Observable<any> {
    const pathService = `/shared-links2?skipCount=0&maxItems=25&include=path,properties,allowableOperations,permissions,aspectNames`
    const httpMethod = 'GET';
    return from(this.publicApiCall(pathService, httpMethod, [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]));
  }




  onSearchResultLoaded(nodePaging: ResultSetPaging) {
    this.data = nodePaging;
    this.totalResults = this.getNumberOfResults();
  }

  getNumberOfResults() {
    if (this.data && this.data.list && this.data.list.pagination) {
      return this.data.list.pagination.totalItems;
    }
    return 0;
  }

  navigate(nodeIdx: any) {
    const nodeId = nodeIdx;
    const isSitePath = (path: PathInfo): boolean => path && path.elements && path.elements.some(({ name }: PathElement) => name === 'Sites');
    this.contentApi
      .getNode(nodeId)
      .pipe(map((node) => node.entry))
      .subscribe(({ path }: Node) => {
        const routeUrl = isSitePath(path) ? '/libraries' : '/personal-files';
        this.router.navigate([routeUrl, nodeId]);
      });
  }

  onNodeDoubleClick(node: NodeEntry) {
    if (node && node.entry) {
      if (node.entry) {
        this.navigate(node.entry['nodeId']);
      }
    }
  }

  handleNodeClick(event: Event) {
    this.onNodeDoubleClick((event as CustomEvent).detail?.node);
  }
}
