export const CONTRACT_DATA_DOCUMENT: object[] = [
];


export const CONTRACT_DATA_ITEM: object[] = [
  {
    listNo: '1', fileName: 'abc.pdf', depositAccNumber: '990', contractPairName: 'บริษัท เอบีซี จำกัด',
    createDate: '8 Mar 2024', editDate: '15 Mar 2024', notificationEmail: 'parin.ch@bcircle.co.th',
    docOwner: 'Parin Chatkaewpaisarn', department: 'TCAP',
    expDate: '8 Mar 2025', contractUpdate: 'x', status: 'Approved', view: 'x', download: 'x',
  }
];

