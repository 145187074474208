/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { NgModule } from '@angular/core';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { MultiSelectModule,DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns'
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons'
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons'
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts'

import {
  ContextActionsDirective,
  PageLayoutComponent,
  PaginationDirective,
  ToolbarComponent
} from '@alfresco/aca-shared';



@NgModule({
  imports: [
    GridModule,
    MultiSelectModule,
    DropDownListAllModule,
    CheckBoxModule,
    DateRangePickerModule,
    ButtonModule,
    ChartModule,
    AccumulationChartModule,
    ContextActionsDirective,
    PageLayoutComponent,
    PaginationDirective,
    ToolbarComponent,
  ],
  exports: [
    GridModule,
    MultiSelectModule,
    DropDownListAllModule,
    CheckBoxModule,
    DateRangePickerModule,
    ButtonModule,
    ChartModule,
    AccumulationChartModule,
    ContextActionsDirective,
    PageLayoutComponent,
    PaginationDirective,
    ToolbarComponent,
  ]
})
export class SyncfuctionsModule {
}
