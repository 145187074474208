import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { DOCUMENT_EXPIRE_ACTION, DocumentExpireAction } from '../actions/document-expire.actions';
import { DocumentExpireComponent } from '../dialog/document-expire.component';
import { MatDialog } from '@angular/material/dialog';
import { DocumentExpireService } from '../dialog/document-expire.service';

@Injectable()
export class DocumentExpireEffects {
  constructor(private actions$: Actions, private dialog: MatDialog, private documentExpireService: DocumentExpireService) { }

  @Effect({ dispatch: false })
  documentExpire$ = this.actions$.pipe(
    ofType<DocumentExpireAction>(DOCUMENT_EXPIRE_ACTION),
    map((action) => {
      if (action.payload) {

        this.dialog.open(DocumentExpireComponent, {
          data: { action: action.payload }
        });
        this.documentExpireService.onActionTakeOwnership(action.payload);
      }
    })
  );
}
