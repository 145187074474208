<div class="search-result-row">
  <!-- File/Folder Name - only show if name exists -->
  <div class="name-section" *ngIf="name">
    <span
      tabindex="0"
      role="link"
      *ngIf="isFile"
      (click)="showPreview($event)"
      (keyup.enter)="showPreview($event)"
      class="aca-link"
      [innerHtml]="name | highlight : rudeWordList">
      {{ name$ | async }}
    </span>

    <span
      tabindex="0"
      role="link"
      *ngIf="!isFile"
      (click)="navigate($event)"
      (keyup.enter)="navigate($event)"
      class="bold aca-link"
      [innerHTML]="name | highlight : rudeWordList">
    </span>

    <!-- Title - only show if title exists -->
    <!-- <span *ngIf="title" class="title" [innerHTML]="title | highlight : rudeWordList">
      {{ title$ | async }}
    </span> -->
  </div>

  <!-- Description & Content - only show sections if they exist -->
  <div class="details-section" *ngIf="hasDescription || hasContent">
    <p *ngIf="hasDescription" class="text-content" [innerHTML]="description | highlight : rudeWordList"></p>
    <p *ngIf="hasContent" class="text-content" [innerHTML]="content | highlight : rudeWordList"></p>
  </div>

  <!-- Location - only show if context exists -->
  <div class="location-section" *ngIf="context">
    <aca-location-link [context]="context" [showLocation]="true"></aca-location-link>
  </div>

</div>
