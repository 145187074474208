<h2 mat-dialog-title>New Group</h2>
<mat-dialog-content>
  <form id="formId" [formGroup]="reactForm" #formDir="ngForm" class="form-horizontal" novalidate>
    <div class="form-group">
      <label for="identifier">Identifier:</label>
      <ejs-textbox id="identifier" name="identifier" formControlName="identifier" required></ejs-textbox>
    </div>

    <div class="form-group">
      <label for="displayName">Display Name:</label>
      <ejs-textbox id="displayName" name="displayName" formControlName="displayName" required></ejs-textbox>
    </div>
  </form>

  <div mat-dialog-actions style="text-align: right">
    <button *ngIf="!updateGroupId" ejs-button class="rounded-button" (click)="createGroup()" [disabled]="reactForm.invalid">Create Group</button>
    <button *ngIf="updateGroupId" ejs-button class="rounded-button" (click)="onUpdate()" [disabled]="reactForm.invalid">Update Group</button>
    <button ejs-button class="rounded-button" (click)="onCancel()">Cancel</button>
  </div>
</mat-dialog-content>
